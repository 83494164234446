interface AboutMainProps {
	props?: string;
}

const AboutMain: React.FC<AboutMainProps> = ({ props }) => {
	return (
		<>
			<h2>Division 2 Build Station and Calculator</h2>
			<p>Credits: </p>
			<p>As of TU16 (PTS): </p>
			<p>Version: </p>
			<p>Change Log:</p>
		</>
	);
};

export default AboutMain;
