import {
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
	IonNote,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
	calculatorOutline,
	calculatorSharp,
	constructOutline,
	constructSharp,
	informationCircleOutline,
	informationCircleSharp,
	libraryOutline,
	librarySharp,
	personCircleOutline,
	personCircleSharp,
	statsChartOutline,
	statsChartSharp,
} from "ionicons/icons";
import "./Menu.css";
import { useContext } from "react";
import AuthContext from "../../context/auth-context";

interface AppPage {
	url: string;
	iosIcon: string;
	mdIcon: string;
	title: string;
}

const appPages: AppPage[] = [
	{
		title: "Build Station",
		url: "/home",
		iosIcon: constructOutline,
		mdIcon: constructSharp,
	},
	{
		title: "Stats",
		url: "/stats",
		iosIcon: statsChartOutline,
		mdIcon: statsChartSharp,
	},
	{
		title: "Calculations",
		url: "/calculations",
		iosIcon: calculatorOutline,
		mdIcon: calculatorSharp,
	},
	{
		title: "Library",
		url: "/library",
		iosIcon: libraryOutline,
		mdIcon: librarySharp,
	},
	{
		title: "Profile",
		url: "/profile",
		iosIcon: personCircleOutline,
		mdIcon: personCircleSharp,
	},
	{
		title: "About",
		url: "/about",
		iosIcon: informationCircleOutline,
		mdIcon: informationCircleSharp,
	},
];

// const labels = ["Family", "Friends", "Notes", "Work", "Travel", "Reminders"];

const Menu: React.FC = () => {
	const ctxAuth = useContext(AuthContext);
	const location = useLocation();

	return (
		<IonMenu contentId="main" type="overlay">
			<IonContent>
				<IonList id="inbox-list">
					<IonListHeader>Build Station</IonListHeader>
					<IonNote>{ctxAuth.user.username}</IonNote>
					{appPages.map((appPage, index) => {
						return (
							<IonMenuToggle key={index} autoHide={false}>
								<IonItem
									className={
										location.pathname === appPage.url
											? "selected"
											: ""
									}
									routerLink={appPage.url}
									routerDirection="none"
									lines="none"
									detail={false}
								>
									<IonIcon
										slot="start"
										ios={appPage.iosIcon}
										md={appPage.mdIcon}
									/>
									<IonLabel>{appPage.title}</IonLabel>
								</IonItem>
							</IonMenuToggle>
						);
					})}
				</IonList>

				{/* <IonList id="labels-list">
					<IonListHeader>Labels</IonListHeader>
					{labels.map((label, index) => (
						<IonItem lines="none" key={index}>
							<IonIcon slot="start" icon={bookmarkOutline} />
							<IonLabel>{label}</IonLabel>
						</IonItem>
					))}
				</IonList> */}
			</IonContent>
		</IonMenu>
	);
};

export default Menu;
