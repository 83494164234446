import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonImg,
	IonItem,
	IonRow,
	IonThumbnail,
} from "@ionic/react";
import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import AppContext from "../../context/app-context";
import BuildGear from "./BuildGear";
import BuildWeapon from "./BuildWeapon";

import css from "./BuildMain.module.css";

interface BuildMainProps {
	props?: string;
}

const weaponSlots = ["primary", "secondary", "pistol"];
const gearSlots = ["mask", "backpack", "vest", "gloves", "holster", "kneepads"];

const BuildMain: React.FC<BuildMainProps> = ({ props }) => {
	const ctxApp = useContext(AppContext);
	const { buildId } = useParams<{ buildId: string }>();

	const [spec, setSpec] = useState("");

	const [cardHeaderClassName, setCardHeaderClassName] =
		useState("specHeader");
	const [cardHeaderClassNameWeapon, setCardHeaderClassNameWeapon] =
		useState("");

	useEffect(() => {
		if (ctxApp.specializationStats.name !== "") {
			setSpec(ctxApp.specializationStats.name.toLowerCase());
			setCardHeaderClassName(css["specHeader"]);
			setCardHeaderClassNameWeapon(
				css[ctxApp.specializationStats.name.toLowerCase() + "Weapon"]
			);
		}
	}, [ctxApp.specializationStats.name]);

	return (
		<>
			<IonGrid>
				<IonRow>
					<IonCol className="spec" size="8">
						<IonCard>
							<IonCardHeader
								className={`${cardHeaderClassName} ${cardHeaderClassNameWeapon}`}
							>
								<img
									className={css.specIconImage}
									alt=""
									src={`assets/images/${spec}.png`}
								/>
								<img
									className={css.specAmmoImage}
									alt=""
									src={`assets/images/${spec}-ammo.png`}
								/>
								<IonCardSubtitle>&nbsp;</IonCardSubtitle>
								<IonCardTitle></IonCardTitle>
							</IonCardHeader>

							<IonCardContent className="ion-no-padding"></IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol>Spec Grenade - {buildId}</IonCol>
				</IonRow>
				<IonRow>
					{weaponSlots.map((slot, index) => {
						return (
							<Fragment key={index}>
								<BuildWeapon slot={slot} />
							</Fragment>
						);
					})}
					<IonCol
						className="ion-no-padding"
						size="12"
						size-lg="6"
						size-md="6"
						size-sm="6"
					></IonCol>
					{gearSlots.map((slot, index) => {
						return (
							<Fragment key={index}>
								<BuildGear slot={slot} />
							</Fragment>
						);
					})}
				</IonRow>
				{/* <IonRow>
					<IonCol>Mask</IonCol>
					<IonCol>Backpack</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>Vest</IonCol>
					<IonCol>Gloves</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>Holster</IonCol>
					<IonCol>Kneepads</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>Skill 1</IonCol>
					<IonCol>Skill 2</IonCol>
				</IonRow> */}

				{/* <IonRow>
							<IonCol size="8">ion-col size="6"</IonCol>
							<IonCol>ion-col</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>ion-col</IonCol>
							<IonCol>ion-col</IonCol>
							<IonCol>ion-col</IonCol>
						</IonRow>

						<IonRow>
							<IonCol size="3">ion-col size="3"</IonCol>
							<IonCol>ion-col</IonCol>
							<IonCol size="3">ion-col size="3"</IonCol>
						</IonRow>

						<IonRow>
							<IonCol size="3">ion-col size="3"</IonCol>
							<IonCol size="3" offset="3">
								ion-col size="3" offset="3"
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol>ion-col</IonCol>
							<IonCol>
								ion-col
								<br />#
							</IonCol>
							<IonCol>
								ion-col
								<br />#
								<br />#
							</IonCol>
							<IonCol>
								ion-col
								<br />#
								<br />#
								<br />#
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol className="ion-align-self-start">
								ion-col start
							</IonCol>
							<IonCol className="ion-align-self-center">
								ion-col center
							</IonCol>
							<IonCol className="ion-align-self-end">
								ion-col end
							</IonCol>
							<IonCol>
								ion-col
								<br />#
								<br />#
							</IonCol>
						</IonRow>

						<IonRow className="ion-align-items-start">
							<IonCol>start ion-col</IonCol>
							<IonCol>start ion-col</IonCol>
							<IonCol className="ion-align-self-end">
								start ion-col end
							</IonCol>
							<IonCol>
								ion-col
								<br />#
								<br />#
							</IonCol>
						</IonRow>

						<IonRow className="ion-align-items-center">
							<IonCol>center ion-col</IonCol>
							<IonCol>center ion-col</IonCol>
							<IonCol>center ion-col</IonCol>
							<IonCol>
								ion-col
								<br />#
								<br />#
							</IonCol>
						</IonRow>

						<IonRow className="ion-align-items-end">
							<IonCol>end ion-col</IonCol>
							<IonCol className="ion-align-self-start">
								end ion-col start
							</IonCol>
							<IonCol>end ion-col</IonCol>
							<IonCol>
								ion-col
								<br />#
								<br />#
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol size="12" size-sm>
								ion-col size="12" size-sm
							</IonCol>
							<IonCol size="12" size-sm>
								ion-col size="12" size-sm
							</IonCol>
							<IonCol size="12" size-sm>
								ion-col size="12" size-sm
							</IonCol>
							<IonCol size="12" size-sm>
								ion-col size="12" size-sm
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol size="12" size-md>
								ion-col size="12" size-md
							</IonCol>
							<IonCol size="12" size-md>
								ion-col size="12" size-md
							</IonCol>
							<IonCol size="12" size-md>
								ion-col size="12" size-md
							</IonCol>
							<IonCol size="12" size-md>
								ion-col size="12" size-md
							</IonCol>
						</IonRow>

						<IonRow>
							<IonCol size="6" size-lg offset="3">
								ion-col size="6" size-lg offset="3"
							</IonCol>
							<IonCol size="3" size-lg>
								ion-col size="3" size-lg
							</IonCol>
						</IonRow> */}
			</IonGrid>
		</>
	);
};

export default BuildMain;
