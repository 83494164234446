import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { useContext, useEffect, useState } from "react";

import AppContext from "../../context/app-context";
import GearStats from "../../models/gearStats";

interface GearExpertiseProps {
	slot: string;
}

const expertiseOptions = Array.from({ length: 21 }, (_, index) => index);

const GearExpertise = ({ slot }: GearExpertiseProps) => {
	const ctxApp = useContext(AppContext);
	const [selectedExpertise, setSelectedExpertise] = useState<
		number | undefined
	>(20);

	const expertiseChangeHandler = (e: CustomEvent) => {
		// console.log(e.detail.value);
		setSelectedExpertise(e.detail.value);
		ctxApp.updateGearStat("expertise", slot, e.detail.value, "number");
	};

	const interfaceOptions = {
		cssClass: "my-custom-interface",
	};

	useEffect(() => {
		function checkValue(gearStats: GearStats) {
			setSelectedExpertise((currValue) => {
				if (gearStats.expertise >= 0) {
					return gearStats.expertise;
				} else {
					return currValue;
				}
			});
		}
		switch (slot.toLowerCase()) {
			case "mask":
				if (ctxApp.maskGear.name) {
					checkValue(ctxApp.maskStats);
				}
				break;
			case "backpack":
				if (ctxApp.backpackGear.name) {
					checkValue(ctxApp.backpackStats);
				}
				break;
			case "vest":
				if (ctxApp.vestGear.name) {
					checkValue(ctxApp.vestStats);
				}
				break;
			case "gloves":
				if (ctxApp.glovesGear.name) {
					checkValue(ctxApp.glovesStats);
				}
				break;
			case "holster":
				if (ctxApp.holsterGear.name) {
					checkValue(ctxApp.holsterStats);
				}
				break;
			case "kneepads":
				if (ctxApp.kneepadsGear.name) {
					checkValue(ctxApp.kneepadsStats);
				}
				break;
		}
	}, [
		ctxApp.backpackGear.name,
		ctxApp.backpackStats,
		ctxApp.glovesGear.name,
		ctxApp.glovesStats,
		ctxApp.holsterGear.name,
		ctxApp.holsterStats,
		ctxApp.kneepadsGear.name,
		ctxApp.kneepadsStats,
		ctxApp.maskGear.name,
		ctxApp.maskStats,
		ctxApp.vestGear.name,
		ctxApp.vestStats,
		slot,
	]);

	return (
		<>
			<IonItem class="ion-no-padding">
				<IonLabel class="ion-no-padding">Expertise: </IonLabel>
				<IonSelect
					interfaceOptions={interfaceOptions}
					className="weaponInput"
					interface="popover"
					placeholder="20"
					value={selectedExpertise}
					onIonChange={expertiseChangeHandler}
				>
					{expertiseOptions.map((expertise) => {
						return (
							<IonSelectOption
								key={expertise}
								value={expertise}
								class="brown-option"
							>
								{expertise}
							</IonSelectOption>
						);
					})}
				</IonSelect>
			</IonItem>
		</>
	);
};

export default GearExpertise;
