import {
	IonItem,
	IonLabel,
	IonList,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import TalentsData from "../../data/TalentsData";
import Talent from "../../models/talent";

let initTalents: Talent[] = TalentsData;
initTalents = initTalents.sort(function (a, b) {
	const nameA = a.name.toUpperCase(); // ignore upper and lowercase
	const nameB = b.name.toUpperCase(); // ignore upper and lowercase
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
});

interface GearTalentProps {
	slot: string;
}

interface SelectChangeEventDetail<T = any> {
	value: T;
}

const GearTalent = ({ slot }: GearTalentProps) => {
	const ctxApp = useContext(AppContext);
	const [currentTalents, setCurrentTalents] = useState(initTalents);
	const [selectedTalent, setSelectedTalent] = useState("N/A");
	const [selectDisabled, setSelectDisabled] = useState(true);

	const talentChangeHandler = (e: CustomEvent<SelectChangeEventDetail>) => {
		// console.log(e.detail.value);
		setSelectedTalent(e.detail.value);
		ctxApp.updateGearStat("talent", slot, e.detail.value);
	};

	const interfaceOptions = {
		cssClass: "my-custom-interface",
	};

	useEffect(() => {
		let updatedName = "";
		let updatedTalents = initTalents;
		switch (slot.toLowerCase()) {
			case "mask":
				if (ctxApp.maskGear.name) {
					if (ctxApp.maskGear.quality === "Exotic") {
						const exoticTalent = initTalents.filter((talent) => {
							return talent.name === ctxApp.maskGear.talent;
						});
						setSelectDisabled(true);
						setSelectedTalent(exoticTalent[0].name);
						setCurrentTalents(exoticTalent);
					}
				}
				break;
			case "backpack":
				if (ctxApp.backpackGear.name) {
					if (ctxApp.backpackGear.quality === "Exotic") {
						const exoticTalent = initTalents.filter((talent) => {
							return talent.name === ctxApp.backpackGear.talent;
						});
						setSelectDisabled(true);
						setSelectedTalent(exoticTalent[0].name);
						setCurrentTalents(exoticTalent);
					} else if (ctxApp.backpackGear.quality === "Named") {
						const namedTalent = initTalents.filter((talent) => {
							return (
								talent.quality === "N" &&
								talent.slot === "Backpack" &&
								talent.name === ctxApp.backpackGear.talent
							);
						});
						setSelectDisabled(true);
						setSelectedTalent(namedTalent[0].name);
						setCurrentTalents(namedTalent);
					} else if (ctxApp.backpackGear.quality === "Gearset") {
						// TODO gearsets
						// console.log(ctxApp.backpackGear.talent);
						const gearsetTalent = initTalents.filter((talent) => {
							return (
								talent.quality === "S" &&
								talent.slot === "Backpack" &&
								talent.name === ctxApp.backpackGear.talent
							);
						});
						setSelectDisabled(true);
						setSelectedTalent(gearsetTalent[0].name);
						setCurrentTalents(gearsetTalent);
					} else {
						updatedTalents = updatedTalents.filter((talent) => {
							return talent.quality === "A";
						});
						setSelectDisabled(false);
						updatedName =
							ctxApp.backpackGear.talent === "A"
								? "Select Talent"
								: ctxApp.backpackGear.talent;
						setSelectedTalent(updatedName);
						setCurrentTalents(updatedTalents);
					}
				}
				break;
			case "vest":
				if (ctxApp.vestGear.name) {
					if (ctxApp.vestGear.quality === "Exotic") {
						const exoticTalent = initTalents.filter((talent) => {
							return talent.name === ctxApp.vestGear.talent;
						});
						setSelectDisabled(true);
						setSelectedTalent(exoticTalent[0].name);
						setCurrentTalents(exoticTalent);
					} else if (ctxApp.vestGear.quality === "Named") {
						const namedTalent = initTalents.filter((talent) => {
							return (
								talent.quality === "N" &&
								talent.slot === "Vest" &&
								talent.name === ctxApp.vestGear.talent
							);
						});
						setSelectDisabled(true);
						setSelectedTalent(namedTalent[0].name);
						setCurrentTalents(namedTalent);
					} else if (ctxApp.vestGear.quality === "Gearset") {
						// TODO gearsets
						// console.log(ctxApp.vestGear.talent);
						const gearsetTalent = initTalents.filter((talent) => {
							return (
								talent.quality === "S" &&
								talent.slot === "Vest" &&
								talent.name === ctxApp.vestGear.talent
							);
						});
						setSelectDisabled(true);
						setSelectedTalent(gearsetTalent[0].name);
						setCurrentTalents(gearsetTalent);
					} else {
						updatedTalents = updatedTalents.filter((talent) => {
							return talent.quality === "A";
						});
						setSelectDisabled(false);
						updatedName =
							ctxApp.vestGear.talent === "A"
								? "Select Talent"
								: ctxApp.vestGear.talent;
						setSelectedTalent(updatedName);
						setCurrentTalents(updatedTalents);
					}
				}
				break;
			case "gloves":
				if (ctxApp.glovesGear.name) {
					if (ctxApp.glovesGear.quality === "Exotic") {
						const exoticTalent = initTalents.filter((talent) => {
							return talent.name === ctxApp.glovesGear.talent;
						});
						setSelectDisabled(true);
						setSelectedTalent(exoticTalent[0].name);
						setCurrentTalents(exoticTalent);
					}
				}
				break;
			case "holster":
				if (ctxApp.holsterGear.name) {
					if (ctxApp.holsterGear.quality === "Exotic") {
						const exoticTalent = initTalents.filter((talent) => {
							return talent.name === ctxApp.holsterGear.talent;
						});
						setSelectDisabled(true);
						setSelectedTalent(exoticTalent[0].name);
						setCurrentTalents(exoticTalent);
					}
				}
				break;
			case "kneepads":
				if (ctxApp.kneepadsGear.name) {
					if (ctxApp.kneepadsGear.quality === "Exotic") {
						const exoticTalent = initTalents.filter((talent) => {
							return talent.name === ctxApp.kneepadsGear.talent;
						});
						setSelectDisabled(true);
						setSelectedTalent(exoticTalent[0].name);
						setCurrentTalents(exoticTalent);
					}
				}
				break;
		}
		// console.log(updatedTalents);
	}, [
		ctxApp.backpackGear.name,
		ctxApp.backpackGear.quality,
		ctxApp.backpackGear.talent,
		ctxApp.glovesGear.name,
		ctxApp.glovesGear.quality,
		ctxApp.glovesGear.talent,
		ctxApp.holsterGear.name,
		ctxApp.holsterGear.quality,
		ctxApp.holsterGear.talent,
		ctxApp.kneepadsGear.name,
		ctxApp.kneepadsGear.quality,
		ctxApp.kneepadsGear.talent,
		ctxApp.maskGear.name,
		ctxApp.maskGear.quality,
		ctxApp.maskGear.talent,
		ctxApp.vestGear.name,
		ctxApp.vestGear.quality,
		ctxApp.vestGear.talent,
		slot,
	]);

	return (
		<>
			<IonList class="ion-no-padding">
				<IonItem class="ion-no-padding">
					<IonLabel position="floating">Talent</IonLabel>
					<IonSelect
						disabled={selectDisabled}
						interfaceOptions={interfaceOptions}
						className="weaponInput"
						interface="popover"
						placeholder={selectedTalent}
						value={selectedTalent}
						onIonChange={talentChangeHandler}
					>
						{currentTalents.map((talent) => {
							return (
								<IonSelectOption
									key={talent.name}
									value={talent.name}
									class="brown-option"
								>
									{talent.label}
								</IonSelectOption>
							);
						})}
					</IonSelect>
				</IonItem>
			</IonList>
		</>
	);
};

export default GearTalent;
