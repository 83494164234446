import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
	apiKey: "AIzaSyDLNWcueCh2gqFUywNjoCaBXiAi1pY8xA4",
	authDomain: "dev-shd-builder.firebaseapp.com",
	projectId: "dev-shd-builder",
	storageBucket: "dev-shd-builder.appspot.com",
	messagingSenderId: "613510278888",
	appId: "1:613510278888:web:052a3e59f7ea9cf58ae35d",
	measurementId: "G-2VJSHH8YHK",
};

// const firebaseConfig = {
// 	apiKey: "AIzaSyCMTgehuHkj6FyCECJA_jDhXVhJWZOCMeo",
// 	authDomain: "build-station-dev.firebaseapp.com",
// 	projectId: "build-station-dev",
// 	storageBucket: "build-station-dev.appspot.com",
// 	messagingSenderId: "684574782371",
// 	appId: "1:684574782371:web:47ed40c302cdc49ffe2016",
// 	measurementId: "G-47B4W0TPLK",
// };

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const authentication = getAuth(app);
export const database = getFirestore(app);
