import { IonContent, IonHeader, IonTitle, IonToolbar } from "@ionic/react";

const PageContent = (props: any) => {
	return (
		<>
			<IonContent fullscreen>
				<IonHeader collapse="condense">
					<IonToolbar>
						<IonTitle size="large">Login</IonTitle>
					</IonToolbar>
				</IonHeader>
				{props.children}
			</IonContent>
		</>
	);
};

export default PageContent;
