const weaponModSlots = [
	{
		key: "optic",
		name: "Optic",
	},
	{
		key: "magazine",
		name: "Magazine",
	},
	{
		key: "under barrel",
		name: "Under Barrel",
	},
	{
		key: "muzzle",
		name: "Muzzle",
	},
];

export default weaponModSlots;
