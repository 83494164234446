import { IonHeader, IonPage } from "@ionic/react";
import AboutMain from "../components/about/AboutMain";
import Toolbar from "../components/toolbar/Toolbar";
import PageContent from "../components/ui/PageContent";

const About: React.FC = () => {
	return (
		<>
			<IonPage>
				<IonHeader>
					<Toolbar title="About" />
				</IonHeader>
				<PageContent>
					<AboutMain />
				</PageContent>
			</IonPage>
		</>
	);
};

export default About;
