import { useContext, useEffect, useState } from "react";
import CardBasic from "../ui/CardBasic";
import WeaponMainSelectModal from "./WeaponMainSelectModal";

import AppContext from "../../context/app-context";
import Weapon from "../../models/weapon";

interface WeaponMainSelectProps {
	slot: string;
	title?: string;
	subTitle?: string;
}

const WeaponMainSelect = ({
	slot,
	title = slot,
	subTitle = "Select your Weapon",
}: WeaponMainSelectProps) => {
	const ctxApp = useContext(AppContext);

	const [cardImage, setCardImage] = useState({ src: "", alt: "" });
	const [cardTitle, setCardTitle] = useState(title);
	const [cardSubTitle, setCardSubTitle] = useState(subTitle);

	const [headerClass, setHeaderClass] = useState<string>();

	useEffect(() => {
		function setClass(weapon: Weapon) {
			let className = "";
			className =
				weapon.quality.toLowerCase() === "named"
					? "high-end "
					: weapon.quality.toLowerCase() + " ";
			className += weapon.weaponType.toLowerCase();
			className = className.replaceAll(" ", "-");
			// console.log(className);
			setHeaderClass(className);
		}

		switch (slot.toLowerCase()) {
			case "primary":
				if (ctxApp.primaryWeapon.name) {
					setCardTitle(ctxApp.primaryWeapon.name);
					setCardSubTitle(ctxApp.primaryWeapon.quality);
					setClass(ctxApp.primaryWeapon);
				}
				break;
			case "secondary":
				if (ctxApp.secondaryWeapon.name) {
					setCardTitle(ctxApp.secondaryWeapon.name);
					setCardSubTitle(ctxApp.secondaryWeapon.quality);
					setClass(ctxApp.secondaryWeapon);
				}
				break;
			case "pistol":
				if (ctxApp.pistolWeapon.name) {
					setCardTitle(ctxApp.pistolWeapon.name);
					setCardSubTitle(ctxApp.pistolWeapon.quality);
					setClass(ctxApp.pistolWeapon);
				}
				break;
		}
	}, [
		slot,
		ctxApp.primaryWeapon,
		ctxApp.secondaryWeapon,
		ctxApp.pistolWeapon,
	]);

	return (
		<>
			<CardBasic
				id={"open-" + slot.toLowerCase() + "-modal"}
				headerClass={headerClass}
				image={cardImage}
				title={cardTitle}
				subTitle={cardSubTitle}
			/>
			<WeaponMainSelectModal slot={slot} />
		</>
	);
};

export default WeaponMainSelect;
