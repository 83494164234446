import {
	IonCheckbox,
	IonChip,
	IonCol,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { checkboxOutline, squareOutline } from "ionicons/icons";
import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import SpecializationsData from "../../data/SpecializationsData";
import Specialization from "../../models/specialization";
import "./SpecializationMain.css";

let initSpecData: Specialization[] = SpecializationsData;
initSpecData = initSpecData.sort(function (a, b) {
	const nameA = a.name.toUpperCase(); // ignore upper and lowercase
	const nameB = b.name.toUpperCase(); // ignore upper and lowercase
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
});

interface SpecializationMainProps {
	props?: string;
}

interface SelectChangeEventDetail<T = any> {
	value: T;
}

interface CheckboxChangeEventDetail<T = any> {
	value: T;
	checked: boolean;
}

const weaponTypes = [
	{
		name: "Marksman Rifle",
		val: "mmrDamage",
		var: "MMR",
		isChecked: true,
		isDisabled: false,
	},
	{
		name: "Rifle",
		val: "rifleDamage",
		var: "Rifle",
		isChecked: false,
		isDisabled: false,
	},
	{
		name: "Assault Rifle",
		val: "arDamage",
		var: "AR",
		isChecked: false,
		isDisabled: false,
	},
	{
		name: "LMG",
		val: "lmgDamage",
		var: "LMG",
		isChecked: false,
		isDisabled: false,
	},
	{
		name: "SMG",
		val: "smgDamage",
		var: "SMG",
		isChecked: false,
		isDisabled: false,
	},
	{
		name: "Shotgun",
		val: "shotgunDamage",
		var: "Shotgun",
		isChecked: false,
		isDisabled: false,
	},
	{
		name: "Pistol",
		val: "pistolDamage",
		var: "Pistol",
		isChecked: false,
		isDisabled: false,
	},
];

const SpecializationMain = ({ props }: SpecializationMainProps) => {
	const ctxApp = useContext(AppContext);
	const [currentSpecData, setCurrentSpecData] = useState(initSpecData);
	// const [selectedSpecStats, setSelectedSpecStats] = useState<[{}]>([
	// 	{ name: "", value: 0 },
	// ]);
	const [selectedSpec, setSelectedSpec] = useState("Select Specialization");
	const [specImage, setSpecImage] = useState("spacer");
	const [selectedSpecDetails, setSelectedSpecDetails] =
		useState<Specialization>();
	const [selectDisabled, setSelectDisabled] = useState(false);

	// const [checked, setChecked] = useState(false);

	const [itemsCheckedCount, setItemsCheckedCount] = useState(0);
	const [selectionsDisabled, setSelectionsDisabled] = useState(false);
	const [isMMRDisabled, setIsMMRDisabled] = useState(false);
	const [isRifleDisabled, setIsRifleDisabled] = useState(false);
	const [isARDisabled, setIsARDisabled] = useState(false);
	const [isLMGDisabled, setIsLMGDisabled] = useState(false);
	const [isSMGDisabled, setIsSMGDisabled] = useState(false);
	const [isShotgunDisabled, setIsShotgunDisabled] = useState(false);
	const [isPistolDisabled, setIsPistolDisabled] = useState(false);
	const [isMMRChecked, setIsMMRChecked] = useState(false);
	const [isRifleChecked, setIsRifleChecked] = useState(false);
	const [isARChecked, setIsARChecked] = useState(false);
	const [isLMGChecked, setIsLMGChecked] = useState(false);
	const [isSMGChecked, setIsSMGChecked] = useState(false);
	const [isShotgunChecked, setIsShotgunChecked] = useState(false);
	const [isPistolChecked, setIsPistolChecked] = useState(false);

	const specChangeHandler = (e: CustomEvent<SelectChangeEventDetail>) => {
		// console.log(e.detail.value);
		// get Spec Details and set the Spec in state/context
		const selectedSpec = currentSpecData.filter((spec) => {
			return spec.name === e.detail.value;
		});
		// console.log(selectedSpec[0]);
		// setSelectedSpecDetails(selectedSpec[0]);
		setSelectedSpec(e.detail.value);
		ctxApp.updateSpecStat("name", e.detail.value);
		ctxApp.updateSpecStat("bonuses", selectedSpec[0].stats);
	};

	const handleWeaponClicked = (e: any) => {
		// console.log(e);
		// console.log(e?.target.textContent);

		function updateSpecStats(
			currState: boolean,
			weaponType: string,
			specValue: number | undefined
		) {
			if (currState) {
				// will be unchecked so need to set stats to 0
				ctxApp.updateSpecStat(weaponType, 0, "number");
			} else {
				// will be checked so need to set stats to amount
				ctxApp.updateSpecStat(weaponType, specValue, "number");
			}
		}

		switch (e?.target.textContent.toLowerCase()) {
			case "marksman rifle":
				setIsMMRChecked((currState) => {
					// console.log(currState);
					updateSpecStats(
						currState,
						"mmrDamage",
						selectedSpecDetails?.weapons.mmr.value
					);
					return !isMMRChecked;
				});
				break;
			case "rifle":
				setIsRifleChecked((currState) => {
					// console.log(currState);
					updateSpecStats(
						currState,
						"rifleDamage",
						selectedSpecDetails?.weapons.rifle.value
					);
					return !isRifleChecked;
				});
				break;
			case "assault rifle":
				setIsARChecked((currState) => {
					// console.log(currState);
					updateSpecStats(
						currState,
						"arDamage",
						selectedSpecDetails?.weapons.ar.value
					);
					return !isARChecked;
				});
				break;
			case "lmg":
				setIsLMGChecked((currState) => {
					// console.log(currState);
					updateSpecStats(
						currState,
						"lmgDamage",
						selectedSpecDetails?.weapons.lmg.value
					);
					return !isLMGChecked;
				});
				break;
			case "smg":
				setIsSMGChecked((currState) => {
					// console.log(currState);
					updateSpecStats(
						currState,
						"smgDamage",
						selectedSpecDetails?.weapons.smg.value
					);
					return !isSMGChecked;
				});
				break;
			case "shotgun":
				setIsShotgunChecked((currState) => {
					// console.log(currState);
					updateSpecStats(
						currState,
						"shotgunDamage",
						selectedSpecDetails?.weapons.shotgun.value
					);
					return !isShotgunChecked;
				});
				break;
			case "pistol":
				setIsPistolChecked((currState) => {
					// console.log(currState);
					updateSpecStats(
						currState,
						"pistolDamage",
						selectedSpecDetails?.weapons.pistol.value
					);
					return !isPistolChecked;
				});
				break;
		}
	};

	const checkedWeapon = (e: CustomEvent<CheckboxChangeEventDetail>) => {
		// console.log(e.detail.checked);
		// console.log(e.detail.value);
		// console.log(selectedSpecDetails);
		switch (e.detail.value) {
			case "mmrDamage":
				setIsMMRChecked(e.detail.checked);
				break;
			case "rifleDamage":
				setIsRifleChecked(e.detail.checked);
				break;
			case "arDamage":
				setIsARChecked(e.detail.checked);
				break;
			case "lmgDamage":
				setIsLMGChecked(e.detail.checked);
				break;
			case "smgDamage":
				setIsSMGChecked(e.detail.checked);
				break;
			case "shotgunDamage":
				setIsShotgunChecked(e.detail.checked);
				break;
			case "pistolDamage":
				setIsPistolChecked(e.detail.checked);
				break;
		}

		setItemsCheckedCount((currState) => {
			if (e.detail.checked) {
				// console.log(e.detail.checked);
				// console.log(currState);
				return currState + 1;
			} else {
				// console.log(e.detail.checked);
				// console.log(currState);
				return currState - 1;
			}
		});
	};

	const interfaceOptions = {
		cssClass: "my-custom-interface",
	};

	// preloaded spec selected
	useEffect(() => {
		if (ctxApp.specializationStats.name) {
			setSelectedSpec(ctxApp.specializationStats.name);
		}
	}, [ctxApp.specializationStats.name]);

	// preloaded checked weapon talents
	useEffect(() => {
		if (ctxApp.specializationStats.mmrDamage) {
			setIsMMRChecked(true);
		}
		if (ctxApp.specializationStats.arDamage) {
			setIsARChecked(true);
		}
		if (ctxApp.specializationStats.rifleDamage) {
			setIsRifleChecked(true);
		}
		if (ctxApp.specializationStats.lmgDamage) {
			setIsLMGChecked(true);
		}
		if (ctxApp.specializationStats.smgDamage) {
			setIsSMGChecked(true);
		}
		if (ctxApp.specializationStats.shotgunDamage) {
			setIsShotgunChecked(true);
		}
		if (ctxApp.specializationStats.pistolDamage) {
			setIsPistolChecked(true);
		}
	}, [
		ctxApp.specializationStats.arDamage,
		ctxApp.specializationStats.lmgDamage,
		ctxApp.specializationStats.mmrDamage,
		ctxApp.specializationStats.pistolDamage,
		ctxApp.specializationStats.rifleDamage,
		ctxApp.specializationStats.shotgunDamage,
		ctxApp.specializationStats.smgDamage,
	]);

	// check if three weapons are checked
	useEffect(() => {
		if (itemsCheckedCount > 2) {
			// console.log("need to disable", itemsCheckedCount);
			setSelectionsDisabled(true);

			if (!isMMRChecked) {
				setIsMMRDisabled(true);
			}
			if (!isRifleChecked) {
				setIsRifleDisabled(true);
			}
			if (!isARChecked) {
				setIsARDisabled(true);
			}
			if (!isLMGChecked) {
				setIsLMGDisabled(true);
			}
			if (!isSMGChecked) {
				setIsSMGDisabled(true);
			}
			if (!isShotgunChecked) {
				setIsShotgunDisabled(true);
			}
			if (!isPistolChecked) {
				setIsPistolDisabled(true);
			}
		} else {
			// console.log("keep enabled", itemsCheckedCount);
			setSelectionsDisabled(false);
			setIsMMRDisabled(false);
			setIsRifleDisabled(false);
			setIsARDisabled(false);
			setIsLMGDisabled(false);
			setIsSMGDisabled(false);
			setIsShotgunDisabled(false);
			setIsPistolDisabled(false);
		}
	}, [
		ctxApp,
		isARChecked,
		isLMGChecked,
		isMMRChecked,
		isPistolChecked,
		isRifleChecked,
		isSMGChecked,
		isShotgunChecked,
		itemsCheckedCount,
		selectedSpecDetails?.weapons.ar.value,
		selectedSpecDetails?.weapons.lmg.value,
		selectedSpecDetails?.weapons.mmr.value,
		selectedSpecDetails?.weapons.pistol.value,
		selectedSpecDetails?.weapons.rifle.value,
		selectedSpecDetails?.weapons.shotgun.value,
		selectedSpecDetails?.weapons.smg.value,
		selectionsDisabled,
	]);

	// set Spec Talents
	useEffect(() => {
		// setSelectedSpec(ctxApp.specializationStats.name);
		// console.log(selectedSpec);
		// if (selectedSpec !== undefined) {
		const spec = currentSpecData.filter((spec) => {
			return spec.name === selectedSpec;
		});
		if (spec.length > 0) {
			// console.log(spec[0]);
			setSelectedSpecDetails(spec[0]);
			// setSelectedSpecStats(spec[0].stats);
			if (
				spec[0].name.toLowerCase() === "technician - damage" ||
				spec[0].name.toLowerCase() === "technician - repair"
			) {
				setSpecImage("technician");
			} else {
				setSpecImage(spec[0].name.toLowerCase());
			}
			// console.log(selectedSpecDetails);
		}
		// }
	}, [currentSpecData, selectedSpec, selectedSpecDetails]);

	return (
		<>
			<IonCol size="12" size-sm="4">
				{ctxApp.specializationStats.name !== "" && (
					<Fragment>
						<img
							className="specImage"
							alt=""
							src={"/assets/images/" + specImage + ".png"}
						></img>
						<img
							className="specImage"
							alt=""
							src={"/assets/images/" + specImage + "-weapon.png"}
						></img>
						<img
							className="specImage"
							alt=""
							src={"/assets/images/" + specImage + "-ammo.png"}
						></img>
					</Fragment>
				)}
				<IonList class="ion-no-padding">
					<IonItem class="ion-no-padding">
						<IonLabel position="floating">Specialization</IonLabel>
						<IonSelect
							disabled={selectDisabled}
							interfaceOptions={interfaceOptions}
							className="weaponInput"
							interface="popover"
							placeholder={selectedSpec}
							value={selectedSpec}
							onIonChange={specChangeHandler}
						>
							{currentSpecData.map((spec) => {
								return (
									<IonSelectOption
										key={spec.name}
										value={spec.value}
										class="brown-option"
									>
										{spec.name}
									</IonSelectOption>
								);
							})}
						</IonSelect>
					</IonItem>
				</IonList>
			</IonCol>
			<IonCol size="12" size-sm="8">
				{/* Chips */}
				{ctxApp.specializationStats.name !== "" &&
					selectedSpecDetails?.stats.map((stat, index) => {
						if (stat.name !== "") {
							return (
								<Fragment key={index}>
									<IonChip key={index}>
										<IonLabel>
											+{stat.val}
											{stat.name === "Skill Tier"
												? ""
												: "% "}
											{stat.name}
										</IonLabel>
									</IonChip>
								</Fragment>
							);
						}
						return <Fragment key={index}></Fragment>;
					})}
				<p></p>
				{ctxApp.specializationStats.name !== "" &&
					weaponTypes.map((type, index) => {
						return (
							<Fragment key={index}>
								<IonChip
									key={index}
									onClick={handleWeaponClicked}
									disabled={eval(
										"is" + type?.var + "Disabled"
									)}
								>
									{eval("is" + type?.var + "Checked") ? (
										<IonIcon
											color={"success"}
											icon={checkboxOutline}
										/>
									) : (
										<IonIcon icon={squareOutline} />
									)}
									<IonLabel>{type.name}</IonLabel>
								</IonChip>
								<span style={{ visibility: "hidden" }}>
									{/* <span> */}
									<IonCheckbox
										disabled={eval(
											"is" + type?.var + "Disabled"
										)}
										slot="start"
										name={type.val}
										value={type.val}
										checked={eval(
											"is" + type?.var + "Checked"
										)}
										onIonChange={checkedWeapon}
									/>
								</span>
							</Fragment>
						);
					})}
			</IonCol>
		</>
	);
};

export default SpecializationMain;
