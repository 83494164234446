import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import Gear from "../../models/gear";
import CardBasic from "../ui/CardBasic";
import GearMainSelectModal from "./GearMainSelectModal";
// import GearMainSelectModal from "./GearMainSelectModal";

interface GearMainSelectProps {
	slot: string;
	title?: string;
	subTitle?: string;
}

const GearMainSelect = ({
	slot,
	title = slot,
	subTitle = "Select your " + slot,
}: GearMainSelectProps) => {
	const ctxApp = useContext(AppContext);

	const [cardTitle, setCardTitle] = useState(title);
	const [cardSubTitle, setCardSubTitle] = useState(subTitle);
	const [headerClass, setHeaderClass] = useState<string>();

	useEffect(() => {
		function setClass(gear: Gear) {
			let className = "";
			className += slot.toLowerCase() + " ";
			className += gear.brand.toLowerCase();

			if (gear.quality.toLowerCase() === "exotic") {
				className += " " + gear.name.toLowerCase();
			}

			className = className.replaceAll(".", "");
			className = className.replaceAll(",", "");
			className = className.replaceAll("'", "");
			className = className.replaceAll(" &", "");
			// replace spaces with dashes
			className = className.replaceAll(" ", "-");
			// console.log(className);
			// setHeaderClass("gear-card-header header-gear-mask-badger-tuff");
			setHeaderClass("gear-card-header header-gear-" + className);
		}

		switch (slot.toLowerCase()) {
			case "mask":
				if (ctxApp.maskGear.name) {
					setCardTitle(ctxApp.maskGear.name);
					setCardSubTitle(ctxApp.maskGear.quality);
					setClass(ctxApp.maskGear);
				}
				break;
			case "backpack":
				if (ctxApp.backpackGear.name) {
					setCardTitle(ctxApp.backpackGear.name);
					setCardSubTitle(ctxApp.backpackGear.quality);
					setClass(ctxApp.backpackGear);
				}
				break;
			case "vest":
				if (ctxApp.vestGear.name) {
					setCardTitle(ctxApp.vestGear.name);
					setCardSubTitle(ctxApp.vestGear.quality);
					setClass(ctxApp.vestGear);
				}
				break;
			case "gloves":
				if (ctxApp.glovesGear.name) {
					setCardTitle(ctxApp.glovesGear.name);
					setCardSubTitle(ctxApp.glovesGear.quality);
					setClass(ctxApp.glovesGear);
				}
				break;
			case "holster":
				if (ctxApp.holsterGear.name) {
					setCardTitle(ctxApp.holsterGear.name);
					setCardSubTitle(ctxApp.holsterGear.quality);
					setClass(ctxApp.holsterGear);
				}
				break;
			case "kneepads":
				if (ctxApp.kneepadsGear.name) {
					setCardTitle(ctxApp.kneepadsGear.name);
					setCardSubTitle(ctxApp.kneepadsGear.quality);
					setClass(ctxApp.kneepadsGear);
				}
				break;
		}
	}, [
		ctxApp.backpackGear.name,
		ctxApp.backpackGear.quality,
		ctxApp.glovesGear.name,
		ctxApp.glovesGear.quality,
		ctxApp.holsterGear.name,
		ctxApp.holsterGear.quality,
		ctxApp.kneepadsGear.name,
		ctxApp.kneepadsGear.quality,
		ctxApp.maskGear,
		ctxApp.vestGear.name,
		ctxApp.vestGear.quality,
		slot,
	]);

	return (
		<>
			<CardBasic
				id={"open-" + slot.toLowerCase() + "-modal"}
				headerClass={headerClass}
				title={cardTitle}
				subTitle={cardSubTitle}
			/>
			<GearMainSelectModal slot={slot} />
		</>
	);
};

export default GearMainSelect;
