import { useContext, useEffect, useState } from "react";
import Gear from "../../models/gear";
import GearExpertise from "./GearExpertise";

// import "./GearMain.css";
import "../../pages/CssGear.css";
import GearCore from "./GearCore";
import GearAttributePrimary from "./GearAttributePrimary";
import GearAttributeSecondary from "./GearAttributeSecondary";
import GearTalent from "./GearTalent";
import GearMainSelect from "./GearMainSelect";
import GearModSelect from "./GearModSelect";

import AppContext from "../../context/app-context";

interface GearMainProps {
	slot: string;
}

const gear: Gear = {
	_id: {
		oid: "",
	},
	slot: "",
	quality: "",
	name: "",
	type: "",
	brand: "",
	core: "",
	attribute1: "",
	attribute2: "",
	mod: "",
	talent: "",
};

const GearMain = ({ slot }: GearMainProps) => {
	const ctxApp = useContext(AppContext);
	const [selectedGear, setSelectedGear] = useState(gear);
	const [showGearDetails, setShowGearDetails] = useState(false);
	const [showGearMod, setShowGearMod] = useState(false);
	const [showTalent, setShowTalent] = useState(false);

	useEffect(() => {
		switch (slot.toLowerCase()) {
			case "mask":
				setSelectedGear(ctxApp.maskGear);
				setShowGearMod(true);
				if (ctxApp.maskGear.quality === "Exotic") {
					setShowTalent(true);
				}
				break;
			case "backpack":
				setSelectedGear(ctxApp.backpackGear);
				setShowGearMod(true);
				setShowTalent(true);
				break;
			case "vest":
				setSelectedGear(ctxApp.vestGear);
				setShowGearMod(true);
				setShowTalent(true);
				break;
			case "gloves":
				setSelectedGear(ctxApp.glovesGear);
				if (ctxApp.glovesGear.brand === "Crafted") {
					setShowGearMod(true);
				}
				if (ctxApp.glovesGear.quality === "Exotic") {
					setShowTalent(true);
				}
				break;
			case "holster":
				setSelectedGear(ctxApp.holsterGear);
				if (ctxApp.holsterGear.brand === "Crafted") {
					setShowGearMod(true);
				}
				if (ctxApp.holsterGear.quality === "Exotic") {
					setShowTalent(true);
				}
				break;
			case "kneepads":
				setSelectedGear(ctxApp.kneepadsGear);
				if (ctxApp.kneepadsGear.brand === "Crafted") {
					setShowGearMod(true);
				}
				if (ctxApp.kneepadsGear.quality === "Exotic") {
					setShowTalent(true);
				}
				break;
		}
	}, [
		ctxApp.backpackGear,
		ctxApp.glovesGear,
		ctxApp.holsterGear,
		ctxApp.kneepadsGear,
		ctxApp.maskGear,
		ctxApp.vestGear,
		slot,
	]);

	useEffect(() => {
		if (selectedGear.name !== "") {
			setShowGearDetails(true);
		}
	}, [selectedGear.name]);

	return (
		<>
			<GearMainSelect slot={slot} />
			{showGearDetails === true && (
				<>
					<GearExpertise slot={slot} />
					<GearCore slot={slot} />
					<GearAttributePrimary slot={slot} />

					<GearAttributeSecondary slot={slot} />

					{/* {showGearMod && <GearModSelect slot={slot} />}
					{showTalent && <GearTalent slot={slot} />} */}
					<GearModSelect slot={slot} />
					<GearTalent slot={slot} />
				</>
			)}
		</>
	);
};

export default GearMain;
