import {
	IonItem,
	IonLabel,
	IonList,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import TalentsData from "../../data/TalentsData";
import Talent from "../../models/talent";
import Weapon from "../../models/weapon";
import WeaponStats from "../../models/weaponStats";

let initTalents: Talent[] = TalentsData;
initTalents = initTalents.sort(function (a, b) {
	const nameA = a.name.toUpperCase(); // ignore upper and lowercase
	const nameB = b.name.toUpperCase(); // ignore upper and lowercase
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
});

interface WeaponTalentProps {
	slot: string;
}

interface SelectChangeEventDetail<T = any> {
	value: T;
}

const WeaponTalent = ({ slot }: WeaponTalentProps) => {
	const ctxApp = useContext(AppContext);
	const [currentTalents, setCurrentTalents] = useState(initTalents);
	const [selectedTalent, setSelectedTalent] = useState("Select Talent");
	const [selectDisabled, setSelectDisabled] = useState(false);

	const talentChangeHandler = (e: CustomEvent<SelectChangeEventDetail>) => {
		// console.log(e.detail.value);
		setSelectedTalent(e.detail.value);
		ctxApp.updateWeaponStat("talent", slot, e.detail.value);
	};

	const interfaceOptions = {
		cssClass: "my-custom-interface",
	};

	useEffect(() => {
		let updatedTalents = initTalents.filter((talent) => {
			return talent.type === "weapon" && talent.quality === "A";
		});

		function checkSlot(weapon: Weapon, stats: WeaponStats) {
			if (weapon.name) {
				if (weapon.quality === "Exotic") {
					updatedTalents = initTalents.filter((talent) => {
						return talent.name === weapon.talent;
					});
					setSelectDisabled(true);
					setSelectedTalent(updatedTalents[0].name);
				} else {
					switch (weapon.weaponType) {
						case "Marksman Rifle":
							updatedTalents = initTalents.filter((talent) => {
								return (
									true === talent.mmr &&
									talent.quality === "A"
								);
							});
							break;
						case "Rifle":
							updatedTalents = initTalents.filter((talent) => {
								return (
									true === talent.rifle &&
									talent.quality === "A"
								);
							});
							break;
						case "Assault Rifle":
							updatedTalents = initTalents.filter((talent) => {
								return (
									true === talent.ar && talent.quality === "A"
								);
							});
							break;
						case "LMG":
							updatedTalents = initTalents.filter((talent) => {
								return (
									true === talent.lmg &&
									talent.quality === "A"
								);
							});
							break;
						case "SMG":
							updatedTalents = initTalents.filter((talent) => {
								return (
									true === talent.smg &&
									talent.quality === "A"
								);
							});
							break;
						case "Shotgun":
							updatedTalents = initTalents.filter((talent) => {
								return (
									true === talent.shotgun &&
									talent.quality === "A"
								);
							});
							break;
						case "Pistol":
							updatedTalents = initTalents.filter((talent) => {
								return true === talent.pistol;
							});
							break;
					}
					setSelectDisabled(false);
					setSelectedTalent(stats.talent);
				}
			}
			setCurrentTalents(updatedTalents);
		}

		switch (slot.toLowerCase()) {
			case "primary":
				checkSlot(ctxApp.primaryWeapon, ctxApp.primaryStats);
				break;
			case "secondary":
				checkSlot(ctxApp.secondaryWeapon, ctxApp.secondaryStats);
				break;
			case "pistol":
				checkSlot(ctxApp.pistolWeapon, ctxApp.pistolStats);
				break;
		}
	}, [
		ctxApp.pistolStats,
		ctxApp.pistolWeapon,
		ctxApp.primaryStats,
		ctxApp.primaryWeapon,
		ctxApp.secondaryStats,
		ctxApp.secondaryWeapon,
		slot,
	]);

	return (
		<>
			<IonList class="ion-no-padding">
				<IonItem class="ion-no-padding">
					<IonLabel position="floating">Talent</IonLabel>
					<IonSelect
						disabled={selectDisabled}
						interfaceOptions={interfaceOptions}
						className="weaponInput"
						interface="popover"
						placeholder="Select Talent"
						value={selectedTalent}
						onIonChange={talentChangeHandler}
					>
						{currentTalents.map((talent) => {
							return (
								<IonSelectOption
									key={talent.name}
									value={talent.name}
									class="brown-option"
								>
									{talent.label}
								</IonSelectOption>
							);
						})}
					</IonSelect>
				</IonItem>
			</IonList>
		</>
	);
};

export default WeaponTalent;

// ar: false,
// rifle: true,
// mmr: false,
// smg: false,
// lmg: false,
// pistol: false,
// shotgun: false,
