import { IonBadge, IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import WatchStats from "../../models/watchStats";

interface StatsWatchProps {
	weaponSlot: string;
	attribute: string;
}

const StatsWatch = ({ weaponSlot, attribute }: StatsWatchProps) => {
	const ctxApp = useContext(AppContext);
	const [showAttribute, setShowAttribute] = useState(true);
	const [gearSlotLabel, setGearSlotLabel] = useState("");
	const [gearSlotDesc, setGearSlotDesc] = useState("");
	const [showStartBadge, setShowStartBadge] = useState(true);
	const [weaponBaseDamage, setWeaponBaseDamage] = useState(0);
	const [gearValue, setGearValue] = useState(0);

	useEffect(() => {
		switch (weaponSlot.toLowerCase()) {
			case "primary":
				setWeaponBaseDamage(ctxApp.primaryWeapon.baseDamage);
				break;
			case "secondary":
				setWeaponBaseDamage(ctxApp.secondaryWeapon.baseDamage);
				break;
			case "pistol":
				setWeaponBaseDamage(ctxApp.pistolWeapon.baseDamage);
				break;
		}
	}, [
		ctxApp.pistolWeapon.baseDamage,
		ctxApp.primaryWeapon.baseDamage,
		ctxApp.secondaryWeapon.baseDamage,
		weaponSlot,
	]);

	useEffect(() => {
		function checkGear(gearStats: WatchStats) {
			switch (attribute) {
				case "Weapon Damage":
					setShowStartBadge(true);
					setGearSlotDesc(
						attribute +
							" " +
							gearStats.offensive.weaponDamage * 5 +
							"/50"
					);
					setGearValue(gearStats.offensive.weaponDamage);
					break;
				case "Critical Hit Chance":
					setShowStartBadge(false);
					setGearValue(gearStats.offensive.criticalHitChance);
					break;
				case "Critical Hit Damage":
					setShowStartBadge(true);
					setGearValue(gearStats.offensive.criticalHitDamage);
					break;
				case "Headshot Damage":
					setShowStartBadge(true);
					setGearValue(gearStats.offensive.headshotDamage);
					break;
			}
		}

		setGearSlotLabel("Keener's Watch");
		setShowAttribute(true);
		checkGear(ctxApp.watchStats);
	}, [attribute, ctxApp.watchStats]);

	return (
		<>
			{showAttribute && (
				<IonItem key={""}>
					{showStartBadge && (
						<IonBadge slot="start">{gearValue}%</IonBadge>
					)}
					<IonThumbnail slot="start">
						<img
							className={"gear25"}
							src={"assets/images/core-weapon-damage.png"}
							alt={"weapon damage"}
						/>
					</IonThumbnail>
					<IonLabel>
						<h3>{gearSlotLabel}</h3>
						<p>{gearSlotDesc}</p>
					</IonLabel>
					{showStartBadge && (
						<IonBadge slot="end">
							{Math.round(
								weaponBaseDamage * (gearValue / 100)
							).toLocaleString("en-US")}
						</IonBadge>
					)}
					{!showStartBadge && (
						<IonBadge slot="end">{gearValue}%</IonBadge>
					)}
				</IonItem>
			)}
		</>
	);
};

export default StatsWatch;
