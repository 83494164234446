import { useContext, useEffect, useState } from "react";
import AppContext from "../context/app-context";
import WeaponStats from "../models/weaponStats";

import gearSlots from "../models/gearSlots";
import Weapon from "../models/weapon";

// const weaponMods = [
// 	{ key: "optics", name: "Optics" },
// 	{ key: "magazine", name: "Magazine" },
// 	{ key: "underbarrel", name: "Underbarrel" },
// 	{ key: "Muzzle", name: "Muzzle" },
// ];

const weaponTypes = [
	{ var: "mmr", name: "Marksman Rifle" },
	{ var: "rifle", name: "Rifle" },
	{ var: "ar", name: "Assault Rifle" },
	{ var: "smg", name: "SMG" },
	{ var: "lmg", name: "LMG" },
	{ var: "shotgun", name: "Shotgun" },
	{ var: "pistol", name: "Pistol" },
];

interface calculatorProps {
	slot: string;
	weapon: Weapon;
	weaponStats: WeaponStats;
}

let initialCalculations = {
	wdPercent: 0,
	chcPercent: 0,
	chdPercent: 0,
	hsdPercent: 0,
	twdPercent: 0,
	dtaPercent: 0,
	dttoocPercent: 0,
	bodyshot: 0,
	critBodyshot: 0,
	headshot: 0,
	critHeadshot: 0,
};

const Calculator = ({ slot, weapon, weaponStats }: calculatorProps) => {
	const ctxApp = useContext(AppContext);
	const [calculations, setCalculations] = useState(initialCalculations);
	const [currentWeapon, setCurrentWeapon] = useState(weapon);
	const [currentWeaponStats, setCurrentWeaponStats] = useState(weaponStats);
	const [wdPercent, setWDPercent] = useState(0);
	const [chcPercent, setCHCPercent] = useState(0);
	const [chdPercent, setCHDPercent] = useState(0);
	const [hsdPercent, setHSDPercent] = useState(0);
	const [twdPercent, setTWDPercent] = useState(0);
	const [dtaPercent, setDTAPercent] = useState(0);
	const [dttoocPercent, setDTTOOCPercent] = useState(0);
	const [bodyshot, setBodyshot] = useState(0);
	const [critBodyshot, setCritBodyshot] = useState(0);
	const [headshot, setHeadshot] = useState(0);
	const [critHeadshot, setCritHeadshot] = useState(0);

	useEffect(() => {
		switch (slot.toLowerCase()) {
			case "primary":
				setCurrentWeapon(ctxApp.primaryWeapon);
				setCurrentWeaponStats(ctxApp.primaryStats);
				break;
			case "secondary":
				setCurrentWeapon(ctxApp.secondaryWeapon);
				setCurrentWeaponStats(ctxApp.secondaryStats);
				break;
			case "pistol":
				setCurrentWeapon(ctxApp.pistolWeapon);
				setCurrentWeaponStats(ctxApp.pistolStats);
				break;
		}
	}, [
		ctxApp.pistolStats,
		ctxApp.pistolWeapon,
		ctxApp.primaryStats,
		ctxApp.primaryWeapon,
		ctxApp.secondaryStats,
		ctxApp.secondaryWeapon,
		slot,
	]);

	// WD = Sum of All Weapon Damage and Weapon Class Damage
	useEffect(() => {
		let wdPercent = 0;
		// spec
		wdPercent += checkSpec();
		// watch
		wdPercent += ctxApp.watchStats.offensive.weaponDamage;
		// expertise
		wdPercent += currentWeaponStats.expertise;
		// console.log(currentWeaponStats.expertise);
		// weapon core1Value
		wdPercent += currentWeaponStats.core1Value!;
		// gear weapon damage
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			wdPercent += checkGear(element);
		}
		// brandset bonus
		wdPercent += checkBrandsets();
		// gearset bonus
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			wdPercent += checkGearsets(element);
		}

		function checkSpec() {
			let wdSpec = 0;
			for (let i = 0; i < weaponTypes.length; i++) {
				const element = weaponTypes[i];
				if (currentWeapon.weaponType === element.name) {
					switch (element.var) {
						case "mmr":
							wdSpec = ctxApp.specializationStats.mmrDamage;
							break;
						case "rifle":
							wdSpec = ctxApp.specializationStats.rifleDamage;
							break;
						case "ar":
							wdSpec = ctxApp.specializationStats.arDamage;
							break;
						case "smg":
							wdSpec = ctxApp.specializationStats.smgDamage;
							break;
						case "lmg":
							wdSpec = ctxApp.specializationStats.lmgDamage;
							break;
						case "pistol":
							wdSpec = ctxApp.specializationStats.pistolDamage;
							break;
					}
				}
			}
			return wdSpec;
		}

		function checkGear(element: any, attribute: string = "Weapon Damage") {
			let attValue = 0;
			switch (element.key) {
				case "mask":
					if (ctxApp.maskStats.core1Name === attribute) {
						attValue = ctxApp.maskStats.core1Value!;
					}
					break;
				case "backpack":
					if (ctxApp.backpackStats.core1Name === attribute) {
						attValue = ctxApp.backpackStats.core1Value!;
					}
					break;
				case "vest":
					if (ctxApp.vestStats.core1Name === attribute) {
						attValue = ctxApp.vestStats.core1Value!;
					}
					break;
				case "gloves":
					if (ctxApp.glovesStats.core1Name === attribute) {
						attValue = ctxApp.glovesStats.core1Value!;
					}
					break;
				case "holster":
					if (ctxApp.holsterStats.core1Name === attribute) {
						attValue = ctxApp.holsterStats.core1Value!;
					}
					break;
				case "kneepads":
					if (ctxApp.kneepadsStats.core1Name === attribute) {
						attValue = ctxApp.kneepadsStats.core1Value!;
					}
					break;
			}
			return attValue;
		}

		function checkBrandsets() {
			let bsBonus = 0;
			for (let i = 0; i < ctxApp.brandsetBonuses.length; i++) {
				const element = ctxApp.brandsetBonuses[i];
				// check weapon damage
				if (element.attribute === "Weapon Damage") {
					bsBonus += element.value;
				}
				// check weapon class damage
				if (element.attribute === currentWeapon.core1) {
					bsBonus += element.value;
				}
			}
			return bsBonus;
		}

		function checkGearsets(element: any) {
			let gsBonus = 0;
			switch (element.key) {
				case "mask":
					if (
						ctxApp.gearsetBonuses.mask.attribute ===
							"Weapon Damage" ||
						ctxApp.gearsetBonuses.mask.attribute ===
							currentWeapon.core1
					) {
						gsBonus = ctxApp.gearsetBonuses.mask.value;
					}
					break;
				case "backpack":
					if (
						ctxApp.gearsetBonuses.backpack.attribute ===
							"Weapon Damage" ||
						ctxApp.gearsetBonuses.backpack.attribute ===
							currentWeapon.core1
					) {
						gsBonus = ctxApp.gearsetBonuses.backpack.value;
					}
					break;
				case "vest":
					if (
						ctxApp.gearsetBonuses.vest.attribute ===
							"Weapon Damage" ||
						ctxApp.gearsetBonuses.vest.attribute ===
							currentWeapon.core1
					) {
						gsBonus = ctxApp.gearsetBonuses.vest.value;
					}
					break;
				case "gloves":
					if (
						ctxApp.gearsetBonuses.gloves.attribute ===
							"Weapon Damage" ||
						ctxApp.gearsetBonuses.gloves.attribute ===
							currentWeapon.core1
					) {
						gsBonus = ctxApp.gearsetBonuses.gloves.value;
					}
					break;
				case "holster":
					if (
						ctxApp.gearsetBonuses.holster.attribute ===
							"Weapon Damage" ||
						ctxApp.gearsetBonuses.holster.attribute ===
							currentWeapon.core1
					) {
						gsBonus = ctxApp.gearsetBonuses.holster.value;
					}
					break;
				case "kneepads":
					if (
						ctxApp.gearsetBonuses.kneepads.attribute ===
							"Weapon Damage" ||
						ctxApp.gearsetBonuses.kneepads.attribute ===
							currentWeapon.core1
					) {
						// console.log(ctxApp.gearsetBonuses.kneepads.value);
						gsBonus = ctxApp.gearsetBonuses.kneepads.value;
					}
					break;
			}
			return gsBonus;
		}

		setWDPercent(wdPercent);
	}, [
		ctxApp.backpackStats.core1Name,
		ctxApp.backpackStats.core1Value,
		ctxApp.brandsetBonuses,
		ctxApp.gearsetBonuses.backpack.attribute,
		ctxApp.gearsetBonuses.backpack.value,
		ctxApp.gearsetBonuses.gloves.attribute,
		ctxApp.gearsetBonuses.gloves.value,
		ctxApp.gearsetBonuses.holster.attribute,
		ctxApp.gearsetBonuses.holster.value,
		ctxApp.gearsetBonuses.kneepads.attribute,
		ctxApp.gearsetBonuses.kneepads.value,
		ctxApp.gearsetBonuses.mask.attribute,
		ctxApp.gearsetBonuses.mask.value,
		ctxApp.gearsetBonuses.vest.attribute,
		ctxApp.gearsetBonuses.vest.value,
		ctxApp.glovesStats.core1Name,
		ctxApp.glovesStats.core1Value,
		ctxApp.holsterStats.core1Name,
		ctxApp.holsterStats.core1Value,
		ctxApp.kneepadsStats.core1Name,
		ctxApp.kneepadsStats.core1Value,
		ctxApp.maskStats.core1Name,
		ctxApp.maskStats.core1Value,
		ctxApp.specializationStats.arDamage,
		ctxApp.specializationStats.lmgDamage,
		ctxApp.specializationStats.mmrDamage,
		ctxApp.specializationStats.pistolDamage,
		ctxApp.specializationStats.rifleDamage,
		ctxApp.specializationStats.smgDamage,
		ctxApp.vestStats.core1Name,
		ctxApp.vestStats.core1Value,
		ctxApp.watchStats.offensive.weaponDamage,
		currentWeapon.core1,
		currentWeapon.weaponType,
		currentWeaponStats.core1Value,
		currentWeaponStats.expertise,
	]);

	/*
	HSD and CHD are additive if they are both present. Example, when you land a critical headshot, you add the two percentages and make their sum a multiplier.
	If one of them is not active, set its value to 0%. Example: if you hit a critical body shot, HSD will be set to 0%. If you land a non-critical headshot, you will set CHD to 0%.
	*/

	// HSD
	useEffect(() => {
		let hsdPercent = 0;
		// spec - nothing (Sharpshooter)
		hsdPercent += checkSpec();
		// console.log("Spec", slot, checkSpec(), hsdPercent);
		// watch
		hsdPercent += ctxApp.watchStats.offensive.headshotDamage;
		// console.log(
		// 	"Watch",
		// 	slot,
		// 	ctxApp.watchStats.offensive.headshotDamage,
		// 	hsdPercent
		// );
		// weapon
		hsdPercent += checkWeapon();
		// console.log("Weapon", slot, checkWeapon(), hsdPercent);
		// gear
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			hsdPercent += checkGear(element);
			// console.log("Gear", slot, element, checkGear(element), hsdPercent);
		}
		// brandsets
		hsdPercent += checkBrandsets();
		// gearsets
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			hsdPercent += checkGearsets(element);
			// console.log(
			// 	"Gearset",
			// 	slot,
			// 	element.name,
			// 	checkGearsets(element),
			// 	hsdPercent
			// );
		}

		function checkSpec() {
			let hsdSpec = 0;
			if (
				ctxApp.specializationStats.name.toLowerCase() ===
					"sharpshooter" &&
				(currentWeapon.weaponType.toLowerCase() === "rifle" ||
					currentWeapon.weaponType.toLowerCase() === "marksman rifle")
			) {
				hsdSpec += 15;
			}
			return hsdSpec;
		}

		function checkWeapon(attribute: string = "Headshot Damage") {
			let hsdWeapon = 0;
			// check hsd
			hsdWeapon += currentWeapon.hsd;
			// check weapon
			if (currentWeapon.core2 === attribute) {
				hsdWeapon += currentWeaponStats.core2Value!;
			}
			if (currentWeaponStats.attribute1 === attribute) {
				hsdWeapon += currentWeaponStats.attribute1Value!;
			}
			// check mods
			if (currentWeaponStats.modOpticStatPos === attribute) {
				hsdWeapon += currentWeaponStats.modOpticValuePos;
			}
			if (currentWeaponStats.modMagazineStatPos === attribute) {
				hsdWeapon += currentWeaponStats.modMagazineValuePos;
			}
			if (currentWeaponStats.modUnderbarrelStatPos === attribute) {
				hsdWeapon += currentWeaponStats.modUnderbarrelValuePos;
			}
			if (currentWeaponStats.modMuzzleStatPos === attribute) {
				hsdWeapon += currentWeaponStats.modMuzzleValuePos;
			}
			return hsdWeapon;
		}
		function checkGear(slot: any, attribute: string = "Headshot Damage") {
			let hsdGear = 0;
			switch (slot.key) {
				case "mask":
					// check core
					if (ctxApp.maskStats.core1Name === attribute) {
						hsdGear += ctxApp.maskStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.maskStats.attribute1Name === attribute) {
						hsdGear += ctxApp.maskStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.maskStats.attribute2Name === attribute) {
						hsdGear += ctxApp.maskStats.attribute2Value!;
					}
					//check mod
					if (ctxApp.maskStats.modName === attribute) {
						hsdGear += ctxApp.maskStats.modValue;
					}
					break;
				case "backpack":
					// check core
					if (ctxApp.backpackStats.core1Name === attribute) {
						hsdGear += ctxApp.backpackStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.backpackStats.attribute1Name === attribute) {
						hsdGear += ctxApp.backpackStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.backpackStats.attribute2Name === attribute) {
						hsdGear += ctxApp.backpackStats.attribute2Value!;
					}
					//check mod
					if (ctxApp.maskStats.modName === attribute) {
						hsdGear += ctxApp.maskStats.modValue;
					}
					break;
				case "vest":
					// check core
					if (ctxApp.vestStats.core1Name === attribute) {
						hsdGear += ctxApp.vestStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.vestStats.attribute1Name === attribute) {
						hsdGear += ctxApp.vestStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.vestStats.attribute2Name === attribute) {
						hsdGear += ctxApp.vestStats.attribute2Value!;
					}
					//check mod
					if (ctxApp.vestStats.modName === attribute) {
						hsdGear += ctxApp.vestStats.modValue;
					}
					break;
				case "gloves":
					// check core
					if (ctxApp.glovesStats.core1Name === attribute) {
						hsdGear += ctxApp.glovesStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.glovesStats.attribute1Name === attribute) {
						hsdGear += ctxApp.glovesStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.glovesStats.attribute2Name === attribute) {
						hsdGear += ctxApp.glovesStats.attribute2Value!;
					}
					//check mod
					if (ctxApp.glovesStats.modName === attribute) {
						hsdGear += ctxApp.glovesStats.modValue;
					}
					break;
				case "holster":
					// check core
					if (ctxApp.holsterStats.core1Name === attribute) {
						hsdGear += ctxApp.holsterStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.holsterStats.attribute1Name === attribute) {
						hsdGear += ctxApp.holsterStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.holsterStats.attribute2Name === attribute) {
						hsdGear += ctxApp.holsterStats.attribute2Value!;
					}
					//check mod
					if (ctxApp.holsterStats.modName === attribute) {
						hsdGear += ctxApp.holsterStats.modValue;
					}
					break;
				case "kneepads":
					// check core
					if (ctxApp.kneepadsStats.core1Name === attribute) {
						hsdGear += ctxApp.kneepadsStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.kneepadsStats.attribute1Name === attribute) {
						hsdGear += ctxApp.kneepadsStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.kneepadsStats.attribute2Name === attribute) {
						hsdGear += ctxApp.kneepadsStats.attribute2Value!;
					}
					//check mod
					if (ctxApp.kneepadsStats.modName === attribute) {
						hsdGear += ctxApp.kneepadsStats.modValue;
					}
					break;
			}
			return hsdGear;
		}
		function checkBrandsets() {
			let bsBonus = 0;
			for (let i = 0; i < ctxApp.brandsetBonuses.length; i++) {
				const element = ctxApp.brandsetBonuses[i];
				// check critical hit damage
				if (element.attribute === "Headshot Damage") {
					bsBonus += element.value;
				}
			}
			return bsBonus;
		}

		function checkGearsets(element: any) {
			let gsBonus = 0;
			const attribute = "Headshot Damage";
			switch (element.key) {
				case "mask":
					if (ctxApp.gearsetBonuses.mask.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.mask.value;
					}
					break;
				case "backpack":
					if (
						ctxApp.gearsetBonuses.backpack.attribute ===
							attribute ||
						ctxApp.gearsetBonuses.backpack.attribute ===
							currentWeapon.core1
					) {
						gsBonus = ctxApp.gearsetBonuses.backpack.value;
					}
					break;
				case "vest":
					if (ctxApp.gearsetBonuses.vest.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.vest.value;
					}
					break;
				case "gloves":
					if (ctxApp.gearsetBonuses.gloves.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.gloves.value;
					}
					break;
				case "holster":
					if (ctxApp.gearsetBonuses.holster.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.holster.value;
					}
					break;
				case "kneepads":
					if (
						ctxApp.gearsetBonuses.kneepads.attribute === attribute
					) {
						// console.log(ctxApp.gearsetBonuses.kneepads.value);
						gsBonus = ctxApp.gearsetBonuses.kneepads.value;
					}
					break;
			}
			// console.log(gsBonus);
			return gsBonus;
		}

		setHSDPercent(hsdPercent);
	}, [
		ctxApp.backpackStats.attribute1Name,
		ctxApp.backpackStats.attribute1Value,
		ctxApp.backpackStats.attribute2Name,
		ctxApp.backpackStats.attribute2Value,
		ctxApp.backpackStats.core1Name,
		ctxApp.backpackStats.core1Value,
		ctxApp.brandsetBonuses,
		ctxApp.gearsetBonuses.backpack.attribute,
		ctxApp.gearsetBonuses.backpack.value,
		ctxApp.gearsetBonuses.gloves.attribute,
		ctxApp.gearsetBonuses.gloves.value,
		ctxApp.gearsetBonuses.holster.attribute,
		ctxApp.gearsetBonuses.holster.value,
		ctxApp.gearsetBonuses.kneepads.attribute,
		ctxApp.gearsetBonuses.kneepads.value,
		ctxApp.gearsetBonuses.mask.attribute,
		ctxApp.gearsetBonuses.mask.value,
		ctxApp.gearsetBonuses.vest.attribute,
		ctxApp.gearsetBonuses.vest.value,
		ctxApp.glovesStats.attribute1Name,
		ctxApp.glovesStats.attribute1Value,
		ctxApp.glovesStats.attribute2Name,
		ctxApp.glovesStats.attribute2Value,
		ctxApp.glovesStats.core1Name,
		ctxApp.glovesStats.core1Value,
		ctxApp.glovesStats.modName,
		ctxApp.glovesStats.modValue,
		ctxApp.holsterStats.attribute1Name,
		ctxApp.holsterStats.attribute1Value,
		ctxApp.holsterStats.attribute2Name,
		ctxApp.holsterStats.attribute2Value,
		ctxApp.holsterStats.core1Name,
		ctxApp.holsterStats.core1Value,
		ctxApp.holsterStats.modName,
		ctxApp.holsterStats.modValue,
		ctxApp.kneepadsStats.attribute1Name,
		ctxApp.kneepadsStats.attribute1Value,
		ctxApp.kneepadsStats.attribute2Name,
		ctxApp.kneepadsStats.attribute2Value,
		ctxApp.kneepadsStats.core1Name,
		ctxApp.kneepadsStats.core1Value,
		ctxApp.kneepadsStats.modName,
		ctxApp.kneepadsStats.modValue,
		ctxApp.maskStats.attribute1Name,
		ctxApp.maskStats.attribute1Value,
		ctxApp.maskStats.attribute2Name,
		ctxApp.maskStats.attribute2Value,
		ctxApp.maskStats.core1Name,
		ctxApp.maskStats.core1Value,
		ctxApp.maskStats.modName,
		ctxApp.maskStats.modValue,
		ctxApp.specializationStats.name,
		ctxApp.vestStats.attribute1Name,
		ctxApp.vestStats.attribute1Value,
		ctxApp.vestStats.attribute2Name,
		ctxApp.vestStats.attribute2Value,
		ctxApp.vestStats.core1Name,
		ctxApp.vestStats.core1Value,
		ctxApp.vestStats.modName,
		ctxApp.vestStats.modValue,
		ctxApp.watchStats.offensive.headshotDamage,
		currentWeapon.core1,
		currentWeapon.core2,
		currentWeapon.hsd,
		currentWeapon.weaponType,
		currentWeaponStats.attribute1,
		currentWeaponStats.attribute1Value,
		currentWeaponStats.core2Value,
		currentWeaponStats.modMagazineStatPos,
		currentWeaponStats.modMagazineValuePos,
		currentWeaponStats.modMuzzleStatPos,
		currentWeaponStats.modMuzzleValuePos,
		currentWeaponStats.modOpticStatPos,
		currentWeaponStats.modOpticValuePos,
		currentWeaponStats.modUnderbarrelStatPos,
		currentWeaponStats.modUnderbarrelValuePos,
		slot,
	]);

	// CHC
	useEffect(() => {
		let chcPercent = 0;
		// spec - nothing
		// watch
		chcPercent += ctxApp.watchStats.offensive.criticalHitChance;
		// console.log(
		// 	"Watch",
		// 	slot,
		// 	ctxApp.watchStats.offensive.criticalHitChance,
		// 	chcPercent
		// );
		// weapon
		chcPercent += checkWeapon("Critical Hit Chance");
		// console.log(
		// 	"Weapon",
		// 	slot,
		// 	checkWeapon("Critical Hit Chance"),
		// 	chcPercent
		// );
		// gear
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			chcPercent += checkGear(element);
			// console.log("Gear", slot, checkGear(element), chcPercent);
		}
		// brandsets
		chcPercent += checkBrandsets();
		// gearset bonus
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			chcPercent += checkGearsets(element);
			// console.log(
			// 	"Gearset",
			// 	slot,
			// 	element.name,
			// 	checkGearsets(element),
			// 	chcPercent
			// );
		}

		function checkWeapon(attribute: string = "Critical Hit Chance") {
			let chcWeapon = 0;
			// check core2
			if (currentWeapon.core2 === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeapon.core2,
				// 	currentWeaponStats.core2Value
				// );
				chcWeapon += currentWeaponStats.core2Value!;
			}
			// check attribute1
			if (currentWeaponStats.attribute1 === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeapon.attribute1,
				// 	currentWeaponStats.attribute1Value
				// );
				chcWeapon += currentWeaponStats.attribute1Value!;
			}
			// check mods
			if (currentWeaponStats.modOpticStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modOpticStatPos,
				// 	currentWeaponStats.modOpticValuePos
				// );
				chcWeapon += currentWeaponStats.modOpticValuePos;
			}
			if (currentWeaponStats.modMagazineStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modMagazineStatPos,
				// 	currentWeaponStats.modMagazineValuePos
				// );
				chcWeapon += currentWeaponStats.modMagazineValuePos;
			}
			if (currentWeaponStats.modUnderbarrelStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modUnderbarrelStatPos,
				// 	currentWeaponStats.modUnderbarrelValuePos
				// );
				chcWeapon += currentWeaponStats.modUnderbarrelValuePos;
			}
			if (currentWeaponStats.modMuzzleStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modMuzzleStatPos,
				// 	currentWeaponStats.modMuzzleValuePos
				// );
				chcWeapon += currentWeaponStats.modMuzzleValuePos;
			}
			return chcWeapon;
		}

		function checkGear(
			slot: any,
			attribute: string = "Critical Hit Chance"
		) {
			let chcGear = 0;
			switch (slot.key) {
				case "mask":
					// check core
					// if (ctxApp.maskStats.core1Name === attribute) {
					// 	chcGear += ctxApp.maskStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.maskStats.attribute1Name === attribute) {
						chcGear += ctxApp.maskStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.maskStats.attribute2Name === attribute) {
						chcGear += ctxApp.maskStats.attribute2Value!;
					}
					break;
				case "backpack":
					// check core
					// if (ctxApp.backpackStats.core1Name === attribute) {
					// 	chcGear += ctxApp.backpackStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.backpackStats.attribute1Name === attribute) {
						chcGear += ctxApp.backpackStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.backpackStats.attribute2Name === attribute) {
						chcGear += ctxApp.backpackStats.attribute2Value!;
					}
					break;
				case "vest":
					// check core
					// if (ctxApp.vestStats.core1Name === attribute) {
					// 	chcGear += ctxApp.vestStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.vestStats.attribute1Name === attribute) {
						chcGear += ctxApp.vestStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.vestStats.attribute2Name === attribute) {
						chcGear += ctxApp.vestStats.attribute2Value!;
					}
					break;
				case "gloves":
					// check core
					// if (ctxApp.glovesStats.core1Name === attribute) {
					// 	chcGear += ctxApp.glovesStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.glovesStats.attribute1Name === attribute) {
						chcGear += ctxApp.glovesStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.glovesStats.attribute2Name === attribute) {
						chcGear += ctxApp.glovesStats.attribute2Value!;
					}
					break;
				case "holster":
					// check core
					// if (ctxApp.holsterStats.core1Name === attribute) {
					// 	chcGear += ctxApp.holsterStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.holsterStats.attribute1Name === attribute) {
						chcGear += ctxApp.holsterStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.holsterStats.attribute2Name === attribute) {
						chcGear += ctxApp.holsterStats.attribute2Value!;
					}
					break;
				case "kneepads":
					// check core
					// if (ctxApp.kneepadsStats.core1Name === attribute) {
					// 	chcGear += ctxApp.kneepadsStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.kneepadsStats.attribute1Name === attribute) {
						chcGear += ctxApp.kneepadsStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.kneepadsStats.attribute2Name === attribute) {
						chcGear += ctxApp.kneepadsStats.attribute2Value!;
					}
					break;
			}
			return chcGear;
		}
		function checkBrandsets() {
			let bsBonus = 0;
			for (let i = 0; i < ctxApp.brandsetBonuses.length; i++) {
				const element = ctxApp.brandsetBonuses[i];
				// check critical hit chance
				if (element.attribute === "Critical Hit Chance") {
					bsBonus += element.value;
				}
			}
			return bsBonus;
		}

		function checkGearsets(element: any) {
			let gsBonus = 0;
			const attribute = "Critical Hit Chance";
			switch (element.key) {
				case "mask":
					if (ctxApp.gearsetBonuses.mask.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.mask.value;
					}
					break;
				case "backpack":
					if (
						ctxApp.gearsetBonuses.backpack.attribute ===
							attribute ||
						ctxApp.gearsetBonuses.backpack.attribute ===
							currentWeapon.core1
					) {
						gsBonus = ctxApp.gearsetBonuses.backpack.value;
					}
					break;
				case "vest":
					if (ctxApp.gearsetBonuses.vest.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.vest.value;
					}
					break;
				case "gloves":
					if (ctxApp.gearsetBonuses.gloves.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.gloves.value;
					}
					break;
				case "holster":
					if (ctxApp.gearsetBonuses.holster.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.holster.value;
					}
					break;
				case "kneepads":
					if (
						ctxApp.gearsetBonuses.kneepads.attribute === attribute
					) {
						// console.log(ctxApp.gearsetBonuses.kneepads.value);
						gsBonus = ctxApp.gearsetBonuses.kneepads.value;
					}
					break;
			}
			return gsBonus;
		}

		// switch (slot.toLowerCase()) {
		// 	case "primary":
		// 		setCHCPercentPrimary(chcPercent);
		// 		break;
		// 	case "secondary":
		// 		setCHCPercentSecondary(chcPercent);
		// 		break;
		// 	default:
		// 		break;
		// 	}
		setCHCPercent(chcPercent);
	}, [
		ctxApp.backpackStats.attribute1Name,
		ctxApp.backpackStats.attribute1Value,
		ctxApp.backpackStats.attribute2Name,
		ctxApp.backpackStats.attribute2Value,
		ctxApp.backpackStats.core1Name,
		ctxApp.backpackStats.core1Value,
		ctxApp.brandsetBonuses,
		ctxApp.gearsetBonuses.backpack.attribute,
		ctxApp.gearsetBonuses.backpack.value,
		ctxApp.gearsetBonuses.gloves.attribute,
		ctxApp.gearsetBonuses.gloves.value,
		ctxApp.gearsetBonuses.holster.attribute,
		ctxApp.gearsetBonuses.holster.value,
		ctxApp.gearsetBonuses.kneepads.attribute,
		ctxApp.gearsetBonuses.kneepads.value,
		ctxApp.gearsetBonuses.mask.attribute,
		ctxApp.gearsetBonuses.mask.value,
		ctxApp.gearsetBonuses.vest.attribute,
		ctxApp.gearsetBonuses.vest.value,
		ctxApp.glovesStats.attribute1Name,
		ctxApp.glovesStats.attribute1Value,
		ctxApp.glovesStats.attribute2Name,
		ctxApp.glovesStats.attribute2Value,
		ctxApp.glovesStats.core1Name,
		ctxApp.glovesStats.core1Value,
		ctxApp.holsterStats.attribute1Name,
		ctxApp.holsterStats.attribute1Value,
		ctxApp.holsterStats.attribute2Name,
		ctxApp.holsterStats.attribute2Value,
		ctxApp.holsterStats.core1Name,
		ctxApp.holsterStats.core1Value,
		ctxApp.kneepadsStats.attribute1Name,
		ctxApp.kneepadsStats.attribute1Value,
		ctxApp.kneepadsStats.attribute2Name,
		ctxApp.kneepadsStats.attribute2Value,
		ctxApp.kneepadsStats.core1Name,
		ctxApp.kneepadsStats.core1Value,
		ctxApp.maskStats.attribute1Name,
		ctxApp.maskStats.attribute1Value,
		ctxApp.maskStats.attribute2Name,
		ctxApp.maskStats.attribute2Value,
		ctxApp.maskStats.core1Name,
		ctxApp.maskStats.core1Value,
		ctxApp.vestStats.attribute1Name,
		ctxApp.vestStats.attribute1Value,
		ctxApp.vestStats.attribute2Name,
		ctxApp.vestStats.attribute2Value,
		ctxApp.vestStats.core1Name,
		ctxApp.vestStats.core1Value,
		ctxApp.watchStats.offensive.criticalHitChance,
		currentWeapon.core1,
		currentWeapon.core2,
		currentWeaponStats.attribute1,
		currentWeaponStats.attribute1Value,
		currentWeaponStats.core2Value,
		currentWeaponStats.modMagazineStatPos,
		currentWeaponStats.modMagazineValuePos,
		currentWeaponStats.modMuzzleStatPos,
		currentWeaponStats.modMuzzleValuePos,
		currentWeaponStats.modOpticStatPos,
		currentWeaponStats.modOpticValuePos,
		currentWeaponStats.modUnderbarrelStatPos,
		currentWeaponStats.modUnderbarrelValuePos,
		slot,
	]);

	// CHD
	useEffect(() => {
		let chdPercent = 0;
		// spec - nothing
		// watch
		chdPercent += ctxApp.watchStats.offensive.criticalHitDamage;
		// weapon
		chdPercent += checkWeapon("Critical Hit Damage");
		// gear
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			chdPercent += checkGear(element);
		}
		// brandsets
		chdPercent += checkBrandsets();
		// gearsets
		chdPercent += checkGearsets();

		function checkWeapon(attribute: string = "Critical Hit Damage") {
			let chdWeapon = 25;
			// check core2
			if (currentWeapon.core2 === attribute) {
				chdWeapon += currentWeaponStats.core2Value!;
			}
			// check attribute1
			if (currentWeaponStats.attribute1 === attribute) {
				chdWeapon += currentWeaponStats.attribute1Value!;
			}
			// check mods
			if (currentWeaponStats.modOpticStatPos === attribute) {
				chdWeapon += currentWeaponStats.modOpticValuePos;
			}
			if (currentWeaponStats.modMagazineStatPos === attribute) {
				chdWeapon += currentWeaponStats.modMagazineValuePos;
			}
			if (currentWeaponStats.modUnderbarrelStatPos === attribute) {
				chdWeapon += currentWeaponStats.modUnderbarrelValuePos;
			}
			if (currentWeaponStats.modMuzzleStatPos === attribute) {
				chdWeapon += currentWeaponStats.modMuzzleValuePos;
			}
			return chdWeapon;
		}

		function checkGear(
			slot: any,
			attribute: string = "Critical Hit Damage"
		) {
			let chdGear = 0;
			switch (slot.key) {
				case "mask":
					// check core
					if (ctxApp.maskStats.core1Name === attribute) {
						chdGear += ctxApp.maskStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.maskStats.attribute1Name === attribute) {
						chdGear += ctxApp.maskStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.maskStats.attribute2Name === attribute) {
						chdGear += ctxApp.maskStats.attribute2Value!;
					}
					break;
				case "backpack":
					// check core
					if (ctxApp.backpackStats.core1Name === attribute) {
						chdGear += ctxApp.backpackStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.backpackStats.attribute1Name === attribute) {
						chdGear += ctxApp.backpackStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.backpackStats.attribute2Name === attribute) {
						chdGear += ctxApp.backpackStats.attribute2Value!;
					}
					break;
				case "vest":
					// check core
					if (ctxApp.vestStats.core1Name === attribute) {
						chdGear += ctxApp.vestStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.vestStats.attribute1Name === attribute) {
						chdGear += ctxApp.vestStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.vestStats.attribute2Name === attribute) {
						chdGear += ctxApp.vestStats.attribute2Value!;
					}
					break;
				case "gloves":
					// check core
					if (ctxApp.glovesStats.core1Name === attribute) {
						chdGear += ctxApp.glovesStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.glovesStats.attribute1Name === attribute) {
						chdGear += ctxApp.glovesStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.glovesStats.attribute2Name === attribute) {
						chdGear += ctxApp.glovesStats.attribute2Value!;
					}
					break;
				case "holster":
					// check core
					if (ctxApp.holsterStats.core1Name === attribute) {
						chdGear += ctxApp.holsterStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.holsterStats.attribute1Name === attribute) {
						chdGear += ctxApp.holsterStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.holsterStats.attribute2Name === attribute) {
						chdGear += ctxApp.holsterStats.attribute2Value!;
					}
					break;
				case "kneepads":
					// check core
					if (ctxApp.kneepadsStats.core1Name === attribute) {
						chdGear += ctxApp.kneepadsStats.core1Value!;
					}
					//check attribute1
					if (ctxApp.kneepadsStats.attribute1Name === attribute) {
						chdGear += ctxApp.kneepadsStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.kneepadsStats.attribute2Name === attribute) {
						chdGear += ctxApp.kneepadsStats.attribute2Value!;
					}
					break;
			}
			return chdGear;
		}
		function checkBrandsets() {
			let bsBonus = 0;
			for (let i = 0; i < ctxApp.brandsetBonuses.length; i++) {
				const element = ctxApp.brandsetBonuses[i];
				// check critical hit damage
				if (element.attribute === "Critical Hit Damage") {
					bsBonus += element.value;
				}
			}
			return bsBonus;
		}

		function checkGearsets() {
			let gsBonus = 0;
			// for (let i = 0; i < ctxApp.gearsetBonuses.length; i++) {
			// 	const element = ctxApp.gearsetBonuses[i];
			// 	// check critical hit damage
			// 	if (element.attribute === "Critical Hit Damage") {
			// 		gsBonus += element.value;
			// 	}
			// }
			return gsBonus;
		}

		setCHDPercent(chdPercent);
	}, [
		ctxApp.backpackStats.attribute1Name,
		ctxApp.backpackStats.attribute1Value,
		ctxApp.backpackStats.attribute2Name,
		ctxApp.backpackStats.attribute2Value,
		ctxApp.backpackStats.core1Name,
		ctxApp.backpackStats.core1Value,
		ctxApp.brandsetBonuses,
		ctxApp.gearsetBonuses,
		ctxApp.glovesStats.attribute1Name,
		ctxApp.glovesStats.attribute1Value,
		ctxApp.glovesStats.attribute2Name,
		ctxApp.glovesStats.attribute2Value,
		ctxApp.glovesStats.core1Name,
		ctxApp.glovesStats.core1Value,
		ctxApp.holsterStats.attribute1Name,
		ctxApp.holsterStats.attribute1Value,
		ctxApp.holsterStats.attribute2Name,
		ctxApp.holsterStats.attribute2Value,
		ctxApp.holsterStats.core1Name,
		ctxApp.holsterStats.core1Value,
		ctxApp.kneepadsStats.attribute1Name,
		ctxApp.kneepadsStats.attribute1Value,
		ctxApp.kneepadsStats.attribute2Name,
		ctxApp.kneepadsStats.attribute2Value,
		ctxApp.kneepadsStats.core1Name,
		ctxApp.kneepadsStats.core1Value,
		ctxApp.maskStats.attribute1Name,
		ctxApp.maskStats.attribute1Value,
		ctxApp.maskStats.attribute2Name,
		ctxApp.maskStats.attribute2Value,
		ctxApp.maskStats.core1Name,
		ctxApp.maskStats.core1Value,
		ctxApp.vestStats.attribute1Name,
		ctxApp.vestStats.attribute1Value,
		ctxApp.vestStats.attribute2Name,
		ctxApp.vestStats.attribute2Value,
		ctxApp.vestStats.core1Name,
		ctxApp.vestStats.core1Value,
		ctxApp.watchStats.offensive.criticalHitDamage,
		currentWeapon.core2,
		currentWeaponStats.attribute1,
		currentWeaponStats.attribute1Value,
		currentWeaponStats.core2Value,
		currentWeaponStats.modMagazineStatPos,
		currentWeaponStats.modMagazineValuePos,
		currentWeaponStats.modMuzzleStatPos,
		currentWeaponStats.modMuzzleValuePos,
		currentWeaponStats.modOpticStatPos,
		currentWeaponStats.modOpticValuePos,
		currentWeaponStats.modUnderbarrelStatPos,
		currentWeaponStats.modUnderbarrelValuePos,
	]);

	// TWD = Sum of Total Weapon Damage
	useEffect(() => {
		setTWDPercent(0);
	}, []);

	// DtA = Damage to Armor (will be replaced by DtH if you have that instead and hitting health)
	useEffect(() => {
		let dtaPercent = 0;
		// spec - nothing
		// watch
		// dtaPercent += ctxApp.watchStats.offensive.criticalHitChance;
		// console.log(
		// 	"Watch",
		// 	slot,
		// 	ctxApp.watchStats.offensive.criticalHitChance,
		// 	dtaPercent
		// );
		// weapon
		dtaPercent += checkWeapon("Damage to Armor");
		// console.log("Weapon", slot, checkWeapon("Damage to Armor"), dtaPercent);
		// gear
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			dtaPercent += checkGear(element);
			// console.log("Gear", slot, checkGear(element), dtaPercent);
		}
		// brandsets
		dtaPercent += checkBrandsets();
		// gearset bonus
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			dtaPercent += checkGearsets(element);
			// console.log(
			// 	"Gearset",
			// 	slot,
			// 	element.name,
			// 	checkGearsets(element),
			// 	dtaPercent
			// );
		}

		function checkWeapon(attribute: string = "Damage to Armor") {
			let dtaWeapon = 0;
			// check core2
			if (currentWeapon.core2 === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeapon.core2,
				// 	currentWeaponStats.core2Value
				// );
				dtaWeapon += currentWeaponStats.core2Value!;
			}
			// check attribute1
			if (currentWeaponStats.attribute1 === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeapon.attribute1,
				// 	currentWeaponStats.attribute1Value
				// );
				dtaWeapon += currentWeaponStats.attribute1Value!;
			}
			// check mods
			if (currentWeaponStats.modOpticStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modOpticStatPos,
				// 	currentWeaponStats.modOpticValuePos
				// );
				dtaWeapon += currentWeaponStats.modOpticValuePos;
			}
			if (currentWeaponStats.modMagazineStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modMagazineStatPos,
				// 	currentWeaponStats.modMagazineValuePos
				// );
				dtaWeapon += currentWeaponStats.modMagazineValuePos;
			}
			if (currentWeaponStats.modUnderbarrelStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modUnderbarrelStatPos,
				// 	currentWeaponStats.modUnderbarrelValuePos
				// );
				dtaWeapon += currentWeaponStats.modUnderbarrelValuePos;
			}
			if (currentWeaponStats.modMuzzleStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modMuzzleStatPos,
				// 	currentWeaponStats.modMuzzleValuePos
				// );
				dtaWeapon += currentWeaponStats.modMuzzleValuePos;
			}
			return dtaWeapon;
		}

		function checkGear(slot: any, attribute: string = "Damage to Armor") {
			let dtaGear = 0;
			switch (slot.key) {
				case "mask":
					// check core
					// if (ctxApp.maskStats.core1Name === attribute) {
					// 	chcGear += ctxApp.maskStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.maskStats.attribute1Name === attribute) {
						dtaGear += ctxApp.maskStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.maskStats.attribute2Name === attribute) {
						dtaGear += ctxApp.maskStats.attribute2Value!;
					}
					break;
				case "backpack":
					// check core
					// if (ctxApp.backpackStats.core1Name === attribute) {
					// 	chcGear += ctxApp.backpackStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.backpackStats.attribute1Name === attribute) {
						dtaGear += ctxApp.backpackStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.backpackStats.attribute2Name === attribute) {
						dtaGear += ctxApp.backpackStats.attribute2Value!;
					}
					break;
				case "vest":
					// check core
					// if (ctxApp.vestStats.core1Name === attribute) {
					// 	chcGear += ctxApp.vestStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.vestStats.attribute1Name === attribute) {
						dtaGear += ctxApp.vestStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.vestStats.attribute2Name === attribute) {
						dtaGear += ctxApp.vestStats.attribute2Value!;
					}
					break;
				case "gloves":
					// check core
					// if (ctxApp.glovesStats.core1Name === attribute) {
					// 	chcGear += ctxApp.glovesStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.glovesStats.attribute1Name === attribute) {
						dtaGear += ctxApp.glovesStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.glovesStats.attribute2Name === attribute) {
						dtaGear += ctxApp.glovesStats.attribute2Value!;
					}
					break;
				case "holster":
					// check core
					// if (ctxApp.holsterStats.core1Name === attribute) {
					// 	chcGear += ctxApp.holsterStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.holsterStats.attribute1Name === attribute) {
						dtaGear += ctxApp.holsterStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.holsterStats.attribute2Name === attribute) {
						dtaGear += ctxApp.holsterStats.attribute2Value!;
					}
					break;
				case "kneepads":
					// check core
					// if (ctxApp.kneepadsStats.core1Name === attribute) {
					// 	chcGear += ctxApp.kneepadsStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.kneepadsStats.attribute1Name === attribute) {
						dtaGear += ctxApp.kneepadsStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.kneepadsStats.attribute2Name === attribute) {
						dtaGear += ctxApp.kneepadsStats.attribute2Value!;
					}
					break;
			}
			return dtaGear;
		}
		function checkBrandsets() {
			let bsBonus = 0;
			for (let i = 0; i < ctxApp.brandsetBonuses.length; i++) {
				const element = ctxApp.brandsetBonuses[i];
				// check damage to armor
				if (element.attribute === "Damage to Armor") {
					bsBonus += element.value;
				}
			}
			return bsBonus;
		}

		function checkGearsets(element: any) {
			let gsBonus = 0;
			const attribute = "Damage to Armor";
			switch (element.key) {
				case "mask":
					if (ctxApp.gearsetBonuses.mask.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.mask.value;
					}
					break;
				case "backpack":
					if (
						ctxApp.gearsetBonuses.backpack.attribute ===
							attribute ||
						ctxApp.gearsetBonuses.backpack.attribute ===
							currentWeapon.core1
					) {
						gsBonus = ctxApp.gearsetBonuses.backpack.value;
					}
					break;
				case "vest":
					if (ctxApp.gearsetBonuses.vest.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.vest.value;
					}
					break;
				case "gloves":
					if (ctxApp.gearsetBonuses.gloves.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.gloves.value;
					}
					break;
				case "holster":
					if (ctxApp.gearsetBonuses.holster.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.holster.value;
					}
					break;
				case "kneepads":
					if (
						ctxApp.gearsetBonuses.kneepads.attribute === attribute
					) {
						// console.log(ctxApp.gearsetBonuses.kneepads.value);
						gsBonus = ctxApp.gearsetBonuses.kneepads.value;
					}
					break;
			}
			return gsBonus;
		}
		setDTAPercent(dtaPercent);
	}, [
		ctxApp.backpackStats.attribute1Name,
		ctxApp.backpackStats.attribute1Value,
		ctxApp.backpackStats.attribute2Name,
		ctxApp.backpackStats.attribute2Value,
		ctxApp.brandsetBonuses,
		ctxApp.gearsetBonuses.backpack.attribute,
		ctxApp.gearsetBonuses.backpack.value,
		ctxApp.gearsetBonuses.gloves.attribute,
		ctxApp.gearsetBonuses.gloves.value,
		ctxApp.gearsetBonuses.holster.attribute,
		ctxApp.gearsetBonuses.holster.value,
		ctxApp.gearsetBonuses.kneepads.attribute,
		ctxApp.gearsetBonuses.kneepads.value,
		ctxApp.gearsetBonuses.mask.attribute,
		ctxApp.gearsetBonuses.mask.value,
		ctxApp.gearsetBonuses.vest.attribute,
		ctxApp.gearsetBonuses.vest.value,
		ctxApp.glovesStats.attribute1Name,
		ctxApp.glovesStats.attribute1Value,
		ctxApp.glovesStats.attribute2Name,
		ctxApp.glovesStats.attribute2Value,
		ctxApp.holsterStats.attribute1Name,
		ctxApp.holsterStats.attribute1Value,
		ctxApp.holsterStats.attribute2Name,
		ctxApp.holsterStats.attribute2Value,
		ctxApp.kneepadsStats.attribute1Name,
		ctxApp.kneepadsStats.attribute1Value,
		ctxApp.kneepadsStats.attribute2Name,
		ctxApp.kneepadsStats.attribute2Value,
		ctxApp.maskStats.attribute1Name,
		ctxApp.maskStats.attribute1Value,
		ctxApp.maskStats.attribute2Name,
		ctxApp.maskStats.attribute2Value,
		ctxApp.vestStats.attribute1Name,
		ctxApp.vestStats.attribute1Value,
		ctxApp.vestStats.attribute2Name,
		ctxApp.vestStats.attribute2Value,
		currentWeapon.core1,
		currentWeapon.core2,
		currentWeaponStats.attribute1,
		currentWeaponStats.attribute1Value,
		currentWeaponStats.core2Value,
		currentWeaponStats.modMagazineStatPos,
		currentWeaponStats.modMagazineValuePos,
		currentWeaponStats.modMuzzleStatPos,
		currentWeaponStats.modMuzzleValuePos,
		currentWeaponStats.modOpticStatPos,
		currentWeaponStats.modOpticValuePos,
		currentWeaponStats.modUnderbarrelStatPos,
		currentWeaponStats.modUnderbarrelValuePos,
		slot,
	]);

	// DtTooC= Damage to Targets Out of Cover
	useEffect(() => {
		let dttoocPercent = 0;
		// spec - nothing
		// watch
		// dttoocPercent += ctxApp.watchStats.offensive.criticalHitChance;
		// console.log(
		// 	"Watch",
		// 	slot,
		// 	ctxApp.watchStats.offensive.criticalHitChance,
		// 	dttoocPercent
		// );
		// weapon
		dttoocPercent += checkWeapon("Damage to TOC");
		// console.log(
		// 	"Weapon",
		// 	slot,
		// 	checkWeapon("Damage to TOC"),
		// 	dttoocPercent
		// );
		// gear
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			dttoocPercent += checkGear(element);
			// console.log("Gear", slot, checkGear(element), dttoocPercent);
		}
		// brandsets
		dttoocPercent += checkBrandsets();
		// gearset bonus
		for (let i = 0; i < gearSlots.length; i++) {
			const element = gearSlots[i];
			dttoocPercent += checkGearsets(element);
			// console.log(
			// 	"Gearset",
			// 	slot,
			// 	element.name,
			// 	checkGearsets(element),
			// 	dttoocPercent
			// );
		}

		function checkWeapon(attribute: string = "Damage to TOC") {
			let dttoocWeapon = 0;
			// check core2
			if (currentWeapon.core2 === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeapon.core2,
				// 	currentWeaponStats.core2Value
				// );
				dttoocWeapon += currentWeaponStats.core2Value!;
			}
			// check attribute1
			if (currentWeaponStats.attribute1 === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeapon.attribute1,
				// 	currentWeaponStats.attribute1Value
				// );
				dttoocWeapon += currentWeaponStats.attribute1Value!;
			}
			// check mods
			if (currentWeaponStats.modOpticStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modOpticStatPos,
				// 	currentWeaponStats.modOpticValuePos
				// );
				dttoocWeapon += currentWeaponStats.modOpticValuePos;
			}
			if (currentWeaponStats.modMagazineStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modMagazineStatPos,
				// 	currentWeaponStats.modMagazineValuePos
				// );
				dttoocWeapon += currentWeaponStats.modMagazineValuePos;
			}
			if (currentWeaponStats.modUnderbarrelStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modUnderbarrelStatPos,
				// 	currentWeaponStats.modUnderbarrelValuePos
				// );
				dttoocWeapon += currentWeaponStats.modUnderbarrelValuePos;
			}
			if (currentWeaponStats.modMuzzleStatPos === attribute) {
				// console.log(
				// 	slot,
				// 	currentWeaponStats.modMuzzleStatPos,
				// 	currentWeaponStats.modMuzzleValuePos
				// );
				dttoocWeapon += currentWeaponStats.modMuzzleValuePos;
			}
			return dttoocWeapon;
		}

		function checkGear(slot: any, attribute: string = "Damage to TOC") {
			let dttoocGear = 0;
			switch (slot.key) {
				case "mask":
					// check core
					// if (ctxApp.maskStats.core1Name === attribute) {
					// 	chcGear += ctxApp.maskStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.maskStats.attribute1Name === attribute) {
						dttoocGear += ctxApp.maskStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.maskStats.attribute2Name === attribute) {
						dttoocGear += ctxApp.maskStats.attribute2Value!;
					}
					break;
				case "backpack":
					// check core
					// if (ctxApp.backpackStats.core1Name === attribute) {
					// 	chcGear += ctxApp.backpackStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.backpackStats.attribute1Name === attribute) {
						dttoocGear += ctxApp.backpackStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.backpackStats.attribute2Name === attribute) {
						dttoocGear += ctxApp.backpackStats.attribute2Value!;
					}
					break;
				case "vest":
					// check core
					// if (ctxApp.vestStats.core1Name === attribute) {
					// 	chcGear += ctxApp.vestStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.vestStats.attribute1Name === attribute) {
						dttoocGear += ctxApp.vestStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.vestStats.attribute2Name === attribute) {
						dttoocGear += ctxApp.vestStats.attribute2Value!;
					}
					break;
				case "gloves":
					// check core
					// if (ctxApp.glovesStats.core1Name === attribute) {
					// 	chcGear += ctxApp.glovesStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.glovesStats.attribute1Name === attribute) {
						dttoocGear += ctxApp.glovesStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.glovesStats.attribute2Name === attribute) {
						dttoocGear += ctxApp.glovesStats.attribute2Value!;
					}
					break;
				case "holster":
					// check core
					// if (ctxApp.holsterStats.core1Name === attribute) {
					// 	chcGear += ctxApp.holsterStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.holsterStats.attribute1Name === attribute) {
						dttoocGear += ctxApp.holsterStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.holsterStats.attribute2Name === attribute) {
						dttoocGear += ctxApp.holsterStats.attribute2Value!;
					}
					break;
				case "kneepads":
					// check core
					// if (ctxApp.kneepadsStats.core1Name === attribute) {
					// 	chcGear += ctxApp.kneepadsStats.core1Value;
					// }
					//check attribute1
					if (ctxApp.kneepadsStats.attribute1Name === attribute) {
						dttoocGear += ctxApp.kneepadsStats.attribute1Value!;
					}
					//check attribute 2
					if (ctxApp.kneepadsStats.attribute2Name === attribute) {
						dttoocGear += ctxApp.kneepadsStats.attribute2Value!;
					}
					break;
			}
			return dttoocGear;
		}
		function checkBrandsets() {
			let bsBonus = 0;
			for (let i = 0; i < ctxApp.brandsetBonuses.length; i++) {
				const element = ctxApp.brandsetBonuses[i];
				// check damage to armor
				if (element.attribute === "Damage to TOC") {
					bsBonus += element.value;
				}
			}
			return bsBonus;
		}

		function checkGearsets(element: any) {
			let gsBonus = 0;
			const attribute = "Damage to TOC";
			switch (element.key) {
				case "mask":
					if (ctxApp.gearsetBonuses.mask.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.mask.value;
					}
					break;
				case "backpack":
					if (
						ctxApp.gearsetBonuses.backpack.attribute ===
							attribute ||
						ctxApp.gearsetBonuses.backpack.attribute ===
							currentWeapon.core1
					) {
						gsBonus = ctxApp.gearsetBonuses.backpack.value;
					}
					break;
				case "vest":
					if (ctxApp.gearsetBonuses.vest.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.vest.value;
					}
					break;
				case "gloves":
					if (ctxApp.gearsetBonuses.gloves.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.gloves.value;
					}
					break;
				case "holster":
					if (ctxApp.gearsetBonuses.holster.attribute === attribute) {
						gsBonus = ctxApp.gearsetBonuses.holster.value;
					}
					break;
				case "kneepads":
					if (
						ctxApp.gearsetBonuses.kneepads.attribute === attribute
					) {
						// console.log(ctxApp.gearsetBonuses.kneepads.value);
						gsBonus = ctxApp.gearsetBonuses.kneepads.value;
					}
					break;
			}
			return gsBonus;
		}
		setDTTOOCPercent(dttoocPercent);
	}, [
		ctxApp.backpackStats.attribute1Name,
		ctxApp.backpackStats.attribute1Value,
		ctxApp.backpackStats.attribute2Name,
		ctxApp.backpackStats.attribute2Value,
		ctxApp.brandsetBonuses,
		ctxApp.gearsetBonuses.backpack.attribute,
		ctxApp.gearsetBonuses.backpack.value,
		ctxApp.gearsetBonuses.gloves.attribute,
		ctxApp.gearsetBonuses.gloves.value,
		ctxApp.gearsetBonuses.holster.attribute,
		ctxApp.gearsetBonuses.holster.value,
		ctxApp.gearsetBonuses.kneepads.attribute,
		ctxApp.gearsetBonuses.kneepads.value,
		ctxApp.gearsetBonuses.mask.attribute,
		ctxApp.gearsetBonuses.mask.value,
		ctxApp.gearsetBonuses.vest.attribute,
		ctxApp.gearsetBonuses.vest.value,
		ctxApp.glovesStats.attribute1Name,
		ctxApp.glovesStats.attribute1Value,
		ctxApp.glovesStats.attribute2Name,
		ctxApp.glovesStats.attribute2Value,
		ctxApp.holsterStats.attribute1Name,
		ctxApp.holsterStats.attribute1Value,
		ctxApp.holsterStats.attribute2Name,
		ctxApp.holsterStats.attribute2Value,
		ctxApp.kneepadsStats.attribute1Name,
		ctxApp.kneepadsStats.attribute1Value,
		ctxApp.kneepadsStats.attribute2Name,
		ctxApp.kneepadsStats.attribute2Value,
		ctxApp.maskStats.attribute1Name,
		ctxApp.maskStats.attribute1Value,
		ctxApp.maskStats.attribute2Name,
		ctxApp.maskStats.attribute2Value,
		ctxApp.vestStats.attribute1Name,
		ctxApp.vestStats.attribute1Value,
		ctxApp.vestStats.attribute2Name,
		ctxApp.vestStats.attribute2Value,
		ctxApp.watchStats.offensive.criticalHitChance,
		currentWeapon.core1,
		currentWeapon.core2,
		currentWeaponStats.attribute1,
		currentWeaponStats.attribute1Value,
		currentWeaponStats.core2Value,
		currentWeaponStats.modMagazineStatPos,
		currentWeaponStats.modMagazineValuePos,
		currentWeaponStats.modMuzzleStatPos,
		currentWeaponStats.modMuzzleValuePos,
		currentWeaponStats.modOpticStatPos,
		currentWeaponStats.modOpticValuePos,
		currentWeaponStats.modUnderbarrelStatPos,
		currentWeaponStats.modUnderbarrelValuePos,
		dttoocPercent,
	]);

	/*
	AMP1, 2, etc = stands for amplifying talents like Glass Cannon, Opportunistic, Intimidate, …
	*/

	useEffect(() => {
		let bodyshot = 0;
		// Base Damage * (1 + WD)
		bodyshot = currentWeapon.baseDamage * (1 + wdPercent / 100);
		// console.log(
		// 	"WD",
		// 	slot,
		// 	bodyshot,
		// 	currentWeapon.baseDamage * (1 + wdPercent / 100)
		// );
		// * (1 + CHD + HSD)
		//		bodyshot *= 1 + chdPercent / 100;
		// console.log(bodyshot);
		// * (1 + TWD)
		bodyshot *= 1 + twdPercent / 100;
		// console.log("TWD", slot, bodyshot, 1 + twdPercent / 100);
		// * (1 + DtA)
		bodyshot *= 1 + dtaPercent / 100;
		// console.log("DTA", slot, bodyshot, 1 + dtaPercent / 100);
		// * (1 + DtTooC)
		bodyshot *= 1 + dttoocPercent / 100;
		// console.log("DTTOC", slot, bodyshot, 1 + dttoocPercent / 100);
		// * (1 + AMP1) * (1 + AMP2)
		// testing with vigilence and glass cannon
		bodyshot *= 1 + 25 / 100;
		// console.log("Vigilence", slot, bodyshot, 1 + 25 / 100);
		bodyshot *= 1 + 30 / 100;
		// console.log("Glass Cannon", slot, bodyshot, 1 + 30 / 100);
		// console.log(bodyshot);
		setBodyshot(bodyshot);
	}, [
		currentWeapon.baseDamage,
		dtaPercent,
		dttoocPercent,
		slot,
		twdPercent,
		wdPercent,
	]);

	useEffect(() => {
		let critBodyshot = 0;
		// Base Damage * (1 + WD)
		critBodyshot = currentWeapon.baseDamage * (1 + wdPercent / 100);
		// console.log(
		// 	"WD",
		// 	slot,
		// 	critBodyshot,
		// 	currentWeapon.baseDamage * (1 + wdPercent / 100)
		// );
		// * (1 + CHD + HSD)
		critBodyshot *= 1 + chdPercent / 100;
		// console.log("CHD", slot, critBodyshot, 1 + chdPercent / 100);
		// * (1 + TWD)
		critBodyshot *= 1 + twdPercent / 100;
		// console.log("TWD", slot, critBodyshot, 1 + twdPercent / 100);
		// * (1 + DtA)
		critBodyshot *= 1 + dtaPercent / 100;
		// console.log("DTA", slot, critBodyshot, 1 + dtaPercent / 100);
		// * (1 + DtTooC)
		critBodyshot *= 1 + dttoocPercent / 100;
		// console.log("DTTOC", slot, critBodyshot, 1 + dttoocPercent / 100);
		// * (1 + AMP1) * (1 + AMP2)
		// testing with vigilence and glass cannon
		critBodyshot *= 1 + 25 / 100;
		// console.log("Vigilence", slot, critBodyshot, 1 + 25 / 100);
		critBodyshot *= 1 + 30 / 100;
		// console.log("Glass Cannon", slot, critBodyshot, 1 + 30 / 100);
		// console.log(bodyshot);
		setCritBodyshot(critBodyshot);
	}, [
		chdPercent,
		currentWeapon.baseDamage,
		dtaPercent,
		dttoocPercent,
		slot,
		twdPercent,
		wdPercent,
	]);

	useEffect(() => {
		let headshot = 0;
		// Base Damage * (1 + WD)
		headshot = currentWeapon.baseDamage * (1 + wdPercent / 100);
		// console.log(
		// 	"WD",
		// 	slot,
		// 	headshot,
		// 	currentWeapon.baseDamage * (1 + wdPercent / 100)
		// );
		// * (1 + CHD + HSD)
		headshot *= 1 + hsdPercent / 100;
		// console.log("CHD", slot, headshot, 1 + chdPercent / 100);
		// * (1 + TWD)
		headshot *= 1 + twdPercent / 100;
		// console.log("TWD", slot, headshot, 1 + twdPercent / 100);
		// * (1 + DtA)
		headshot *= 1 + dtaPercent / 100;
		// console.log("DTA", slot, headshot, 1 + dtaPercent / 100);
		// * (1 + DtTooC)
		headshot *= 1 + dttoocPercent / 100;
		// console.log("DTTOC", slot, headshot, 1 + dttoocPercent / 100);
		// * (1 + AMP1) * (1 + AMP2)
		// testing with vigilence and glass cannon
		headshot *= 1 + 25 / 100;
		// console.log("Vigilence", slot, headshot, 1 + 25 / 100);
		headshot *= 1 + 30 / 100;
		// console.log("Glass Cannon", slot, headshot, 1 + 30 / 100);
		// console.log(bodyshot);
		setHeadshot(headshot);
	}, [
		chdPercent,
		currentWeapon.baseDamage,
		dtaPercent,
		dttoocPercent,
		hsdPercent,
		slot,
		twdPercent,
		wdPercent,
	]);

	useEffect(() => {
		let critHeadshot = 0;
		// Base Damage * (1 + WD)
		critHeadshot = currentWeapon.baseDamage * (1 + wdPercent / 100);
		// console.log(
		// 	"WD",
		// 	slot,
		// 	critHeadshot,
		// 	currentWeapon.baseDamage * (1 + wdPercent / 100)
		// );
		// * (1 + CHD + HSD)
		critHeadshot *= 1 + (chdPercent + hsdPercent) / 100;
		// console.log("CHD", slot, critHeadshot, 1 + chdPercent / 100);
		// * (1 + TWD)
		critHeadshot *= 1 + twdPercent / 100;
		// console.log("TWD", slot, critHeadshot, 1 + twdPercent / 100);
		// * (1 + DtA)
		critHeadshot *= 1 + dtaPercent / 100;
		// console.log("DTA", slot, critHeadshot, 1 + dtaPercent / 100);
		// * (1 + DtTooC)
		critHeadshot *= 1 + dttoocPercent / 100;
		// console.log("DTTOC", slot, critHeadshot, 1 + dttoocPercent / 100);
		// * (1 + AMP1) * (1 + AMP2)
		// testing with vigilence and glass cannon
		critHeadshot *= 1 + 25 / 100;
		// console.log("Vigilence", slot, critHeadshot, 1 + 25 / 100);
		critHeadshot *= 1 + 30 / 100;
		// console.log("Glass Cannon", slot, critHeadshot, 1 + 30 / 100);
		// console.log(bodyshot);
		setCritHeadshot(critHeadshot);
	}, [
		chdPercent,
		currentWeapon.baseDamage,
		dtaPercent,
		dttoocPercent,
		hsdPercent,
		slot,
		twdPercent,
		wdPercent,
	]);

	useEffect(() => {
		setCalculations((prevState) => {
			return {
				...prevState,
				wdPercent,
				chcPercent,
				chdPercent,
				hsdPercent,
				twdPercent,
				dtaPercent,
				dttoocPercent,
				bodyshot,
				critBodyshot,
				headshot,
				critHeadshot,
			};
		});
	}, [
		wdPercent,
		chcPercent,
		chdPercent,
		hsdPercent,
		twdPercent,
		dtaPercent,
		dttoocPercent,
		bodyshot,
		critBodyshot,
		headshot,
		critHeadshot,
	]);

	return calculations;
};

export default Calculator;

// // function damagePerBullet(area: string, crit: boolean) {
/*
		Damage per Bullet = Base Damage * (1 + WD) * (1 + CHD + HSD) * (1 + TWD) * (1 + DtA) * (1 + DtTooC) * (1 + AMP1) * (1 + AMP2)
		• WD = Sum of All Weapon Damage and Weapon Class Damage
		• TWD = Sum of Total Weapon Damage
		• DtA = Damage to Armor (will be replaced by DtH if you have that instead and hitting health)
		• DtTooC= Damage to Targets Out of Cover
		• AMP1, 2, etc = stands for amplifying talents like Glass Cannon, Opportunistic, Intimidate, …
		HSD and CHD are additive if they are both present. Example, when you land a critical headshot, you add the two percentages and make their sum a multiplier.
		If one of them is not active, set its value to 0%. Example: if you hit a critical body shot, HSD will be set to 0%. If you land a non-critical headshot, you will set CHD to 0%.
		*/
// // let damagePerBullet = 0;
// WD = Sum of All Weapon Damage and Weapon Class Damage
// TWD = Sum of Total Weapon Damage
// DtA = Damage to Armor (will be replaced by DtH if you have that instead and hitting health)
// DtTooC= Damage to Targets Out of Cover
// AMP1, 2, etc = stands for amplifying talents like Glass Cannon, Opportunistic, Intimidate, …
// HSD and CHD are additive if they are both present.
// // }
