import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonImg,
	IonRow,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import Weapon from "../../models/weapon";
import WeaponStats from "../../models/weaponStats";

import css from "./BuildMain.module.css";
import "../../pages/CssWeapon.css";

interface BuildWeaponProps {
	slot: string;
}

const imagesURI: string = "../../assets/images/weapon-talent-";

const BuildWeapon: React.FC<BuildWeaponProps> = ({ slot }) => {
	const ctxApp = useContext(AppContext);
	const [weaponName, setWeaponName] = useState<string>();
	const [weaponCardHeaderClassName, setWeaponCardHeaderClassName] =
		useState("");
	const [talentImage, setTalentImage] = useState("assets/images/spacer.png");
	const [talentLabel, setTalentLabel] = useState("");
	const [expertiseImage, setExpertiseImage] = useState(
		"assets/images/proficient.png"
	);
	const [expertiseValue, setExpertiseValue] = useState<string | number>(
		"Proficient"
	);

	useEffect(() => {
		function processSlot(weapon: Weapon, stats: WeaponStats) {
			setWeaponName(weapon.name);
			// console.log(
			// 	(weapon.quality + "-" + weapon.weaponType)
			// 		.toLowerCase()
			// 		.replaceAll(" ", "-")
			// );
			setWeaponCardHeaderClassName(
				css[
					(weapon.quality + "-" + weapon.weaponType)
						.toLowerCase()
						.replaceAll(" ", "-")
				]
			);
			setTalentImage(
				"assets/images/weapon-talent-" +
					stats.talent.toLowerCase().replaceAll(" ", "-") +
					".png"
			);
			setTalentLabel(stats.talent);
			setExpertiseValue(stats.expertise);
			setExpertiseImage(
				`assets/images/proficient-${stats.expertise}.png`
			);
			// setTalent(
			// 	require("../../assets/images/weapon-talent-" +
			// 		stats.talent +
			// 		".png")
			// );
		}

		switch (slot.toLowerCase()) {
			case "primary":
				processSlot(ctxApp.primaryWeapon, ctxApp.primaryStats);
				break;
			case "secondary":
				processSlot(ctxApp.secondaryWeapon, ctxApp.secondaryStats);
				break;
			case "pistol":
				processSlot(ctxApp.pistolWeapon, ctxApp.pistolStats);
				break;
		}
	}, [
		ctxApp.pistolStats,
		ctxApp.pistolWeapon,
		ctxApp.primaryStats,
		ctxApp.primaryWeapon,
		ctxApp.secondaryStats,
		ctxApp.secondaryWeapon,
		slot,
	]);

	return (
		<>
			<IonCol
				className="ion-no-padding"
				size="12"
				size-lg="6"
				size-md="6"
				size-sm="6"
			>
				<IonGrid className="ion-no-padding">
					<IonRow>
						<IonCol className="ionColBorder ion-no-padding">
							<IonCard>
								{/* <img
													className={css.weaponHeader}
													alt=""
													src={
														"assets/images/assault-rifle-high-end.png"
													}
												/> */}
								<IonCardHeader
									className={weaponCardHeaderClassName}
								>
									<img
										className={css.weaponTalentImage}
										alt={""}
										src={talentImage}
									/>
									<IonImg
										className={css.weaponExpertiseImage}
										alt={""}
										src={expertiseImage}
									/>
									<p className={css.weaponTitle}>
										{weaponName}
									</p>
									<p className={css.weaponTalentLabel}>
										{talentLabel}
									</p>
									{/* <p className={css.weaponExpertiseLabel}>
										Expertise ({expertiseValue})
									</p> */}
									<IonCardSubtitle>&nbsp;</IonCardSubtitle>
									<IonCardTitle></IonCardTitle>
								</IonCardHeader>

								<IonCardContent className="ion-no-padding"></IonCardContent>
							</IonCard>
						</IonCol>
						{/* <IonCol
												size="9"
												className="ionColBorder ion-no-padding"
											>
												<p>Primary</p>
											</IonCol> */}
					</IonRow>
				</IonGrid>
			</IonCol>
		</>
	);
};

export default BuildWeapon;
