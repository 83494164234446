import { Redirect, Route } from "react-router-dom";
import {
	IonApp,
	IonIcon,
	IonLabel,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
	setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
	bodyOutline,
	bugOutline,
	calculatorOutline,
	constructOutline,
	exitOutline,
	imagesOutline,
	keyOutline,
	libraryOutline,
	statsChartOutline,
} from "ionicons/icons";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Components */
import Login from "./pages/Login";
import Home from "./pages/Home";
import Stats from "./pages/Stats";
import Calculations from "./pages/Calculations";

import TSData from "./pages/TSData";
import Build from "./pages/Build";
import Library from "./pages/Library";
import Menu from "./components/menu/Menu";
import Page from "./pages/Page";
import { useContext } from "react";
import AuthContext from "./context/auth-context";
import RegisterForm from "./components/RegisterForm";
import About from "./pages/About";
import Profile from "./pages/Profile";

setupIonicReact();

const App: React.FC = () => {
	const ctxAuth = useContext(AuthContext);

	const logoutHandler = () => {
		// console.log("logging out");
		ctxAuth.onLogout();
		// history.replace("/");
	};

	return (
		<>
			{/* <IonApp>
				<IonReactRouter>
					<IonSplitPane contentId="main">
						<Menu />
						<IonRouterOutlet id="main">
							<Route path="/" exact={true}>
								<Redirect to="/home" />
							</Route>
							<Route path="/:name" exact={true}>
								<Page />
							</Route>
							<Route exact path="/home">
								<Home />
							</Route>
							<Route exact path="/home">
								<Home />
							</Route>
							<Route exact path="/library">
								<Library />
							</Route>
							<Route exact path="/stats">
								<Stats />
							</Route>
							<Route exact path="/calculations">
								<Calculations />
							</Route>
							<Route exact path="/build">
								<Build />
							</Route>
							<Route exact path="/login">
								<Login />
							</Route>
							<Route exact path="/tsdata">
								<TSData />
							</Route>
						</IonRouterOutlet>
					</IonSplitPane>
				</IonReactRouter>
			</IonApp> */}

			<IonApp>
				{/* <IonSplitPane contentId="main"> */}
				<IonReactRouter>
					{ctxAuth.isLoggedIn && <Menu />}

					<IonTabs>
						<IonRouterOutlet>
							<Route exact path="/">
								<Redirect to="/home" />
							</Route>
							<Route exact path="/home">
								<Home />
							</Route>
							<Route exact path="/library">
								<Library />
							</Route>
							<Route exact path="/stats">
								<Stats />
							</Route>
							<Route exact path="/about">
								<About />
							</Route>
							<Route exact path="/profile">
								<Profile />
							</Route>
							<Route exact path="/calculations">
								<Calculations />
							</Route>
							<Route exact path="/build">
								<Build />
							</Route>
							<Route exact path="/build/:buildId">
								<Build />
							</Route>
							<Route exact path="/login">
								<Login />
							</Route>
							<Route exact path="/register">
								<RegisterForm />
							</Route>
							<Route exact path="/tsdata">
								<TSData />
							</Route>
						</IonRouterOutlet>
						<IonTabBar slot="bottom" id="main">
							<IonTabButton tab="home" href="/home">
								<IonIcon icon={constructOutline} />
								<IonLabel>Build Station</IonLabel>
							</IonTabButton>
							{ctxAuth.isLoggedIn && (
								<IonTabButton tab="library" href="/library">
									<IonIcon icon={libraryOutline} />
									<IonLabel>Library</IonLabel>
								</IonTabButton>
							)}
							<IonTabButton tab="stats" href="/stats">
								<IonIcon icon={statsChartOutline} />
								<IonLabel>Stats</IonLabel>
							</IonTabButton>
							<IonTabButton
								tab="calculations"
								href="/calculations"
							>
								<IonIcon icon={calculatorOutline} />
								<IonLabel>Calculations</IonLabel>
							</IonTabButton>
							{ctxAuth.isLoggedIn && (
								<IonTabButton tab="build" href="/build">
									<IonIcon icon={imagesOutline} />
									<IonLabel>Build Image</IonLabel>
								</IonTabButton>
							)}
							{!ctxAuth.isLoggedIn && (
								<IonTabButton tab="login" href="/login">
									<IonIcon icon={keyOutline} />
									<IonLabel>Login</IonLabel>
								</IonTabButton>
							)}
							{/* {ctxAuth.isLoggedIn && (
								<IonTabButton
									onClick={logoutHandler}
									tab="logout"
									href="#"
								>
									<IonIcon icon={exitOutline} />
									<IonLabel>Logout</IonLabel>
								</IonTabButton>
							)} */}
							{ctxAuth.isAdmin && (
								<IonTabButton tab="tsdata" href="/tsdata">
									<IonIcon icon={bugOutline} />
									<IonLabel>TS Data</IonLabel>
								</IonTabButton>
							)}
						</IonTabBar>
					</IonTabs>
				</IonReactRouter>
				{/* </IonSplitPane> */}
			</IonApp>
		</>
	);
};

export default App;
