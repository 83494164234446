import {
	IonButton,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
} from "@ionic/react";
import { useContext, useRef, useState } from "react";
// import { useHistory } from "react-router";
import { useHistory } from "react-router-dom";
import AuthContext from "../context/auth-context";
import "./LoginForm.css";

interface ContainerProps {
	name?: string;
}

interface InputChangeEventDetail<T = any> {
	value: T;
}

const LoginForm: React.FC<ContainerProps> = ({ name }) => {
	const history = useHistory();
	const { isLoggedIn, onLogin, isLoading } = useContext(AuthContext);

	const [username, setUsername] = useState("shd.builder.dev@fooweb.com");
	const usernameInputRef = useRef<HTMLIonInputElement>(null);
	const [password, setPassword] = useState("p@$$W)RD");
	const passwordInputRef = useRef<HTMLIonInputElement>(null);

	// const [error, setError] = useState(null);

	const usernameHandler = (event: CustomEvent<InputChangeEventDetail>) => {
		// console.log(event);
		setUsername(event.detail.value);
	};

	const passwordHandler = (event: CustomEvent<InputChangeEventDetail>) => {
		// console.log(event);
		setPassword(event.detail.value);
	};

	const handleLogin = (event: React.SyntheticEvent) => {
		event.preventDefault();
		// console.log(username, password);
		// onLogin(username, password).catch((error: string) => setError(null));
		// const enteredUsername = usernameInputRef.current?.value;
		// const enteredPassword = passwordInputRef.current?.value;
		// TODO validation
		onLogin(username, password);
		history.replace("/");
	};

	return (
		<div className="container">
			<div>
				<IonLoading
					cssClass="my-custom-class"
					isOpen={isLoading}
					// onDidDismiss={() => setShowLoading(false)}
					message={"Checking login..."}
					duration={1000}
				/>
			</div>

			{isLoggedIn && <p>Logged in!</p>}
			{!isLoggedIn && (
				<form onSubmit={handleLogin} className="gap-4 grid grid-cols-2">
					<IonItem>
						<IonLabel position="stacked">Username</IonLabel>
						<IonInput
							required
							ref={usernameInputRef}
							name="username"
							type="text"
							value={username}
							onIonChange={usernameHandler}
							placeholder="newemail@fooweb.com"
						/>
					</IonItem>
					<IonItem>
						<IonLabel position="stacked">Password</IonLabel>
						<IonInput
							required
							ref={passwordInputRef}
							name="password"
							value={password}
							onIonChange={passwordHandler}
							placeholder="password"
						/>
					</IonItem>
					<IonButton type="submit" onClick={handleLogin} size="small">
						Login
					</IonButton>
					<IonButton
						routerLink="/register"
						routerDirection="none"
						size="small"
					>
						Register
					</IonButton>
				</form>
			)}
		</div>
	);
};

export default LoginForm;
