import {
	IonInput,
	IonItem,
	IonList,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import WeaponAttributesData from "../../data/WeaponAttributesData";
import Weapon from "../../models/weapon";
import WeaponAttribute from "../../models/weaponAttributes";
import WeaponStats from "../../models/weaponStats";

let initWeaponAttributes: WeaponAttribute[] = WeaponAttributesData;
initWeaponAttributes = initWeaponAttributes.sort(function (a, b) {
	const nameA = a.name.toUpperCase(); // ignore upper and lowercase
	const nameB = b.name.toUpperCase(); // ignore upper and lowercase
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
});

interface WeaponAttributeProps {
	slot: string;
}

interface SelectChangeEventDetail<T = any> {
	value: T;
}

const WeaponAttributeTertiary = ({ slot }: WeaponAttributeProps) => {
	const ctxApp = useContext(AppContext);
	const [currentAttributes, setCurrentAttributes] =
		useState(initWeaponAttributes);
	const [selectedAttribute1Name, setSelectedAttribute1Name] =
		useState("Select Attribute");
	const [selectedAttribute1Value, setSelectedAttribute1Value] = useState<
		number | undefined
	>(0);
	const [selectedAttribute1Max, setSelectedAttribute1Max] = useState("0");
	const [selectDisabled, setSelectDisabled] = useState(false);

	const attribute1NameChangeHandler = (
		e: CustomEvent<SelectChangeEventDetail>
	) => {
		// console.log(e.detail.value);
		setSelectedAttribute1Name(e.detail.value);
		// reset input value only if no stats available
		// switch (slot.toLowerCase()) {
		// 	case "primary":
		// 		setSelectedAttribute1Value(
		// 			ctxApp.primaryStats.attribute1Value!
		// 		);
		// 		break;
		// 	case "secondary":
		// 		setSelectedAttribute1Value(
		// 			ctxApp.secondaryStats.attribute1Value!
		// 		);
		// 		break;
		// 	case "pistol":
		// 		setSelectedAttribute1Value(ctxApp.pistolStats.attribute1Value!);
		// 		break;
		// 	default:
		// 		setSelectedAttribute1Value(0);
		// 		break;
		// }
		// get/set max attribte value
		// console.log(e.detail);
		if (e.detail.value !== "" && e.detail.value !== "Select Attribute") {
			const selectedAttribute: WeaponAttribute[] =
				initWeaponAttributes.filter((attribute) => {
					return attribute.name === e.detail.value;
				});
			setSelectedAttribute1Max(
				parseInt(selectedAttribute[0].max).toString()
			);
			// setSelectedAttribute1Value(parseInt(selectedAttribute[0].max));
		}
		ctxApp.updateWeaponStat("attribute1", slot, e.detail.value);
	};

	const attribute1ValueChangeHandler = (e: CustomEvent) => {
		// console.log(e.detail.value);
		setSelectedAttribute1Value(e.detail.value);
		// setSelectedAttribute1Value((currValue) => {
		// 	if (currValue === undefined) {
		// 		return undefined;
		// 	} else {
		// 		return e.detail.value;
		// 	}
		// });
		ctxApp.updateWeaponStat(
			"attribute1Value",
			slot,
			e.detail.value,
			"number"
		);
	};

	const options = {
		cssClass: "my-custom-interface",
	};

	useEffect(() => {
		let updatedName = "";
		let updatedAttributes = initWeaponAttributes;

		function checkAttribute(weapon: Weapon, stats: WeaponStats) {
			if (weapon.name) {
				if (weapon.quality === "Exotic") {
					const exoticAttribute = initWeaponAttributes.filter(
						(attribute) => {
							return attribute.name === weapon.attribute1;
						}
					);
					setSelectDisabled(true);
					setSelectedAttribute1Name(exoticAttribute[0].name);
					setSelectedAttribute1Value((currValue) => {
						if (currValue === 0) {
							return 0;
						} else {
							return stats.attribute1Value!;
						}
					});
					setSelectedAttribute1Max(exoticAttribute[0].max);
				} else {
					updatedAttributes = updatedAttributes.filter(
						(attribute) => {
							return attribute.name !== weapon.core2;
						}
					);
					setSelectDisabled(false);
					updatedName =
						stats.attribute1 !== ""
							? stats.attribute1
							: "Select Attribute";
					setSelectedAttribute1Name(updatedName);
					setSelectedAttribute1Value((currValue) => {
						if (currValue === undefined) {
							return 0;
						} else {
							return stats.attribute1Value!;
						}
					});
					// const attribute = initWeaponAttributes.filter(
					// 	(attribute) => {
					// 		return attribute.name === updatedName;
					// 	}
					// );
					// console.log(attribute);
					// setSelectedAttribute1Max(attribute[0].max);
				}
			}
		}

		switch (slot.toLowerCase()) {
			case "primary":
				checkAttribute(ctxApp.primaryWeapon, ctxApp.primaryStats);
				// if (ctxApp.primaryWeapon.name) {
				// 	if (ctxApp.primaryWeapon.quality === "Exotic") {
				// 		const exoticAttribute = initWeaponAttributes.filter(
				// 			(attribute) => {
				// 				return (
				// 					attribute.name ===
				// 					ctxApp.primaryWeapon.attribute1
				// 				);
				// 			}
				// 		);
				// 		setSelectDisabled(true);
				// 		setSelectedAttribute1Name(exoticAttribute[0].name);
				// 		setSelectedAttribute1Value((currValue) => {
				// 			if (currValue === 0) {
				// 				return 0;
				// 			} else {
				// 				return ctxApp.primaryStats.attribute1Value!;
				// 			}
				// 		});
				// 		setSelectedAttribute1Max(exoticAttribute[0].max);
				// 	} else {
				// 		updatedAttributes = updatedAttributes.filter(
				// 			(attribute) => {
				// 				return (
				// 					attribute.name !==
				// 					ctxApp.primaryWeapon.core2
				// 				);
				// 			}
				// 		);
				// 		setSelectDisabled(false);
				// 		updatedName =
				// 			ctxApp.primaryStats.attribute1 !== ""
				// 				? ctxApp.primaryStats.attribute1
				// 				: "Select Attribute";
				// 		setSelectedAttribute1Name(updatedName);
				// 		setSelectedAttribute1Value((currValue) => {
				// 			if (currValue === undefined) {
				// 				return 0;
				// 			} else {
				// 				return ctxApp.primaryStats.attribute1Value!;
				// 			}
				// 		});
				// 		// const attribute = initWeaponAttributes.filter(
				// 		// 	(attribute) => {
				// 		// 		return attribute.name === updatedName;
				// 		// 	}
				// 		// );
				// 		// console.log(attribute);
				// 		// setSelectedAttribute1Max(attribute[0].max);
				// 	}
				// }
				break;
			case "secondary":
				checkAttribute(ctxApp.secondaryWeapon, ctxApp.secondaryStats);
				// if (ctxApp.secondaryWeapon.name) {
				// 	if (ctxApp.secondaryWeapon.quality === "Exotic") {
				// 		const exoticAttribute = initWeaponAttributes.filter(
				// 			(attribute) => {
				// 				return (
				// 					attribute.name ===
				// 					ctxApp.secondaryWeapon.attribute1
				// 				);
				// 			}
				// 		);
				// 		setSelectDisabled(true);
				// 		setSelectedAttribute1Name(exoticAttribute[0].name);
				// 	} else {
				// 		updatedAttributes = updatedAttributes.filter(
				// 			(attribute) => {
				// 				return (
				// 					attribute.name !==
				// 					ctxApp.secondaryWeapon.core2
				// 				);
				// 			}
				// 		);
				// 		setSelectDisabled(false);
				// 		updatedName =
				// 			ctxApp.secondaryStats.attribute1 !== ""
				// 				? ctxApp.secondaryStats.attribute1
				// 				: "Select Attribute";
				// 		setSelectedAttribute1Name(updatedName);
				// 		setSelectedAttribute1Value((currValue) => {
				// 			if (currValue === 0) {
				// 				return 0;
				// 			} else {
				// 				return ctxApp.secondaryStats.attribute1Value!;
				// 			}
				// 		});
				// 	}
				// }
				break;
			case "pistol":
				checkAttribute(ctxApp.pistolWeapon, ctxApp.pistolStats);
				// if (ctxApp.pistolWeapon.name) {
				// 	if (ctxApp.pistolWeapon.quality === "Exotic") {
				// 		const exoticAttribute = initWeaponAttributes.filter(
				// 			(attribute) => {
				// 				return (
				// 					attribute.name ===
				// 					ctxApp.pistolWeapon.attribute1
				// 				);
				// 			}
				// 		);
				// 		setSelectDisabled(true);
				// 		setSelectedAttribute1Name(exoticAttribute[0].name);
				// 	} else {
				// 		updatedAttributes = updatedAttributes.filter(
				// 			(attribute) => {
				// 				return (
				// 					attribute.name !== ctxApp.pistolWeapon.core2
				// 				);
				// 			}
				// 		);
				// 		setSelectDisabled(false);
				// 		updatedName =
				// 			ctxApp.pistolStats.attribute1 !== ""
				// 				? ctxApp.pistolStats.attribute1
				// 				: "Select Attribute";
				// 		setSelectedAttribute1Name(updatedName);
				// 		setSelectedAttribute1Value((currValue) => {
				// 			if (currValue === undefined) {
				// 				return 0;
				// 			} else {
				// 				return ctxApp.pistolStats.attribute1Value!;
				// 			}
				// 		});
				// 	}
				// }
				break;
		}
		setCurrentAttributes(updatedAttributes);
	}, [
		ctxApp.pistolStats,
		ctxApp.pistolWeapon,
		ctxApp.primaryStats,
		ctxApp.primaryWeapon,
		ctxApp.secondaryStats,
		ctxApp.secondaryWeapon,
		slot,
	]);

	return (
		<>
			<IonList class="ion-no-padding">
				<IonItem class="ion-no-padding">
					<IonSelect
						disabled={selectDisabled}
						interfaceOptions={options}
						className="weaponInput"
						interface="popover"
						placeholder={selectedAttribute1Name}
						value={selectedAttribute1Name}
						onIonChange={attribute1NameChangeHandler}
					>
						{currentAttributes.map(
							(attribute: WeaponAttribute, index) => {
								return (
									<IonSelectOption
										key={index}
										value={attribute.name}
										class="brown-option"
									>
										{attribute.name}
									</IonSelectOption>
								);
							}
						)}
					</IonSelect>
					<IonInput
						class="ion-no-padding"
						className="weaponInput"
						type="number"
						step="0.1"
						min="0.0"
						max={selectedAttribute1Max}
						value={selectedAttribute1Value}
						// debounce={2000}
						placeholder={selectedAttribute1Max}
						onIonChange={attribute1ValueChangeHandler}
					></IonInput>
				</IonItem>
			</IonList>
		</>
	);
};

export default WeaponAttributeTertiary;
