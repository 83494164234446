import { IonBadge, IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import GearStats from "../../models/gearStats";

import "./StatsGear.css";

interface StatsBackpackProps {
	weaponSlot: string;
	gearSlot: string;
	attribute: string;
}

const StatsGear = ({ weaponSlot, gearSlot, attribute }: StatsBackpackProps) => {
	const ctxApp = useContext(AppContext);
	const [showAttribute, setShowAttribute] = useState(true);
	const [gearSlotLabel, setGearSlotLabel] = useState("");
	const [gearSlotDesc, setGearSlotDesc] = useState("");
	const [showStartBadge, setShowStartBadge] = useState(true);
	const [weaponBaseDamage, setWeaponBaseDamage] = useState(0);
	const [gearValue, setGearValue] = useState(0);

	useEffect(() => {
		switch (weaponSlot.toLowerCase()) {
			case "primary":
				setWeaponBaseDamage(ctxApp.primaryWeapon.baseDamage);
				break;
			case "secondary":
				setWeaponBaseDamage(ctxApp.secondaryWeapon.baseDamage);
				break;
			case "pistol":
				setWeaponBaseDamage(ctxApp.pistolWeapon.baseDamage);
				break;
		}
	}, [
		ctxApp.pistolWeapon.baseDamage,
		ctxApp.primaryWeapon.baseDamage,
		ctxApp.secondaryWeapon.baseDamage,
		weaponSlot,
	]);

	useEffect(() => {
		function checkGear(gearStats: GearStats) {
			switch (attribute) {
				case "Weapon Damage":
					if (gearStats.core1Name === attribute) {
						setShowStartBadge(true);
						setGearValue(gearStats.core1Value!);
					} else {
						setGearValue(0);
					}
					break;
				case "Critical Hit Chance":
					if (gearStats.attribute1Name === attribute) {
						setShowStartBadge(false);
						setGearValue(gearStats.attribute1Value!);
					} else if (gearStats.attribute2Name === attribute) {
						setShowStartBadge(false);
						setGearValue(gearStats.attribute2Value!);
					} else {
						setShowAttribute(false);
						setGearValue(0);
					}
					break;
				case "Critical Hit Damage":
					if (gearStats.attribute1Name === attribute) {
						setShowStartBadge(true);
						setGearValue(gearStats.attribute1Value!);
					} else if (gearStats.attribute2Name === attribute) {
						setShowStartBadge(true);
						setGearValue(gearStats.attribute2Value!);
					} else {
						setShowAttribute(false);
						setGearValue(0);
					}
					break;
				case "Headshot Damage":
					if (gearStats.attribute1Name === attribute) {
						setShowStartBadge(true);
						setGearValue(gearStats.attribute1Value!);
					} else if (gearStats.attribute2Name === attribute) {
						setShowStartBadge(true);
						setGearValue(gearStats.attribute2Value!);
					} else if (gearStats.modName === attribute) {
						setShowStartBadge(true);
						setGearValue(gearStats.modValue);
					} else {
						setShowAttribute(false);
						setGearValue(0);
					}
					break;
			}
		}

		switch (gearSlot) {
			case "mask":
				setGearSlotLabel("Mask");
				setGearSlotDesc(ctxApp.maskGear.name);
				setShowAttribute(true);
				checkGear(ctxApp.maskStats);
				break;
			case "backpack":
				setGearSlotLabel("Backpack");
				setGearSlotDesc(ctxApp.backpackGear.name);
				setShowAttribute(true);
				checkGear(ctxApp.backpackStats);
				break;
			case "vest":
				setGearSlotLabel("Vest");
				setGearSlotDesc(ctxApp.vestGear.name);
				setShowAttribute(true);
				checkGear(ctxApp.vestStats);
				break;
			case "gloves":
				setGearSlotLabel("Gloves");
				setGearSlotDesc(ctxApp.glovesGear.name);
				setShowAttribute(true);
				checkGear(ctxApp.glovesStats);
				break;
			case "holster":
				setGearSlotLabel("Holster");
				setGearSlotDesc(ctxApp.holsterGear.name);
				setShowAttribute(true);
				checkGear(ctxApp.holsterStats);
				break;
			case "kneepads":
				setGearSlotLabel(ctxApp.kneepadsGear.name);
				setGearSlotDesc(ctxApp.kneepadsStats.core1Name);
				setShowAttribute(true);
				checkGear(ctxApp.kneepadsStats);
				break;
		}
	}, [
		attribute,
		ctxApp.backpackGear.name,
		ctxApp.backpackStats,
		ctxApp.glovesGear.name,
		ctxApp.glovesStats,
		ctxApp.holsterGear.name,
		ctxApp.holsterStats,
		ctxApp.kneepadsGear.name,
		ctxApp.kneepadsStats,
		ctxApp.maskGear.name,
		ctxApp.maskStats,
		ctxApp.vestGear.name,
		ctxApp.vestStats,
		gearSlot,
	]);

	return (
		<>
			{showAttribute && (
				<IonItem key={""}>
					{showStartBadge && (
						<IonBadge slot="start">{gearValue}%</IonBadge>
					)}
					<IonThumbnail slot="start">
						<img
							className={"gear75"}
							src={
								"assets/images/" + gearSlot + "-1.4-100x92.png"
							}
							alt={gearSlot}
						/>
						{/* <img
							className="mask"
							src={
								"assets/images/" + gearSlot + "-1.4-100x92.png"
							}
							alt={gearSlot}
						/>
						<img
							className="mask"
							src={
								"assets/images/" + gearSlot + "-1.4-100x92.png"
							}
							alt={gearSlot}
							width={10}
						/>
						<img
							className="mask"
							src={
								"assets/images/" + gearSlot + "-1.4-100x92.png"
							}
							alt={gearSlot}
							width={10}
						/> */}
					</IonThumbnail>
					<IonLabel>
						<h3>{gearSlotLabel}</h3>
						<p>{gearSlotDesc}</p>
					</IonLabel>
					{showStartBadge && (
						<IonBadge slot="end">
							{Math.round(
								weaponBaseDamage * (gearValue / 100)
							).toLocaleString("en-US")}
						</IonBadge>
					)}
					{!showStartBadge && (
						<IonBadge slot="end">{gearValue}%</IonBadge>
					)}
				</IonItem>
			)}
		</>
	);
};

export default StatsGear;
