import {
	IonInput,
	IonItem,
	IonList,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import Gear from "../../models/gear";
import GearStats from "../../models/gearStats";

interface GearCoreProps {
	slot: string;
}

interface SelectChangeEventDetail<T = any> {
	value: T;
}

interface CoreGearAttribute {
	key: string;
	name: string;
	max: number;
}

const coreGearAttributes: CoreGearAttribute[] = [
	{ key: "weaponDamage", name: "Weapon Damage", max: 15 },
	{ key: "armor", name: "Armor", max: 100000 },
	{ key: "skillTier", name: "Skill Tier", max: 1 },
];

const GearCore = ({ slot }: GearCoreProps) => {
	const ctxApp = useContext(AppContext);
	const [selectedCore1Name, setSelectedCore1Name] =
		useState("Select Attribute");
	const [selectedCore1Value, setSelectedCore1Value] = useState<
		number | undefined
	>(undefined);
	const [selectedCore1Max, setSelectedCore1Max] = useState(0);
	const [selectDisabled, setSelectDisabled] = useState(false);

	const [selectClassName, setSelectClassName] = useState("gearSelect");

	const core1NameChangeHandler = (
		e: CustomEvent<SelectChangeEventDetail>
	) => {
		// console.log(e.detail.value);
		setSelectedCore1Name(e.detail.value);
		// reset input value only if no stats available
		// switch (slot.toLowerCase()) {
		// 	case "mask":
		// 		setSelectedCore1Value(ctxApp.maskStats.core1Value);
		// 		break;
		// 	case "backpack":
		// 		setSelectedCore1Value(ctxApp.backpackStats.core1Value);
		// 		break;
		// 	case "vest":
		// 		setSelectedCore1Value(ctxApp.vestStats.core1Value);
		// 		break;
		// 	case "gloves":
		// 		setSelectedCore1Value(ctxApp.glovesStats.core1Value);
		// 		break;
		// 	case "holster":
		// 		setSelectedCore1Value(ctxApp.holsterStats.core1Value);
		// 		break;
		// 	case "kneepads":
		// 		setSelectedCore1Value(ctxApp.kneepadsStats.core1Value);
		// 		break;
		// 	default:
		// 		setSelectedCore1Value(0);
		// 		break;
		// }
		// TODO going to attempt to reset always when the name selector changes
		// setSelectedCore1Value(0);

		// get/set max attribte value
		// console.log(e.detail);
		if (e.detail.value !== "" && e.detail.value !== "Select Attribute") {
			const selectedAttribute: CoreGearAttribute[] =
				coreGearAttributes.filter((attribute) => {
					return attribute.name === e.detail.value;
				});
			setSelectedCore1Max(selectedAttribute[0].max);
		}
		ctxApp.updateGearStat("core1Name", slot, e.detail.value);
	};

	const core1ValueChangeHandler = (e: CustomEvent) => {
		// console.log(e.detail.value);
		setSelectedCore1Value(e.detail.value);
		ctxApp.updateGearStat("core1Value", slot, e.detail.value, "number");
	};

	const options = {
		cssClass: "my-custom-interface",
	};

	useEffect(() => {
		function check(gear: Gear, gearStats: GearStats) {
			let updatedName = "";
			if (gear.quality === "Exotic") {
				const exoticAttribute = coreGearAttributes.filter(
					(attribute) => {
						return attribute.name === gear.core;
					}
				);
				setSelectDisabled(true);
				setSelectedCore1Name(exoticAttribute[0].name);
				setSelectedCore1Value((currValue) => {
					if (gearStats.core1Value === null) {
						return currValue;
					} else {
						return gearStats.core1Value;
					}
				});
			} else if (gear.brand === "Crafted") {
				setSelectDisabled(false);
				if (gearStats.core1Name === "") {
					setSelectedCore1Name("Select Attribute");
				} else {
					setSelectedCore1Name(gearStats.core1Name);
				}
				setSelectedCore1Value((currValue) => {
					if (gearStats.core1Value === null) {
						return currValue;
					} else {
						return gearStats.core1Value;
					}
				});
			} else {
				setSelectDisabled(false);
				updatedName =
					gearStats.core1Name === ""
						? gear.core
						: gearStats.core1Name;
				setSelectedCore1Name(updatedName);
				setSelectedCore1Value((currValue) => {
					if (gearStats.core1Value === null) {
						return currValue;
					} else {
						return gearStats.core1Value;
					}
				});
			}
		}
		switch (slot.toLowerCase()) {
			case "mask":
				if (ctxApp.maskGear.name) {
					check(ctxApp.maskGear, ctxApp.maskStats);
				}
				break;
			case "backpack":
				if (ctxApp.backpackGear.name) {
					check(ctxApp.backpackGear, ctxApp.backpackStats);
				}
				break;
			case "vest":
				if (ctxApp.vestGear.name) {
					check(ctxApp.vestGear, ctxApp.vestStats);
				}
				break;
			case "gloves":
				if (ctxApp.glovesGear.name) {
					check(ctxApp.glovesGear, ctxApp.glovesStats);
				}
				break;
			case "holster":
				if (ctxApp.holsterGear.name) {
					check(ctxApp.holsterGear, ctxApp.holsterStats);
				}
				break;
			case "kneepads":
				if (ctxApp.kneepadsGear.name) {
					check(ctxApp.kneepadsGear, ctxApp.kneepadsStats);
				}
				break;
		}
	}, [
		ctxApp.backpackGear,
		ctxApp.backpackStats,
		ctxApp.glovesGear,
		ctxApp.glovesStats,
		ctxApp.holsterGear,
		ctxApp.holsterStats,
		ctxApp.kneepadsGear,
		ctxApp.kneepadsStats,
		ctxApp.maskGear,
		ctxApp.maskStats,
		ctxApp.vestGear,
		ctxApp.vestStats,
		slot,
	]);

	return (
		<>
			<IonList class="ion-no-padding">
				<IonItem class="ion-no-padding">
					<IonSelect
						disabled={selectDisabled}
						interfaceOptions={options}
						className={selectClassName}
						interface="popover"
						placeholder={selectedCore1Name}
						value={selectedCore1Name}
						onIonChange={core1NameChangeHandler}
					>
						{coreGearAttributes.map(
							(attribute: CoreGearAttribute, index) => {
								return (
									<IonSelectOption
										key={index}
										value={attribute.name}
										class="brown-option"
									>
										{attribute.name}
									</IonSelectOption>
								);
							}
						)}
					</IonSelect>
					<IonInput
						class="ion-no-padding"
						className="gearInput"
						type="number"
						value={selectedCore1Value}
						max={selectedCore1Max}
						min="1"
						debounce={2000}
						placeholder={selectedCore1Max.toString()}
						onIonChange={core1ValueChangeHandler}
					></IonInput>
				</IonItem>
			</IonList>
		</>
	);
};

export default GearCore;
