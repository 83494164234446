import WeaponMod from "../models/weaponMod";

const WeaponModsData: WeaponMod[] = [
	{
		_id: {
			oid: "62487bd5d03ce64590e2868d",
		},
		slot: "Magazine",
		type: "5.56",
		name: "Light Extended 5.56 Mag",
		label: "Light Extended 5.56 Mag",
		posVal: "10",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28693",
		},
		slot: "Magazine",
		type: "8.02",
		name: "Thin 7.62 Mag",
		label: "Thin 7.62 Mag",
		posVal: "20",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28694",
		},
		slot: "Magazine",
		type: "8.02",
		name: "Weighted 7.62 Mag",
		label: "Weighted 7.62 Mag",
		posVal: "20",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28697",
		},
		slot: "Magazine",
		type: ".45 ACP",
		name: "Oversized .45 ACP Mag",
		label: "Oversized .45 ACP Mag",
		posVal: "20",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "-10",
		negStat: "Reload Speed %",
		negStatFactor: "percent",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2869b",
		},
		slot: "Magazine",
		type: "9mm",
		name: "Heavy Spring 9mm Mag",
		label: "Heavy Spring 9mm Mag",
		posVal: "10",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2869c",
		},
		slot: "Magazine",
		type: "9mm",
		name: "Oversized 9mm Mag",
		label: "Oversized 9mm Mag",
		posVal: "10",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2869e",
		},
		slot: "Magazine",
		type: "Ammunition Belt",
		name: "Calibrated Link",
		label: "Calibrated Link",
		posVal: "15",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286a7",
		},
		slot: "Magazine",
		type: "Chameleon",
		name: "Chameleon ActivCamo Processor",
		label: "Chameleon ActivCamo Processor",
		posVal: "20",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286a9",
		},
		slot: "Magazine",
		type: "Eagle Bearer",
		name: "Bullet Eyrie",
		label: "Bullet Eyrie",
		posVal: "30",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ab",
		},
		slot: "Magazine",
		type: "Integrated",
		name: "Overbalanced Integrated Spring",
		label: "Overbalanced Integrated Spring",
		posVal: "20",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ac",
		},
		slot: "Magazine",
		type: "Integrated",
		name: "Stiff Integrated Spring",
		label: "Stiff Integrated Spring",
		posVal: "5",
		posStat: "Weapon Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ae",
		},
		slot: "Magazine",
		type: "Lady Death",
		name: "Death's Hand",
		label: "Death's Hand",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286b1",
		},
		slot: "Magazine",
		type: "Mantis",
		name: "Well-Oiled Machine",
		label: "Well-Oiled Machine",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286b2",
		},
		slot: "Magazine",
		type: "Merciless",
		name: "Merciless Magazine",
		label: "Merciless Magazine",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286bd",
		},
		slot: "Magazine",
		type: "Revolver Drum",
		name: "Speed Loading Revolver Drum",
		label: "Speed Loading Revolver Drum",
		posVal: "20",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286bf",
		},
		slot: "Magazine",
		type: "Scorpio",
		name: "Venom Glands",
		label: "Venom Glands",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286c1",
		},
		slot: "Magazine",
		type: "The Bighorn",
		name: "Tension Spring Magazine",
		label: "Tension Spring Magazine",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286c6",
		},
		slot: "Magazine",
		type: "Tubular",
		name: "Hard Tubular Spring",
		label: "Hard Tubular Spring",
		posVal: "5",
		posStat: "Rate of Fire %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ce",
		},
		slot: "Muzzle",
		type: "8.02",
		name: "Flash Hider 7.62",
		label: "Flash Hider 7.62",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286d3",
		},
		slot: "Muzzle",
		type: ".45 ACP",
		name: "Compensator .45",
		label: "Compensator .45",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286d6",
		},
		slot: "Muzzle",
		type: ".45 ACP",
		name: "Muzzle Brake .45",
		label: "Muzzle Brake .45",
		posVal: "3",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286da",
		},
		slot: "Muzzle",
		type: "9mm",
		name: "Flash Hider 9mm",
		label: "Flash Hider 9mm",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286e7",
		},
		slot: "Muzzle",
		type: "Mantis",
		name: "Wrapped Suppressor",
		label: "Wrapped Suppressor",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286e8",
		},
		slot: "Muzzle",
		type: "Merciless",
		name: "Merciless Muzzle",
		label: "Merciless Muzzle",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286eb",
		},
		slot: "Muzzle",
		type: "QDC",
		name: "QDC Sound Supressor",
		label: "QDC Sound Supressor",
		posVal: "20",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286f6",
		},
		slot: "Optic",
		type: "Eagle Bearer",
		name: "Eagle Eye Sight",
		label: "Eagle Eye Sight",
		posVal: "10",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286f9",
		},
		slot: "Optic",
		type: "Ironsights",
		name: "Weighted Iron Sights",
		label: "Weighted Iron Sights",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28703",
		},
		slot: "Optic",
		type: "Mantis",
		name: "Anti-Glint Coated Sight",
		label: "Anti-Glint Coated Sight",
		posVal: "40",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28706",
		},
		slot: "Optic",
		type: "Micro",
		name: "Rugged Mini Reflex Sight",
		label: "Rugged Mini Reflex Sight",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28708",
		},
		slot: "Optic",
		type: "Ruthless",
		name: "Unidentified Custom Russian Sight",
		label: "Unidentified Custom Russian Sight",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28709",
		},
		slot: "Optic",
		type: "Scorpio",
		name: "Starlight Receptor",
		label: "Starlight Receptor",
		posVal: "10",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2870e",
		},
		slot: "Optic",
		type: "Short",
		name: "Small RDS Scope",
		label: "Small RDS Scope",
		posVal: "15",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "-5",
		negStat: "Accuracy",
		negStatFactor: "number",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28711",
		},
		slot: "Optic",
		type: "The Bighorn",
		name: "Fire Select Scope",
		label: "Fire Select Scope",
		posVal: "30",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28712",
		},
		slot: "Optic",
		type: "The Chatterbox",
		name: "Modified Optics",
		label: "Modified Optics",
		posVal: "15",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28717",
		},
		slot: "Under Barrel",
		type: "Chameleon",
		name: "Chameleon Dampener",
		label: "Chameleon Dampener",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28721",
		},
		slot: "Under Barrel",
		type: "Long",
		name: "Short Grip",
		label: "Short Grip",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28722",
		},
		slot: "Under Barrel",
		type: "Long",
		name: "Tactical Short Grip",
		label: "Tactical Short Grip",
		posVal: "10",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "Firewall",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28724",
		},
		slot: "Under Barrel",
		type: "Mantis",
		name: "Ghillie Frame",
		label: "Ghillie Frame",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2872c",
		},
		slot: "Under Barrel",
		type: "Side",
		name: "Laser Pointer",
		label: "Laser Pointer",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28730",
		},
		slot: "Under Barrel",
		type: "The Chatterbox",
		name: "Chatterbox Cannister",
		label: "Chatterbox Cannister",
		posVal: "10",
		posStat: "Weapon Handling",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2868b",
		},
		slot: "Magazine",
		type: "5.56",
		name: "Balanced Spring 5.56 Mag",
		label: "Balanced Spring 5.56 Mag",
		posVal: "20",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2868c",
		},
		slot: "Magazine",
		type: "5.56",
		name: "Infantry 5.56 Mag",
		label: "Infantry 5.56 Mag",
		posVal: "30",
		posStat: "Optimal Range",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "Survivalist",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2868e",
		},
		slot: "Magazine",
		type: "5.56",
		name: "Sturdy Extended 5.56 Mag",
		label: "Sturdy Extended 5.56 Mag",
		posVal: "20",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "-10",
		negStat: "Reload Speed %",
		negStatFactor: "percent",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28690",
		},
		slot: "Magazine",
		type: "8.02",
		name: "Light Extended 7.62 Mag",
		label: "Light Extended 7.62 Mag",
		posVal: "10",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28692",
		},
		slot: "Magazine",
		type: "8.02",
		name: "Sturdy Extended 7.62 Mag",
		label: "Sturdy Extended 7.62 Mag",
		posVal: "20",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "-10",
		negStat: "Reload Speed %",
		negStatFactor: "percent",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28695",
		},
		slot: "Magazine",
		type: ".45 ACP",
		name: "Extended .45 ACP Mag",
		label: "Extended .45 ACP Mag",
		posVal: "10",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28698",
		},
		slot: "Magazine",
		type: ".45 ACP",
		name: "Precision Feeding .45 Mag",
		label: "Precision Feeding .45 Mag",
		posVal: "20",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2869d",
		},
		slot: "Magazine",
		type: "9mm",
		name: "Segmented 9mm Mag",
		label: "Segmented 9mm Mag",
		posVal: "20",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286a0",
		},
		slot: "Magazine",
		type: "Ammunition Belt",
		name: "Large Pouch",
		label: "Large Pouch",
		posVal: "35",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286a3",
		},
		slot: "Magazine",
		type: "Ammunition Belt",
		name: "Tactical Small Pouch",
		label: "Tactical Small Pouch",
		posVal: "20",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286a5",
		},
		slot: "Magazine",
		type: "Bullet King",
		name: "Mag of Holding",
		label: "Mag of Holding",
		posVal: "",
		posStat: "",
		posStatFactor: "",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286b3",
		},
		slot: "Magazine",
		type: "MM 7.62",
		name: "Lightweight Marksman Mag",
		label: "Lightweight Marksman Mag",
		posVal: "20",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286b4",
		},
		slot: "Magazine",
		type: "MM 7.62",
		name: "Mended Marksman Mag",
		label: "Mended Marksman Mag",
		posVal: "20",
		posStat: "Optimal Range",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286b6",
		},
		slot: "Magazine",
		type: "MM 7.62",
		name: "Tightly Packed Marksman Mag",
		label: "Tightly Packed Marksman Mag",
		posVal: "5",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286b7",
		},
		slot: "Magazine",
		type: "Nemesis",
		name: "Nemesis Bolt",
		label: "Nemesis Bolt",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286b8",
		},
		slot: "Magazine",
		type: "Pestilence",
		name: "Plagued Magazine",
		label: "Plagued Magazine",
		posVal: "10",
		posStat: "Rate of Fire %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ba",
		},
		slot: "Magazine",
		type: "Pistol",
		name: "Field Pistol Mag",
		label: "Field Pistol Mag",
		posVal: "30",
		posStat: "Optimal Range",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286bc",
		},
		slot: "Magazine",
		type: "Regulus",
		name: "Engraved Drum",
		label: "Engraved Drum",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286c7",
		},
		slot: "Muzzle",
		type: "5.56",
		name: "Compensator 5.56",
		label: "Compensator 5.56",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286c8",
		},
		slot: "Muzzle",
		type: "5.56",
		name: "Flash Hider 5.56",
		label: "Flash Hider 5.56",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286cb",
		},
		slot: "Muzzle",
		type: "5.56",
		name: "Muzzle Brake 5.56",
		label: "Muzzle Brake 5.56",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286cf",
		},
		slot: "Muzzle",
		type: "8.02",
		name: "Large Suppressor 7.62",
		label: "Large Suppressor 7.62",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286d0",
		},
		slot: "Muzzle",
		type: "8.02",
		name: "Loud Vent Brake 7.62",
		label: "Loud Vent Brake 7.62",
		posVal: "10",
		posStat: "Optimal Range",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286d5",
		},
		slot: "Muzzle",
		type: ".45 ACP",
		name: "Loud Vent Brake .45",
		label: "Loud Vent Brake .45",
		posVal: "10",
		posStat: "Optimal Range",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286db",
		},
		slot: "Muzzle",
		type: "9mm",
		name: "Loud Vent Brake 9mm",
		label: "Loud Vent Brake 9mm",
		posVal: "10",
		posStat: "Optimal Range",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286e2",
		},
		slot: "Muzzle",
		type: "Chameleon",
		name: "Chameleon Grip",
		label: "Chameleon Grip",
		posVal: "20",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286e5",
		},
		slot: "Muzzle",
		type: "Lady Death",
		name: "Kiss of Death",
		label: "Kiss of Death",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ee",
		},
		slot: "Muzzle",
		type: "Scorpio",
		name: "Aculus",
		label: "Aculus",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286f0",
		},
		slot: "Muzzle",
		type: "The Chatterbox",
		name: "Chatterbox Muzzle",
		label: "Chatterbox Muzzle",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286fe",
		},
		slot: "Optic",
		type: "Long",
		name: "C79 Scope (3.4x)",
		label: "C79 Scope (3.4x)",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28710",
		},
		slot: "Optic",
		type: "Sweet Dreams",
		name: "Outcast Optics",
		label: "Outcast Optics",
		posVal: "15",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28714",
		},
		slot: "Under Barrel",
		type: "Backfire",
		name: "Barbed Frame",
		label: "Barbed Frame",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28716",
		},
		slot: "Under Barrel",
		type: "Capacitor",
		name: "Dialetic Safety Grip",
		label: "Dialetic Safety Grip",
		posVal: "10",
		posStat: "Weapon Handling",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2871a",
		},
		slot: "Under Barrel",
		type: "Gadget",
		name: "Compact Coupled Laser Pointer",
		label: "Compact Coupled Laser Pointer",
		posVal: "10",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2871d",
		},
		slot: "Under Barrel",
		type: "Lady Death",
		name: "Death's Maw",
		label: "Death's Maw",
		posVal: "500",
		posStat: "Melee Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28726",
		},
		slot: "Under Barrel",
		type: "Nemesis",
		name: "Nemesis Bipod",
		label: "Nemesis Bipod",
		posVal: "5",
		posStat: "Weapon Handling",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28727",
		},
		slot: "Under Barrel",
		type: "Pestilence",
		name: "Caution Tape",
		label: "Caution Tape",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28729",
		},
		slot: "Under Barrel",
		type: "Scorpio",
		name: "Chelae Grip",
		label: "Chelae Grip",
		posVal: "10",
		posStat: "Weapon Handling",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2872a",
		},
		slot: "Under Barrel",
		type: "Short",
		name: "Angled Grip",
		label: "Angled Grip",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2868f",
		},
		slot: "Magazine",
		type: "5.56",
		name: "Tactical 5.56 Mag",
		label: "Tactical 5.56 Mag",
		posVal: "10",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28691",
		},
		slot: "Magazine",
		type: "8.02",
		name: "Precision 7.62 Mag",
		label: "Precision 7.62 Mag",
		posVal: "7",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28696",
		},
		slot: "Magazine",
		type: ".45 ACP",
		name: "Force Feed .45 ACP Mag",
		label: "Force Feed .45 ACP Mag",
		posVal: "5",
		posStat: "Rate of Fire %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28699",
		},
		slot: "Magazine",
		type: ".45 ACP",
		name: "Short Spring .45 ACP Mag",
		label: "Short Spring .45 ACP Mag",
		posVal: "20",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286a4",
		},
		slot: "Magazine",
		type: "Backfire",
		name: "Barbed Grip Magazine",
		label: "Barbed Grip Magazine",
		posVal: "20",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286a6",
		},
		slot: "Magazine",
		type: "Capacitor",
		name: "Voltage Generator",
		label: "Voltage Generator",
		posVal: "11",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286aa",
		},
		slot: "Magazine",
		type: "Integrated",
		name: "Compensated Integrated Spring",
		label: "Compensated Integrated Spring",
		posVal: "20",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ad",
		},
		slot: "Magazine",
		type: "Integrated",
		name: "Underbalanced Integrated Spring",
		label: "Underbalanced Integrated Spring",
		posVal: "5",
		posStat: "Rate of Fire %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286bb",
		},
		slot: "Magazine",
		type: "Pistol",
		name: "Police Pistol Mag",
		label: "Police Pistol Mag",
		posVal: "20",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286c0",
		},
		slot: "Magazine",
		type: "Sweet Dreams",
		name: "Modified Feed System",
		label: "Modified Feed System",
		posVal: "15",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286c2",
		},
		slot: "Magazine",
		type: "The Chatterbox",
		name: "Chatterbox Magazine",
		label: "Chatterbox Magazine",
		posVal: "10",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286c5",
		},
		slot: "Magazine",
		type: "Tubular",
		name: "Flexible Tubular Spring",
		label: "Flexible Tubular Spring",
		posVal: "20",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286cc",
		},
		slot: "Muzzle",
		type: "5.56",
		name: "Omega 5.56 Rifle Suppressor",
		label: "Omega 5.56 Rifle Suppressor",
		posVal: "20",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "-10",
		negStat: "Optimal Range",
		negStatFactor: "percent",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286cd",
		},
		slot: "Muzzle",
		type: "8.02",
		name: "Compensator 7.62",
		label: "Compensator 7.62",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286d1",
		},
		slot: "Muzzle",
		type: "8.02",
		name: "Muzzle Brake 7.62",
		label: "Muzzle Brake 7.62",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286d2",
		},
		slot: "Muzzle",
		type: "8.02",
		name: "Omega 7.62 Rifle Suppressor",
		label: "Omega 7.62 Rifle Suppressor",
		posVal: "20",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "-10",
		negStat: "Optimal Range",
		negStatFactor: "percent",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286d7",
		},
		slot: "Muzzle",
		type: ".45 ACP",
		name: "Osprey .45 Suppressor",
		label: "Osprey .45 Suppressor",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286de",
		},
		slot: "Muzzle",
		type: "9mm",
		name: "Small Suppressor 9mm",
		label: "Small Suppressor 9mm",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286df",
		},
		slot: "Muzzle",
		type: "Backfire",
		name: "Jagged Muzzle",
		label: "Jagged Muzzle",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286e1",
		},
		slot: "Muzzle",
		type: "Capacitor",
		name: "Discharge Electrode",
		label: "Discharge Electrode",
		posVal: "30",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286e4",
		},
		slot: "Muzzle",
		type: "Eagle Bearer",
		name: "Jagged Break",
		label: "Jagged Break",
		posVal: "15",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286f3",
		},
		slot: "Optic",
		type: "Capacitor",
		name: "Conductive Focal",
		label: "Conductive Focal",
		posVal: "10",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286f8",
		},
		slot: "Optic",
		type: "Ironsights",
		name: "Streamlined Iron Sights",
		label: "Streamlined Iron Sights",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286fb",
		},
		slot: "Optic",
		type: "Liberty",
		name: "Liberty Sight",
		label: "Liberty Sight",
		posVal: "5",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286fc",
		},
		slot: "Optic",
		type: "Long",
		name: "552 Holo Sight",
		label: "552 Holo Sight",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28705",
		},
		slot: "Optic",
		type: "Micro",
		name: "Low Reflex Sight",
		label: "Low Reflex Sight",
		posVal: "5",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "-20",
		negStat: "Optimal Range",
		negStatFactor: "number",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2870a",
		},
		slot: "Optic",
		type: "Short",
		name: "EXPS3 Holo Sight",
		label: "EXPS3 Holo Sight",
		posVal: "3",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2870c",
		},
		slot: "Optic",
		type: "Short",
		name: "Reflex Sight",
		label: "Reflex Sight",
		posVal: "5",
		posStat: "Weapon Handling",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2870d",
		},
		slot: "Optic",
		type: "Short",
		name: "Russian Red Dot Sight",
		label: "Russian Red Dot Sight",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2870f",
		},
		slot: "Optic",
		type: "Short",
		name: "T2 Micro Red Dot Sight",
		label: "T2 Micro Red Dot Sight",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28715",
		},
		slot: "Under Barrel",
		type: "Bullet King",
		name: "Solid Gold Bipod",
		label: "Solid Gold Bipod",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2871e",
		},
		slot: "Under Barrel",
		type: "Liberty",
		name: "Liberty Grip",
		label: "Liberty Grip",
		posVal: "15",
		posStat: "Rate of Fire %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2871f",
		},
		slot: "Under Barrel",
		type: "Long",
		name: "Handstop",
		label: "Handstop",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28723",
		},
		slot: "Under Barrel",
		type: "Lullaby",
		name: "Hardened Grip",
		label: "Hardened Grip",
		posVal: "25",
		posStat: "Optimal Range",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28725",
		},
		slot: "Under Barrel",
		type: "Merciless",
		name: "Merciless Grip",
		label: "Merciless Grip",
		posVal: "10",
		posStat: "Weapon Handling",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2872b",
		},
		slot: "Under Barrel",
		type: "Short",
		name: "Vertical Grip",
		label: "Vertical Grip",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2872e",
		},
		slot: "Under Barrel",
		type: "Sweet Dreams",
		name: "Stunner",
		label: "Stunner",
		posVal: "25",
		posStat: "Optimal Range",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2872f",
		},
		slot: "Under Barrel",
		type: "The Bighorn",
		name: "Carbon Steel Muzzle",
		label: "Carbon Steel Muzzle",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2869a",
		},
		slot: "Magazine",
		type: "9mm",
		name: "Extended 9mm Mag",
		label: "Extended 9mm Mag",
		posVal: "20",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "-10",
		negStat: "Reload Speed %",
		negStatFactor: "percent",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2869f",
		},
		slot: "Magazine",
		type: "Ammunition Belt",
		name: "Gunner Large Pouch",
		label: "Gunner Large Pouch",
		posVal: "50",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "Gunner",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286a1",
		},
		slot: "Magazine",
		type: "Ammunition Belt",
		name: "Nimble Link Belt",
		label: "Nimble Link Belt",
		posVal: "5",
		posStat: "Rate of Fire %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286a2",
		},
		slot: "Magazine",
		type: "Ammunition Belt",
		name: "Non-disruptive Link",
		label: "Non-disruptive Link",
		posVal: "10",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286a8",
		},
		slot: "Magazine",
		type: "Diamondback",
		name: "Diamondback Lever",
		label: "Diamondback Lever",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286af",
		},
		slot: "Magazine",
		type: "Liberty",
		name: "Trigger Mechanisim",
		label: "Trigger Mechanisim",
		posVal: "15",
		posStat: "Weapon Handling",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286b0",
		},
		slot: "Magazine",
		type: "Lullaby",
		name: "Modified Feed System",
		label: "Modified Feed System",
		posVal: "15",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286b5",
		},
		slot: "Magazine",
		type: "MM 7.62",
		name: "Sturdy Marksman Mag",
		label: "Sturdy Marksman Mag",
		posVal: "20",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286b9",
		},
		slot: "Magazine",
		type: "Pistol",
		name: "Extended Pistol Mag",
		label: "Extended Pistol Mag",
		posVal: "11",
		posStat: "Extra Rounds",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286be",
		},
		slot: "Magazine",
		type: "Ruthless",
		name: "Ruthless Magazine",
		label: "Ruthless Magazine",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286c3",
		},
		slot: "Magazine",
		type: "The Ravenous",
		name: "Gluttonous Magazines",
		label: "Gluttonous Magazines",
		posVal: "10",
		posStat: "Reload Speed %",
		posStatFactor: "percent",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286c4",
		},
		slot: "Magazine",
		type: "Tubular",
		name: "Counter-Clockwise Tubular Spring",
		label: "Counter-Clockwise Tubular Spring",
		posVal: "5",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286c9",
		},
		slot: "Muzzle",
		type: "5.56",
		name: "Large Suppressor 5.56",
		label: "Large Suppressor 5.56",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ca",
		},
		slot: "Muzzle",
		type: "5.56",
		name: "Loud Vent Brake 5.56",
		label: "Loud Vent Brake 5.56",
		posVal: "10",
		posStat: "Optimal Range",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286d4",
		},
		slot: "Muzzle",
		type: ".45 ACP",
		name: "Flash Hider .45",
		label: "Flash Hider .45",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286d8",
		},
		slot: "Muzzle",
		type: ".45 ACP",
		name: "Small Suppressor .45",
		label: "Small Suppressor .45",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286d9",
		},
		slot: "Muzzle",
		type: "9mm",
		name: "Compensator 9mm",
		label: "Compensator 9mm",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286dc",
		},
		slot: "Muzzle",
		type: "9mm",
		name: "Muzzle Brake 9mm",
		label: "Muzzle Brake 9mm",
		posVal: "3",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286dd",
		},
		slot: "Muzzle",
		type: "9mm",
		name: "Osprey 9mm Suppressor",
		label: "Osprey 9mm Suppressor",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286e0",
		},
		slot: "Muzzle",
		type: "Bullet King",
		name: "Gold Plated Muzzle",
		label: "Gold Plated Muzzle",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286e3",
		},
		slot: "Muzzle",
		type: "Diamondback",
		name: "Rattlesnake Ornamentation",
		label: "Rattlesnake Ornamentation",
		posVal: "15",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286e6",
		},
		slot: "Muzzle",
		type: "Liberty",
		name: "Liberty Customs",
		label: "Liberty Customs",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286e9",
		},
		slot: "Muzzle",
		type: "Nemesis",
		name: "Nemesis Barrel",
		label: "Nemesis Barrel",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ea",
		},
		slot: "Muzzle",
		type: "Pestilence",
		name: "Plagured Muzzle",
		label: "Plagured Muzzle",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ec",
		},
		slot: "Muzzle",
		type: "Regulus",
		name: "Engraved Muzzle",
		label: "Engraved Muzzle",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ed",
		},
		slot: "Muzzle",
		type: "Ruthless",
		name: "Mayhem's Muzzle",
		label: "Mayhem's Muzzle",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ef",
		},
		slot: "Muzzle",
		type: "The Bighorn",
		name: "Bighorn Grip",
		label: "Bighorn Grip",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286f1",
		},
		slot: "Muzzle",
		type: "The Ravenous",
		name: "Wolf's Claw",
		label: "Wolf's Claw",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286f2",
		},
		slot: "Optic",
		type: "Backfire",
		name: "Quick Sight",
		label: "Quick Sight",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286f4",
		},
		slot: "Optic",
		type: "Chameleon",
		name: "Chameleon Optics",
		label: "Chameleon Optics",
		posVal: "15",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286f5",
		},
		slot: "Optic",
		type: "Diamondback",
		name: "Diamondback Dot Sight",
		label: "Diamondback Dot Sight",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286f7",
		},
		slot: "Optic",
		type: "Ironsights",
		name: "Open Iron Sights",
		label: "Open Iron Sights",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286fa",
		},
		slot: "Optic",
		type: "Lady Death",
		name: "Gaze of Death",
		label: "Gaze of Death",
		posVal: "10",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286fd",
		},
		slot: "Optic",
		type: "Long",
		name: "ACOG Scope (4x)",
		label: "ACOG Scope (4x)",
		posVal: "20",
		posStat: "Optimal Range",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e286ff",
		},
		slot: "Optic",
		type: "Long",
		name: "CQBSS Scope (8x)",
		label: "CQBSS Scope (8x)",
		posVal: "30",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28700",
		},
		slot: "Optic",
		type: "Long",
		name: "Digital Scope",
		label: "Digital Scope",
		posVal: "45",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "-5",
		negStat: "Critical Hit Damage",
		negStatFactor: "number",
		spec: "Sharpshooter",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28701",
		},
		slot: "Optic",
		type: "Long",
		name: "VX-1 Scope (12x)",
		label: "VX-1 Scope (12x)",
		posVal: "35",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "-10",
		negStat: "Reload Speed %",
		negStatFactor: "percent",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28702",
		},
		slot: "Optic",
		type: "Lullaby",
		name: "Damaged Optics",
		label: "Damaged Optics",
		posVal: "15",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28704",
		},
		slot: "Optic",
		type: "Merciless",
		name: "Merciless Optics",
		label: "Merciless Optics",
		posVal: "10",
		posStat: "Accuracy",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28707",
		},
		slot: "Optic",
		type: "Nemesis",
		name: "Nemesis Digital Scope",
		label: "Nemesis Digital Scope",
		posVal: "45",
		posStat: "Headshot Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2870b",
		},
		slot: "Optic",
		type: "Short",
		name: "PRO Red Dot Sight",
		label: "PRO Red Dot Sight",
		posVal: "5",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28713",
		},
		slot: "Optic",
		type: "The Ravenous",
		name: "Predatory Optics",
		label: "Predatory Optics",
		posVal: "5",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28718",
		},
		slot: "Under Barrel",
		type: "Diamondback",
		name: "Diamondback Grip",
		label: "Diamondback Grip",
		posVal: "10",
		posStat: "Stability",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28719",
		},
		slot: "Under Barrel",
		type: "Eagle Bearer",
		name: "Talon Grip",
		label: "Talon Grip",
		posVal: "10",
		posStat: "Weapon Handling",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2871b",
		},
		slot: "Under Barrel",
		type: "Gadget",
		name: "Compact Coupled Small Laser Pointer",
		label: "Compact Coupled Small Laser Pointer",
		posVal: "10",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2871c",
		},
		slot: "Under Barrel",
		type: "Gadget",
		name: "Pistol Flashlight",
		label: "Pistol Flashlight",
		posVal: "",
		posStat: "",
		posStatFactor: "",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "A",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28720",
		},
		slot: "Under Barrel",
		type: "Long",
		name: "Linked Laser Pointer",
		label: "Linked Laser Pointer",
		posVal: "",
		posStat: "Technician Laser Pointer",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "Technician",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28728",
		},
		slot: "Under Barrel",
		type: "Ruthless",
		name: "Ruthless Grip",
		label: "Ruthless Grip",
		posVal: "10",
		posStat: "Weapon Handling",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e2872d",
		},
		slot: "Under Barrel",
		type: "Side",
		name: "Small Laser Pointer",
		label: "Small Laser Pointer",
		posVal: "7",
		posStat: "Critical Hit Chance",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "Demolitionist",
	},
	{
		_id: {
			oid: "62487bd5d03ce64590e28731",
		},
		slot: "Under Barrel",
		type: "The Ravenous",
		name: "Devouring Maw",
		label: "Devouring Maw",
		posVal: "5",
		posStat: "Critical Hit Damage",
		posStatFactor: "number",
		negVal: "",
		negStat: "",
		negStatFactor: "",
		spec: "",
	},
];

export default WeaponModsData;
