import {
	IonAccordion,
	IonAccordionGroup,
	IonItem,
	IonLabel,
	IonPage,
} from "@ionic/react";
import Header from "../components/ui/Header";
import PageContent from "../components/ui/PageContent";

import AppContext from "../context/app-context";
import { Fragment, useContext } from "react";

import BrandsData from "../data/BrandsData";

const gearSlots = ["mask", "backpack", "vest", "gloves", "holster", "kneepads"];

interface TSDataProps {
	props?: any;
	// loadoutStats: LoadoutStats;
	// primary: Weapon;
	// statsPrimary: WeaponStats;
	// secondary: Weapon;
	// statsSecondary: WeaponStats;
	// pistol: Weapon;
	// statsPistol: WeaponStats;
	// mask: Gear;
	// statsMask: GearStats;
	// backpack: Gear;
	// statsBackpack: GearStats;
	// vest: Gear;
	// statsVest: GearStats;
	// gloves: Gear;
	// statsGloves: GearStats;
	// holster: Gear;
	// statsHolster: GearStats;
	// kneepads: Gear;
	// statsKneepads: GearStats;
}

const showData = [
	// "loadoutStats",
	"primaryWeapon",
	"primaryStats",
	"secondaryWeapon",
	"secondaryStats",
	"pistolWeapon",
	"pistolStats",
	"maskGear",
	"backpackGear",
	"vestGear",
	"glovesGear",
	"holsterGear",
	"kneepadsGear",
	"maskStats",
	"backpackStats",
	"vestStats",
	"glovesStats",
	"holsterStats",
	"kneepadsStats",
	"specializationStats",
	"watchStats",
	"brandsetBonuses",
	"gearsetBonuses",
];

const TSData = ({
	props,
}: // loadoutStats,
// primary,
// statsPrimary,
// secondary,
// statsSecondary,
// pistol,
// statsPistol,
// mask,
// statsMask,
// backpack,
// statsBackpack,
// vest,
// statsVest,
// gloves,
// statsGloves,
// holster,
// statsHolster,
// kneepads,
// statsKneepads,
TSDataProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const ctxApp = useContext(AppContext);

	return (
		<IonPage>
			<Header title="TSData" />
			<PageContent>
				{/* <p>
					Primary Weapon Base Damage:{" "}
					{ctxApp.primaryWeapon.baseDamage}
				</p>
				<p>
					Secondary Weapon Base Damage:{" "}
					{ctxApp.secondaryWeapon.baseDamage}
				</p> */}
				<IonAccordionGroup>
					{showData.map((variable, index) => {
						return (
							<IonAccordion
								key={index}
								className="ion-no-padding"
								value={variable}
							>
								<IonItem slot="header" color="danger">
									<IonLabel>{variable}</IonLabel>
								</IonItem>
								<div className="ion-no-padding" slot="content">
									<IonItem key={index} lines="full">
										<IonLabel
											key={index}
											className="ion-text-wrap"
										>
											{/* <IonText
													key={index}
													color="danger"
												>
													<h3>
														{variable} {"=>"}
													</h3>
												</IonText> */}
											<pre>
												{JSON.stringify(
													eval("ctxApp." + variable),
													null,
													2
												)}
											</pre>
										</IonLabel>
									</IonItem>
								</div>
							</IonAccordion>
						);
					})}
				</IonAccordionGroup>
				{/* {BrandsData.map((brand, index) => {
					let brandName = brand.name
						.toLowerCase()
						.replaceAll(" &", "")
						.replaceAll(",", "")
						.replaceAll(".", "")
						.replaceAll("'", "")
						.replaceAll(" ", "-");

					// return gearSlots.map((slot, index) => {
					return (
						<Fragment key={index}>
							<pre>
								.header-gear-{slot}-{brandName} &#123;
								background-image: url("/src/assets/images/
								{slot}.png"), url("/src/assets/images/brand-
								{brandName}
								.png"); background-position: right, left;
								background-size: contain, contain; &#125;
								.brand-{brandName} &#123; background-image:
								url("/src/assets/images/brand-{brandName}
								.png"); background-size: contain;
								background-repeat: no-repeat; background-color:
								black; &#125;
							</pre>
						</Fragment>
					);
					// });
				})} */}
				{BrandsData.map((brand, index) => {
					let brandName = brand.name
						.toLowerCase()
						.replaceAll(" &", "")
						.replaceAll(",", "")
						.replaceAll(".", "")
						.replaceAll("'", "")
						.replaceAll(" ", "");
					let brandNameCSS = brand.name
						.toLowerCase()
						.replaceAll(" &", "")
						.replaceAll(",", "")
						.replaceAll(".", "")
						.replaceAll("'", "")
						.replaceAll(" ", "-");
					return (
						<Fragment key={index}>
							<pre>
								.brand{brandName} &#123; background-image:
								url("/src/assets/images/brands/brand-
								{brandNameCSS}
								.png"); background-size: contain;
								background-repeat: no-repeat; background-color:
								black; &#125;
							</pre>
						</Fragment>
					);
				})}
			</PageContent>
		</IonPage>
	);
};

export default TSData;
