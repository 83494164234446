import {
	IonAccordion,
	IonAccordionGroup,
	IonHeader,
	IonItem,
	IonLabel,
	IonPage,
} from "@ionic/react";
import PageContent from "../components/ui/PageContent";
import weaponSlots from "../models/weaponSlots";
import StatsMain from "../components/stats/StatsMain";
import { useContext } from "react";
import AppContext from "../context/app-context";
import Toolbar from "../components/toolbar/Toolbar";

interface StatsProps {
	prop?: string;
}

const Stats = ({ prop }: StatsProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const ctxApp = useContext(AppContext);

	return (
		<IonPage>
			<IonHeader>
				<Toolbar title="Stats" />
			</IonHeader>
			<PageContent>
				<IonAccordionGroup>
					{weaponSlots.map((slot, index) => {
						return (
							<IonAccordion
								key={slot.key + "Weapon"}
								value={slot.key + "Weapon"}
							>
								<IonItem slot="header" color="light">
									<IonLabel>
										{slot.name} -{" "}
										{eval(
											"ctxApp." + slot.key + "Weapon.name"
										)}
									</IonLabel>
								</IonItem>
								<div className="ion-padding" slot="content">
									<IonAccordionGroup value="Weapon Stats">
										<IonAccordion value="Weapon Stats">
											<IonItem slot="header" color="dark">
												<IonLabel>
													Weapon Stats
												</IonLabel>
											</IonItem>
											<div
												className="ion-no-padding"
												slot="content"
											>
												<StatsMain
													slot={slot.key}
													weapon={eval(
														"ctxApp." +
															slot.key +
															"Weapon"
													)}
													weaponStats={eval(
														"ctxApp." +
															slot.key +
															"Stats"
													)}
												/>
											</div>
										</IonAccordion>
									</IonAccordionGroup>
								</div>
							</IonAccordion>
						);
					})}
					<IonAccordion key="skillSlot1" value="skillSlot1">
						<IonItem slot="header" color="light">
							<IonLabel>Skill Slot 1</IonLabel>
						</IonItem>
						<div className="ion-padding" slot="content">
							Skill Slot 1 Details
						</div>
					</IonAccordion>
					<IonAccordion key="skillSlot2" value="skillSlot2">
						<IonItem slot="header" color="light">
							<IonLabel>Skill Slot 2</IonLabel>
						</IonItem>
						<div className="ion-padding" slot="content">
							Skill Slot 2 Details
						</div>
					</IonAccordion>
				</IonAccordionGroup>
			</PageContent>
		</IonPage>
	);
};

export default Stats;
