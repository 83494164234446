import { IonBadge, IonItem, IonLabel } from "@ionic/react";
import { useEffect, useState } from "react";
import Weapon from "../../models/weapon";
import WeaponStats from "../../models/weaponStats";
import CardBasic from "../ui/CardBasic";

import useCalculator from "../../hooks/useCalculator";

interface WeaponCalculationProps {
	slot: string;
	weapon: Weapon;
	weaponStats: WeaponStats;
}

const WeaponCalculations = ({
	slot,
	weapon,
	weaponStats,
}: WeaponCalculationProps) => {
	const calcs = useCalculator({
		slot: slot,
		weapon: weapon,
		weaponStats: weaponStats,
	});

	const [wdPercent, setWDPercent] = useState(0);
	const [chcPercent, setCHCPercent] = useState(0);
	const [chdPercent, setCHDPercent] = useState(0);
	const [hsdPercent, setHSDPercent] = useState(0);
	const [twdPercent, setTWDPercent] = useState(0);
	const [dtaPercent, setDTAPercent] = useState(0);
	const [dttoocPercent, setDTTOOCPercent] = useState(0);
	const [bodyshot, setBodyshot] = useState(0);
	const [critBodyshot, setCritBodyshot] = useState(0);
	const [headshot, setHeadshot] = useState(0);
	const [critHeadshot, setCritHeadshot] = useState(0);

	// WD
	useEffect(() => {
		setWDPercent(calcs.wdPercent);
	}, [calcs.wdPercent]);

	// CHC
	useEffect(() => {
		setCHCPercent(calcs.chcPercent);
	}, [calcs.chcPercent]);

	// CHD
	useEffect(() => {
		setCHDPercent(calcs.chdPercent);
	}, [calcs.chdPercent]);

	// HSD
	useEffect(() => {
		setHSDPercent(calcs.hsdPercent);
	}, [calcs.hsdPercent]);

	// TWD
	useEffect(() => {
		setTWDPercent(calcs.twdPercent);
	}, [calcs.twdPercent]);

	// DtA
	useEffect(() => {
		setDTAPercent(calcs.dtaPercent);
	}, [calcs.dtaPercent]);

	// DtTooC
	useEffect(() => {
		setDTTOOCPercent(calcs.dttoocPercent);
	}, [calcs.dttoocPercent]);

	// Bodyshot
	useEffect(() => {
		setBodyshot(calcs.bodyshot);
	}, [calcs.bodyshot]);

	// Crit Bodyshot
	useEffect(() => {
		setCritBodyshot(calcs.critBodyshot);
	}, [calcs.critBodyshot]);

	// Headshot
	useEffect(() => {
		setHeadshot(calcs.headshot);
	}, [calcs.headshot]);

	// Headshot
	useEffect(() => {
		setCritHeadshot(calcs.critHeadshot);
	}, [calcs.critHeadshot]);

	return (
		<>
			<CardBasic
				id={weapon.name}
				title={weapon.name}
				subTitle={weapon.quality}
				content={weapon.weaponType}
			/>
			<IonItem key="Expertise" class="ion-no-padding">
				<IonLabel class="ion-no-padding">Expertise:</IonLabel>
				<IonBadge color="danger">
					{weaponStats.expertise.toLocaleString("en-US")}%
				</IonBadge>
			</IonItem>
			<IonItem key="wdPercent" class="ion-no-padding">
				<IonLabel class="ion-no-padding">WD Percent:</IonLabel>
				<IonBadge color="danger">
					{wdPercent.toLocaleString("en-US")}%
				</IonBadge>
			</IonItem>
			<IonItem key="chcPercent" class="ion-no-padding">
				<IonLabel class="ion-no-padding">CHC Percent:</IonLabel>
				<IonBadge color="danger">
					{chcPercent.toLocaleString("en-US")}%
				</IonBadge>
			</IonItem>
			<IonItem key="chdPercent" class="ion-no-padding">
				<IonLabel class="ion-no-padding">CHD Percent:</IonLabel>
				<IonBadge color="danger">
					{chdPercent.toLocaleString("en-US")}%
				</IonBadge>
			</IonItem>
			<IonItem key="hsdPercent" class="ion-no-padding">
				<IonLabel class="ion-no-padding">HSD Percent:</IonLabel>
				<IonBadge color="danger">
					{hsdPercent.toLocaleString("en-US")}%
				</IonBadge>
			</IonItem>
			<IonItem key="twdPercent" class="ion-no-padding">
				<IonLabel class="ion-no-padding">TWD Percent:</IonLabel>
				<IonBadge color="danger">
					{twdPercent.toLocaleString("en-US")}%
				</IonBadge>
			</IonItem>
			<IonItem key="dtaPercent" class="ion-no-padding">
				<IonLabel class="ion-no-padding">DtA Percent:</IonLabel>
				<IonBadge color="danger">
					{dtaPercent.toLocaleString("en-US")}%
				</IonBadge>
			</IonItem>
			<IonItem key="dttoocPercent" class="ion-no-padding">
				<IonLabel class="ion-no-padding">
					DMG Out of Cover Percent:
				</IonLabel>
				<IonBadge color="danger">
					{dttoocPercent.toLocaleString("en-US")}%
				</IonBadge>
			</IonItem>
			<IonItem key="bodyshot" class="ion-no-padding">
				<IonLabel class="ion-no-padding">Bodyshot:</IonLabel>
				<IonBadge color="danger">
					{Math.round(bodyshot).toLocaleString("en-US")}
				</IonBadge>
			</IonItem>
			<IonItem key="critBodyshot" class="ion-no-padding">
				<IonLabel class="ion-no-padding">Crit Bodyshot:</IonLabel>
				<IonBadge color="danger">
					{Math.round(critBodyshot).toLocaleString("en-US")}
				</IonBadge>
			</IonItem>
			<IonItem key="headshot" class="ion-no-padding">
				<IonLabel class="ion-no-padding">Headshot:</IonLabel>
				<IonBadge color="danger">
					{Math.round(headshot).toLocaleString("en-US")}
				</IonBadge>
			</IonItem>
			<IonItem key="dcritHeadshot" class="ion-no-padding">
				<IonLabel class="ion-no-padding">Crit Headshot:</IonLabel>
				<IonBadge color="danger">
					{Math.round(critHeadshot).toLocaleString("en-US")}
				</IonBadge>
			</IonItem>
			{/* {calculatedStats.map((stat) => {
				return (
					<IonItem key={stat.name} class="ion-no-padding">
						<IonLabel class="ion-no-padding">{stat.name}:</IonLabel>
						<IonBadge color="danger">
							{Math.round(eval(stat.var)).toLocaleString("en-US")}
							%
						</IonBadge>
					</IonItem>
				);
			})} */}
			{/* {wdItems.map((stat, index) => {
				return (
					<IonItem key={index} class="ion-no-padding">
						<IonLabel class="ion-no-padding">{stat.name}:</IonLabel>
						<IonBadge color="danger">{stat.value}%</IonBadge>
					</IonItem>
				);
			})} */}
		</>
	);
};

export default WeaponCalculations;
