import { IonBadge, IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import GearStats from "../../models/gearStats";

interface StatsModsProps {
	weaponSlot: string;
	gearSlot: string;
	attribute: string;
}

const StatsMods = ({ weaponSlot, gearSlot, attribute }: StatsModsProps) => {
	const ctxApp = useContext(AppContext);
	const [showAttribute, setShowAttribute] = useState(true);
	const [gearSlotLabel, setGearSlotLabel] = useState("");
	const [showStartBadge, setShowStartBadge] = useState(true);
	const [weaponBaseDamage, setWeaponBaseDamage] = useState(0);
	const [gearValue, setGearValue] = useState(0);
	const [gearSlotDesc, setGearSlotDesc] = useState("");

	useEffect(() => {
		switch (weaponSlot.toLowerCase()) {
			case "primary":
				setWeaponBaseDamage(ctxApp.primaryWeapon.baseDamage);
				break;
			case "secondary":
				setWeaponBaseDamage(ctxApp.secondaryWeapon.baseDamage);
				break;
			case "pistol":
				setWeaponBaseDamage(ctxApp.pistolWeapon.baseDamage);
				break;
		}
	}, [
		ctxApp.pistolWeapon.baseDamage,
		ctxApp.primaryWeapon.baseDamage,
		ctxApp.secondaryWeapon.baseDamage,
		weaponSlot,
	]);

	useEffect(() => {
		function checkGear(gearStats: GearStats) {
			switch (attribute) {
				case "Weapon Damage":
					setShowAttribute(false);
					setGearValue(0);
					break;
				case "Critical Hit Chance":
					if (gearStats.modName === attribute) {
						setShowStartBadge(false);
						setGearValue(gearStats.modValue);
					} else {
						setShowAttribute(false);
						setGearValue(0);
					}
					break;
				case "Critical Hit Damage":
					if (gearStats.modName === attribute) {
						setShowStartBadge(true);
						setGearValue(gearStats.modValue);
					} else {
						setShowAttribute(false);
						setGearValue(0);
					}
					break;
				case "Headshot Damage":
					if (gearStats.modName === attribute) {
						setShowStartBadge(true);
						setGearValue(gearStats.modValue);
					} else {
						setShowAttribute(false);
						setGearValue(0);
					}
					break;
			}
		}

		switch (gearSlot) {
			case "mask":
				setGearSlotLabel(ctxApp.maskGear.name);
				setGearSlotDesc(ctxApp.maskStats.modName);
				setShowAttribute(true);
				checkGear(ctxApp.maskStats);
				break;
			case "backpack":
				setGearSlotLabel("Backpack");
				setShowAttribute(true);
				checkGear(ctxApp.backpackStats);
				break;
			case "vest":
				setGearSlotLabel("Vest");
				setShowAttribute(true);
				checkGear(ctxApp.vestStats);
				break;
			case "gloves":
				setGearSlotLabel("Gloves");
				setShowAttribute(true);
				checkGear(ctxApp.glovesStats);
				break;
			case "holster":
				setGearSlotLabel("Holster");
				setShowAttribute(true);
				checkGear(ctxApp.holsterStats);
				break;
			case "kneepads":
				setGearSlotLabel("Kneepads");
				setShowAttribute(true);
				checkGear(ctxApp.kneepadsStats);
				break;
		}
	}, [
		attribute,
		ctxApp.backpackStats,
		ctxApp.glovesStats,
		ctxApp.holsterStats,
		ctxApp.kneepadsStats,
		ctxApp.maskGear.name,
		ctxApp.maskStats,
		ctxApp.vestStats,
		gearSlot,
	]);

	return (
		<>
			{showAttribute && (
				<IonItem key={""}>
					{showStartBadge && (
						<IonBadge slot="start">{gearValue}%</IonBadge>
					)}
					<IonThumbnail class="ion-no-padding" slot="start">
						<img
							className={"gear75"}
							src={
								"assets/images/" + gearSlot + "-1.4-100x92.png"
							}
							alt={gearSlot}
						/>
					</IonThumbnail>
					<IonLabel>
						<h3>{gearSlotLabel}</h3>
						<p>{gearSlotDesc}</p>
					</IonLabel>
					{showStartBadge && (
						<IonBadge slot="end">
							{Math.round(
								weaponBaseDamage * (gearValue / 100)
							).toLocaleString("en-US")}
						</IonBadge>
					)}
					{!showStartBadge && (
						<IonBadge slot="end">{gearValue}%</IonBadge>
					)}
				</IonItem>
			)}
		</>
	);
};

export default StatsMods;
