import {
	IonAlert,
	IonButton,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonToast,
	useIonRouter,
} from "@ionic/react";
import { useContext, useState } from "react";
import "./RegisterForm.css";

// Helpers
import { createUser } from "../helpers/firebase";
import AuthContext from "../context/auth-context";

interface ContainerProps {
	name?: string;
}

interface InputChangeEventDetail<T = any> {
	value: T;
}

const RegisterForm: React.FC<ContainerProps> = ({ name }) => {
	const ctxAuth = useContext(AuthContext);
	const router = useIonRouter();

	const [username, setUsername] = useState("shd.builder.dev@fooweb.com");
	const [password, setPassword] = useState("p@$$W)RD");

	const [error, setError] = useState("");
	const [showToast, setShowToast] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const usernameHandler = (event: CustomEvent<InputChangeEventDetail>) => {
		// console.log(event);
		setUsername(event.detail.value);
	};

	const passwordHandler = (event: CustomEvent<InputChangeEventDetail>) => {
		// console.log(event);
		setPassword(event.detail.value);
	};

	const handleRegister = (event: React.SyntheticEvent) => {
		event.preventDefault();
		// console.log(username, password);
		createUser(username, password)
			.then((user) => {
				if (user) {
					// router.push("/");
					router.push("/login", "forward");
					console.log(user);
					// onLogin(username, password);
				}
			})
			.catch((error) => {
				console.log(error);
				setError(error);
				setShowToast(true);
				setShowAlert(true);
				// router.push(`/register?error=${error}`)
			});
	};

	return (
		<div className="container">
			<div>
				<IonAlert
					isOpen={showAlert}
					onDidDismiss={() => setShowAlert(false)}
					header="Alert"
					subHeader="Important message"
					message={error}
					buttons={["OK"]}
				/>
			</div>
			<div>
				<IonToast
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={error}
					duration={4000}
				/>
			</div>
			<div>
				<IonLoading
					cssClass="my-custom-class"
					isOpen={ctxAuth.isLoading}
					// onDidDismiss={() => setShowLoading(false)}
					message={"Checking login..."}
					duration={1000}
				/>
			</div>

			{ctxAuth.isLoggedIn && <p>Logged in!</p>}
			{!ctxAuth.isLoggedIn && (
				<form
					onSubmit={handleRegister}
					className="gap-4 grid grid-cols-2"
				>
					<IonItem>
						<IonLabel position="stacked">Username</IonLabel>
						<IonInput
							name="username"
							type="text"
							value={username}
							onIonChange={usernameHandler}
							placeholder="newemail@fooweb.com"
						/>
					</IonItem>
					<IonItem>
						<IonLabel position="stacked">Password</IonLabel>
						<IonInput
							name="password"
							value={password}
							onIonChange={passwordHandler}
							placeholder="password"
						/>
					</IonItem>
					<IonButton
						type="submit"
						onClick={handleRegister}
						size="small"
					>
						Register
					</IonButton>
				</form>
			)}
		</div>
	);
};

export default RegisterForm;
