import Weapon from "../../models/weapon";
import WeaponAttributePrimary from "./WeaponAttributePrimary";
import WeaponAttributeSecondary from "./WeaponAttributeSecondary";
import WeaponAttributeTertiary from "./WeaponAttributeTertiary";
import WeaponExpertise from "./WeaponExpertise";

// import css from "./WeaponMain.module.css";
// import "./WeaponMain.css";
import "../../pages/CssMain.css";
import "../../pages/CssWeapon.css";
import WeaponMainSelect from "./WeaponMainSelect";
import WeaponMods from "./WeaponMods";
import WeaponTalent from "./WeaponTalent";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";

interface WeaponMainProps {
	slot: string;
}

let weapon: Weapon = {
	_id: {
		oid: "",
	},
	variant: "",
	name: "",
	slot: "",
	quality: "",
	weaponType: "",
	baseDamage: 0,
	optimalRange: 0,
	rpm: 0,
	magSize: 0,
	reloadSpeedMS: 0,
	hsd: 0,
	core1: "",
	core1Max: "",
	core2: "",
	core2Max: "",
	attribute1: "",
	talent: "",
	optics: "",
	underBarrel: "",
	magazine: "",
	muzzle: "",
};

const WeaponMain = ({ slot }: WeaponMainProps) => {
	const ctxApp = useContext(AppContext);
	const [selectedWeapon, setSelectedWeapon] = useState(weapon);
	const [showWeaponDetails, setShowWeaponDeatils] = useState(false);

	useEffect(() => {
		switch (slot.toLowerCase()) {
			case "primary":
				if (ctxApp.primaryWeapon.name !== "") {
					setShowWeaponDeatils(true);
					setSelectedWeapon(ctxApp.primaryWeapon);
				}
				break;
			case "secondary":
				if (ctxApp.secondaryWeapon.name !== "") {
					setShowWeaponDeatils(true);
					setSelectedWeapon(ctxApp.secondaryWeapon);
				}
				break;
			case "pistol":
				if (ctxApp.pistolWeapon.name !== "") {
					setShowWeaponDeatils(true);
					setSelectedWeapon(ctxApp.pistolWeapon);
				}
				break;
		}
	}, [
		ctxApp.pistolWeapon,
		ctxApp.primaryWeapon,
		ctxApp.secondaryWeapon,
		slot,
	]);

	return (
		<>
			<WeaponMainSelect slot={slot} />
			<WeaponExpertise slot={slot} />
			<WeaponAttributePrimary slot={slot} />
			<WeaponAttributeSecondary slot={slot} />
			<WeaponAttributeTertiary slot={slot} />
			<WeaponTalent slot={slot} />
			<WeaponMods slot={slot} weapon={selectedWeapon} />
			{/* {showWeaponDetails === true && (
				<>
					<WeaponExpertise slot={slot} />
					<WeaponAttributePrimary slot={slot} />
					<WeaponAttributeSecondary slot={slot} />
					<WeaponAttributeTertiary slot={slot} />
					<WeaponTalent slot={slot} />
					<WeaponMods slot={slot} weapon={selectedWeapon} />
				</>
			)} */}
		</>
	);
};
// };

export default WeaponMain;
