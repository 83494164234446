import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { useContext, useEffect, useState } from "react";

import AppContext from "../../context/app-context";
import WeaponStats from "../../models/weaponStats";

interface WeaponExpertiseProps {
	slot: string;
}

const expertiseOptions = Array.from({ length: 21 }, (_, index) => index);

const WeaponExpertise = ({ slot }: WeaponExpertiseProps) => {
	const ctxApp = useContext(AppContext);

	const [selectedExpertise, setSelectedExpertise] = useState<
		number | undefined
	>(20);

	const expertiseChangeHandler = (e: CustomEvent) => {
		// console.log(slot, e.detail.value);
		setSelectedExpertise(e.detail.value);
		ctxApp.updateWeaponStat("expertise", slot, e.detail.value, "number");
	};

	const interfaceOptions = {
		cssClass: "my-custom-interface",
	};

	useEffect(() => {
		function checkValue(weaponStats: WeaponStats) {
			setSelectedExpertise((currValue) => {
				if (weaponStats.expertise >= 0) {
					return weaponStats.expertise;
				} else {
					return currValue;
				}
			});
		}

		switch (slot.toLowerCase()) {
			case "primary":
				if (ctxApp.primaryWeapon.name) {
					checkValue(ctxApp.primaryStats);
				}
				break;
			case "secondary":
				if (ctxApp.secondaryWeapon.name) {
					checkValue(ctxApp.secondaryStats);
				}
				break;
			case "pistol":
				if (ctxApp.pistolWeapon.name) {
					checkValue(ctxApp.pistolStats);
				}
				break;
		}
	}, [
		ctxApp.pistolStats,
		ctxApp.pistolWeapon.name,
		ctxApp.primaryStats,
		ctxApp.primaryWeapon.name,
		ctxApp.secondaryStats,
		ctxApp.secondaryWeapon.name,
		slot,
	]);

	return (
		<>
			<IonItem class="ion-no-padding">
				<IonLabel class="ion-no-padding">Expertise: </IonLabel>
				<IonSelect
					interfaceOptions={interfaceOptions}
					className="weaponInput"
					interface="popover"
					placeholder="20"
					value={selectedExpertise}
					onIonChange={expertiseChangeHandler}
				>
					{expertiseOptions.map((expertise) => {
						return (
							<IonSelectOption
								key={expertise}
								value={expertise}
								class="brown-option"
							>
								{expertise}
							</IonSelectOption>
						);
					})}
				</IonSelect>
			</IonItem>
		</>
	);
};

export default WeaponExpertise;
