import {
	IonButton,
	IonButtons,
	IonIcon,
	IonMenuButton,
	IonTitle,
	IonToolbar,
	useIonLoading,
} from "@ionic/react";
import { useHistory } from "react-router";
import {
	ellipsisHorizontalOutline,
	ellipsisVerticalOutline,
	logInOutline,
	logoAndroid,
	logOutOutline,
	personCircleOutline,
	searchCircleOutline,
} from "ionicons/icons";
import { Fragment, useContext } from "react";
import AppContext from "../../context/app-context";

// testing
import {
	primaryWeaponHSOS,
	primaryStatsHSOS,
	secondaryWeaponHSOS,
	secondaryStatsHSOS,
	pistolWeaponHSOS,
	pistolStatsHSOS,
	maskGearHSOS,
	maskStatsHSOS,
	backpackGearHSOS,
	backpackStatsHSOS,
	vestGearHSOS,
	vestStatsHSOS,
	glovesGearHSOS,
	glovesStatsHSOS,
	holsterGearHSOS,
	holsterStatsHSOS,
	kneepadsGearHSOS,
	kneepadsStatsHSOS,
	watchStatsHSOS,
	specializationStatsHSOS,
	brandsetBonusesHSOS,
	gearsetBonusesHSOS,
} from "../../data/Loadout-HeadhshotOS";
import AuthContext from "../../context/auth-context";

interface ToolbarProps {
	title?: string;
}

const Toolbar = ({ title }: ToolbarProps) => {
	const ctxAuth = useContext(AuthContext);
	const ctxApp = useContext(AppContext);
	const history = useHistory();
	const [present, dismiss] = useIonLoading();

	const logoutHandler = () => {
		// console.log("logging out");
		ctxAuth.onLogout();
		history.replace("/login");
	};

	const loginHandler = () => {
		// ctxAuth.onLogout();
		history.replace("/login");
	};

	// testing
	const loadOSBuild = () => {
		present({
			message: "Loading...",
			duration: 5000,
			spinner: "lines-sharp-small",
		});
		// "bubbles" ｜ "circles" ｜ "circular" ｜ "crescent" ｜ "dots" ｜ "lines" ｜ "lines-sharp" ｜ "lines-sharp-small" ｜ "lines-small" ｜ null ｜ undefined
		// console.log("Loading Headshot OS Build");
		ctxApp.loadBuild("primary", primaryWeaponHSOS);
		ctxApp.loadBuild("primaryStats", primaryStatsHSOS);
		ctxApp.loadBuild("secondary", secondaryWeaponHSOS);
		ctxApp.loadBuild("secondaryStats", secondaryStatsHSOS);
		ctxApp.loadBuild("pistol", pistolWeaponHSOS);
		ctxApp.loadBuild("pistolStats", pistolStatsHSOS);
		ctxApp.loadBuild("mask", maskGearHSOS);
		ctxApp.loadBuild("maskStats", maskStatsHSOS);
		ctxApp.loadBuild("backpack", backpackGearHSOS);
		ctxApp.loadBuild("backpackStats", backpackStatsHSOS);
		ctxApp.loadBuild("vest", vestGearHSOS);
		ctxApp.loadBuild("vestStats", vestStatsHSOS);
		ctxApp.loadBuild("gloves", glovesGearHSOS);
		ctxApp.loadBuild("glovesStats", glovesStatsHSOS);
		ctxApp.loadBuild("holster", holsterGearHSOS);
		ctxApp.loadBuild("holsterStats", holsterStatsHSOS);
		ctxApp.loadBuild("kneepads", kneepadsGearHSOS);
		ctxApp.loadBuild("kneepadsStats", kneepadsStatsHSOS);
		ctxApp.loadBuild("watchStats", watchStatsHSOS);
		ctxApp.loadBuild("specializationStats", specializationStatsHSOS);
		ctxApp.loadBuild("brandsetBonuses", brandsetBonusesHSOS);
		ctxApp.loadBuild("gearsetBonuses", gearsetBonusesHSOS);
	};

	return (
		<>
			<IonToolbar>
				<sup>Build Station v0.95.170-beta</sup>
				<IonButtons slot="start">
					<IonMenuButton />
				</IonButtons>
				<IonButtons slot="secondary">
					{ctxAuth.isAdmin && (
						<IonButton onClick={loadOSBuild}>
							<IonIcon slot="icon-only" icon={logoAndroid} />
						</IonButton>
					)}
					{ctxAuth.isAdmin && (
						<Fragment>
							<IonButton>
								<IonIcon
									slot="icon-only"
									icon={personCircleOutline}
								/>
							</IonButton>
							<IonButton>
								<IonIcon
									slot="icon-only"
									icon={searchCircleOutline}
								/>
							</IonButton>
						</Fragment>
					)}
					{ctxAuth.isLoggedIn && (
						<IonButton
							onClick={logoutHandler}
							routerLink="#"
							routerDirection="none"
						>
							<IonIcon slot="icon-only" icon={logOutOutline} />
						</IonButton>
					)}
					{!ctxAuth.isLoggedIn && (
						<IonButton
							onClick={loginHandler}
							routerLink="#"
							routerDirection="none"
						>
							<IonIcon slot="icon-only" icon={logInOutline} />
						</IonButton>
					)}
				</IonButtons>
				{/* <IonButtons slot="primary">
					<IonButton color="secondary">
						<IonIcon
							slot="icon-only"
							ios={ellipsisHorizontalOutline}
							md={ellipsisVerticalOutline}
						/>
					</IonButton>
				</IonButtons> */}
				<IonTitle>{title}</IonTitle>
			</IonToolbar>
		</>
	);
};

export default Toolbar;
