import React, { useState, useRef, useEffect, useContext } from "react";
import {
	IonButtons,
	IonButton,
	IonModal,
	IonHeader,
	IonContent,
	IonToolbar,
	IonTitle,
	IonGrid,
	IonRow,
	IonCol,
	IonAlert,
	IonSearchbar,
} from "@ionic/react";

import weaponsData from "../../data/WeaponsData";
import WeaponMainSelectCard from "./WeaponMainSelectCard";
import Weapon from "../../models/weapon";
import "./WeaponMainSelectModal.css";
import AppContext from "../../context/app-context";

interface WeaponMainSelectModalProps {
	slot: string;
}

function sortWeapons(
	weaponType: string,
	exotics: boolean = false,
	named: boolean = false
) {
	return weaponsData
		.sort(function (a, b) {
			const nameA = a.name.toUpperCase(); // ignore upper and lowercase
			const nameB = b.name.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		})
		.filter((weapon) => {
			if (exotics) {
				return (
					weapon.weaponType.toLowerCase() === weaponType &&
					weapon.quality.toLowerCase() === "exotic"
				);
			} else if (named) {
				return (
					weapon.weaponType.toLowerCase() === weaponType &&
					weapon.quality.toLowerCase() === "named"
				);
			} else {
				return (
					weapon.weaponType.toLowerCase() === weaponType &&
					weapon.quality.toLowerCase() !== "exotic" &&
					weapon.quality.toLowerCase() !== "named"
				);
			}
		});
}

const initWeaponsAR = sortWeapons("assault rifle");
const initWeaponsARExotic = sortWeapons("assault rifle", true);
const initWeaponsARNamed = sortWeapons("assault rifle", false, true);
const initWeaponsMMR = sortWeapons("marksman rifle");
const initWeaponsMMRExotic = sortWeapons("marksman rifle", true);
const initWeaponsMMRNamed = sortWeapons("marksman rifle", false, true);
const initWeaponsRifle = sortWeapons("rifle");
const initWeaponsRifleExotic = sortWeapons("rifle", true);
const initWeaponsRifleNamed = sortWeapons("rifle", false, true);
const initWeaponsLMG = sortWeapons("lmg");
const initWeaponsLMGExotic = sortWeapons("lmg", true);
const initWeaponsLMGNamed = sortWeapons("lmg", false, true);
const initWeaponsSMG = sortWeapons("smg");
const initWeaponsSMGExotic = sortWeapons("smg", true);
const initWeaponsSMGNamed = sortWeapons("smg", false, true);
const initWeaponsShotgun = sortWeapons("shotgun");
const initWeaponsShotgunExotic = sortWeapons("shotgun", true);
const initWeaponsShotgunNamed = sortWeapons("shotgun", false, true);
const initWeaponsPistol = sortWeapons("pistol");
const initWeaponsPistolExotic = sortWeapons("pistol", true);
const initWeaponsPistolNamed = sortWeapons("pistol", false, true);

const initWeapons = initWeaponsARExotic.concat(
	initWeaponsARNamed,
	initWeaponsAR,
	initWeaponsMMRExotic,
	initWeaponsMMRNamed,
	initWeaponsMMR,
	initWeaponsRifleExotic,
	initWeaponsRifleNamed,
	initWeaponsRifle,
	initWeaponsLMGExotic,
	initWeaponsLMGNamed,
	initWeaponsLMG,
	initWeaponsSMGExotic,
	initWeaponsSMGNamed,
	initWeaponsSMG,
	initWeaponsShotgunExotic,
	initWeaponsShotgunNamed,
	initWeaponsShotgun,
	initWeaponsPistolExotic,
	initWeaponsPistolNamed,
	initWeaponsPistol
);

const WeaponMainSelectModal = ({ slot }: WeaponMainSelectModalProps) => {
	const ctxApp = useContext(AppContext);

	const [showAlert, setShowAlert] = useState(false);

	const modal = useRef<HTMLIonModalElement>(null);
	const page = useRef(null);
	const searchbar = useRef<HTMLIonSearchbarElement>(null);

	const [searchText, setSearchText] = useState("");

	const [presentingElement, setPresentingElement] =
		useState<HTMLElement | null>(null);

	const [currentWeapons, setCurrentWeapons] = useState(initWeapons);

	useEffect(() => {
		setPresentingElement(page.current);
	}, []);

	useEffect(() => {
		let updatedWeapons: Weapon[] = initWeapons;
		switch (slot.toLowerCase()) {
			case "pistol":
				updatedWeapons = initWeapons.filter((weapon) => {
					return weapon.slot === "S";
				});
				break;
			default:
				updatedWeapons = initWeapons.filter((weapon) => {
					return weapon.slot === "P";
				});
				break;
		}
		if (searchText !== "") {
			console.log(searchText);
			updatedWeapons = updatedWeapons.filter((weapon) => {
				return weapon.name
					.toLowerCase()
					.includes(searchText.toLowerCase());
			});
		}
		setCurrentWeapons(updatedWeapons);
	}, [searchText, slot]);

	function receiveSelectedWeapon(weapon: Weapon) {
		// check if two exotics have been selected
		if (weapon.quality.toLowerCase() === "exotic") {
			switch (slot.toLowerCase()) {
				case "primary":
					if (
						ctxApp.secondaryWeapon.quality.toLowerCase() ===
							"exotic" ||
						ctxApp.pistolWeapon.quality.toLowerCase() === "exotic"
					) {
						setShowAlert(true);
					} else {
						ctxApp.updateWeapon(slot, weapon);
						modal.current?.dismiss();
					}
					break;
				case "secondary":
					if (
						ctxApp.primaryWeapon.quality.toLowerCase() ===
							"exotic" ||
						ctxApp.pistolWeapon.quality.toLowerCase() === "exotic"
					) {
						setShowAlert(true);
					} else {
						ctxApp.updateWeapon(slot, weapon);
						modal.current?.dismiss();
					}
					break;
				case "pistol":
					if (
						ctxApp.primaryWeapon.quality.toLowerCase() ===
							"exotic" ||
						ctxApp.secondaryWeapon.quality.toLowerCase() ===
							"exotic"
					) {
						setShowAlert(true);
					} else {
						ctxApp.updateWeapon(slot, weapon);
						modal.current?.dismiss();
					}
					break;
			}
		} else {
			ctxApp.updateWeapon(slot, weapon);
			modal.current?.dismiss();
		}
		setSearchText("");
	}

	function dismiss() {
		modal.current?.dismiss();
		setSearchText("");
	}

	useEffect(() => {
		if (searchText === "") {
			searchbar.current?.setFocus();
		}
	}, [searchText]);

	return (
		<>
			<IonAlert
				cssClass={"two-exotics"}
				isOpen={showAlert}
				onDidDismiss={() => setShowAlert(false)}
				header="Two Exotics!"
				subHeader="Not Allowed!"
				message="Cannot equip two Exotic Weapons!"
				buttons={["OK"]}
			/>
			<IonModal
				ref={modal}
				trigger={"open-" + slot.toLowerCase() + "-modal"}
				presentingElement={presentingElement!}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{slot}</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={(ev) => dismiss()}>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonSearchbar
					ref={searchbar}
					color={"medium"}
					value={searchText}
					onIonChange={(e) => setSearchText(e.detail.value!)}
				></IonSearchbar>
				<IonContent className="ion-padding">
					<IonGrid>
						<IonRow>
							{currentWeapons.map((weapon, index) => {
								return (
									<IonCol
										key={index}
										className=""
										size="12"
										size-md="6"
										size-sm="6"
									>
										<WeaponMainSelectCard
											slot={slot}
											id={slot}
											headerClass="exotic-rifle"
											weapon={weapon}
											sendSelectedWeaponToParent={
												receiveSelectedWeapon
											}
										/>
									</IonCol>
								);
							})}
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonModal>
		</>
	);
};

export default WeaponMainSelectModal;
