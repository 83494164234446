import React, { useState } from "react";

import {
	primaryWeapon,
	primaryStats,
	secondaryWeapon,
	secondaryStats,
	pistolWeapon,
	pistolStats,
	maskGear,
	maskStats,
	backpackGear,
	backpackStats,
	vestGear,
	vestStats,
	glovesGear,
	glovesStats,
	holsterGear,
	holsterStats,
	kneepadsGear,
	kneepadsStats,
	watchStats,
	specializationStats,
	brandsetBonuses,
	gearsetBonuses,
} from "../data/Loadout-Shell";

const AppContext = React.createContext({
	primaryWeapon,
	primaryStats,
	secondaryWeapon,
	secondaryStats,
	pistolWeapon,
	pistolStats,
	maskGear,
	maskStats,
	backpackGear,
	backpackStats,
	vestGear,
	vestStats,
	glovesGear,
	glovesStats,
	holsterGear,
	holsterStats,
	kneepadsGear,
	kneepadsStats,
	watchStats,
	specializationStats,
	brandsetBonuses,
	gearsetBonuses,
	updateWeapon: (weaponSlot: string, value: any) => {},
	updateGear: (gearSlot: string, value: any) => {},
	updateWeaponStat: (
		attribute: any,
		weaponSlot: any,
		value: any,
		valueType?: string
	) => {},
	updateGearStat: (
		attribute: any,
		gearSlot: any,
		value: any,
		valueType?: string
	) => {},
	updateSpecStat: (attribute: any, value: any, valueType?: string) => {},
	updateWatchStat: (node: string, attribute: string, value: number) => {},
	resetStats: (slot: any) => {},
	loadBuild: (action: any, value: any) => {},
});

export const AppContextProvider = (props: any) => {
	// weapons
	const [primary, setPrimaryWeapon] = useState(primaryWeapon);
	const [secondary, setSecondaryWeapon] = useState(secondaryWeapon);
	const [pistol, setPistolWeapon] = useState(pistolWeapon);
	// gear
	const [mask, setMaskGear] = useState(maskGear);
	const [backpack, setBackpackGear] = useState(backpackGear);
	const [vest, setVestGear] = useState(vestGear);
	const [gloves, setGlovesGear] = useState(glovesGear);
	const [holster, setHolsterGear] = useState(holsterGear);
	const [kneepads, setKneepadsGear] = useState(kneepadsGear);
	// weapon stats
	const [statsPrimary, setStatsPrimary] = useState(primaryStats);
	const [statsSecondary, setStatsSecondary] = useState(secondaryStats);
	const [statsPistol, setStatsPistol] = useState(pistolStats);
	// gear stats
	const [statsMask, setStatsMask] = useState(maskStats);
	const [statsBackpack, setStatsBackpack] = useState(backpackStats);
	const [statsVest, setStatsVest] = useState(vestStats);
	const [statsGloves, setStatsGloves] = useState(glovesStats);
	const [statsHolster, setStatsHolster] = useState(holsterStats);
	const [statsKneepads, setStatsKneepads] = useState(kneepadsStats);
	// other stats
	const [statsWatch, setStatsWatch] = useState(watchStats);
	const [statsSpecialization, setStatsSpecialization] =
		useState(specializationStats);
	const [bonusesBrandset, setBonusesBrandset] = useState(brandsetBonuses);
	const [bonusesGearset, setBonusesGearset] = useState(gearsetBonuses);

	// handlers
	const updateWeaponHandler = (slot: any, value: any) => {
		// console.log("updateWeaponHandler : action : ", action);
		if (slot.toLowerCase() === "primary") {
			// console.log("updateWeaponHandler : value : ", value);
			setPrimaryWeapon(() => {
				return value;
			});
			// reset weapon stats
			setStatsPrimary(primaryStats);
		}
		if (slot.toLowerCase() === "secondary") {
			// console.log("updateWeaponHandler : value : ", value);
			setSecondaryWeapon(() => {
				return value;
			});
		}
		if (slot.toLowerCase() === "pistol") {
			// console.log("updateWeaponHandler : value : ", value);
			setPistolWeapon(() => {
				return value;
			});
		}
	};

	const updateGearHandler = (slot: any, value: any) => {
		// console.log("updateGearHandler : slot : ", slot);
		switch (slot.toLowerCase()) {
			case "mask":
				setMaskGear(() => {
					return value;
				});
				break;
			case "backpack":
				setBackpackGear(() => {
					return value;
				});
				break;
			case "vest":
				setVestGear(() => {
					return value;
				});
				break;
			case "gloves":
				setGlovesGear(() => {
					return value;
				});
				break;
			case "holster":
				setHolsterGear(() => {
					return value;
				});
				break;
			case "kneepads":
				setKneepadsGear(() => {
					return value;
				});
				break;
		}
	};

	const updateWeaponStatHandler = (
		attribute: any,
		slot: any,
		value: any,
		type?: string
	) => {
		// console.log("updateWeaponStatHandler : ", attribute, slot, value, type);
		let convertedValue: any;
		if (type === "number") {
			convertedValue = parseFloat(value);
		} else {
			convertedValue = value;
		}
		switch (slot.toLowerCase()) {
			case "primary":
				setStatsPrimary((currStats) => {
					return { ...currStats, [attribute]: convertedValue };
				});
				break;
			case "secondary":
				setStatsSecondary((currStats) => {
					return { ...currStats, [attribute]: convertedValue };
				});
				break;
			case "pistol":
				setStatsPistol((currStats) => {
					return { ...currStats, [attribute]: convertedValue };
				});
				break;
		}
	};

	const updateGearStatHandler = (
		attribute: any,
		slot: any,
		value: any,
		type?: string
	) => {
		// console.log("updateGearStatHandler : ", attribute, slot, value, type);
		let convertedValue: any;
		if (type === "number") {
			convertedValue = parseFloat(value);
		} else {
			convertedValue = value;
		}
		switch (slot.toLowerCase()) {
			case "mask":
				setStatsMask((currStats) => {
					return { ...currStats, [attribute]: convertedValue };
				});
				break;
			case "backpack":
				setStatsBackpack((currStats) => {
					return { ...currStats, [attribute]: convertedValue };
				});
				break;
			case "vest":
				setStatsVest((currStats) => {
					return { ...currStats, [attribute]: convertedValue };
				});
				break;
			case "gloves":
				setStatsGloves((currStats) => {
					return { ...currStats, [attribute]: convertedValue };
				});
				break;
			case "holster":
				setStatsHolster((currStats) => {
					return { ...currStats, [attribute]: convertedValue };
				});
				break;
			case "kneepads":
				setStatsKneepads((currStats) => {
					return { ...currStats, [attribute]: convertedValue };
				});
				break;
		}
	};

	const updateSpecStatHandler = (
		attribute: any,
		value: any,
		type?: string
	) => {
		// console.log("updateGearStatHandler : ", attribute, slot, value, type);
		let convertedValue: any;
		if (type === "number") {
			convertedValue = parseFloat(value);
		} else {
			convertedValue = value;
		}
		setStatsSpecialization((currStats) => {
			return {
				...currStats,
				[attribute]: convertedValue,
			};
		});
	};

	const updateWatchStatHandler = (
		node: string,
		attribute: string,
		value: any
	) => {
		setStatsWatch((currStats) => {
			return {
				...currStats,
				[node]: { ...currStats[node], [attribute]: parseFloat(value) },
			};
		});
	};

	const resetStatsHandler = (slot: any) => {
		// console.log("resetStatsHandler : ", slot);
		switch (slot.toLowerCase()) {
			case "mask":
				// console.log("maskStats : ", maskStats);
				setStatsMask(() => {
					return maskStats;
				});
				break;
			case "backpack":
				setStatsBackpack(() => {
					return backpackStats;
				});
				break;
			case "vest":
				setStatsVest(() => {
					return vestStats;
				});
				break;
			case "gloves":
				setStatsGloves(() => {
					return glovesStats;
				});
				break;
			case "holster":
				setStatsHolster(() => {
					return holsterStats;
				});
				break;
			case "kneepads":
				setStatsKneepads(() => {
					return kneepadsStats;
				});
				break;
		}
	};

	const loadBuildHandler = (item: any, value: any) => {
		// console.log("loadBuildHandler : ", item, value);
		try {
			switch (item.toLowerCase()) {
				case "primary":
					setPrimaryWeapon(value);
					break;
				case "primarystats":
					setStatsPrimary(value);
					break;
				case "secondary":
					setSecondaryWeapon(value);
					break;
				case "secondarystats":
					setStatsSecondary(value);
					break;
				case "pistol":
					setPistolWeapon(value);
					break;
				case "pistolstats":
					setStatsPistol(value);
					break;
				case "mask":
					setMaskGear(value);
					break;
				case "maskstats":
					setStatsMask(value);
					break;
				case "backpack":
					setBackpackGear(value);
					break;
				case "backpackstats":
					setStatsBackpack(value);
					break;
				case "vest":
					setVestGear(value);
					break;
				case "veststats":
					setStatsVest(value);
					break;
				case "gloves":
					setGlovesGear(value);
					break;
				case "glovesstats":
					setStatsGloves(value);
					break;
				case "holster":
					setHolsterGear(value);
					break;
				case "holsterstats":
					setStatsHolster(value);
					break;
				case "kneepads":
					setKneepadsGear(value);
					break;
				case "kneepadsstats":
					setStatsKneepads(value);
					break;
				case "specializationstats":
					setStatsSpecialization(value);
					break;
				case "watchstats":
					setStatsWatch(value);
					break;
				case "brandsetbonuses":
					setBonusesBrandset(value);
					break;
				case "gearsetbonuses":
					setBonusesGearset(value);
					break;
			}
		} catch (error) {
			console.log(error);
		}
	};

	// function successCallback(result: any) {
	// 	console.log("Could not load build item (result): " + result);
	// }

	// function failureCallback(error: any) {
	// 	console.log("Could not load build item (error): " + error);
	// }

	return (
		<AppContext.Provider
			value={{
				primaryWeapon: primary,
				secondaryWeapon: secondary,
				pistolWeapon: pistol,
				primaryStats: statsPrimary,
				secondaryStats: statsSecondary,
				pistolStats: statsPistol,
				maskGear: mask,
				backpackGear: backpack,
				vestGear: vest,
				glovesGear: gloves,
				holsterGear: holster,
				kneepadsGear: kneepads,
				maskStats: statsMask,
				backpackStats: statsBackpack,
				vestStats: statsVest,
				glovesStats: statsGloves,
				holsterStats: statsHolster,
				kneepadsStats: statsKneepads,
				watchStats: statsWatch,
				specializationStats: statsSpecialization,
				brandsetBonuses: bonusesBrandset,
				gearsetBonuses: bonusesGearset,
				updateWeapon: updateWeaponHandler,
				updateGear: updateGearHandler,
				updateWeaponStat: updateWeaponStatHandler,
				updateGearStat: updateGearStatHandler,
				updateSpecStat: updateSpecStatHandler,
				updateWatchStat: updateWatchStatHandler,
				resetStats: resetStatsHandler,
				loadBuild: loadBuildHandler,
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
};

export default AppContext;
