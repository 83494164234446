import GearMod from "../models/gearMod";

const GearModsData: GearMod[] = [
	{
		_id: {
			$oid: "62487c33d03ce64590e28734",
		},
		quality: "A",
		type: "",
		stat: "Critical Hit Chance",
		max: 6,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e28735",
		},
		quality: "A",
		type: "",
		stat: "Critical Hit Damage",
		max: 12,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e28736",
		},
		quality: "A",
		type: "",
		stat: "Headshot Damage",
		max: 10,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e28737",
		},
		quality: "A",
		type: "",
		stat: "Armor on Kill",
		max: 18935,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e28738",
		},
		quality: "A",
		type: "",
		stat: "Incoming Repairs",
		max: 20,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e28739",
		},
		quality: "A",
		type: "",
		stat: "Protection from Elites",
		max: 13,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e2873a",
		},
		quality: "A",
		type: "",
		stat: "Bleed Resistance",
		max: 10,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e2873b",
		},
		quality: "A",
		type: "",
		stat: "Blind/Deaf Resistance",
		max: 10,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e2873c",
		},
		quality: "A",
		type: "",
		stat: "Burn Resistance",
		max: 10,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e2873d",
		},
		quality: "A",
		type: "",
		stat: "Disrupt Resistance",
		max: 10,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e2873e",
		},
		quality: "A",
		type: "",
		stat: "Shock Resistance",
		max: 10,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e2873f",
		},
		quality: "A",
		type: "",
		stat: "Repair Skills",
		max: 20,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e28740",
		},
		quality: "A",
		type: "",
		stat: "Skill Haste",
		max: 12,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e28741",
		},
		quality: "A",
		type: "",
		stat: "Skill Duration",
		max: 10,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e28742",
		},
		quality: "A",
		type: "",
		stat: "Disorient Resistance",
		max: 10,
	},
	{
		_id: {
			$oid: "62487c33d03ce64590e28743",
		},
		quality: "A",
		type: "",
		stat: "Ensnare Resistance",
		max: 10,
	},
];

export default GearModsData;
