import {
	IonAccordion,
	IonAccordionGroup,
	IonBadge,
	IonItem,
	IonItemDivider,
	IonLabel,
} from "@ionic/react";
import gearSlots from "../../models/gearSlots";
import StatsGear from "./StatsGear";
import StatsWatch from "./StatsWatch";
import StatsTalents from "./StatsTalents";
import StatsGearsets from "./StatsGearsets";
import StatsWeapon from "./StatsWeapon";
import { Fragment, useEffect, useState } from "react";
import StatsExpertise from "./StatsExpertise";
import StatsMods from "./StatsMods";
import { caretDownCircle } from "ionicons/icons";
import useCalculator from "../../hooks/useCalculator";
import Weapon from "../../models/weapon";
import WeaponStats from "../../models/weaponStats";

interface StatsBreakdownProps {
	weaponSlot: string;
	statSection: string;
	weapon: Weapon;
	weaponStats: WeaponStats;
}

const defaultHideHeaders = false;

const StatsBreakdown = ({
	weaponSlot,
	statSection,
	weapon,
	weaponStats,
}: StatsBreakdownProps) => {
	// const ctxApp = useContext(AppContext);
	const [hideExpertiseHeader, setHideExpertiseHeader] =
		useState(defaultHideHeaders);
	const [hideWeaponHeader, setHideWeaponHeader] =
		useState(defaultHideHeaders);
	const [hideGearHeader, setHideGearHeader] = useState(defaultHideHeaders);
	const [hideModsHeader, setHideModsHeader] = useState(defaultHideHeaders);
	const [hideTalentsHeader, setHideTalentsHeader] =
		useState(defaultHideHeaders);
	const [hideGearsetsHeader, setHideGearsetsHeader] =
		useState(defaultHideHeaders);
	const [hideWatchHeader, setHideWatchHeader] = useState(defaultHideHeaders);
	const [sectionTotalPercent, setSectionTotalPercent] = useState(0);
	const [sectionTotalAmount, setSectionTotalAmount] = useState("0");
	const [hideSectionTotalAmount, setHideSectionTotalAmount] = useState(true);

	const calcs = useCalculator({
		slot: weaponSlot,
		weapon: weapon,
		weaponStats: weaponStats,
	});

	// const [wdPercent, setWDPercent] = useState(0);
	// const [chcPercent, setCHCPercent] = useState(0);
	// const [chdPercent, setCHDPercent] = useState(0);
	// const [hsdPercent, setHSDPercent] = useState(0);
	// const [twdPercent, setTWDPercent] = useState(0);
	// const [dtaPercent, setDTAPercent] = useState(0);
	// const [dttoocPercent, setDTTOOCPercent] = useState(0);

	function showHeaderHandler(category: string, hidden: boolean) {
		hidden = false;
		switch (category) {
			case "Expertise":
				setHideExpertiseHeader(hidden);
				break;
			case "Weapon":
				setHideWeaponHeader(hidden);
				break;
			case "Gear":
				setHideGearHeader(hidden);
				break;
			case "Talents":
				setHideTalentsHeader(hidden);
				break;
			case "Gearsets":
				setHideGearsetsHeader(hidden);
				break;
			case "Watch":
				setHideWatchHeader(hidden);
				break;
		}
	}

	useEffect(() => {
		switch (statSection.toLowerCase()) {
			case "weapon damage":
				setSectionTotalPercent(calcs.wdPercent);
				setHideSectionTotalAmount(false);
				setSectionTotalAmount(
					Math.round(
						weapon.baseDamage * (1 + calcs.wdPercent / 100)
					).toLocaleString("en-US")
				);
				break;
			case "critical hit chance":
				setSectionTotalPercent(calcs.chcPercent);
				break;
			case "critical hit damage":
				setSectionTotalPercent(calcs.chdPercent);
				break;
			case "headshot damage":
				setSectionTotalPercent(calcs.hsdPercent);
				break;
		}
	}, [
		calcs.chcPercent,
		calcs.chdPercent,
		calcs.hsdPercent,
		calcs.wdPercent,
		statSection,
		weapon.baseDamage,
	]);

	return (
		<>
			<IonAccordionGroup>
				<IonAccordion
					key="TEST"
					value={statSection}
					toggleIcon={caretDownCircle}
					toggleIconSlot="start"
				>
					<IonItem slot="header" color="medium">
						<IonBadge slot="end">{sectionTotalPercent}%</IonBadge>
						<IonLabel>{statSection}</IonLabel>
						{!hideSectionTotalAmount && (
							<IonBadge slot="end">{sectionTotalAmount}</IonBadge>
						)}
					</IonItem>
					<div className="ion-padding" slot="content">
						<Fragment>
							{statSection === "Weapon Damage" &&
								hideExpertiseHeader === false && (
									<Fragment>
										<IonItemDivider key="Expertise">
											<IonLabel>Expertise</IonLabel>
										</IonItemDivider>
										<StatsExpertise
											weaponSlot={weaponSlot}
											showHeader={showHeaderHandler}
										/>
									</Fragment>
								)}
							{hideWeaponHeader === false && (
								<Fragment>
									<IonItemDivider key="Weapon">
										<IonLabel>Weapon</IonLabel>
									</IonItemDivider>
									<StatsWeapon
										weaponSlot={weaponSlot}
										attribute={statSection}
										showHeader={showHeaderHandler}
									/>
								</Fragment>
							)}
							{hideGearHeader === false && (
								<Fragment>
									<IonItemDivider key="Gear">
										<IonLabel>Gear</IonLabel>
									</IonItemDivider>
									{gearSlots.map((slot) => {
										return (
											<Fragment key={slot.key + "Gear"}>
												<StatsGear
													weaponSlot={weaponSlot}
													gearSlot={slot.key}
													attribute={statSection}
												/>
											</Fragment>
										);
									})}
								</Fragment>
							)}
							{hideModsHeader === false && (
								<Fragment>
									<IonItemDivider key="Mods">
										<IonLabel>Mods</IonLabel>
									</IonItemDivider>
									{gearSlots.map((slot) => {
										return (
											<Fragment key={slot.key + "Mods"}>
												<StatsMods
													weaponSlot={weaponSlot}
													gearSlot={slot.key}
													attribute={statSection}
												/>
											</Fragment>
										);
									})}
								</Fragment>
							)}
							{hideTalentsHeader === false && (
								<Fragment>
									{" "}
									<IonItemDivider key="Talents">
										<IonLabel>Talents</IonLabel>
									</IonItemDivider>
									<StatsTalents
										weaponSlot={weaponSlot}
										attribute={statSection}
									/>
								</Fragment>
							)}
							{hideGearsetsHeader === false && (
								<Fragment>
									<IonItemDivider key="Gearsets">
										<IonLabel>Gearsets</IonLabel>
									</IonItemDivider>
									{gearSlots.map((slot) => {
										return (
											<Fragment
												key={slot.key + "Gearset"}
											>
												<StatsGearsets
													key={slot.key + "Gearset"}
													weaponSlot={weaponSlot}
													gearSlot={slot.key}
													attribute={statSection}
												/>
											</Fragment>
										);
									})}
								</Fragment>
							)}
							{hideWatchHeader === false && (
								<Fragment>
									{" "}
									<IonItemDivider key="Watch">
										<IonLabel>Watch</IonLabel>
									</IonItemDivider>
									<StatsWatch
										weaponSlot={weaponSlot}
										attribute={statSection}
									/>
								</Fragment>
							)}
						</Fragment>
					</div>
				</IonAccordion>
			</IonAccordionGroup>
		</>
	);
};

export default StatsBreakdown;
