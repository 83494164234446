import {
	IonInput,
	IonItem,
	IonList,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import GearAttributesData from "../../data/GearAttributesData";
import Gear from "../../models/gear";
import GearAttribute from "../../models/gearAttribute";
import GearStats from "../../models/gearStats";

let initGearAttributes: GearAttribute[] = GearAttributesData;
initGearAttributes = initGearAttributes.sort(function (a, b) {
	const nameA = a.name.toUpperCase(); // ignore upper and lowercase
	const nameB = b.name.toUpperCase(); // ignore upper and lowercase
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
});

interface GearAttributePrimaryProps {
	slot: string;
}

interface SelectChangeEventDetail<T = any> {
	value: T;
}

const GearAttributePrimary = ({ slot }: GearAttributePrimaryProps) => {
	const ctxApp = useContext(AppContext);
	const [currentAttributes, setCurrentAttributes] =
		useState(initGearAttributes);
	const [selectedAttribute1Name, setSelectedAttribute1Name] =
		useState("Select Attribute");
	const [selectedAttribute1Value, setSelectedAttribute1Value] = useState<
		number | undefined
	>(undefined);
	const [selectedAttribute1Max, setSelectedAttribute1Max] = useState(0);
	const [selectDisabled, setSelectDisabled] = useState(false);
	const [inputDisabled, setInputDisabled] = useState(false);

	const [selectClassName, setSelectClassName] = useState("gearSelect");
	const [inputClassName, setInputClassName] = useState("gearInput");

	const attribute1NameChangeHandler = (
		e: CustomEvent<SelectChangeEventDetail>
	) => {
		// console.log(e.detail.value);
		setSelectedAttribute1Name(e.detail.value);
		// reset input value only if no stats available
		// switch (slot.toLowerCase()) {
		// 	case "mask":
		// 		setSelectedAttribute1Value(ctxApp.maskStats.attribute1Value);
		// 		break;
		// 	case "backpack":
		// 		setSelectedAttribute1Value(
		// 			ctxApp.backpackStats.attribute1Value
		// 		);
		// 		break;
		// 	case "vest":
		// 		setSelectedAttribute1Value(ctxApp.vestStats.attribute1Value);
		// 		break;
		// 	case "gloves":
		// 		setSelectedAttribute1Value(ctxApp.glovesStats.attribute1Value);
		// 		break;
		// 	case "holster":
		// 		setSelectedAttribute1Value(ctxApp.holsterStats.attribute1Value);
		// 		break;
		// 	case "kneepads":
		// 		setSelectedAttribute1Value(
		// 			ctxApp.kneepadsStats.attribute1Value
		// 		);
		// 		break;
		// 	default:
		// 		setSelectedAttribute1Value(0);
		// 		break;
		// }
		// TODO going to attempt to reset always when the name selector changes
		// setSelectedAttribute1Value(0);

		// get/set max attribte value
		// console.log(e.detail);
		if (e.detail.value !== "" && e.detail.value !== "Select Attribute") {
			const selectedAttribute: GearAttribute[] =
				initGearAttributes.filter((attribute) => {
					return attribute.name === e.detail.value;
				});
			setSelectedAttribute1Max(selectedAttribute[0].max);
		}
		ctxApp.updateGearStat("attribute1Name", slot, e.detail.value);
	};

	const attribute1ValueChangeHandler = (e: CustomEvent) => {
		// console.log(e.detail.value);
		setSelectedAttribute1Value(e.detail.value);
		ctxApp.updateGearStat(
			"attribute1Value",
			slot,
			e.detail.value,
			"number"
		);
	};

	const options = {
		cssClass: "my-custom-interface",
	};

	useEffect(() => {
		function check(gear: Gear, gearStats: GearStats) {
			// console.log("checking", slot);
			let updatedName = "";
			let updatedAttributes = initGearAttributes;
			if (gear.quality === "Exotic") {
				setSelectDisabled(true);
				if (gear.name === "Memento") {
					console.log("Memento");
				} else {
					const exoticAttribute = initGearAttributes.filter(
						(attribute) => {
							return attribute.name === gear.attribute1;
						}
					);
					setSelectedAttribute1Name(exoticAttribute[0].name);
					setSelectedAttribute1Value((currValue) => {
						if (gearStats.attribute1Value === null) {
							return currValue;
						} else {
							return gearStats.attribute1Value;
						}
					});
					setCurrentAttributes(exoticAttribute);
				}
			} else if (
				gear.quality === "Named" &&
				slot.toLowerCase() !== "backpack" &&
				slot.toLowerCase() !== "vest"
			) {
				// Named
				const namedAttribute = initGearAttributes.filter(
					(attribute) => {
						return (
							attribute.quality === "N" &&
							attribute.name === gear.attribute1
						);
					}
				);
				setSelectDisabled(true);
				setInputDisabled(true);
				setSelectClassName("gearSelectNamed");
				setInputClassName("gearInputNamed");
				setSelectedAttribute1Name(gear.attribute1);
				setSelectedAttribute1Value(namedAttribute[0].max);
				// setCurrentAttributes(namedAttribute);
			} else {
				updatedAttributes = updatedAttributes.filter((attribute) => {
					return (
						attribute.quality === "A" &&
						attribute.name !== gear.attribute2
					);
				});
				setSelectDisabled(false);
				setInputDisabled(false);
				updatedName =
					gearStats.attribute1Name === ""
						? "Select Attribute"
						: gearStats.attribute1Name;
				setSelectClassName("gearSelect");
				setInputClassName("gearInput");
				setSelectedAttribute1Name(updatedName);
				setSelectedAttribute1Value((currValue) => {
					if (gearStats.attribute1Value === null) {
						return currValue;
					} else {
						return gearStats.attribute1Value;
					}
				});
				setCurrentAttributes(updatedAttributes);
			}
		}

		switch (slot.toLowerCase()) {
			case "mask":
				if (ctxApp.maskGear.name) {
					check(ctxApp.maskGear, ctxApp.maskStats);
				}
				break;
			case "backpack":
				if (ctxApp.backpackGear.name) {
					check(ctxApp.backpackGear, ctxApp.backpackStats);
				}
				break;
			case "vest":
				if (ctxApp.vestGear.name) {
					check(ctxApp.vestGear, ctxApp.vestStats);
				}
				break;
			case "gloves":
				if (ctxApp.glovesGear.name) {
					check(ctxApp.glovesGear, ctxApp.glovesStats);
				}
				break;
			case "holster":
				if (ctxApp.holsterGear.name) {
					check(ctxApp.holsterGear, ctxApp.holsterStats);
				}
				break;
			case "kneepads":
				if (ctxApp.kneepadsGear.name) {
					check(ctxApp.kneepadsGear, ctxApp.kneepadsStats);
				}
				break;
		}
	}, [
		ctxApp.backpackGear,
		ctxApp.backpackStats,
		ctxApp.glovesGear,
		ctxApp.glovesStats,
		ctxApp.holsterGear,
		ctxApp.holsterStats,
		ctxApp.kneepadsGear,
		ctxApp.kneepadsStats,
		ctxApp.maskGear,
		ctxApp.maskStats,
		ctxApp.vestGear,
		ctxApp.vestStats,
		slot,
	]);

	return (
		<>
			<IonList class="ion-no-padding">
				<IonItem class="ion-no-padding">
					<IonSelect
						disabled={selectDisabled}
						interfaceOptions={options}
						className={selectClassName}
						interface="popover"
						placeholder={selectedAttribute1Name}
						value={selectedAttribute1Name}
						onIonChange={attribute1NameChangeHandler}
					>
						{currentAttributes.map(
							(attribute: GearAttribute, index) => {
								return (
									<IonSelectOption
										key={index}
										value={attribute.name}
										class="brown-option"
									>
										{attribute.name}
									</IonSelectOption>
								);
							}
						)}
					</IonSelect>
					<IonInput
						disabled={inputDisabled}
						class="ion-no-padding"
						className={inputClassName}
						type="number"
						value={selectedAttribute1Value}
						max={selectedAttribute1Max}
						min="0"
						debounce={2000}
						placeholder={selectedAttribute1Max.toString()}
						onIonChange={attribute1ValueChangeHandler}
					></IonInput>
				</IonItem>
			</IonList>
		</>
	);
};

export default GearAttributePrimary;
