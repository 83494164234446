const WeaponAttributesData = [
	{
		_id: {
			$oid: "62487770d03ce64590e2864e",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Damage to Armor",
		stat: "Damage to Armor",
		statFactor: "number",
		max: "6",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e28650",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Rate of Fire %",
		stat: "Rate of Fire %",
		statFactor: "percent",
		max: "5",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e28652",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Accuracy",
		stat: "Accuracy",
		statFactor: "number",
		max: "12",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e28654",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Magazine Size %",
		stat: "Magazine Size %",
		statFactor: "percent",
		max: "12.5",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e28655",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Optimal Range",
		stat: "Optimal Range",
		statFactor: "number",
		max: "24",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e28657",
		},
		quality: "N",
		type: "O",
		weapon: "",
		name: "Armor on Kill %",
		stat: "Armor on Kill %",
		statFactor: "percent",
		max: "10",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e28656",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Swap Speed",
		stat: "Swap Speed",
		statFactor: "number",
		max: "15",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e28658",
		},
		quality: "N",
		type: "O",
		weapon: "",
		name: "Skill Tier",
		stat: "Skill Tier",
		statFactor: "number",
		max: "1",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e2864b",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Critical Hit Damage",
		stat: "Critical Hit Damage",
		statFactor: "number",
		max: "10",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e2864c",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Headshot Damage",
		stat: "Headshot Damage",
		statFactor: "number",
		max: "10",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e28651",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Stability",
		stat: "Stability",
		statFactor: "number",
		max: "12",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e28653",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Reload Speed %",
		stat: "Reload Speed %",
		statFactor: "percent",
		max: "12",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e2864a",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Critical Hit Chance",
		stat: "Critical Hit Chance",
		statFactor: "number",
		max: "9.5",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e2864d",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Damage to TOC",
		stat: "Damage to TOC",
		statFactor: "number",
		max: "10",
	},
	{
		_id: {
			$oid: "62487770d03ce64590e2864f",
		},
		quality: "A",
		type: "O",
		weapon: "",
		name: "Health Damage",
		stat: "Health Damage",
		statFactor: "number",
		max: "9.5",
	},
];

export default WeaponAttributesData;
