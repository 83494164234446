import Brand from "../models/brand";

const BrandsData: Brand[] = [
	{
		_id: {
			oid: "623502a6e36fe9fac95c87c5",
		},
		name: "Overlord Armaments",
		iconURL: "https://puu.sh/D4eDx.png",
		text: "Overlord Armaments",
		value: "Overlord Armaments",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87cf",
		},
		name: "Aces & Eights",
		iconURL: "https://i.imgur.com/PtNkqMZ.png",
		text: "Aces & Eights",
		value: "Aces & Eights",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87c0",
		},
		name: "Gila Guard",
		iconURL: "https://puu.sh/D4eDB.png",
		text: "Gila Guard",
		value: "Gila Guard",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87c9",
		},
		name: "Sokolov Concern",
		iconURL: "https://puu.sh/D4eDG.png",
		text: "Sokolov Concern",
		value: "Sokolov Concern",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87ce",
		},
		name: "True Patriot",
		iconURL: "https://i.imgur.com/hAVQM8F.png",
		text: "True Patriot",
		value: "True Patriot",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87d6",
		},
		name: "Walker, Harris & Co.",
		iconURL: "https://i.imgur.com/cOtB0Uf.png",
		text: "Walker, Harris & Co.",
		value: "Walker, Harris & Co.",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87da",
		},
		name: "Rigger",
		iconURL: "https://i.imgur.com/1WCdHXM.png",
		text: "Rigger",
		value: "Rigger",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87db",
		},
		name: "Belstone Armory",
		iconURL: "https://i.imgur.com/iCDOWbj.png",
		text: "Belstone Armory",
		value: "Belstone Armory",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87cd",
		},
		name: "Ongoing Directive",
		iconURL: "https://i.imgur.com/r7wihru.png",
		text: "Ongoing Directive",
		value: "Ongoing Directive",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87cb",
		},
		name: "Yaahl Gear",
		iconURL: "https://puu.sh/Eivj5.png",
		text: "Yaahl Gear",
		value: "Yaahl Gear",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87d0",
		},
		name: "Negotiator's Dilemma",
		iconURL: "https://i.imgur.com/0zrR7UI.png",
		text: "Negotiator's Dilemma",
		value: "Negotiator's Dilemma",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87ba",
		},
		name: "Alps Summit Armaments",
		iconURL: "https://puu.sh/D4eDq.png",
		text: "Alps Summit Armaments",
		value: "Alps Summit Armaments",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87bb",
		},
		name: "Badger Tuff",
		iconURL: "https://puu.sh/D4eDs.png",
		text: "Badger Tuff",
		value: "Badger Tuff",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87c2",
		},
		name: "Grupo Sombra S.A.",
		iconURL: "https://i.imgur.com/m5j0yqs.png",
		text: "Grupo Sombra S.A.",
		value: "Grupo Sombra S.A.",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87c4",
		},
		name: "Murakami Industries",
		iconURL: "https://puu.sh/Eivj2.png",
		text: "Murakami Industries",
		value: "Murakami Industries",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87dd",
		},
		name: "Crafted",
		iconURL: "https://i.imgur.com/kzqpQH8.png",
		text: "Crafted",
		value: "Crafted",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87bc",
		},
		name: "Ceska Vyroba s.r.o.",
		iconURL: "https://i.imgur.com/oRFe6pE.png",
		text: "Ceska Vyroba s.r.o.",
		value: "Ceska Vyroba s.r.o.",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87c6",
		},
		name: "Petrov Defense Group",
		iconURL: "https://puu.sh/D4eDD.png",
		text: "Petrov Defense Group",
		value: "Petrov Defense Group",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87c8",
		},
		name: "Richter & Kaiser GmbH",
		iconURL: "https://puu.sh/D4eDF.png",
		text: "Richter & Kaiser GmbH",
		value: "Richter & Kaiser GmbH",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87cc",
		},
		name: "Hard Wired",
		iconURL: "https://i.imgur.com/De6m9NO.png",
		text: "Hard Wired",
		value: "Hard Wired",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87d7",
		},
		name: "Eclipse Protocol",
		iconURL: "https://i.imgur.com/fbcV3JY.png",
		text: "Eclipse Protocol",
		value: "Eclipse Protocol",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87b9",
		},
		name: "Airaldi Holdings",
		iconURL: "https://puu.sh/D4eDt.png",
		text: "Airaldi Holdings",
		value: "Airaldi Holdings",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87c3",
		},
		name: "Hana-U Corporation",
		iconURL: "https://i.imgur.com/eUhsXYn.png",
		text: "Hana-U Corporation",
		value: "Hana-U Corporation",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87c7",
		},
		name: "Providence Defense",
		iconURL: "https://puu.sh/D4eDE.png",
		text: "Providence Defense",
		value: "Providence Defense",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87ca",
		},
		name: "Wyvern Wear",
		iconURL: "https://puu.sh/D4eDH.png",
		text: "Wyvern Wear",
		value: "Wyvern Wear",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87d1",
		},
		name: "Tip of the Spear",
		iconURL: "https://i.imgur.com/faLj58L.png",
		text: "Tip of the Spear",
		value: "Tip of the Spear",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87b8",
		},
		name: "5.11 Tactical",
		iconURL: "https://puu.sh/Eivbc.png",
		text: "5.11 Tactical",
		value: "5.11 Tactical",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87be",
		},
		name: "Douglas & Harding",
		iconURL: "https://puu.sh/D4eDw.png",
		text: "Douglas & Harding",
		value: "Douglas & Harding",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87c1",
		},
		name: "Golan Gear Ltd",
		iconURL: "https://i.imgur.com/oPCQxDO.png",
		text: "Golan Gear Ltd",
		value: "Golan Gear Ltd",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87dc",
		},
		name: "Exotic",
		iconURL: "https://i.imgur.com/Ig7DWh7.png",
		text: "Exotic",
		value: "Exotic",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87bd",
		},
		name: "China Light Industries Corporation",
		iconURL: "https://i.imgur.com/Lp0klc0.png",
		text: "China Light Industries Corporation",
		value: "China Light Industries Corporation",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87bf",
		},
		name: "Fenris Group AB",
		iconURL: "https://puu.sh/D4eDy.png",
		text: "Fenris Group AB",
		value: "Fenris Group AB",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87d8",
		},
		name: "Hunter's Fury",
		iconURL: "https://i.imgur.com/avuG3TM.png",
		text: "Hunter's Fury",
		value: "Hunter's Fury",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87d2",
		},
		name: "Foundry Bulwark",
		iconURL: "https://i.imgur.com/HPx0FU7.png",
		text: "Foundry Bulwark",
		value: "Foundry Bulwark",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87d3",
		},
		name: "Future Initiative",
		iconURL: "https://i.imgur.com/qyQzKCU.png",
		text: "Future Initiative",
		value: "Future Initiative",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87d4",
		},
		name: "Striker's Battlegear",
		iconURL: "https://i.imgur.com/jlIXDlg.png",
		text: "Striker's Battlegear",
		value: "Striker's Battlegear",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87d5",
		},
		name: "System Corruption",
		iconURL: "https://i.imgur.com/8OehPqT.png",
		text: "System Corruption",
		value: "System Corruption",
	},
	{
		_id: {
			oid: "623502a6e36fe9fac95c87d9",
		},
		name: "Empress International",
		iconURL: "https://i.imgur.com/h1EG9y6.png",
		text: "Empress International",
		value: "Empress International",
	},
];

export default BrandsData;
