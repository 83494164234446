import Weapon from "../models/weapon";

const WeaponsData: Weapon[] = [
	{
		_id: {
			oid: "6247a47dd03ce64590e28414",
		},
		variant: "Carbine 7",
		name: "Carbine 7",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 47012,
		optimalRange: 35,
		rpm: 790,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2840a",
		},
		variant: "AUG",
		name: "The Bighorn Full-Auto Mode",
		slot: "P",
		quality: "Exotic",
		weaponType: "Assault Rifle",
		baseDamage: 51243,
		optimalRange: 27,
		rpm: 800,
		magSize: 40,
		reloadSpeedMS: 2100,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "Critical Hit Damage",
		talent: "Big Game Hunter",
		optics: "The Bighorn",
		underBarrel: "The Bighorn",
		magazine: "The Bighorn",
		muzzle: "The Bighorn",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2840d",
		},
		variant: "ACR",
		name: "ACR",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 53124,
		optimalRange: 27,
		rpm: 650,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2840e",
		},
		variant: "ACR",
		name: "ACR-E",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 53124,
		optimalRange: 27,
		rpm: 650,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28410",
		},
		variant: "AK-47",
		name: "Black Market AK-M",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 60645,
		optimalRange: 31,
		rpm: 600,
		magSize: 30,
		reloadSpeedMS: 2500,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2841c",
		},
		variant: "FAMAS",
		name: "FAMAS 2010 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 44191,
		optimalRange: 28,
		rpm: 900,
		magSize: 30,
		reloadSpeedMS: 2200,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2841e",
		},
		variant: "G36",
		name: "G36 Enhanced",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 48892,
		optimalRange: 32,
		rpm: 750,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2841f",
		},
		variant: "G36",
		name: "G36 Enhanced Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 48892,
		optimalRange: 32,
		rpm: 750,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28424",
		},
		variant: "P416",
		name: "Military P416",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 47952,
		optimalRange: 26,
		rpm: 750,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28428",
		},
		variant: "SCAR",
		name: "Tactical Mk 16",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 55004,
		optimalRange: 35,
		rpm: 625,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28429",
		},
		variant: "SCAR",
		name: "Tactical Mk 16 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 55004,
		optimalRange: 35,
		rpm: 625,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2842a",
		},
		variant: "TAR-21",
		name: "CTAR-21",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 41371,
		optimalRange: 35,
		rpm: 900,
		magSize: 30,
		reloadSpeedMS: 2100,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2842b",
		},
		variant: "AK-47",
		name: "Manic",
		slot: "P",
		quality: "Named",
		weaponType: "Assault Rifle",
		baseDamage: 53593,
		optimalRange: 31,
		rpm: 600,
		magSize: 30,
		reloadSpeedMS: 2500,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfect Overflowing",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2842e",
		},
		variant: "FAMAS",
		name: "Burnout",
		slot: "P",
		quality: "Named",
		weaponType: "Assault Rifle",
		baseDamage: 44191,
		optimalRange: 28,
		rpm: 900,
		magSize: 30,
		reloadSpeedMS: 2200,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfectly On Empty",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2842f",
		},
		variant: "M4",
		name: "Pyromaniac",
		slot: "P",
		quality: "Named",
		weaponType: "Assault Rifle",
		baseDamage: 44191,
		optimalRange: 35,
		rpm: 850,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfectly Ignited",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28437",
		},
		variant: "L86",
		name: "Military L86 LSW",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 59705,
		optimalRange: 32,
		rpm: 610,
		magSize: 30,
		reloadSpeedMS: 3000,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28415",
		},
		variant: "F2000",
		name: "F2000",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 39490,
		optimalRange: 27,
		rpm: 850,
		magSize: 30,
		reloadSpeedMS: 2100,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28438",
		},
		variant: "M249",
		name: "M249 B",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 56414,
		optimalRange: 35,
		rpm: 550,
		magSize: 100,
		reloadSpeedMS: 5600,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28422",
		},
		variant: "M4",
		name: "Police M4 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 44191,
		optimalRange: 35,
		rpm: 850,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28439",
		},
		variant: "M249",
		name: "Military MK46",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 56414,
		optimalRange: 35,
		rpm: 550,
		magSize: 100,
		reloadSpeedMS: 5600,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28423",
		},
		variant: "P416",
		name: "Custom P416 G3",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 47952,
		optimalRange: 26,
		rpm: 750,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28442",
		},
		variant: "MG5",
		name: "Infantry MG5 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 43251,
		optimalRange: 30,
		rpm: 800,
		magSize: 50,
		reloadSpeedMS: 5600,
		hsd: 85,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "Ammunition Belt",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28425",
		},
		variant: "Honey Badger",
		name: "Honey Badger",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 47952,
		optimalRange: 35,
		rpm: 750,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28445",
		},
		variant: "RPK-74",
		name: "Black Market RPK-74 E Replica",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 55474,
		optimalRange: 28,
		rpm: 650,
		magSize: 45,
		reloadSpeedMS: 3000,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28430",
		},
		variant: "P416",
		name: "Glory Daze",
		slot: "P",
		quality: "Named",
		weaponType: "Assault Rifle",
		baseDamage: 47952,
		optimalRange: 26,
		rpm: 750,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfect Near Sighted",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28448",
		},
		variant: "NEGEV",
		name: "IWI NEGEV",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 37610,
		optimalRange: 40,
		rpm: 850,
		magSize: 100,
		reloadSpeedMS: 5800,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28431",
		},
		variant: "Honey Badger",
		name: "Savage Wolverine",
		slot: "P",
		quality: "Named",
		weaponType: "Assault Rifle",
		baseDamage: 47952,
		optimalRange: 35,
		rpm: 750,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfectly Close & Personal",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28455",
		},
		variant: "M44",
		name: "Custom M44",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 376096,
		optimalRange: 60,
		rpm: 55,
		magSize: 5,
		reloadSpeedMS: 3200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28433",
		},
		variant: "NEGEV",
		name: "Bullet King",
		slot: "P",
		quality: "Exotic",
		weaponType: "LMG",
		baseDamage: 37610,
		optimalRange: 40,
		rpm: 850,
		magSize: 100,
		reloadSpeedMS: 0,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "Critical Hit Damage",
		talent: "Bullet Hell",
		optics: "Bullet King",
		underBarrel: "Bullet King",
		magazine: "Bullet King",
		muzzle: "Bullet King",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2845a",
		},
		variant: "Model 700",
		name: "M700 Tactical",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 376096,
		optimalRange: 42,
		rpm: 54,
		magSize: 7,
		reloadSpeedMS: 3300,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28436",
		},
		variant: "L86",
		name: "Custom L86 A2 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 59705,
		optimalRange: 32,
		rpm: 610,
		magSize: 30,
		reloadSpeedMS: 3000,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2845d",
		},
		variant: "SCAR",
		name: "SOCOM Mk20 SSR",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 143857,
		optimalRange: 60,
		rpm: 275,
		magSize: 20,
		reloadSpeedMS: 2200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2843f",
		},
		variant: "M60",
		name: "Classic M60 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 63466,
		optimalRange: 40,
		rpm: 500,
		magSize: 100,
		reloadSpeedMS: 5800,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28460",
		},
		variant: "SR-1",
		name: "SR-1",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 357291,
		optimalRange: 60,
		rpm: 60,
		magSize: 5,
		reloadSpeedMS: 3200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28443",
		},
		variant: "MG5",
		name: "MG5",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 44661,
		optimalRange: 30,
		rpm: 800,
		magSize: 50,
		reloadSpeedMS: 5600,
		hsd: 85,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "Ammunition Belt",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28466",
		},
		variant: "SVD",
		name: "Paratrooper SVD",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 159841,
		optimalRange: 34,
		rpm: 260,
		magSize: 10,
		reloadSpeedMS: 2600,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28447",
		},
		variant: "RPK-74",
		name: "Military RPK-74 M",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 55474,
		optimalRange: 28,
		rpm: 650,
		magSize: 45,
		reloadSpeedMS: 3000,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28467",
		},
		variant: "SVD",
		name: "Surplus SVD",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 159841,
		optimalRange: 34,
		rpm: 260,
		magSize: 10,
		reloadSpeedMS: 2600,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2844a",
		},
		variant: "L86",
		name: "Tabula Rasa",
		slot: "P",
		quality: "Named",
		weaponType: "LMG",
		baseDamage: 59705,
		optimalRange: 32,
		rpm: 610,
		magSize: 30,
		reloadSpeedMS: 3000,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfectly Steady Handed",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2846b",
		},
		variant: "SR-1",
		name: "Designated Hitter",
		slot: "P",
		quality: "Named",
		weaponType: "Marksman Rifle",
		baseDamage: 357291,
		optimalRange: 60,
		rpm: 60,
		magSize: 5,
		reloadSpeedMS: 3200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "Perfect Reformation",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28450",
		},
		variant: "NEGEV",
		name: "Carnage",
		slot: "P",
		quality: "Named",
		weaponType: "LMG",
		baseDamage: 37610,
		optimalRange: 40,
		rpm: 850,
		magSize: 100,
		reloadSpeedMS: 5800,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfect Sadist",
		optics: "Short",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28471",
		},
		variant: "586 Magnum",
		name: "Police 686 Magnum",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 190399,
		optimalRange: 18,
		rpm: 160,
		magSize: 6,
		reloadSpeedMS: 1800,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "Revolver Drum",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28459",
		},
		variant: "Model 700",
		name: "M700 Carbon Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 376096,
		optimalRange: 42,
		rpm: 54,
		magSize: 7,
		reloadSpeedMS: 3300,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28472",
		},
		variant: "586 Magnum",
		name: "Police 686 Magnum Replica",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 190399,
		optimalRange: 18,
		rpm: 160,
		magSize: 6,
		reloadSpeedMS: 1800,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "Revolver Drum",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2845b",
		},
		variant: "Model 700",
		name: "Model 700",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 399602,
		optimalRange: 42,
		rpm: 54,
		magSize: 7,
		reloadSpeedMS: 7200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "",
		magazine: "Integrated",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28473",
		},
		variant: "93R",
		name: "93R",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 32908,
		optimalRange: 16,
		rpm: 1100,
		magSize: 20,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "Pistol",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28461",
		},
		variant: "SR-1",
		name: "SR-1 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 357291,
		optimalRange: 60,
		rpm: 60,
		magSize: 5,
		reloadSpeedMS: 3200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28474",
		},
		variant: "Desert Eagle",
		name: "D50",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 220956,
		optimalRange: 16,
		rpm: 150,
		magSize: 8,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28465",
		},
		variant: "SRS",
		name: "SRS A1 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 338957,
		optimalRange: 42,
		rpm: 60,
		magSize: 7,
		reloadSpeedMS: 3000,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2847a",
		},
		variant: "M9",
		name: "Military M9 Replica",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 81801,
		optimalRange: 16,
		rpm: 350,
		magSize: 15,
		reloadSpeedMS: 1700,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "Pistol",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28468",
		},
		variant: "M44",
		name: "The White Death",
		slot: "P",
		quality: "Named",
		weaponType: "Marksman Rifle",
		baseDamage: 423108,
		optimalRange: 60,
		rpm: 55,
		magSize: 5,
		reloadSpeedMS: 4200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "137",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "Integrated",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2847c",
		},
		variant: "PF45",
		name: "Custom PF45",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 82741,
		optimalRange: 14,
		rpm: 370,
		magSize: 12,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "Micro",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28469",
		},
		variant: "Model 700",
		name: "Ekim's Long Stick",
		slot: "P",
		quality: "Named",
		weaponType: "Marksman Rifle",
		baseDamage: 399602,
		optimalRange: 42,
		rpm: 54,
		magSize: 7,
		reloadSpeedMS: 7200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "Perfect Ranger",
		optics: "Short / Long",
		underBarrel: "",
		magazine: "Integrated",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2847d",
		},
		variant: "PF45",
		name: "First Wave PF45",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 81741,
		optimalRange: 14,
		rpm: 370,
		magSize: 15,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2846a",
		},
		variant: "SCAR",
		name: "The Darkness",
		slot: "P",
		quality: "Named",
		weaponType: "Marksman Rifle",
		baseDamage: 143857,
		optimalRange: 60,
		rpm: 275,
		magSize: 20,
		reloadSpeedMS: 2200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "Perfect Eyeless",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2847e",
		},
		variant: "Px4",
		name: "Px4 Storm Type F",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 79920,
		optimalRange: 16,
		rpm: 380,
		magSize: 17,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2846e",
		},
		variant: "Desert Eagle",
		name: "Liberty",
		slot: "S",
		quality: "Exotic",
		weaponType: "Pistol",
		baseDamage: 235060,
		optimalRange: 16,
		rpm: 150,
		magSize: 8,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "Damage to TOC",
		talent: "Liberty or Death",
		optics: "Liberty",
		underBarrel: "Liberty",
		magazine: "Liberty",
		muzzle: "Liberty",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28494",
		},
		variant: "M1A",
		name: "M1A CQB",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 156080,
		optimalRange: 60,
		rpm: 320,
		magSize: 10,
		reloadSpeedMS: 3000,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2846f",
		},
		variant: "m586",
		name: "Regulus",
		slot: "S",
		quality: "Exotic",
		weaponType: "Pistol",
		baseDamage: 282072,
		optimalRange: 18,
		rpm: 160,
		magSize: 6,
		reloadSpeedMS: 1800,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "0",
		attribute1: "Headshot Damage",
		talent: "Regicide",
		optics: "Regulus",
		underBarrel: "Regulus",
		magazine: "Regulus",
		muzzle: "Regulus",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28498",
		},
		variant: "M4",
		name: "LVOA-C",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 100136,
		optimalRange: 50,
		rpm: 360,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28476",
		},
		variant: "M1911",
		name: "M45A1",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 98725,
		optimalRange: 12,
		rpm: 310,
		magSize: 7,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "Micro",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284a1",
		},
		variant: "SIG 716",
		name: "SIG 716 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 97785,
		optimalRange: 60,
		rpm: 320,
		magSize: 20,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28478",
		},
		variant: "M1911",
		name: "Tactical M1911 Replica",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 98725,
		optimalRange: 12,
		rpm: 310,
		magSize: 7,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "Micro",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284a6",
		},
		variant: "M4",
		name: "Surge",
		slot: "P",
		quality: "Named",
		weaponType: "Rifle",
		baseDamage: 100136,
		optimalRange: 50,
		rpm: 360,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "Perfect Spike",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28479",
		},
		variant: "M9",
		name: "Military M9",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 81801,
		optimalRange: 16,
		rpm: 350,
		magSize: 15,
		reloadSpeedMS: 1700,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "Pistol",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284aa",
		},
		variant: "SPAS-12",
		name: "Sweet Dreams",
		slot: "P",
		quality: "Exotic",
		weaponType: "Shotgun",
		baseDamage: 582944,
		optimalRange: 10,
		rpm: 70,
		magSize: 8,
		reloadSpeedMS: 5450,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "Damage to TOC",
		talent: "Sandman",
		optics: "Sweet Dreams",
		underBarrel: "Sweet Dreams",
		magazine: "Sweet Dreams",
		muzzle: "Sweet Dreams",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ab",
		},
		variant: "AA-12",
		name: "ACS-12",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 169243,
		optimalRange: 14,
		rpm: 300,
		magSize: 20,
		reloadSpeedMS: 2300,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2847b",
		},
		variant: "M9",
		name: "Officer's M9 A1",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 81801,
		optimalRange: 16,
		rpm: 350,
		magSize: 20,
		reloadSpeedMS: 1700,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "Micro",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2847f",
		},
		variant: "Px4",
		name: "Px4 Storm Type T",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 79920,
		optimalRange: 16,
		rpm: 380,
		magSize: 17,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284b1",
		},
		variant: "M870",
		name: "Custom M870 MCS Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 695775,
		optimalRange: 14,
		rpm: 75,
		magSize: 5,
		reloadSpeedMS: 3700,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28480",
		},
		variant: "Px4",
		name: "Px4 Storm Type T Replica",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 79920,
		optimalRange: 16,
		rpm: 380,
		magSize: 17,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284b6",
		},
		variant: "SASG-12",
		name: "Black Market SASG-12 S",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 364812,
		optimalRange: 14,
		rpm: 180,
		magSize: 7,
		reloadSpeedMS: 3000,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28481",
		},
		variant: "Rhino",
		name: "Diceros",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 169243,
		optimalRange: 18,
		rpm: 180,
		magSize: 6,
		reloadSpeedMS: 1800,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "Gadget",
		magazine: "Revolver Drum",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284b7",
		},
		variant: "SASG-12",
		name: "Black Market SASG-12 S Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 364812,
		optimalRange: 14,
		rpm: 180,
		magSize: 7,
		reloadSpeedMS: 3000,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284b8",
		},
		variant: "SASG-12",
		name: "SASG-12",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 364812,
		optimalRange: 14,
		rpm: 180,
		magSize: 7,
		reloadSpeedMS: 3000,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28482",
		},
		variant: "Rhino",
		name: "Diceros Replica",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 169243,
		optimalRange: 18,
		rpm: 180,
		magSize: 6,
		reloadSpeedMS: 1800,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "Gadget",
		magazine: "Revolver Drum",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28484",
		},
		variant: "X-45",
		name: "X-45",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 76630,
		optimalRange: 16,
		rpm: 400,
		magSize: 10,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284be",
		},
		variant: "Super 90",
		name: "Super 90 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 440031,
		optimalRange: 16,
		rpm: 160,
		magSize: 8,
		reloadSpeedMS: 4800,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2848a",
		},
		variant: "AK-M",
		name: "Merciless",
		slot: "P",
		quality: "Exotic",
		weaponType: "Rifle",
		baseDamage: 84622,
		optimalRange: 50,
		rpm: 400,
		magSize: 30,
		reloadSpeedMS: 2500,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "Critical Hit Chance",
		talent: "Binary Trigger",
		optics: "Merciless",
		underBarrel: "Merciless",
		magazine: "Merciless",
		muzzle: "Merciless",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284c4",
		},
		variant: "M870",
		name: "Cu??lebre",
		slot: "P",
		quality: "Named",
		weaponType: "Shotgun",
		baseDamage: 695775,
		optimalRange: 14,
		rpm: 75,
		magSize: 5,
		reloadSpeedMS: 3700,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfect Jazz Hands",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28490",
		},
		variant: "UIC15",
		name: "UIC15 MOD2",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 105777,
		optimalRange: 50,
		rpm: 240,
		magSize: 30,
		reloadSpeedMS: 1900,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284c5",
		},
		variant: "KSG",
		name: "The Send-Off",
		slot: "P",
		quality: "Named",
		weaponType: "Shotgun",
		baseDamage: 594230,
		optimalRange: 16,
		rpm: 70,
		magSize: 12,
		reloadSpeedMS: 8050,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfect Distance",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28492",
		},
		variant: "M16A2",
		name: "M16A2 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 84622,
		optimalRange: 32,
		rpm: 300,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284c9",
		},
		variant: "P90",
		name: "The Chatterbox",
		slot: "P",
		quality: "Exotic",
		weaponType: "SMG",
		baseDamage: 42781,
		optimalRange: 25,
		rpm: 700,
		magSize: 50,
		reloadSpeedMS: 1700,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "Headshot Damage",
		talent: "Incessant Chatter",
		optics: "The Chatterbox",
		underBarrel: "The Chatterbox",
		magazine: "The Chatterbox",
		muzzle: "The Chatterbox",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28495",
		},
		variant: "M1A",
		name: "M1A CQB Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 156080,
		optimalRange: 60,
		rpm: 320,
		magSize: 10,
		reloadSpeedMS: 3000,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284cd",
		},
		variant: "AUG",
		name: "Tactical AUG A3P Replica",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 57825,
		optimalRange: 18,
		rpm: 725,
		magSize: 25,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28497",
		},
		variant: "M4",
		name: "Lightweight M4",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 103897,
		optimalRange: 50,
		rpm: 360,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284d1",
		},
		variant: "MP5",
		name: "MP5-N",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 51713,
		optimalRange: 16,
		rpm: 800,
		magSize: 32,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28499",
		},
		variant: "M4",
		name: "LVOA-C Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 100136,
		optimalRange: 50,
		rpm: 360,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284d2",
		},
		variant: "MP5",
		name: "MP5A2",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 51713,
		optimalRange: 16,
		rpm: 800,
		magSize: 32,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2849f",
		},
		variant: "SIG 716",
		name: "SIG 716",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 97785,
		optimalRange: 60,
		rpm: 320,
		magSize: 20,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284d8",
		},
		variant: "PP-19",
		name: "PP-19",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 59705,
		optimalRange: 16,
		rpm: 700,
		magSize: 53,
		reloadSpeedMS: 2500,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "",
		magazine: "",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284a0",
		},
		variant: "SIG 716",
		name: "SIG 716 CQB",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 99665,
		optimalRange: 60,
		rpm: 320,
		magSize: 20,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "8.02",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284dd",
		},
		variant: "T821",
		name: "Black Market T821 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 66757,
		optimalRange: 17,
		rpm: 550,
		magSize: 32,
		reloadSpeedMS: 1900,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284a2",
		},
		variant: "UMP-45",
		name: "USC .45 ACP",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 98255,
		optimalRange: 50,
		rpm: 420,
		magSize: 20,
		reloadSpeedMS: 2300,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: ".45 ACP",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284de",
		},
		variant: "T821",
		name: "Police T821",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 66757,
		optimalRange: 17,
		rpm: 550,
		magSize: 32,
		reloadSpeedMS: 1900,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284a3",
		},
		variant: "UMP-45",
		name: "USC .45 ACP Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 98255,
		optimalRange: 50,
		rpm: 420,
		magSize: 20,
		reloadSpeedMS: 2300,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: ".45 ACP",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ed",
		},
		variant: "PP-19",
		name: "Cold Relations",
		slot: "P",
		quality: "Named",
		weaponType: "SMG",
		baseDamage: 59705,
		optimalRange: 16,
		rpm: 700,
		magSize: 53,
		reloadSpeedMS: 2500,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfect Strained",
		optics: "Short / Long",
		underBarrel: "",
		magazine: "",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284a4",
		},
		variant: "1886",
		name: "The Virginian",
		slot: "P",
		quality: "Named",
		weaponType: "Rifle",
		baseDamage: 342247,
		optimalRange: 28,
		rpm: 100,
		magSize: 5,
		reloadSpeedMS: 3400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "Perfect Boomerang",
		optics: "",
		underBarrel: "",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ee",
		},
		variant: "Thompson",
		name: "The Sleigher",
		slot: "P",
		quality: "Named",
		weaponType: "SMG",
		baseDamage: 84621,
		optimalRange: 16,
		rpm: 250,
		magSize: 50,
		reloadSpeedMS: 2300,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "Hidden Rock",
		optics: "",
		underBarrel: "",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284a5",
		},
		variant: "M1A",
		name: "Baker's Dozen",
		slot: "P",
		quality: "Named",
		weaponType: "Rifle",
		baseDamage: 205442,
		optimalRange: 60,
		rpm: 180,
		magSize: 10,
		reloadSpeedMS: 3000,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "Perfect Lucky Shot",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284f2",
		},
		variant: "Desert Eagle",
		name: "Survivalist D50",
		slot: "S",
		quality: "Named",
		weaponType: "Pistol",
		baseDamage: 220956,
		optimalRange: 16,
		rpm: 150,
		magSize: 8,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "11",
		core2: "Damage to TOC",
		core2Max: "8",
		attribute1: "",
		talent: "Perfect Sadist",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284a8",
		},
		variant: "SIG 716",
		name: "Artist's Tool",
		slot: "P",
		quality: "Named",
		weaponType: "Rifle",
		baseDamage: 99665,
		optimalRange: 60,
		rpm: 320,
		magSize: 20,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "Perfect Rifleman",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "8.02",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284f6",
		},
		variant: "SIX12",
		name: "The Mop",
		slot: "P",
		quality: "Named",
		weaponType: "Shotgun",
		baseDamage: 451314,
		optimalRange: 16,
		rpm: 130,
		magSize: 7,
		reloadSpeedMS: 2400,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "Armor on Kill %",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ae",
		},
		variant: "Double Barrel",
		name: "Double Barrel Sawed Off",
		slot: "S",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 620556,
		optimalRange: 11,
		rpm: 200,
		magSize: 2,
		reloadSpeedMS: 2200,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284f7",
		},
		variant: "SIX12",
		name: "Scorpio",
		slot: "P",
		quality: "Exotic",
		weaponType: "Shotgun",
		baseDamage: 451314,
		optimalRange: 16,
		rpm: 130,
		magSize: 7,
		reloadSpeedMS: 2400,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "Critical Hit Damage",
		talent: "Septic Shock",
		optics: "Scorpio",
		underBarrel: "Scorpio",
		magazine: "Scorpio",
		muzzle: "Scorpio",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284b0",
		},
		variant: "M870",
		name: "Custom M870 MCS",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 695775,
		optimalRange: 14,
		rpm: 75,
		magSize: 5,
		reloadSpeedMS: 3700,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284f8",
		},
		variant: "PDR",
		name: "Capacitor",
		slot: "P",
		quality: "Exotic",
		weaponType: "Assault Rifle",
		baseDamage: 51713,
		optimalRange: 28,
		rpm: 700,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "21",
		attribute1: "Health Damage",
		talent: "Capacitance",
		optics: "Capacitor",
		underBarrel: "Capacitor",
		magazine: "Capacitor",
		muzzle: "Capacitor",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284b2",
		},
		variant: "M870",
		name: "M870 Express",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 695775,
		optimalRange: 14,
		rpm: 75,
		magSize: 5,
		reloadSpeedMS: 3700,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284fd",
		},
		variant: "Tactical .308",
		name: "Tactical .308",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 324383,
		optimalRange: 42,
		rpm: 70,
		magSize: 7,
		reloadSpeedMS: 3400,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284b3",
		},
		variant: "M870",
		name: "M870 Express Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 695775,
		optimalRange: 14,
		rpm: 75,
		magSize: 5,
		reloadSpeedMS: 3700,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ff",
		},
		variant: "Sig Sauer 556",
		name: "SIG 556",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 50773,
		optimalRange: 35,
		rpm: 720,
		magSize: 30,
		reloadSpeedMS: 2170,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284b4",
		},
		variant: "M870",
		name: "Military M870",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 695775,
		optimalRange: 14,
		rpm: 75,
		magSize: 5,
		reloadSpeedMS: 3700,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28501",
		},
		variant: "MPX",
		name: "Backfire",
		slot: "P",
		quality: "Exotic",
		weaponType: "SMG",
		baseDamage: 53124,
		optimalRange: 22,
		rpm: 850,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "Magazine Size %",
		talent: "Payment in Kind",
		optics: "Backfire",
		underBarrel: "Backfire",
		magazine: "Backfire",
		muzzle: "Backfire",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284b9",
		},
		variant: "SASG-12",
		name: "Tactical SASG-12 K",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 364812,
		optimalRange: 14,
		rpm: 180,
		magSize: 7,
		reloadSpeedMS: 3000,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28502",
		},
		variant: "SRS",
		name: "Mantis",
		slot: "P",
		quality: "Exotic",
		weaponType: "Marksman Rifle",
		baseDamage: 338957,
		optimalRange: 42,
		rpm: 60,
		magSize: 7,
		reloadSpeedMS: 3400,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "Damage to TOC",
		talent: "In Plain Sight",
		optics: "Mantis",
		underBarrel: "Mantis",
		magazine: "Mantis",
		muzzle: "Mantis",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284bc",
		},
		variant: "Super 90",
		name: "Marine Super 90",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 440031,
		optimalRange: 16,
		rpm: 160,
		magSize: 8,
		reloadSpeedMS: 4800,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284bf",
		},
		variant: "Super 90",
		name: "Tactical Super 90 SBS",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 440031,
		optimalRange: 16,
		rpm: 160,
		magSize: 8,
		reloadSpeedMS: 4800,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284c1",
		},
		variant: "AA-12",
		name: "Rock n' Roll",
		slot: "P",
		quality: "Named",
		weaponType: "Shotgun",
		baseDamage: 169243,
		optimalRange: 14,
		rpm: 300,
		magSize: 20,
		reloadSpeedMS: 2300,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfectly Extra",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284c2",
		},
		variant: "Double Barrel",
		name: "Boomstick",
		slot: "P",
		quality: "Named",
		weaponType: "Shotgun",
		baseDamage: 620556,
		optimalRange: 11,
		rpm: 200,
		magSize: 2,
		reloadSpeedMS: 2200,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfect Pumped Up",
		optics: "",
		underBarrel: "",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284c3",
		},
		variant: "Double Barrel",
		name: "Backup Boomstick",
		slot: "S",
		quality: "Named",
		weaponType: "Shotgun",
		baseDamage: 620556,
		optimalRange: 11,
		rpm: 200,
		magSize: 2,
		reloadSpeedMS: 2200,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "17",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284c8",
		},
		variant: "CMGG",
		name: "Lady Death",
		slot: "P",
		quality: "Exotic",
		weaponType: "SMG",
		baseDamage: 41371,
		optimalRange: 26,
		rpm: 900,
		magSize: 32,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "Damage to Armor",
		talent: "Breathe Free",
		optics: "Lady Death",
		underBarrel: "Lady Death",
		magazine: "Lady Death",
		muzzle: "Lady Death",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ca",
		},
		variant: "AUG",
		name: "AUG A3 Para XS",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 57825,
		optimalRange: 18,
		rpm: 725,
		magSize: 25,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ce",
		},
		variant: "CMMG",
		name: "CMMG Banshee",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 50303,
		optimalRange: 16,
		rpm: 900,
		magSize: 32,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284cf",
		},
		variant: "MP5",
		name: "MP5 ST",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 51713,
		optimalRange: 16,
		rpm: 800,
		magSize: 32,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284d9",
		},
		variant: "SMG-9",
		name: "Converted SMG-9",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 51713,
		optimalRange: 20,
		rpm: 825,
		magSize: 32,
		reloadSpeedMS: 2000,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284db",
		},
		variant: "SMG-9",
		name: "Converted SMG-9 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 51713,
		optimalRange: 20,
		rpm: 825,
		magSize: 32,
		reloadSpeedMS: 2000,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284e0",
		},
		variant: "Thompson",
		name: "Tommy Gun",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 55474,
		optimalRange: 16,
		rpm: 800,
		magSize: 50,
		reloadSpeedMS: 2300,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284e2",
		},
		variant: "UMP-45",
		name: "Tactical UMP-45",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 65817,
		optimalRange: 14,
		rpm: 600,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: ".45 ACP",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284e3",
		},
		variant: "UMP-45",
		name: "Tactical UMP-45 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 65817,
		optimalRange: 14,
		rpm: 600,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: ".45 ACP",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28503",
		},
		variant: "MK47",
		name: "Resolute MK47",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 104837,
		optimalRange: 60,
		rpm: 300,
		magSize: 30,
		reloadSpeedMS: 1970,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284e5",
		},
		variant: "Vector",
		name: "Vector SBR .45 ACP",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 40430,
		optimalRange: 15,
		rpm: 1200,
		magSize: 25,
		reloadSpeedMS: 1900,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: ".45 ACP",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284e6",
		},
		variant: "Vector",
		name: "Vector SBR .45 ACP Replica",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 40430,
		optimalRange: 15,
		rpm: 1200,
		magSize: 25,
		reloadSpeedMS: 1900,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: ".45 ACP",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284e8",
		},
		variant: "CMMG",
		name: "The Grudge",
		slot: "P",
		quality: "Named",
		weaponType: "SMG",
		baseDamage: 50303,
		optimalRange: 16,
		rpm: 900,
		magSize: 32,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfect Vindictive",
		optics: "Micro / Short",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ef",
		},
		variant: "Vector",
		name: "Dark Winter",
		slot: "P",
		quality: "Named",
		weaponType: "SMG",
		baseDamage: 40430,
		optimalRange: 15,
		rpm: 1200,
		magSize: 25,
		reloadSpeedMS: 1900,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfect Killer",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: ".45 ACP",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284f5",
		},
		variant: "SIX12",
		name: "SIX-12",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 451314,
		optimalRange: 16,
		rpm: 130,
		magSize: 7,
		reloadSpeedMS: 2400,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284fc",
		},
		variant: "KARD",
		name: 'TDI "Kard" Custom',
		slot: "S",
		quality: "Named",
		weaponType: "Pistol",
		baseDamage: 109068,
		optimalRange: 12,
		rpm: 310,
		magSize: 7,
		reloadSpeedMS: 1700,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "Skill Tier",
		talent: "A",
		optics: "Micro",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28500",
		},
		variant: "Sig Sauer 556",
		name: "Mechanical Animal",
		slot: "P",
		quality: "Named",
		weaponType: "Assault Rifle",
		baseDamage: 50773,
		optimalRange: 35,
		rpm: 720,
		magSize: 30,
		reloadSpeedMS: 2170,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "Future Perfection",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2840b",
		},
		variant: "M4",
		name: "Eagle Bearer",
		slot: "P",
		quality: "Exotic",
		weaponType: "Assault Rifle",
		baseDamage: 51713,
		optimalRange: 35,
		rpm: 750,
		magSize: 30,
		reloadSpeedMS: 2500,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "Headshot Damage",
		talent: "Eagle's Strike",
		optics: "Eagle Bearer",
		underBarrel: "Eagle Bearer",
		magazine: "Eagle Bearer",
		muzzle: "Eagle Bearer",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2840f",
		},
		variant: "AK-47",
		name: "AK-M",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 62056,
		optimalRange: 31,
		rpm: 600,
		magSize: 30,
		reloadSpeedMS: 2500,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "8.02",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2840c",
		},
		variant: "Vector",
		name: "Chameleon",
		slot: "P",
		quality: "Exotic",
		weaponType: "Assault Rifle",
		baseDamage: 39960,
		optimalRange: 15,
		rpm: 900,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 50,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "Critical Hit Chance",
		talent: "Adaptive Instincts",
		optics: "Chameleon",
		underBarrel: "Chameleon",
		magazine: "Chameleon",
		muzzle: "Chameleon",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28411",
		},
		variant: "AK-47",
		name: "Black Market AK-M Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 60645,
		optimalRange: 31,
		rpm: 600,
		magSize: 30,
		reloadSpeedMS: 2500,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28412",
		},
		variant: "AK-47",
		name: "Military AK-M",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 60645,
		optimalRange: 31,
		rpm: 600,
		magSize: 30,
		reloadSpeedMS: 2500,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28413",
		},
		variant: "AUG",
		name: "AUG A3-CQC",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 46071,
		optimalRange: 27,
		rpm: 680,
		magSize: 30,
		reloadSpeedMS: 2100,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28417",
		},
		variant: "FAL",
		name: "FAL",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 50772,
		optimalRange: 32,
		rpm: 650,
		magSize: 20,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28418",
		},
		variant: "FAL",
		name: "FAL SA-58",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 50772,
		optimalRange: 32,
		rpm: 650,
		magSize: 20,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28419",
		},
		variant: "FAL",
		name: "FAL SA-58 Para",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 50772,
		optimalRange: 32,
		rpm: 650,
		magSize: 20,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2841a",
		},
		variant: "FAL",
		name: "FAL SA-58 Para Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 50772,
		optimalRange: 32,
		rpm: 650,
		magSize: 20,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2841b",
		},
		variant: "FAMAS",
		name: "FAMAS 2010",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 44191,
		optimalRange: 28,
		rpm: 900,
		magSize: 30,
		reloadSpeedMS: 2200,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28420",
		},
		variant: "G36",
		name: "Military G36",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 48892,
		optimalRange: 32,
		rpm: 750,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28421",
		},
		variant: "M4",
		name: "Police M4",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 44191,
		optimalRange: 35,
		rpm: 850,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28427",
		},
		variant: "SCAR",
		name: "SOCOM Mk16",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 55004,
		optimalRange: 35,
		rpm: 625,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2843b",
		},
		variant: "M249",
		name: "Tactical M249 Para",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 56414,
		optimalRange: 35,
		rpm: 550,
		magSize: 100,
		reloadSpeedMS: 5600,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28446",
		},
		variant: "RPK-74",
		name: "Classic RPK-74",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 55474,
		optimalRange: 28,
		rpm: 650,
		magSize: 45,
		reloadSpeedMS: 3000,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2844c",
		},
		variant: "M249",
		name: "The Stinger",
		slot: "P",
		quality: "Named",
		weaponType: "LMG",
		baseDamage: 56414,
		optimalRange: 35,
		rpm: 550,
		magSize: 100,
		reloadSpeedMS: 5600,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "Swift",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2844d",
		},
		variant: "M60",
		name: "Good Times",
		slot: "P",
		quality: "Named",
		weaponType: "LMG",
		baseDamage: 61116,
		optimalRange: 40,
		rpm: 500,
		magSize: 100,
		reloadSpeedMS: 5800,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfect Fast Hands",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28453",
		},
		variant: "M44",
		name: "Classic M44 Carbine",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 423108,
		optimalRange: 60,
		rpm: 55,
		magSize: 5,
		reloadSpeedMS: 4200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "Integrated",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28454",
		},
		variant: "M44",
		name: "Classic M44 Carbine Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 423108,
		optimalRange: 60,
		rpm: 55,
		magSize: 5,
		reloadSpeedMS: 4200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "Integrated",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28457",
		},
		variant: "M44",
		name: "Hunting M44",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 416056,
		optimalRange: 60,
		rpm: 55,
		magSize: 5,
		reloadSpeedMS: 4200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Integrated",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2845e",
		},
		variant: "SCAR",
		name: "SOCOM Mk20 SSR Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 143857,
		optimalRange: 60,
		rpm: 275,
		magSize: 20,
		reloadSpeedMS: 2200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28464",
		},
		variant: "SRS",
		name: "SRS A1",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 338957,
		optimalRange: 42,
		rpm: 60,
		magSize: 7,
		reloadSpeedMS: 3000,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28475",
		},
		variant: "M1911",
		name: "M1911",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 101076,
		optimalRange: 12,
		rpm: 310,
		magSize: 7,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "Pistol",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28477",
		},
		variant: "M1911",
		name: "Tactical M1911",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 98725,
		optimalRange: 12,
		rpm: 310,
		magSize: 7,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "Micro",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28483",
		},
		variant: "Rhino",
		name: "Snubnosed Diceros",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 170183,
		optimalRange: 18,
		rpm: 180,
		magSize: 6,
		reloadSpeedMS: 1800,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "Revolver Drum",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28486",
		},
		variant: "X-45",
		name: "X-45 Tactical Replica",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 76630,
		optimalRange: 16,
		rpm: 400,
		magSize: 15,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28489",
		},
		variant: "PF45",
		name: "Lightning Rod",
		slot: "S",
		quality: "Named",
		weaponType: "Pistol",
		baseDamage: 82741,
		optimalRange: 14,
		rpm: 370,
		magSize: 15,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "Perfect Salvage",
		optics: "Micro",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2848c",
		},
		variant: "P416",
		name: "The Ravenous",
		slot: "P",
		quality: "Exotic",
		weaponType: "Rifle",
		baseDamage: 122231,
		optimalRange: 50,
		rpm: 240,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "Damage to TOC",
		talent: "Geri and Freki",
		optics: "The Ravenous",
		underBarrel: "The Ravenous",
		magazine: "The Ravenous",
		muzzle: "The Ravenous",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2848d",
		},
		variant: "1886",
		name: "Diamondback",
		slot: "P",
		quality: "Exotic",
		weaponType: "Rifle",
		baseDamage: 329084,
		optimalRange: 28,
		rpm: 100,
		magSize: 5,
		reloadSpeedMS: 3400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "Headshot Damage",
		talent: "Agonizing Bite",
		optics: "Diamondback",
		underBarrel: "Diamondback",
		magazine: "Diamondback",
		muzzle: "Diamondback",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2848f",
		},
		variant: "ACR",
		name: "ACR SS",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 92614,
		optimalRange: 50,
		rpm: 420,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28496",
		},
		variant: "M1A",
		name: "SOCOM M1A",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 156080,
		optimalRange: 60,
		rpm: 320,
		magSize: 10,
		reloadSpeedMS: 3000,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2849c",
		},
		variant: "SCAR",
		name: "Military Mk17",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 116120,
		optimalRange: 50,
		rpm: 275,
		magSize: 20,
		reloadSpeedMS: 2200,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284a7",
		},
		variant: "SCAR",
		name: "Everlasting Gaze",
		slot: "P",
		quality: "Named",
		weaponType: "Rifle",
		baseDamage: 116120,
		optimalRange: 50,
		rpm: 275,
		magSize: 20,
		reloadSpeedMS: 2200,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "Perfect Perpetuation",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284b5",
		},
		variant: "KSG",
		name: "KSG Shotgun",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 594230,
		optimalRange: 16,
		rpm: 70,
		magSize: 12,
		reloadSpeedMS: 8050,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284bb",
		},
		variant: "SPAS-12",
		name: "SPAS-12 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 646883,
		optimalRange: 16,
		rpm: 70,
		magSize: 8,
		reloadSpeedMS: 5450,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284c0",
		},
		variant: "Super 90",
		name: "Tactical Super 90 SBS Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 440031,
		optimalRange: 16,
		rpm: 160,
		magSize: 8,
		reloadSpeedMS: 4800,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284c6",
		},
		variant: "SASG-12",
		name: "Tsunami",
		slot: "P",
		quality: "Named",
		weaponType: "Shotgun",
		baseDamage: 364812,
		optimalRange: 14,
		rpm: 180,
		magSize: 7,
		reloadSpeedMS: 3000,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfect Pummel",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284c7",
		},
		variant: "Super 90",
		name: "Enforcer",
		slot: "P",
		quality: "Named",
		weaponType: "Shotgun",
		baseDamage: 440031,
		optimalRange: 16,
		rpm: 160,
		magSize: 8,
		reloadSpeedMS: 4800,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfect Pummel",
		optics: "Micro / Short",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284cb",
		},
		variant: "AUG",
		name: "Enhanced AUG A3P",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 57825,
		optimalRange: 18,
		rpm: 725,
		magSize: 25,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284cc",
		},
		variant: "AUG",
		name: "Tactical AUG A3P",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 57825,
		optimalRange: 18,
		rpm: 725,
		magSize: 25,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284d0",
		},
		variant: "MP5",
		name: "MP5 ST Replica",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 51713,
		optimalRange: 16,
		rpm: 800,
		magSize: 32,
		reloadSpeedMS: 2100,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284d4",
		},
		variant: "MPX",
		name: "MPX",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 53124,
		optimalRange: 22,
		rpm: 850,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284d5",
		},
		variant: "P90",
		name: "P90",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 48892,
		optimalRange: 15,
		rpm: 900,
		magSize: 50,
		reloadSpeedMS: 2200,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284d6",
		},
		variant: "P90",
		name: "P90 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 48892,
		optimalRange: 15,
		rpm: 900,
		magSize: 50,
		reloadSpeedMS: 2200,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284da",
		},
		variant: "SMG-9",
		name: "Converted SMG-9 A2",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 49833,
		optimalRange: 20,
		rpm: 825,
		magSize: 32,
		reloadSpeedMS: 2000,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284e4",
		},
		variant: "Vector",
		name: "Tactical Vector SBR 9mm",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 42311,
		optimalRange: 15,
		rpm: 1200,
		magSize: 19,
		reloadSpeedMS: 1900,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284e7",
		},
		variant: "Vector",
		name: "Vector SBR 9mm",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 37139,
		optimalRange: 15,
		rpm: 1200,
		magSize: 33,
		reloadSpeedMS: 1900,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284e9",
		},
		variant: "MP7",
		name: "Swap Chain",
		slot: "P",
		quality: "Named",
		weaponType: "SMG",
		baseDamage: 47952,
		optimalRange: 19,
		rpm: 950,
		magSize: 40,
		reloadSpeedMS: 2000,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfect Unwavering",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ea",
		},
		variant: "MPX",
		name: "Safety Distance",
		slot: "P",
		quality: "Named",
		weaponType: "SMG",
		baseDamage: 53124,
		optimalRange: 22,
		rpm: 850,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfect Outsider",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ec",
		},
		variant: "P90",
		name: "Emeline's Guard",
		slot: "P",
		quality: "Named",
		weaponType: "SMG",
		baseDamage: 48892,
		optimalRange: 15,
		rpm: 900,
		magSize: 50,
		reloadSpeedMS: 2200,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfect Preservation",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284f1",
		},
		variant: "Rhino",
		name: "Diceros Special",
		slot: "S",
		quality: "Named",
		weaponType: "Pistol",
		baseDamage: 169243,
		optimalRange: 18,
		rpm: 180,
		magSize: 6,
		reloadSpeedMS: 1800,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "11",
		core2: "Stability",
		core2Max: "9.3",
		attribute1: "",
		talent: "Perfect Optimist",
		optics: "Short",
		underBarrel: "Gadget",
		magazine: "Revolver Drum",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284f4",
		},
		variant: "M1911",
		name: "Maxim 9",
		slot: "S",
		quality: "Named",
		weaponType: "Pistol",
		baseDamage: 98725,
		optimalRange: 16,
		rpm: 280,
		magSize: 17,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "11",
		core2: "Headshot Damage",
		core2Max: "9",
		attribute1: "",
		talent: "Perfect Spike",
		optics: "",
		underBarrel: "",
		magazine: "Pistol",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284f9",
		},
		variant: "PDR",
		name: "PDR",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 51713,
		optimalRange: 28,
		rpm: 700,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Damage to Health",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short",
		underBarrel: "",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284fa",
		},
		variant: "PDR",
		name: "Test Subject",
		slot: "P",
		quality: "Named",
		weaponType: "Assault Rifle",
		baseDamage: 51713,
		optimalRange: 28,
		rpm: 700,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Damage to Health",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfectly In Sync",
		optics: "Short",
		underBarrel: "",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284fb",
		},
		variant: "KARD",
		name: "KARD-45",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 109068,
		optimalRange: 12,
		rpm: 310,
		magSize: 7,
		reloadSpeedMS: 1700,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "Micro",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284fe",
		},
		variant: "Tactical .308",
		name: "Scalpel",
		slot: "P",
		quality: "Named",
		weaponType: "Marksman Rifle",
		baseDamage: 324383,
		optimalRange: 42,
		rpm: 70,
		magSize: 7,
		reloadSpeedMS: 3400,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "Future Perfection",
		optics: "Micro / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28504",
		},
		variant: "MK47",
		name: "Harmony",
		slot: "P",
		quality: "Named",
		weaponType: "Rifle",
		baseDamage: 104837,
		optimalRange: 60,
		rpm: 300,
		magSize: 30,
		reloadSpeedMS: 1970,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "Perfectly In Sync",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28416",
		},
		variant: "F2000",
		name: "F2000 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 39490,
		optimalRange: 27,
		rpm: 850,
		magSize: 30,
		reloadSpeedMS: 2100,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2841d",
		},
		variant: "G36",
		name: "G36 C",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 48892,
		optimalRange: 32,
		rpm: 750,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28426",
		},
		variant: "SCAR",
		name: "Mk16",
		slot: "P",
		quality: "High End",
		weaponType: "Assault Rifle",
		baseDamage: 55004,
		optimalRange: 35,
		rpm: 625,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2842c",
		},
		variant: "AUG",
		name: "Invisible Hand",
		slot: "P",
		quality: "Named",
		weaponType: "Assault Rifle",
		baseDamage: 46071,
		optimalRange: 27,
		rpm: 680,
		magSize: 30,
		reloadSpeedMS: 2100,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfect Allegro",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2842d",
		},
		variant: "F2000",
		name: "Shield Splinterer",
		slot: "P",
		quality: "Named",
		weaponType: "Assault Rifle",
		baseDamage: 39960,
		optimalRange: 27,
		rpm: 850,
		magSize: 30,
		reloadSpeedMS: 2100,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfect Optimist",
		optics: "Ironsights / Short / Long",
		underBarrel: "",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28432",
		},
		variant: "TAR-21",
		name: "The Railsplitter",
		slot: "P",
		quality: "Named",
		weaponType: "Assault Rifle",
		baseDamage: 41371,
		optimalRange: 35,
		rpm: 900,
		magSize: 30,
		reloadSpeedMS: 2100,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfectly Accurate",
		optics: "Micro / Short",
		underBarrel: "Side",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28434",
		},
		variant: "M249",
		name: "Pestilence",
		slot: "P",
		quality: "Exotic",
		weaponType: "LMG",
		baseDamage: 47012,
		optimalRange: 35,
		rpm: 550,
		magSize: 100,
		reloadSpeedMS: 5600,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "Damage to Armor",
		talent: "Plague of the Outcasts",
		optics: "Pestilence",
		underBarrel: "Pestilence",
		magazine: "Pestilence",
		muzzle: "Pestilence",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28435",
		},
		variant: "L86",
		name: "Custom L86 A2",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 59705,
		optimalRange: 32,
		rpm: 610,
		magSize: 30,
		reloadSpeedMS: 3000,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2843a",
		},
		variant: "M249",
		name: "Military MK46 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 56414,
		optimalRange: 35,
		rpm: 550,
		magSize: 100,
		reloadSpeedMS: 5600,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2843c",
		},
		variant: "M60",
		name: "Black Market M60 E6",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 61116,
		optimalRange: 40,
		rpm: 500,
		magSize: 100,
		reloadSpeedMS: 5800,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2843d",
		},
		variant: "M60",
		name: "Black Market M60 E6 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 61116,
		optimalRange: 40,
		rpm: 500,
		magSize: 100,
		reloadSpeedMS: 5800,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2843e",
		},
		variant: "M60",
		name: "Classic M60",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 63466,
		optimalRange: 40,
		rpm: 500,
		magSize: 100,
		reloadSpeedMS: 5800,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28440",
		},
		variant: "M60",
		name: "Military M60 E4",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 61116,
		optimalRange: 40,
		rpm: 500,
		magSize: 100,
		reloadSpeedMS: 5800,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28441",
		},
		variant: "MG5",
		name: "Infantry MG5",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 43251,
		optimalRange: 30,
		rpm: 800,
		magSize: 50,
		reloadSpeedMS: 5600,
		hsd: 85,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "Ammunition Belt",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28444",
		},
		variant: "RPK-74",
		name: "Black Market RPK-74 E",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 55474,
		optimalRange: 28,
		rpm: 650,
		magSize: 45,
		reloadSpeedMS: 3000,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28449",
		},
		variant: "KAC",
		name: "Stoner LAMG",
		slot: "P",
		quality: "High End",
		weaponType: "LMG",
		baseDamage: 47952,
		optimalRange: 40,
		rpm: 580,
		magSize: 200,
		reloadSpeedMS: 4670,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "Ammunition Belt",
		muzzle: "QDC",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2844b",
		},
		variant: "M249",
		name: "Black Friday",
		slot: "P",
		quality: "Named",
		weaponType: "LMG",
		baseDamage: 56414,
		optimalRange: 35,
		rpm: 550,
		magSize: 100,
		reloadSpeedMS: 5600,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfectly Unhinged",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Ammunition Belt",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2844e",
		},
		variant: "MG5",
		name: "Sleipner",
		slot: "P",
		quality: "Named",
		weaponType: "LMG",
		baseDamage: 44661,
		optimalRange: 30,
		rpm: 800,
		magSize: 50,
		reloadSpeedMS: 5600,
		hsd: 85,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfect Frenzy",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "Ammunition Belt",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2844f",
		},
		variant: "RPK-74",
		name: "New Reliable",
		slot: "P",
		quality: "Named",
		weaponType: "LMG",
		baseDamage: 55474,
		optimalRange: 28,
		rpm: 650,
		magSize: 45,
		reloadSpeedMS: 3000,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfectly Optimized",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28451",
		},
		variant: "KAC",
		name: "Quiet Roar",
		slot: "P",
		quality: "Named",
		weaponType: "LMG",
		baseDamage: 47952,
		optimalRange: 40,
		rpm: 580,
		magSize: 200,
		reloadSpeedMS: 4670,
		hsd: 65,
		core1: "LMG Damage",
		core1Max: "15",
		core2: "Damage to TOC",
		core2Max: "12",
		attribute1: "A",
		talent: "Perfect Overwhelm",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "Ammunition Belt",
		muzzle: "QDC",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28452",
		},
		variant: "AWM",
		name: "Nemesis",
		slot: "P",
		quality: "Exotic",
		weaponType: "Marksman Rifle",
		baseDamage: 940240,
		optimalRange: 60,
		rpm: 50,
		magSize: 5,
		reloadSpeedMS: 3200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "Critical Hit Chance",
		talent: "Electromagnetic Accelerator",
		optics: "Nemesis",
		underBarrel: "Nemesis",
		magazine: "Nemesis",
		muzzle: "Nemesis",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28456",
		},
		variant: "M44",
		name: "Custom M44 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 376096,
		optimalRange: 60,
		rpm: 55,
		magSize: 5,
		reloadSpeedMS: 3200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28458",
		},
		variant: "Model 700",
		name: "M700 Carbon",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 376096,
		optimalRange: 42,
		rpm: 54,
		magSize: 7,
		reloadSpeedMS: 3300,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2845c",
		},
		variant: "Model 700",
		name: "Model 700 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 399602,
		optimalRange: 42,
		rpm: 54,
		magSize: 7,
		reloadSpeedMS: 7200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "",
		magazine: "Integrated",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2845f",
		},
		variant: "SR-1",
		name: "Adrestia",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 357291,
		optimalRange: 60,
		rpm: 60,
		magSize: 5,
		reloadSpeedMS: 3200,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28462",
		},
		variant: "G28",
		name: "G28",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 146677,
		optimalRange: 60,
		rpm: 275,
		magSize: 20,
		reloadSpeedMS: 2400,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28463",
		},
		variant: "SRS",
		name: "Covert SRS",
		slot: "P",
		quality: "High End",
		weaponType: "Marksman Rifle",
		baseDamage: 338957,
		optimalRange: 42,
		rpm: 60,
		magSize: 7,
		reloadSpeedMS: 3000,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "A",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2846c",
		},
		variant: "SRS",
		name: "Pinprick",
		slot: "P",
		quality: "Named",
		weaponType: "Marksman Rifle",
		baseDamage: 338957,
		optimalRange: 42,
		rpm: 60,
		magSize: 7,
		reloadSpeedMS: 3000,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "Perfect First Blood",
		optics: "Ironsights / Short / Long",
		underBarrel: "Side / Short",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2846d",
		},
		variant: "SVD",
		name: "Commando",
		slot: "P",
		quality: "Named",
		weaponType: "Marksman Rifle",
		baseDamage: 159841,
		optimalRange: 34,
		rpm: 260,
		magSize: 10,
		reloadSpeedMS: 2600,
		hsd: 0,
		core1: "Marksman Rifle Damage",
		core1Max: "15",
		core2: "Headshot Damage",
		core2Max: "111",
		attribute1: "A",
		talent: "Perfectly Naked",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "MM 7.62",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28470",
		},
		variant: "586 Magnum",
		name: "586 Magnum",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 190399,
		optimalRange: 18,
		rpm: 160,
		magSize: 6,
		reloadSpeedMS: 1800,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "Revolver Drum",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28485",
		},
		variant: "X-45",
		name: "X-45 Tactical",
		slot: "S",
		quality: "High End",
		weaponType: "Pistol",
		baseDamage: 76630,
		optimalRange: 16,
		rpm: 400,
		magSize: 15,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28487",
		},
		variant: "586 Magnum",
		name: "Orbit",
		slot: "S",
		quality: "Named",
		weaponType: "Pistol",
		baseDamage: 130399,
		optimalRange: 18,
		rpm: 160,
		magSize: 6,
		reloadSpeedMS: 1800,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "Perfect Finisher",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "Revolver Drum",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28488",
		},
		variant: "M1911",
		name: "Mozambique Special",
		slot: "S",
		quality: "Named",
		weaponType: "Pistol",
		baseDamage: 98725,
		optimalRange: 12,
		rpm: 310,
		magSize: 7,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "15",
		core2: "",
		core2Max: "",
		attribute1: "A",
		talent: "Perfect Breadbasket",
		optics: "Micro",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2848b",
		},
		variant: "AK-M",
		name: "Ruthless",
		slot: "P",
		quality: "Exotic",
		weaponType: "Rifle",
		baseDamage: 84622,
		optimalRange: 50,
		rpm: 400,
		magSize: 30,
		reloadSpeedMS: 2500,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "Critical Hit Chance",
		talent: "Binary Trigger",
		optics: "Ruthless",
		underBarrel: "Ruthless",
		magazine: "Ruthless",
		muzzle: "Ruthless",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2848e",
		},
		variant: "1886",
		name: "1886",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 342247,
		optimalRange: 28,
		rpm: 100,
		magSize: 5,
		reloadSpeedMS: 3400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28491",
		},
		variant: "M16A2",
		name: "M16A2",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 84622,
		optimalRange: 32,
		rpm: 300,
		magSize: 30,
		reloadSpeedMS: 2400,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "",
		magazine: "5.56",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28493",
		},
		variant: "M1A",
		name: "Classic M1A",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 205442,
		optimalRange: 60,
		rpm: 180,
		magSize: 10,
		reloadSpeedMS: 3000,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "MM 7.62",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2849a",
		},
		variant: "MDR",
		name: "Urban MDR",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 97785,
		optimalRange: 28,
		rpm: 360,
		magSize: 20,
		reloadSpeedMS: 1900,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2849b",
		},
		variant: "MDR",
		name: "Urban MDR Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 97785,
		optimalRange: 28,
		rpm: 360,
		magSize: 20,
		reloadSpeedMS: 1900,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2849d",
		},
		variant: "SCAR",
		name: "Police Mk17",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 116120,
		optimalRange: 50,
		rpm: 275,
		magSize: 20,
		reloadSpeedMS: 2200,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e2849e",
		},
		variant: "SCAR",
		name: "Police Mk17 Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Rifle",
		baseDamage: 116120,
		optimalRange: 50,
		rpm: 275,
		magSize: 20,
		reloadSpeedMS: 2200,
		hsd: 60,
		core1: "Rifle Damage",
		core1Max: "15",
		core2: "Critical Hit Damage",
		core2Max: "17",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "8.02",
		muzzle: "8.02",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284a9",
		},
		variant: "SPAS-12",
		name: "Lullaby",
		slot: "P",
		quality: "Exotic",
		weaponType: "Shotgun",
		baseDamage: 582944,
		optimalRange: 10,
		rpm: 70,
		magSize: 8,
		reloadSpeedMS: 5450,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "Damage to TOC",
		talent: "Sandman",
		optics: "Lullaby",
		underBarrel: "Lullaby",
		magazine: "Lullaby",
		muzzle: "Lullaby",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ac",
		},
		variant: "Double Barrel",
		name: "Double Barrel Shotgun",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 620556,
		optimalRange: 11,
		rpm: 200,
		magSize: 2,
		reloadSpeedMS: 2200,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ad",
		},
		variant: "Double Barrel",
		name: "Double Barrel Shotgun Replica",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 620556,
		optimalRange: 11,
		rpm: 200,
		magSize: 2,
		reloadSpeedMS: 2200,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284af",
		},
		variant: "Double Barrel",
		name: "Firestarter",
		slot: "S",
		quality: "Named",
		weaponType: "Shotgun",
		baseDamage: 620556,
		optimalRange: 11,
		rpm: 200,
		magSize: 2,
		reloadSpeedMS: 2200,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "11",
		core2: "Damage to Health",
		core2Max: "7.5",
		attribute1: "",
		talent: "Primer Rounds",
		optics: "",
		underBarrel: "",
		magazine: "",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284ba",
		},
		variant: "SPAS-12",
		name: "SPAS-12",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 646883,
		optimalRange: 16,
		rpm: 70,
		magSize: 8,
		reloadSpeedMS: 5450,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284bd",
		},
		variant: "Super 90",
		name: "Super 90",
		slot: "P",
		quality: "High End",
		weaponType: "Shotgun",
		baseDamage: 440031,
		optimalRange: 16,
		rpm: 160,
		magSize: 8,
		reloadSpeedMS: 4800,
		hsd: 45,
		core1: "Shotgun Damage",
		core1Max: "15",
		core2: "Damage to Armor",
		core2Max: "12",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: "Tubular",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284d3",
		},
		variant: "MP7",
		name: "MP7",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 47952,
		optimalRange: 19,
		rpm: 950,
		magSize: 40,
		reloadSpeedMS: 2000,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "",
		muzzle: "5.56",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284d7",
		},
		variant: "PP-19",
		name: "Enhanced PP-19",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 59705,
		optimalRange: 16,
		rpm: 700,
		magSize: 53,
		reloadSpeedMS: 2500,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "",
		magazine: "",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284dc",
		},
		variant: "T821",
		name: "Black Market T821",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 66757,
		optimalRange: 17,
		rpm: 550,
		magSize: 32,
		reloadSpeedMS: 1900,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Micro / Short",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284df",
		},
		variant: "Thompson",
		name: "M1928",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 70518,
		optimalRange: 16,
		rpm: 600,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "",
		underBarrel: "",
		magazine: ".45 ACP",
		muzzle: "",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284e1",
		},
		variant: "UMP-45",
		name: "Police UMP-45",
		slot: "P",
		quality: "High End",
		weaponType: "SMG",
		baseDamage: 65817,
		optimalRange: 14,
		rpm: 600,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "A",
		optics: "Short / Long",
		underBarrel: "Side / Short / Long",
		magazine: ".45 ACP",
		muzzle: ".45 ACP",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284eb",
		},
		variant: "MPX",
		name: "The Apartment",
		slot: "P",
		quality: "Named",
		weaponType: "SMG",
		baseDamage: 53124,
		optimalRange: 22,
		rpm: 850,
		magSize: 30,
		reloadSpeedMS: 2300,
		hsd: 50,
		core1: "SMG Damage",
		core1Max: "15",
		core2: "Critical Hit Chance",
		core2Max: "21",
		attribute1: "A",
		talent: "Perfectly Measured",
		optics: "Short / Long",
		underBarrel: "Side / Short",
		magazine: "9mm",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284f0",
		},
		variant: "93R",
		name: "Sharpshooters 93R",
		slot: "S",
		quality: "Named",
		weaponType: "Pistol",
		baseDamage: 32908,
		optimalRange: 16,
		rpm: 1100,
		magSize: 20,
		reloadSpeedMS: 1900,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "11",
		core2: "Reload Speed",
		core2Max: "9.3",
		attribute1: "",
		talent: "Perfect Unwavering",
		optics: "",
		underBarrel: "",
		magazine: "Pistol",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e284f3",
		},
		variant: "P320",
		name: "P320 XCompact",
		slot: "S",
		quality: "Named",
		weaponType: "Pistol",
		baseDamage: 79920,
		optimalRange: 16,
		rpm: 380,
		magSize: 15,
		reloadSpeedMS: 1600,
		hsd: 100,
		core1: "Pistol Damage",
		core1Max: "11",
		core2: "Damage to Armor",
		core2Max: "5",
		attribute1: "",
		talent: "Perfect Preservation",
		optics: "Micro",
		underBarrel: "Gadget",
		magazine: "Pistol",
		muzzle: "9mm",
	},
	{
		_id: {
			oid: "6247a47dd03ce64590e28505",
		},
		variant: "AUG",
		name: "The Bighorn - Semi-Auto Mode",
		slot: "P",
		quality: "Exotic",
		weaponType: "Assault Rifle",
		baseDamage: 230829,
		optimalRange: 27,
		rpm: 120,
		magSize: 40,
		reloadSpeedMS: 2100,
		hsd: 55,
		core1: "Assault Rifle Damage",
		core1Max: "15",
		core2: "Health Damage",
		core2Max: "21",
		attribute1: "Critical Hit Damage",
		talent: "Big Game Hunter",
		optics: "The Bighorn",
		underBarrel: "The Bighorn",
		magazine: "The Bighorn",
		muzzle: "The Bighorn",
	},
];

export default WeaponsData;
