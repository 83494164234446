import React, { useState, useRef, useEffect, useContext } from "react";
import {
	IonButtons,
	IonButton,
	IonModal,
	IonHeader,
	IonContent,
	IonToolbar,
	IonTitle,
	IonGrid,
	IonRow,
	IonCol,
	IonSearchbar,
	IonAlert,
} from "@ionic/react";

import gearData from "../../data/GearData-Test";
import GearMainSelectCard from "./GearMainSelectCard";
import Gear from "../../models/gear";

import AppContext from "../../context/app-context";

interface GearMainSelectModalProps {
	slot: string;
}

const initGear = gearData.sort(function (a, b) {
	const nameA = a.name.toUpperCase(); // ignore upper and lowercase
	const nameB = b.name.toUpperCase(); // ignore upper and lowercase
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
});

const GearMainSelectModal = ({ slot }: GearMainSelectModalProps) => {
	const ctxApp = useContext(AppContext);

	const [showAlert, setShowAlert] = useState(false);

	const modal = useRef<HTMLIonModalElement>(null);
	const page = useRef(null);

	const [searchText, setSearchText] = useState("");

	const [presentingElement, setPresentingElement] =
		useState<HTMLElement | null>(null);

	const [currentGear, setCurrentGear] = useState(initGear);

	useEffect(() => {
		setPresentingElement(page.current);
	}, []);

	useEffect(() => {
		let updatedGear = initGear.filter((gear) => {
			return gear.slot.toLowerCase() === slot.toLowerCase();
		});
		if (searchText !== "") {
			// console.log(searchText);
			updatedGear = updatedGear.filter((item) => {
				return item.name
					.toLowerCase()
					.includes(searchText.toLowerCase());
			});
		}
		setCurrentGear(updatedGear);
	}, [searchText, slot]);

	function receiveSelectedGear(gear: Gear) {
		// console.log("In GearMainSelectModal", gear);
		// check if another slot has an exotic
		if (gear.quality.toLowerCase() === "exotic") {
			switch (slot.toLowerCase()) {
				case "mask":
					if (
						ctxApp.backpackGear.quality.toLowerCase() ===
							"exotic" ||
						ctxApp.vestGear.quality.toLowerCase() === "exotic" ||
						ctxApp.glovesGear.quality.toLowerCase() === "exotic" ||
						ctxApp.holsterGear.quality.toLowerCase() === "exotic" ||
						ctxApp.kneepadsGear.quality.toLowerCase() === "exotic"
					) {
						setShowAlert(true);
					} else {
						ctxApp.updateGear(slot, gear);
						// do we need to reset the stats state as well?
						ctxApp.resetStats(slot);
						modal.current?.dismiss();
					}
					break;
				case "backpack":
					if (
						ctxApp.maskGear.quality.toLowerCase() === "exotic" ||
						ctxApp.vestGear.quality.toLowerCase() === "exotic" ||
						ctxApp.glovesGear.quality.toLowerCase() === "exotic" ||
						ctxApp.holsterGear.quality.toLowerCase() === "exotic" ||
						ctxApp.kneepadsGear.quality.toLowerCase() === "exotic"
					) {
						setShowAlert(true);
					} else {
						ctxApp.updateGear(slot, gear);
						// do we need to reset the stats state as well?
						ctxApp.resetStats(slot);
						modal.current?.dismiss();
					}
					break;
				case "vest":
					if (
						ctxApp.maskGear.quality.toLowerCase() === "exotic" ||
						ctxApp.backpackGear.quality.toLowerCase() ===
							"exotic" ||
						ctxApp.glovesGear.quality.toLowerCase() === "exotic" ||
						ctxApp.holsterGear.quality.toLowerCase() === "exotic" ||
						ctxApp.kneepadsGear.quality.toLowerCase() === "exotic"
					) {
						setShowAlert(true);
					} else {
						ctxApp.updateGear(slot, gear);
						// do we need to reset the stats state as well?
						ctxApp.resetStats(slot);
						modal.current?.dismiss();
					}
					break;
				case "gloves":
					if (
						ctxApp.maskGear.quality.toLowerCase() === "exotic" ||
						ctxApp.backpackGear.quality.toLowerCase() ===
							"exotic" ||
						ctxApp.vestGear.quality.toLowerCase() === "exotic" ||
						ctxApp.holsterGear.quality.toLowerCase() === "exotic" ||
						ctxApp.kneepadsGear.quality.toLowerCase() === "exotic"
					) {
						alert("two exotics");
					} else {
						ctxApp.updateGear(slot, gear);
						// do we need to reset the stats state as well?
						ctxApp.resetStats(slot);
						modal.current?.dismiss();
					}
					break;
				case "holster":
					if (
						ctxApp.maskGear.quality.toLowerCase() === "exotic" ||
						ctxApp.backpackGear.quality.toLowerCase() ===
							"exotic" ||
						ctxApp.vestGear.quality.toLowerCase() === "exotic" ||
						ctxApp.glovesGear.quality.toLowerCase() === "exotic" ||
						ctxApp.kneepadsGear.quality.toLowerCase() === "exotic"
					) {
						setShowAlert(true);
					} else {
						ctxApp.updateGear(slot, gear);
						// do we need to reset the stats state as well?
						ctxApp.resetStats(slot);
						modal.current?.dismiss();
					}
					break;
				case "kneepads":
					if (
						ctxApp.maskGear.quality.toLowerCase() === "exotic" ||
						ctxApp.backpackGear.quality.toLowerCase() ===
							"exotic" ||
						ctxApp.vestGear.quality.toLowerCase() === "exotic" ||
						ctxApp.glovesGear.quality.toLowerCase() === "exotic" ||
						ctxApp.holsterGear.quality.toLowerCase() === "exotic"
					) {
						setShowAlert(true);
					} else {
						// do we need to reset the stats state as well?
						ctxApp.resetStats(slot);
						ctxApp.updateGear(slot, gear);
						modal.current?.dismiss();
					}
					break;
			}
		} else {
			// do we need to reset the stats state as well?
			ctxApp.resetStats(slot);
			ctxApp.updateGear(slot, gear);
			modal.current?.dismiss();
		}
	}

	function dismiss() {
		setSearchText("");
		modal.current?.dismiss();
	}

	return (
		<>
			<IonAlert
				cssClass={"two-exotics"}
				isOpen={showAlert}
				onDidDismiss={() => setShowAlert(false)}
				header="Two Exotics!"
				subHeader="Not Allowed!"
				message="Cannot equip two Exotic Gear Items!"
				buttons={["OK"]}
			/>
			<IonModal
				ref={modal}
				trigger={"open-" + slot.toLowerCase() + "-modal"}
				presentingElement={presentingElement!}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{slot}</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={(ev) => dismiss()}>
								Close
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonSearchbar
					color={"medium"}
					value={searchText}
					onIonChange={(e) => setSearchText(e.detail.value!)}
				></IonSearchbar>
				<IonContent className="ion-padding">
					<IonGrid>
						<IonRow>
							{currentGear.map((gear, index) => {
								return (
									<IonCol
										key={index}
										className=""
										size="12"
										size-md="6"
										size-sm="6"
									>
										<GearMainSelectCard
											slot={slot}
											id={slot}
											gear={gear}
											sendSelectedGearToParent={
												receiveSelectedGear
											}
										/>
									</IonCol>
								);
							})}
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonModal>
		</>
	);
};

export default GearMainSelectModal;
