import BrandsetBonus from "../models/brandsetBonus";
import Gear from "../models/gear";
import GearsetBonus from "../models/gearsetBonus";
import GearStats from "../models/gearStats";
import SpecializationStats from "../models/specializationStats";
import WatchStats from "../models/watchStats";
import Weapon from "../models/weapon";
import WeaponStats from "../models/weaponStats";

export const primaryWeaponCritOS: Weapon = {
	_id: {
		oid: "6247a47dd03ce64590e28493",
	},
	variant: "M1A",
	name: "Classic M1A",
	slot: "P",
	quality: "High End",
	weaponType: "Rifle",
	baseDamage: 205442,
	optimalRange: 60,
	rpm: 180,
	magSize: 10,
	reloadSpeedMS: 3000,
	hsd: 60,
	core1: "Rifle Damage",
	core1Max: "15",
	core2: "Critical Hit Damage",
	core2Max: "17",
	attribute1: "A",
	talent: "A",
	optics: "Short / Long",
	underBarrel: "Side / Short / Long",
	magazine: "MM 7.62",
	muzzle: "",
};

export const secondaryWeaponCritOS: Weapon = {
	_id: {
		oid: "6247a47dd03ce64590e284bd",
	},
	variant: "Carbine 7",
	name: "Carbine 7",
	slot: "P",
	quality: "High End",
	weaponType: "Assault Rifle",
	baseDamage: 47012,
	optimalRange: 35,
	rpm: 790,
	magSize: 30,
	reloadSpeedMS: 2400,
	hsd: 55,
	core1: "Assault Rifle Damage",
	core1Max: "15",
	core2: "Health Damage",
	core2Max: "21",
	attribute1: "A",
	talent: "A",
	optics: "Short / Long",
	underBarrel: "Side / Short / Long",
	magazine: "5.56",
	muzzle: "5.56",
};

export const pistolWeaponCritOS: Weapon = {
	_id: {
		oid: "6247a47dd03ce64590e2846f",
	},
	variant: "m586",
	name: "Regulus",
	slot: "S",
	quality: "Exotic",
	weaponType: "Pistol",
	baseDamage: 282072,
	optimalRange: 18,
	rpm: 160,
	magSize: 6,
	reloadSpeedMS: 1800,
	hsd: 100,
	core1: "Pistol Damage",
	core1Max: "15",
	core2: "",
	core2Max: "0",
	attribute1: "Headshot Damage",
	talent: "Regicide",
	optics: "Regulus",
	underBarrel: "Regulus",
	magazine: "Regulus",
	muzzle: "Regulus",
};

export const maskGearCritOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e7098",
	},
	slot: "mask",
	quality: "Named",
	name: "Punch Drunk",
	type: "N",
	brand: "Douglas & Harding",
	core: "Weapon Damage",
	attribute1: "Headshot Damage",
	attribute2: "A",
	mod: "O",
	talent: "",
};

export const backpackGearCritOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e7153",
	},
	slot: "backpack",
	quality: "Named",
	name: "The Gift",
	type: "N",
	brand: "Providence Defense",
	core: "Weapon Damage",
	attribute1: "A",
	attribute2: "A",
	mod: "O",
	talent: "Perfect Vigilance",
};

export const vestGearCritOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e707b",
	},
	slot: "vest",
	quality: "Named",
	name: "The Sacrifice",
	type: "N",
	brand: "Providence Defense",
	core: "Weapon Damage",
	attribute1: "A",
	attribute2: "A",
	mod: "O",
	talent: "Perfect Glass Cannon",
};

export const glovesGearCritOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e712f",
	},
	slot: "gloves",
	quality: "High End",
	name: "Improvised Gloves",
	type: "A",
	brand: "Crafted",
	core: "A",
	attribute1: "A",
	attribute2: "A",
	mod: "A",
	talent: "",
};

export const holsterGearCritOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e70ec",
	},
	slot: "holster",
	quality: "Exotic",
	name: "Dodge City Gunslinger's Holster",
	type: "E",
	brand: "Exotic",
	core: "Weapon Damage",
	attribute1: "Weapon Handling",
	attribute2: "Headshot Damage",
	mod: "",
	talent: "Quick Draw",
};

export const kneepadsGearCritOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e70d0",
	},
	slot: "kneepads",
	quality: "Named",
	name: "Fox's Prayer",
	type: "N",
	brand: "Overlord Armaments",
	core: "Weapon Damage",
	attribute1: "Damage to TOC",
	attribute2: "A",
	mod: "",
	talent: "",
};

export const primaryStatsCritOS: WeaponStats = {
	expertise: 0,
	core1Value: 15,
	core2Value: 17,
	attribute1: "Damage to TOC",
	attribute1Value: 9,
	talent: "Optimist",
	modOpticName: "C79 Scope (3.4x)",
	modOpticStatPos: "Critical Hit Chance",
	modOpticValuePos: 5,
	modOpticStatNeg: "",
	modOpticValueNeg: 0,
	modMagazineName: "Flexible Tubular Spring",
	modMagazineStatPos: "Reload Speed %",
	modMagazineValuePos: 20,
	modMagazineStatNeg: "",
	modMagazineValueNeg: 0,
	modUnderbarrelName: "Short Grip",
	modUnderbarrelStatPos: "Critical Hit Damage",
	modUnderbarrelValuePos: 5,
	modUnderbarrelStatNeg: "",
	modUnderbarrelValueNeg: 0,
	modMuzzleName: "",
	modMuzzleStatPos: "",
	modMuzzleValuePos: 0,
	modMuzzleStatNeg: "",
	modMuzzleValueNeg: 0,
};

export const secondaryStatsCritOS: WeaponStats = {
	expertise: 0,
	core1Value: 12.3,
	core2Value: 21,
	attribute1: "Damage to TOC",
	attribute1Value: 10,
	talent: "Boomerang",
	modOpticName: "C79 Scope (3.4x)",
	modOpticStatPos: "Critical Hit Chance",
	modOpticValuePos: 5,
	modOpticStatNeg: "",
	modOpticValueNeg: 0,
	modMagazineName: "Tightly Packed Marksman Mag",
	modMagazineStatPos: "Extra Rounds",
	modMagazineValuePos: 5,
	modMagazineStatNeg: "",
	modMagazineValueNeg: 0,
	modUnderbarrelName: "Laser Pointer",
	modUnderbarrelStatPos: "Critical Hit Chance",
	modUnderbarrelValuePos: 5,
	modUnderbarrelStatNeg: "",
	modUnderbarrelValueNeg: 0,
	modMuzzleName: "",
	modMuzzleStatPos: "",
	modMuzzleValuePos: 0,
	modMuzzleStatNeg: "",
	modMuzzleValueNeg: 0,
};

export const pistolStatsCritOS: WeaponStats = {
	expertise: 1,
	core1Value: 15,
	core2Value: 10,
	attribute1: "Headshot Damage",
	attribute1Value: 10,
	talent: "Regecide",
	modOpticName: "",
	modOpticStatPos: "",
	modOpticValuePos: 0,
	modOpticStatNeg: "",
	modOpticValueNeg: 0,
	modMagazineName: "Engraved Drum",
	modMagazineStatPos: "Reload Speed %",
	modMagazineValuePos: 10,
	modMagazineStatNeg: "",
	modMagazineValueNeg: 0,
	modUnderbarrelName: "",
	modUnderbarrelStatPos: "",
	modUnderbarrelValuePos: 0,
	modUnderbarrelStatNeg: "",
	modUnderbarrelValueNeg: 0,
	modMuzzleName: "Engraved Muzzle",
	modMuzzleStatPos: "Accuracy",
	modMuzzleValuePos: 20,
	modMuzzleStatNeg: "",
	modMuzzleValueNeg: 0,
};

export const maskStatsCritOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 14.1,
	attribute1Name: "Critical Hit Chance",
	attribute1Value: 5,
	attribute2Name: "Headshot Damage",
	attribute2Value: 10,
	talent: "",
	modName: "Critical Hit Damage",
	modValue: 12,
};

export const backpackStatsCritOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 11.6,
	attribute1Name: "Critical Hit Chance",
	attribute1Value: 5,
	attribute2Name: "Headshot Damage",
	attribute2Value: 15,
	talent: "Vigilance",
	modName: "Critical Hit Damage",
	modValue: 12,
};

export const vestStatsCritOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 11.9,
	attribute1Name: "Critical Hit Chance",
	attribute1Value: 5,
	attribute2Name: "Headshot Damage",
	attribute2Value: 0,
	talent: "Vigilance",
	modName: "Critical Hit Damage",
	modValue: 12,
};

export const glovesStatsCritOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 15,
	attribute1Name: "Critical Hit Chance",
	attribute1Value: 5,
	attribute2Name: "Headshot Damage",
	attribute2Value: 10,
	talent: "",
	modName: "",
	modValue: 0,
};

export const holsterStatsCritOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 15,
	attribute1Name: "Critical Hit Chance",
	attribute1Value: 5,
	attribute2Name: "Headshot Damage",
	attribute2Value: 10,
	talent: "",
	modName: "",
	modValue: 0,
};

export const kneepadsStatsCritOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 15,
	attribute1Name: "Damage to TOoC",
	attribute1Value: 8,
	attribute2Name: "Headshot Damage",
	attribute2Value: 0,
	talent: "",
	modName: "",
	modValue: 0,
};

export const specializationStatsCritOS: SpecializationStats = {
	name: "Firewall",
	bonuses: [
		{
			name: "Pulse Resistance",
			val: 50,
		},
		{
			name: "Health on Kill %",
			val: 30,
		},
		{
			name: "Burn Duration",
			val: 20,
		},
	],
	mmrDamage: 0,
	rifleDamage: 15,
	arDamage: 15,
	smgDamage: 0,
	lmgDamage: 0,
	shotgunDamage: 15,
	pistolDamage: 0,
};

export const watchStatsCritOS: WatchStats = {
	offensive: {
		weaponDamage: 10,
		headshotDamage: 20,
		criticalHitDamage: 20,
		criticalHitChance: 10,
	},
	defensive: {
		totalHealth: 0,
		totalArmor: 0,
		explosiveResistance: 0,
		hazardProtection: 0,
	},

	utility: {
		skillDamage: 0,
		skillRepair: 0,
		skillDuration: 0,
		skillHaste: 0,
	},
	handling: {
		accuracy: 0,
		stability: 0,
		reloadSpeed: 0,
		ammoCapacity: 0,
	},
};

export const brandsetBonusesCritOS: BrandsetBonus[] = [];

export const gearsetBonusesCritOS: GearsetBonus = {
	mask: {
		attribute: "",
		value: 0,
	},
	backpack: {
		attribute: "",
		value: 0,
	},
	vest: {
		attribute: "Critical Hit Damage",
		value: 15,
	},
	gloves: {
		attribute: "",
		value: 0,
	},
	holster: {
		attribute: "",
		value: 0,
	},
	kneepads: {
		attribute: "Rifle Damage",
		value: 10,
	},
};
