import { IonHeader, IonPage, useIonRouter } from "@ionic/react";
import { useContext, useEffect } from "react";
import LoginForm from "../components/LoginForm";
import Toolbar from "../components/toolbar/Toolbar";
import PageContent from "../components/ui/PageContent";
import AuthContext from "../context/auth-context";
import "./Login.css";

const Login: React.FC = () => {
	const ctxAuth = useContext(AuthContext);
	const router = useIonRouter();

	useEffect(() => {
		if (ctxAuth.isLoggedIn) {
			// router.push("/");
		}
	}, [ctxAuth.isLoggedIn, router]);

	return (
		<>
			<IonPage>
				<IonHeader>
					<Toolbar title="Login" />
				</IonHeader>
				<PageContent>
					{ctxAuth.isLoggedIn}
					{ctxAuth.isLoggedIn && <p>Logged In</p>}
					{!ctxAuth.isLoggedIn && (
						<div>
							<div className="">
								<p className="">Login to Build Station</p>
								<LoginForm />
							</div>
						</div>
					)}
				</PageContent>
			</IonPage>
		</>
	);
};

export default Login;
