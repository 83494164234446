import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Fragment, useEffect, useState } from "react";
import Weapon from "../../models/weapon";
import WeaponStats from "../../models/weaponStats";
import StatsBreakdown from "./StatsBreakdown";

import useCalculator from "../../hooks/useCalculator";

interface StatsMainProps {
	weapon: Weapon;
	weaponStats: WeaponStats;
	slot: string;
}

const sections = [
	{
		name: "Weapon Stats",
		stats: [
			{ name: "Weapon Damage" },
			{ name: "Critical Hit Chance" },
			{ name: "Critical Hit Damage" },
			{ name: "Headshot Damage" },
		],
	},
];

const StatsMain = ({ weapon, weaponStats, slot }: StatsMainProps) => {
	const calcs = useCalculator({
		slot: slot,
		weapon: weapon,
		weaponStats: weaponStats,
	});

	const [wdPercent, setWDPercent] = useState(0);
	const [chcPercent, setCHCPercent] = useState(0);
	const [chdPercent, setCHDPercent] = useState(0);
	const [hsdPercent, setHSDPercent] = useState(0);
	const [twdPercent, setTWDPercent] = useState(0);
	const [dtaPercent, setDTAPercent] = useState(0);
	const [dttoocPercent, setDTTOOCPercent] = useState(0);

	// WD
	useEffect(() => {
		setWDPercent(calcs.wdPercent);
	}, [calcs.wdPercent]);

	// CHC
	useEffect(() => {
		setCHCPercent(calcs.chcPercent);
	}, [calcs.chcPercent]);

	// CHD
	useEffect(() => {
		setCHDPercent(calcs.chdPercent);
	}, [calcs.chdPercent]);

	// HSD
	useEffect(() => {
		setHSDPercent(calcs.hsdPercent);
	}, [calcs.hsdPercent]);

	// TWD
	useEffect(() => {
		setTWDPercent(calcs.twdPercent);
	}, [calcs.twdPercent]);

	// DtA
	useEffect(() => {
		setDTAPercent(calcs.dtaPercent);
	}, [calcs.dtaPercent]);

	// DtTooC
	useEffect(() => {
		setDTTOOCPercent(calcs.dttoocPercent);
	}, [calcs.dttoocPercent]);

	return (
		<IonGrid className="ion-no-padding">
			<IonRow className="ion-no-padding">
				<IonCol className="ion-padding">
					{/* <IonAccordionGroup> */}
					{sections.map((section) => {
						return (
							<Fragment key={section.name}>
								{/* Section Accordion (Weapon Stats) */}
								{/* <IonAccordion key={section.name}>
										<IonItem slot="header" color="medium">
											<IonLabel>{section.name}</IonLabel>
										</IonItem>
										<div
											className="ion-padding"
											slot="content"
										> */}
								{section.stats.map((subSection) => {
									return (
										<Fragment key={subSection.name}>
											{/* accordion will be here */}
											{/* <IonItemDivider
												key={subSection.name}
											>
												<IonLabel>
													{subSection.name}
												</IonLabel>
											</IonItemDivider> */}
											<StatsBreakdown
												weaponSlot={slot}
												statSection={subSection.name}
												weapon={weapon}
												weaponStats={weaponStats}
											/>
											{/* <IonItem color="tertiary">
												{subSection.name ===
													"Weapon Damage" && (
													<IonBadge slot="start">
														{wdPercent + "%"}
													</IonBadge>
												)}
												{subSection.name ===
													"Critical Hit Chance" && (
													<IonBadge slot="end">
														{chcPercent + "%"}
													</IonBadge>
												)}
												{subSection.name ===
													"Critical Hit Damage" && (
													<IonBadge slot="start">
														{chdPercent + "%"}
													</IonBadge>
												)}
												{subSection.name ===
													"Headshot Damage" && (
													<IonBadge slot="start">
														{hsdPercent + "%"}
													</IonBadge>
												)}
												<IonLabel>
													Total {subSection.name}
												</IonLabel>
												<IonBadge slot="end">
													{subSection.name ===
														"Weapon Damage" &&
														Math.round(
															weapon.baseDamage *
																(1 +
																	wdPercent /
																		100)
														).toLocaleString(
															"en-US"
														)}
													{subSection.name ===
														"Critical Hit Damage" &&
														Math.round(
															weapon.baseDamage *
																(1 +
																	chdPercent /
																		100)
														).toLocaleString(
															"en-US"
														)}
													{subSection.name ===
														"Headshot Damage" &&
														Math.round(
															weapon.baseDamage *
																(1 +
																	hsdPercent /
																		100)
														).toLocaleString(
															"en-US"
														)}
												</IonBadge>
											</IonItem> */}
										</Fragment>
									);
								})}
								{/* </div>
									</IonAccordion> */}
							</Fragment>
						);
					})}
					{/* </IonAccordionGroup> */}
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default StatsMain;
