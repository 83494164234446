import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import CalculationsMain from "../components/calculations/CalculationsMain";
import Toolbar from "../components/toolbar/Toolbar";

interface CalculationProps {
	props?: any;
}

const Calculations = ({ props }: CalculationProps) => {
	return (
		<>
			<IonPage>
				<IonHeader>
					<Toolbar title="Calculations" />
				</IonHeader>
				<IonContent fullscreen>
					<IonHeader collapse="condense">
						<IonToolbar>
							<IonTitle size="large">Calculations</IonTitle>
						</IonToolbar>
					</IonHeader>
					<CalculationsMain />
				</IonContent>
			</IonPage>
		</>
	);
};

export default Calculations;
