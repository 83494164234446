import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
} from "@ionic/react";
import GearMain from "../components/gear/GearMain";
import WeaponMain from "../components/weapons/WeaponMain";
import "./Home.css";
import gearSlots from "../models/gearSlots";
import SpecializationMain from "../components/specialization/SpecializationMain";
import Toolbar from "../components/toolbar/Toolbar";
import WatchMain from "../components/watch/WatchMain";

interface HomeProps {
	props?: any;
}

const Home = ({ props }: HomeProps) => {
	return (
		<>
			{/* {console.log("In Home fn")} */}
			<IonPage>
				<IonHeader>
					<Toolbar title="Home" />
				</IonHeader>
				<IonContent fullscreen>
					<IonGrid className="ion-no-padding" fixed>
						<IonRow>
							<IonCol size="12">
								<IonGrid>
									<IonRow>
										<SpecializationMain />
									</IonRow>
								</IonGrid>
							</IonCol>
							{/* <IonCol size="12" size-sm="4">
								Tree Bonus
							</IonCol>
							<IonCol size="12" size-sm="4">
								Weapon Bonus
							</IonCol> */}
						</IonRow>
						<IonRow>
							<IonCol size="12" size-sm="4">
								<WeaponMain slot="Primary" />
							</IonCol>
							<IonCol size="12" size-sm="4">
								<WeaponMain slot="Secondary" />
							</IonCol>
							<IonCol size="12" size-sm="4">
								<WeaponMain slot="Pistol" />
							</IonCol>
						</IonRow>
						<IonRow className="ion-align-items-top">
							<IonCol size="12" size-sm="4">
								<IonGrid>
									<IonRow>
										<IonCol>
											<GearMain slot="Mask" />
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<GearMain slot="Vest" />
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<GearMain slot="Holster" />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCol>
							<IonCol size="12" size-sm="4">
								<IonGrid>
									<IonRow>
										<IonCol>
											<GearMain slot="Backpack" />
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<GearMain slot="Gloves" />
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<GearMain slot="Kneepads" />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCol>
							<IonCol size="12" size-sm="4">
								<IonGrid>
									<IonRow>
										<IonCol>
											<IonCard>
												<IonCardHeader>
													<IonCardSubtitle></IonCardSubtitle>
													<IonCardTitle>
														Keener's Watch
													</IonCardTitle>
												</IonCardHeader>
												<IonCardContent></IonCardContent>
											</IonCard>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<WatchMain node="offensive" />
										</IonCol>
										<IonCol>
											<WatchMain node="defensive" />
										</IonCol>
										<IonCol>
											<WatchMain node="utility" />
										</IonCol>
										<IonCol>
											<WatchMain node="handling" />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCol>
						</IonRow>
					</IonGrid>
					{/* <IonGrid className="ion-no-padding" fixed>
						<IonRow>
							<IonCol size="12" size-xl="8">
							<IonCol>
								<IonGrid>
									<IonRow>
										<SpecializationMain />
									</IonRow>
									<IonRow>
										<IonCol size="12" size-sm="4">
											<WeaponMain slot="Primary" />
										</IonCol>
										<IonCol size="12" size-sm="4">
											<WeaponMain slot="Secondary" />
										</IonCol>
										<IonCol size="12" size-sm="4">
											<WeaponMain slot="Pistol" />
										</IonCol>
										{gearSlots.map(
											(slot: any, index: number) => {
												return (
													<IonCol
														key={index}
														size="12"
														size-sm="6"
													>
														<GearMain
															slot={slot.name}
														/>
													</IonCol>
												);
											}
										)}
									</IonRow>
									<IonRow>
										<IonCol size="12">
											Keener's Watch
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol size="12" size-sm="6">
											<WatchMain node="offensive" />
										</IonCol>
										<IonCol size="12" size-sm="6">
											<WatchMain node="defensive" />
										</IonCol>
										<IonCol size="12" size-sm="6">
											<WatchMain node="utility" />
										</IonCol>
										<IonCol size="12" size-sm="6">
											<WatchMain node="handling" />
										</IonCol>
									</IonRow>
								</IonGrid>
							</IonCol>
							<IonCol size="12" size-xl="4">
								Calculations
							</IonCol>
						</IonRow>
					</IonGrid> */}
				</IonContent>
			</IonPage>
		</>
	);
};

export default Home;
