import React, { useEffect, useState } from "react";

import { useIonRouter } from "@ionic/react";

import { useHistory } from "react-router";

// Firebase
import { authentication } from "../firebase";
import {
	// getIdTokenResult,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	signOut,
} from "firebase/auth";

// Helpers
import { getFirebaseDoc, createUser } from "../helpers/firebase";

// Hooks
import useLocalStorage from "../hooks/useLocalStorage";

interface User {
	uid: string;
	username: string | null;
}

const aUser: User = {
	uid: "",
	username: "",
};

const AuthContext = React.createContext({
	user: aUser,
	token: "",
	isLoggedIn: false,
	isAdmin: false,
	isLoading: false,
	login: (token: string) => {},
	logout: () => {},
	onRegister: (email: string, password: string) => {},
	onLogin: (email: string, password: string) => {},
	onLogout: () => {},
});

export const AuthContextProvider = (props: any) => {
	const router = useIonRouter();
	// const history = useHistory();
	const [user, setUser] = useState(aUser);
	const [token, setToken] = useState("");
	const [userId, setUserId] = useLocalStorage("userId", "");
	const [userToken, setUserToken] = useLocalStorage("userToken", "");
	const [userTokenExpiration, setUserTokenExpiration] = useLocalStorage(
		"userTokenExpiration",
		""
	);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [loading, setLoading] = useState(false);

	// const userIsLoggedIn = !!token;

	const format = async (user: User) => {
		// console.log("format: user: ", user);
		const existing = await getFirebaseDoc("users", user.uid);
		// console.log("format: existing: getFirebaseDoc", existing);
		if (existing) {
			const authenticatedUser: User = {
				uid: existing.uid,
				username: existing.email,
			};

			// console.log(existing);
			// check if admin
			if (existing.isAdmin) {
				setIsAdmin(true);
			}

			setUser(authenticatedUser);
			return authenticatedUser;
		}
	};

	const handle = async (raw: any) => {
		if (!raw) {
			localStorage.clear();
			// console.log("clear localStorage", raw);
		} else {
			// console.log("handle fn:", raw);
			const user = await format(raw);
			// console.log("handle fn: user: ", user);
			// setUser(user);
			return user;
		}
	};

	const registerHandler = (username: string, password: string) => {
		createUser(username, password)
			.then((user) => {
				if (user) {
					// router.push("/");
					router.push("/", "forward");
					console.log(user);
				}
			})
			.catch((error) => {
				console.log(error);
				// setError(error);
				// setShowToast(true);
				// setShowAlert(true);
				// router.push(`/register?error=${error}`)
			});
	};

	const handleLogin = (token: any) => {
		setToken(token);
	};

	const loginHandler = (email: string, password: string) => {
		setLoading(true);

		return new Promise((resolve, reject) => {
			signInWithEmailAndPassword(authentication, email, password)
				.then(async (response) => {
					response.user.getIdTokenResult().then((res) => {
						// response.user.getIdToken().then((res) => {
						// console.log(res);
						setUserToken(res.token);
						setUserTokenExpiration(res.claims.exp);
					});
					await handle(response.user);
					setUserId(response.user.uid);
					// console.log(response.user);
					setIsLoggedIn(true);
					resolve(true);
					return setLoading(false);
				})
				.catch((error) => {
					setIsLoggedIn(false);
					setIsAdmin(false);
					reject(error);

					return setLoading(false);
				});
		});
	};

	const handleLogout = () => {
		setToken("");
		router.push("/login", "forward");
	};

	const logoutHandler = async () => {
		setLoading(true);
		// console.log(authentication);
		localStorage.clear();
		// localStorage.removeItem("userToken")
		await signOut(authentication);
		setToken("");
		setIsLoggedIn(false);
		setIsAdmin(false);
		// await handle();
		setLoading(false);
	};

	useEffect(() => {
		async function checkLogin(user: User) {
			// console.log(user.uid);
			const existing = await getFirebaseDoc("users", user.uid);
			if (existing) {
				const authenticatedUser: User = {
					uid: existing.uid,
					username: existing.email,
				};

				// console.log(existing);
				// check if admin
				if (existing.isAdmin) {
					setIsAdmin(true);
				}

				setUser(authenticatedUser);
				return authenticatedUser;
			}
		}

		onAuthStateChanged(authentication, (user) => {
			if (user) {
				// console.log(user);
				setIsLoggedIn(true);
				const theUser: User = {
					uid: user.uid,
					username: user.email,
				};
				checkLogin(theUser);
				// format(theUser);
			} else {
				setIsLoggedIn(false);
				setIsAdmin(false);
			}
		});
		// const userTest = {
		// 	uid: "FpfHE0tpMHhwZvzrnXZrHbKqvhr2",
		// 	email: "shd.builder.dev@fooweb.com",
		// 	emailVerified: false,
		// 	isAnonymous: false,
		// 	providerData: [
		// 		{
		// 			providerId: "password",
		// 			uid: "shd.builder.dev@fooweb.com",
		// 			displayName: null,
		// 			email: "shd.builder.dev@fooweb.com",
		// 			phoneNumber: null,
		// 			photoURL: null,
		// 		},
		// 	],
		// 	stsTokenManager: {
		// 		refreshToken:
		// 			"AOEOula5vONkezW-A8_n1p6tUqRRJmZTFslcB9xr9P44cPwfhQtkT9tZ1HoMGXz3RGSRcFccgOfpMNuy8ubZW2aIIrdAOwHktL5hwmAReqnmyAEeaXKq8l9OsI_FfFvz85O2ZjkBZnpAQPVUW6MrjkX6JwKRrXZiLQOzt5SAOCuhTCQPijRazxbxlYOgCB1NakT5IrEjNk16KIGtKQH0zol6m3Rs78lN2w",
		// 		accessToken:
		// 			"eyJhbGciOiJSUzI1NiIsImtpZCI6IjA2M2E3Y2E0M2MzYzc2MDM2NzRlZGE0YmU5NzcyNWI3M2QwZGMwMWYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZGV2LXNoZC1idWlsZGVyIiwiYXVkIjoiZGV2LXNoZC1idWlsZGVyIiwiYXV0aF90aW1lIjoxNjYwMDE5MTE2LCJ1c2VyX2lkIjoiRnBmSEUwdHBNSGh3WnZ6cm5YWnJIYktxdmhyMiIsInN1YiI6IkZwZkhFMHRwTUhod1p2enJuWFpySGJLcXZocjIiLCJpYXQiOjE2NjAwMTkxMTYsImV4cCI6MTY2MDAyMjcxNiwiZW1haWwiOiJzaGQuYnVpbGRlci5kZXZAZm9vd2ViLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJzaGQuYnVpbGRlci5kZXZAZm9vd2ViLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.e5lMyUwgyRT8GmGPLBtyL1p1PNOZDOR-VIpi8tAQZKyjiw-LzuJ6tNCqk7L_txJV6yCjEGT_qchVW_07o9Zi6PsWC-LzxufGQoUvCdb5ZZb4nzMUrlGjltHla35rb5woLA55IaSw-wxu1ehtBc36yPnBYUqCrPc7KhaHhox4HzZUyR8aOCGAg_EtLqmqRt-dqSOW70nalN30SjMxU5yC4CzLCsxE4Qjys8hKjkRFGUPSIk_8bMY7_qJHO_HlKBcsrHE64mM1YXaYFC4shVjnPTltzi39n8Q-PApV_Tws80OtrT5s28W83PYb0l68v-BSPJaOqdE9-TVcTM-2C1RD4g",
		// 		expirationTime: 1660022716401,
		// 	},
		// 	createdAt: "1656629598854",
		// 	lastLoginAt: "1660019116422",
		// 	apiKey: "AIzaSyDLNWcueCh2gqFUywNjoCaBXiAi1pY8xA4",
		// 	appName: "[DEFAULT]",
		// };
		// console.log(userTest.stsTokenManager.accessToken)
	}, []);

	return (
		<AuthContext.Provider
			value={{
				user: user,
				token: token,
				isLoggedIn: isLoggedIn,
				isAdmin: isAdmin,
				isLoading: loading,
				login: handleLogin,
				logout: handleLogout,
				onRegister: registerHandler,
				onLogin: loginHandler,
				onLogout: logoutHandler,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContext;

// RAW
// {
//     "user": {
//         "uid": "mXhChsqACCXrBRmryO228Ozov8u2",
//         "email": "newemail@fooweb.com",
//         "emailVerified": false,
//         "isAnonymous": false,
//         "providerData": [
//             {
//                 "providerId": "password",
//                 "uid": "newemail@fooweb.com",
//                 "displayName": null,
//                 "email": "newemail@fooweb.com",
//                 "phoneNumber": null,
//                 "photoURL": null
//             }
//         ],
//         "stsTokenManager": {
//             "refreshToken": "AOEOulYDhn7XFsmE0lppEPcuiXPmejhyO-_AGVP5m2cVWTkBht4ldIE6BWTlTq6WtlxpIr1S1wQGe7RDnzvRNA4a6hEjkTsR9ccYWioWJrFUUM6lzmm0MorXhFg5iD5-krjHO9tSp6buydCSGNaDru6i_8Dx2FweFRkL0WZop60bxgGMoBBKOtHBlh5iuUZkYCUhsYFcR_Di64mMMVGKXkJ99Mia51gCiQ",
//             "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjA2M2E3Y2E0M2MzYzc2MDM2NzRlZGE0YmU5NzcyNWI3M2QwZGMwMWYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZGV2LXNoZC1idWlsZGVyIiwiYXVkIjoiZGV2LXNoZC1idWlsZGVyIiwiYXV0aF90aW1lIjoxNjU5OTI5NjAyLCJ1c2VyX2lkIjoibVhoQ2hzcUFDQ1hyQlJtcnlPMjI4T3pvdjh1MiIsInN1YiI6Im1YaENoc3FBQ0NYckJSbXJ5TzIyOE96b3Y4dTIiLCJpYXQiOjE2NTk5Mjk2MDIsImV4cCI6MTY1OTkzMzIwMiwiZW1haWwiOiJuZXdlbWFpbEBmb293ZWIuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbIm5ld2VtYWlsQGZvb3dlYi5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.f5UU4T7jfpAj903g1S29sdYZvhOQYvP5mWY2730ednQH7qD1Zb2CtJjl2VFsb2mtygargev5qqBlA3B9DScIA8mgkswE8KvTOF6ALXpn8IuXd7DDrnFXIDPNoXjjCIXSVuo8tyt2HTIjJZCpG6UHPQnXVh4kl2m9EsmbomCDgNcpp7Q8cSPjwdA4y9AnYWvzx8KfSeomLQDmLkO_MSeCPXwODOlWBJ0mLYfoKfJvmELzEri-UXEa4BHPp1QdM6gM-xnUFqLpeL8FF2NutMl1-Et5vityF9SO-1IBN_2pGm1lZ4BeJcm4fXnnncuMlfXYj9qiKPUyFpfbLot2UF-KKQ",
//             "expirationTime": 1659933202690
//         },
//         "createdAt": "1659913853683",
//         "lastLoginAt": "1659929528284",
//         "apiKey": "AIzaSyDLNWcueCh2gqFUywNjoCaBXiAi1pY8xA4",
//         "appName": "[DEFAULT]"
//     },
//     "providerId": null,
//     "_tokenResponse": {
//         "kind": "identitytoolkit#VerifyPasswordResponse",
//         "localId": "mXhChsqACCXrBRmryO228Ozov8u2",
//         "email": "newemail@fooweb.com",
//         "displayName": "",
//         "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjA2M2E3Y2E0M2MzYzc2MDM2NzRlZGE0YmU5NzcyNWI3M2QwZGMwMWYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZGV2LXNoZC1idWlsZGVyIiwiYXVkIjoiZGV2LXNoZC1idWlsZGVyIiwiYXV0aF90aW1lIjoxNjU5OTI5NjAyLCJ1c2VyX2lkIjoibVhoQ2hzcUFDQ1hyQlJtcnlPMjI4T3pvdjh1MiIsInN1YiI6Im1YaENoc3FBQ0NYckJSbXJ5TzIyOE96b3Y4dTIiLCJpYXQiOjE2NTk5Mjk2MDIsImV4cCI6MTY1OTkzMzIwMiwiZW1haWwiOiJuZXdlbWFpbEBmb293ZWIuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbIm5ld2VtYWlsQGZvb3dlYi5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.f5UU4T7jfpAj903g1S29sdYZvhOQYvP5mWY2730ednQH7qD1Zb2CtJjl2VFsb2mtygargev5qqBlA3B9DScIA8mgkswE8KvTOF6ALXpn8IuXd7DDrnFXIDPNoXjjCIXSVuo8tyt2HTIjJZCpG6UHPQnXVh4kl2m9EsmbomCDgNcpp7Q8cSPjwdA4y9AnYWvzx8KfSeomLQDmLkO_MSeCPXwODOlWBJ0mLYfoKfJvmELzEri-UXEa4BHPp1QdM6gM-xnUFqLpeL8FF2NutMl1-Et5vityF9SO-1IBN_2pGm1lZ4BeJcm4fXnnncuMlfXYj9qiKPUyFpfbLot2UF-KKQ",
//         "registered": true,
//         "refreshToken": "AOEOulYDhn7XFsmE0lppEPcuiXPmejhyO-_AGVP5m2cVWTkBht4ldIE6BWTlTq6WtlxpIr1S1wQGe7RDnzvRNA4a6hEjkTsR9ccYWioWJrFUUM6lzmm0MorXhFg5iD5-krjHO9tSp6buydCSGNaDru6i_8Dx2FweFRkL0WZop60bxgGMoBBKOtHBlh5iuUZkYCUhsYFcR_Di64mMMVGKXkJ99Mia51gCiQ",
//         "expiresIn": "3600"
//     },
//     "operationType": "signIn"
// }

// FORMAT
// {
//     "email": "shd.builder.dev@fooweb.com",
//     "id": "FpfHE0tpMHhwZvzrnXZrHbKqvhr2",
//     "updated_at": {
//         "seconds": 1657042084,
//         "nanoseconds": 533000000
//     },
//     "password": "p@$$W)RD",
//     "created_at": {
//         "seconds": 1656629599,
//         "nanoseconds": 523000000
//     }
// }

// Token Result
// getIdToken()
// eyJhbGciOiJSUzI1NiIsImtpZCI6IjA2M2E3Y2E0M2MzYzc2MDM2NzRlZGE0YmU5NzcyNWI3M2QwZGMwMWYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZGV2LXNoZC1idWlsZGVyIiwiYXVkIjoiZGV2LXNoZC1idWlsZGVyIiwiYXV0aF90aW1lIjoxNjYwMDA0MjkwLCJ1c2VyX2lkIjoiRnBmSEUwdHBNSGh3WnZ6cm5YWnJIYktxdmhyMiIsInN1YiI6IkZwZkhFMHRwTUhod1p2enJuWFpySGJLcXZocjIiLCJpYXQiOjE2NjAwMDQyOTAsImV4cCI6MTY2MDAwNzg5MCwiZW1haWwiOiJzaGQuYnVpbGRlci5kZXZAZm9vd2ViLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJzaGQuYnVpbGRlci5kZXZAZm9vd2ViLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.DBfCXrHTIlwn-rwjeLkI7Ks3WcfVWjV6LGUH2OZ5lxmxl4FsXM7Zdq9--_Olo4UjtVOJt3UoohK4z0cklVGHTKIM8GXQlQ-lYSAiU7Jquclj0e2EHLkF-BqNPX6JL3FdBOugNntts7gHgyecOZ5N2pAmzhcke_xLxbQCP82S_W8vENaqTB8fkBqUdtsmNla_9fynnV3ZzShER5ApeqBDwWa4H1iMiu5_ybX-B_UXA351LhmSN5MwgYGhkH1_88jegp-Vscgsvnaex5E8Oy9auC8QhHMD6arr21CD_VUbiLDUqQR2moWMdgVvoQ0nOkvVH8xmaZWXLDZSmJaox5gHMA

// getIdTokenResult()
// {
//     "claims": {
//         "iss": "https://securetoken.google.com/dev-shd-builder",
//         "aud": "dev-shd-builder",
//         "auth_time": 1660004237,
//         "user_id": "FpfHE0tpMHhwZvzrnXZrHbKqvhr2",
//         "sub": "FpfHE0tpMHhwZvzrnXZrHbKqvhr2",
//         "iat": 1660004237,
//         "exp": 1660007837,
//         "email": "shd.builder.dev@fooweb.com",
//         "email_verified": false,
//         "firebase": {
//             "identities": {
//                 "email": [
//                     "shd.builder.dev@fooweb.com"
//                 ]
//             },
//             "sign_in_provider": "password"
//         }
//     },
//     "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjA2M2E3Y2E0M2MzYzc2MDM2NzRlZGE0YmU5NzcyNWI3M2QwZGMwMWYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZGV2LXNoZC1idWlsZGVyIiwiYXVkIjoiZGV2LXNoZC1idWlsZGVyIiwiYXV0aF90aW1lIjoxNjYwMDA0MjM3LCJ1c2VyX2lkIjoiRnBmSEUwdHBNSGh3WnZ6cm5YWnJIYktxdmhyMiIsInN1YiI6IkZwZkhFMHRwTUhod1p2enJuWFpySGJLcXZocjIiLCJpYXQiOjE2NjAwMDQyMzcsImV4cCI6MTY2MDAwNzgzNywiZW1haWwiOiJzaGQuYnVpbGRlci5kZXZAZm9vd2ViLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJzaGQuYnVpbGRlci5kZXZAZm9vd2ViLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.k_bZP5PKyC5L6pOdXXJZ3LsI7YVHChKyjfQRvbjWVb0XZzWeLXTCboFum7v9p3n2i9b8qhAVr07bsNPjZ7cULHzwT55_SpZJEHUvHMxbbOj9kcWgDwMi_npQNxP29bne09f-pxhwNM7L2gQn2d8te9AQb4QXrqiiTLKVv0MZdRul2sJutX3s9DfOpNDydiUFOEtD45r_seYVfzrPTvpCn2A2eGi6CBXgVU1YA2EP2BAWmI514S5rk45h2QPBjL3Gr29bJU5W_MIPZT-fshx547xsd8o9FaQ8Jp73PktV4XQP4_8HQHFEqec9h8HX1fOjn503BI97Ff0JKXi8HRtw3g",
//     "authTime": "Tue, 09 Aug 2022 00:17:17 GMT",
//     "issuedAtTime": "Tue, 09 Aug 2022 00:17:17 GMT",
//     "expirationTime": "Tue, 09 Aug 2022 01:17:17 GMT",
//     "signInProvider": "password",
//     "signInSecondFactor": null
// }

// https://rnfirebase.io/auth/usage
