import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";

import talentsData from "../../data/TalentsData";

interface CalculationsTalentProps {
	talent?: string;
}

const CalculationsTalent: React.FC<CalculationsTalentProps> = ({ talent }) => {
	const ctxApp = useContext(AppContext);

	const [talent1Desc, setTalent1Desc] = useState<string>();
	const [talent2Desc, setTalent2Desc] = useState<string>();
	const [talent3Desc, setTalent3Desc] = useState<string>();

	useEffect(() => {
		setTalent1Desc((currState) => {
			const talentDesc = talentsData.filter((talent) => {
				return ctxApp.primaryStats.talent === talent.name;
			});
			if (talentDesc.length > 0) {
				return talentDesc[0].desc;
			} else {
				return "No Talent Found named: " + talent;
			}
		});
		setTalent2Desc((currState) => {
			const talentDesc = talentsData.filter((talent) => {
				return ctxApp.secondaryStats.talent === talent.name;
			});
			if (talentDesc.length > 0) {
				return talentDesc[0].desc;
			} else {
				return "No Talent Found named: " + talent;
			}
		});
		setTalent3Desc((currState) => {
			const talentDesc = talentsData.filter((talent) => {
				return ctxApp.pistolStats.talent === talent.name;
			});
			if (talentDesc.length > 0) {
				return talentDesc[0].desc;
			} else {
				return "No Talent Found named: " + talent;
			}
		});
	}, [
		ctxApp.pistolStats.talent,
		ctxApp.primaryStats.talent,
		ctxApp.secondaryStats.talent,
		talent,
	]);

	return (
		<>
			<p>Primary Weapon Talent: {ctxApp.primaryStats.talent}</p>
			<p>{talent1Desc}</p>
			<hr />
			<p>Secondary Weapon Talent: {ctxApp.secondaryStats.talent}</p>
			<p>{talent2Desc}</p>
			<hr />
			<p>Pistol Talent: {ctxApp.pistolStats.talent}</p>
			<p>{talent3Desc}</p>
			<hr />
		</>
	);
};

export default CalculationsTalent;
