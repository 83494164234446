import { IonHeader, IonPage } from "@ionic/react";
import LibraryMain from "../components/library/LibraryMain";
import Toolbar from "../components/toolbar/Toolbar";
import PageContent from "../components/ui/PageContent";

const Library: React.FC = () => {
	return (
		<>
			<IonPage>
				<IonHeader>
					<Toolbar title="Library" />
				</IonHeader>
				<PageContent>
					<LibraryMain />
				</PageContent>
			</IonPage>
		</>
	);
};

export default Library;
