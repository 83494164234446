import {
	IonInput,
	IonItem,
	IonList,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import GearModsData from "../../data/GearModsData";
import Gear from "../../models/gear";
import GearMod from "../../models/gearMod";
import GearStats from "../../models/gearStats";

let initGearMods: GearMod[] = GearModsData;
initGearMods = initGearMods.sort(function (a, b) {
	const nameA = a.stat.toUpperCase(); // ignore upper and lowercase
	const nameB = b.stat.toUpperCase(); // ignore upper and lowercase
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
});

interface GearModProps {
	slot: string;
}

interface SelectChangeEventDetail<T = any> {
	value: T;
}

const GearModSelect = ({ slot }: GearModProps) => {
	const ctxApp = useContext(AppContext);
	const [currentMods, setCurrentMods] = useState(initGearMods);
	const [selectedModName, setSelectedModName] = useState("Select Mod");
	const [selectedModValue, setSelectedModValue] = useState(0);
	const [selectedModMax, setSelectedModMax] = useState(0);
	const [hasModSlot, setHasModSlot] = useState(false);

	const modNameChangeHandler = (e: CustomEvent<SelectChangeEventDetail>) => {
		// console.log(e.detail.value);
		setSelectedModName(e.detail.value);
		// reset input value only if no stats available
		switch (slot.toLowerCase()) {
			case "mask":
				setSelectedModValue(ctxApp.maskStats.modValue);
				break;
			case "backpack":
				setSelectedModValue(ctxApp.backpackStats.modValue);
				break;
			case "vest":
				setSelectedModValue(ctxApp.vestStats.modValue);
				break;
			case "gloves":
				setSelectedModValue(ctxApp.glovesStats.modValue);
				break;
			case "holster":
				setSelectedModValue(ctxApp.holsterStats.modValue);
				break;
			case "kneepads":
				setSelectedModValue(ctxApp.kneepadsStats.modValue);
				break;
			default:
				setSelectedModValue(0);
				break;
		}
		// TODO going to attempt to reset always when the name selector changes
		// setSelectedModValue(0);

		// get/set max attribte value
		// console.log(e.detail);
		if (e.detail.value !== "" && e.detail.value !== "Select Mod") {
			const selectedMod: GearMod[] = initGearMods.filter((mod) => {
				return mod.stat === e.detail.value;
			});
			setSelectedModMax(selectedMod[0].max);
		}
		ctxApp.updateGearStat("modName", slot, e.detail.value);
	};

	const modValueChangeHandler = (e: CustomEvent) => {
		// console.log(e.detail.value);
		setSelectedModValue(e.detail.value);
		ctxApp.updateGearStat("modValue", slot, e.detail.value, "number");
	};

	const interfaceOptions = {
		cssClass: "my-custom-interface",
	};

	useEffect(() => {
		function check(gear: Gear, gearStats: GearStats) {
			// console.log("checking", slot);
			let updatedName = "";
			let updatedMods = initGearMods;
			if (gear.brand === "Crafted") {
				setHasModSlot(true);
			}
			updatedName =
				gearStats.modName === "" ? "Select Mod" : gearStats.modName;
			setSelectedModName(updatedName);
			setSelectedModValue(gearStats.modValue);
			setCurrentMods(updatedMods);
		}

		switch (slot.toLowerCase()) {
			case "mask":
				setHasModSlot(true);
				if (ctxApp.maskGear.name) {
					check(ctxApp.maskGear, ctxApp.maskStats);
				}
				break;
			case "backpack":
				setHasModSlot(true);
				if (ctxApp.backpackGear.name) {
					check(ctxApp.backpackGear, ctxApp.backpackStats);
				}
				break;
			case "vest":
				setHasModSlot(true);
				if (ctxApp.vestGear.name) {
					check(ctxApp.vestGear, ctxApp.vestStats);
				}
				break;
			case "gloves":
				setHasModSlot(false);
				if (ctxApp.glovesGear.name) {
					check(ctxApp.glovesGear, ctxApp.glovesStats);
				}
				break;
			case "holster":
				setHasModSlot(false);
				if (ctxApp.holsterGear.name) {
					check(ctxApp.holsterGear, ctxApp.holsterStats);
				}
				break;
			case "kneepads":
				setHasModSlot(false);
				if (ctxApp.kneepadsGear.name) {
					check(ctxApp.kneepadsGear, ctxApp.kneepadsStats);
				}
				break;
		}
		// console.log(updatedAttributes);
	}, [
		ctxApp.backpackGear.name,
		ctxApp.glovesGear.brand,
		ctxApp.glovesGear.name,
		ctxApp.holsterGear.name,
		ctxApp.kneepadsGear.name,
		ctxApp.maskGear.name,
		ctxApp.maskStats.modName,
		ctxApp.maskStats.modValue,
		ctxApp.vestGear.name,
		slot,
	]);

	if (!hasModSlot) {
		return <></>;
	} else {
		return (
			<>
				<IonList class="ion-no-padding">
					<IonItem class="ion-no-padding">
						<IonSelect
							interfaceOptions={interfaceOptions}
							className="weaponInput"
							interface="popover"
							placeholder={selectedModName}
							value={selectedModName}
							onIonChange={modNameChangeHandler}
						>
							{currentMods.map((mod) => {
								return (
									<IonSelectOption
										key={mod.stat}
										value={mod.stat}
										class="brown-option"
									>
										{mod.stat}
									</IonSelectOption>
								);
							})}
						</IonSelect>
						<IonInput
							class="ion-no-padding"
							className="weaponInput"
							type="number"
							value={selectedModValue}
							max={selectedModMax}
							min="0"
							debounce={2000}
							placeholder={selectedModMax.toString()}
							onIonChange={modValueChangeHandler}
						></IonInput>
					</IonItem>
				</IonList>
			</>
		);
	}
};

export default GearModSelect;
