import BrandsetBonus from "../models/brandsetBonus";
import Gear from "../models/gear";
import GearsetBonus from "../models/gearsetBonus";
import GearStats from "../models/gearStats";
import SpecializationStats from "../models/specializationStats";
import WatchStats from "../models/watchStats";
import Weapon from "../models/weapon";
import WeaponStats from "../models/weaponStats";

export const primaryWeaponHSOS: Weapon = {
	_id: {
		oid: "6247a47dd03ce64590e284bd",
	},
	variant: "Super 90",
	name: "Super 90",
	slot: "P",
	quality: "High End",
	weaponType: "Shotgun",
	baseDamage: 440031,
	optimalRange: 16,
	rpm: 160,
	magSize: 8,
	reloadSpeedMS: 4800,
	hsd: 45,
	core1: "Shotgun Damage",
	core1Max: "15",
	core2: "Damage to Armor",
	core2Max: "12",
	attribute1: "A",
	talent: "A",
	optics: "Short / Long",
	underBarrel: "Side / Short / Long",
	magazine: "Tubular",
	muzzle: "",
};

export const secondaryWeaponHSOS: Weapon = {
	_id: {
		oid: "6247a47dd03ce64590e28493",
	},
	variant: "M1A",
	name: "Classic M1A",
	slot: "P",
	quality: "High End",
	weaponType: "Rifle",
	baseDamage: 205442,
	optimalRange: 60,
	rpm: 180,
	magSize: 10,
	reloadSpeedMS: 3000,
	hsd: 60,
	core1: "Rifle Damage",
	core1Max: "15",
	core2: "Critical Hit Damage",
	core2Max: "17",
	attribute1: "A",
	talent: "A",
	optics: "Short / Long",
	underBarrel: "Side / Short / Long",
	magazine: "MM 7.62",
	muzzle: "",
};

export const pistolWeaponHSOS: Weapon = {
	_id: {
		oid: "6247a47dd03ce64590e2846f",
	},
	variant: "m586",
	name: "Regulus",
	slot: "S",
	quality: "Exotic",
	weaponType: "Pistol",
	baseDamage: 282072,
	optimalRange: 18,
	rpm: 160,
	magSize: 6,
	reloadSpeedMS: 1800,
	hsd: 100,
	core1: "Pistol Damage",
	core1Max: "15",
	core2: "",
	core2Max: "0",
	attribute1: "Headshot Damage",
	talent: "Regicide",
	optics: "Regulus",
	underBarrel: "Regulus",
	magazine: "Regulus",
	muzzle: "Regulus",
};

export const maskGearHSOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e7098",
	},
	slot: "mask",
	quality: "Named",
	name: "Punch Drunk",
	type: "N",
	brand: "Douglas & Harding",
	core: "Weapon Damage",
	attribute1: "Headshot Damage",
	attribute2: "A",
	mod: "O",
	talent: "",
};

export const backpackGearHSOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e7153",
	},
	slot: "backpack",
	quality: "Named",
	name: "The Gift",
	type: "N",
	brand: "Providence Defense",
	core: "Weapon Damage",
	attribute1: "A",
	attribute2: "A",
	mod: "O",
	talent: "Perfect Vigilance",
};

export const vestGearHSOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e707b",
	},
	slot: "vest",
	quality: "Named",
	name: "The Sacrifice",
	type: "N",
	brand: "Providence Defense",
	core: "Weapon Damage",
	attribute1: "A",
	attribute2: "A",
	mod: "O",
	talent: "Perfect Glass Cannon",
};

export const glovesGearHSOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e712f",
	},
	slot: "gloves",
	quality: "High End",
	name: "Improvised Gloves",
	type: "A",
	brand: "Crafted",
	core: "A",
	attribute1: "A",
	attribute2: "A",
	mod: "A",
	talent: "",
};

export const holsterGearHSOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e70ec",
	},
	slot: "holster",
	quality: "Exotic",
	name: "Dodge City Gunslinger's Holster",
	type: "E",
	brand: "Exotic",
	core: "Weapon Damage",
	attribute1: "Weapon Handling",
	attribute2: "Headshot Damage",
	mod: "",
	talent: "Quick Draw",
};

export const kneepadsGearHSOS: Gear = {
	_id: {
		oid: "625ece2d26c312c72a8e70d0",
	},
	slot: "kneepads",
	quality: "Named",
	name: "Fox's Prayer",
	type: "N",
	brand: "Overlord Armaments",
	core: "Weapon Damage",
	attribute1: "Damage to TOC",
	attribute2: "A",
	mod: "",
	talent: "",
};

export const primaryStatsHSOS: WeaponStats = {
	expertise: 0,
	core1Value: 13.4,
	core2Value: 11,
	attribute1: "Damage to TOC",
	attribute1Value: 9,
	talent: "Optimist",
	modOpticName: "C79 Scope (3.4x)",
	modOpticStatPos: "Critical Hit Chance",
	modOpticValuePos: 5,
	modOpticStatNeg: "",
	modOpticValueNeg: 0,
	modMagazineName: "Flexible Tubular Spring",
	modMagazineStatPos: "Reload Speed %",
	modMagazineValuePos: 20,
	modMagazineStatNeg: "",
	modMagazineValueNeg: 0,
	modUnderbarrelName: "Short Grip",
	modUnderbarrelStatPos: "Critical Hit Damage",
	modUnderbarrelValuePos: 5,
	modUnderbarrelStatNeg: "",
	modUnderbarrelValueNeg: 0,
	modMuzzleName: "",
	modMuzzleStatPos: "",
	modMuzzleValuePos: 0,
	modMuzzleStatNeg: "",
	modMuzzleValueNeg: 0,
};

export const secondaryStatsHSOS: WeaponStats = {
	expertise: 0,
	core1Value: 15,
	core2Value: 17,
	attribute1: "Damage to TOC",
	attribute1Value: 10,
	talent: "Boomerang",
	modOpticName: "C79 Scope (3.4x)",
	modOpticStatPos: "Critical Hit Chance",
	modOpticValuePos: 5,
	modOpticStatNeg: "",
	modOpticValueNeg: 0,
	modMagazineName: "Tightly Packed Marksman Mag",
	modMagazineStatPos: "Extra Rounds",
	modMagazineValuePos: 5,
	modMagazineStatNeg: "",
	modMagazineValueNeg: 0,
	modUnderbarrelName: "Laser Pointer",
	modUnderbarrelStatPos: "Critical Hit Chance",
	modUnderbarrelValuePos: 5,
	modUnderbarrelStatNeg: "",
	modUnderbarrelValueNeg: 0,
	modMuzzleName: "",
	modMuzzleStatPos: "",
	modMuzzleValuePos: 0,
	modMuzzleStatNeg: "",
	modMuzzleValueNeg: 0,
};

export const pistolStatsHSOS: WeaponStats = {
	expertise: 12,
	core1Value: 15,
	core2Value: 0,
	attribute1: "Headshot Damage",
	attribute1Value: 10,
	talent: "Regecide",
	modOpticName: "",
	modOpticStatPos: "",
	modOpticValuePos: 0,
	modOpticStatNeg: "",
	modOpticValueNeg: 0,
	modMagazineName: "Engraved Drum",
	modMagazineStatPos: "Reload Speed %",
	modMagazineValuePos: 10,
	modMagazineStatNeg: "",
	modMagazineValueNeg: 0,
	modUnderbarrelName: "",
	modUnderbarrelStatPos: "",
	modUnderbarrelValuePos: 0,
	modUnderbarrelStatNeg: "",
	modUnderbarrelValueNeg: 0,
	modMuzzleName: "Engraved Muzzle",
	modMuzzleStatPos: "Accuracy",
	modMuzzleValuePos: 20,
	modMuzzleStatNeg: "",
	modMuzzleValueNeg: 0,
};

export const maskStatsHSOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 15,
	attribute1Name: "Headshot Damage",
	attribute1Value: 20,
	attribute2Name: "Critical Hit Chance",
	attribute2Value: 6,
	talent: "",
	modName: "Headshot Damage",
	modValue: 10,
};

export const backpackStatsHSOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 15,
	attribute1Name: "Critical Hit Chance",
	attribute1Value: 6,
	attribute2Name: "Headshot Damage",
	attribute2Value: 10,
	talent: "Vigilance",
	modName: "Headshot Damage",
	modValue: 10,
};

export const vestStatsHSOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 15,
	attribute1Name: "Headshot Damage",
	attribute1Value: 10,
	attribute2Name: "Critical Hit Chance",
	attribute2Value: 6,
	talent: "Perfect Glass Cannon",
	modName: "Headshot Damage",
	modValue: 10,
};

export const glovesStatsHSOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 15,
	attribute1Name: "Critical Hit Chance",
	attribute1Value: 6,
	attribute2Name: "Headshot Damage",
	attribute2Value: 10,
	talent: "",
	modName: "Headshot Damage",
	modValue: 10,
};

export const holsterStatsHSOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 15,
	attribute1Name: "Weapon Handling",
	attribute1Value: 8,
	attribute2Name: "Headshot Damage",
	attribute2Value: 10,
	talent: "Quick Draw",
	modName: "",
	modValue: 0,
};

export const kneepadsStatsHSOS: GearStats = {
	expertise: 0,
	core1Name: "Weapon Damage",
	core1Value: 15,
	attribute1Name: "Damage to TOC",
	attribute1Value: 8,
	attribute2Name: "Headshot Damage",
	attribute2Value: 10,
	talent: "",
	modName: "",
	modValue: 0,
};

export const specializationStatsHSOS: SpecializationStats = {
	name: "Sharpshooter",
	bonuses: [
		{
			name: "Pulse Resistance",
			val: 50,
		},
		{
			name: "Health on Kill %",
			val: 30,
		},
		{
			name: "Burn Duration",
			val: 20,
		},
	],
	mmrDamage: 0,
	rifleDamage: 15,
	arDamage: 15,
	smgDamage: 0,
	lmgDamage: 0,
	shotgunDamage: 0,
	pistolDamage: 15,
};

export const watchStatsHSOS: WatchStats = {
	offensive: {
		weaponDamage: 10,
		headshotDamage: 20,
		criticalHitDamage: 20,
		criticalHitChance: 10,
	},
	defensive: {
		totalHealth: 0,
		totalArmor: 0,
		explosiveResistance: 0,
		hazardProtection: 0,
	},

	utility: {
		skillDamage: 0,
		skillRepair: 0,
		skillDuration: 0,
		skillHaste: 0,
	},
	handling: {
		accuracy: 0,
		stability: 0,
		reloadSpeed: 0,
		ammoCapacity: 0,
	},
};

export const brandsetBonusesHSOS: BrandsetBonus[] = [
	// {
	// 	slot: "Kneepads",
	// 	attribute: "Rifle Damage",
	// 	value: 10,
	// },
];

export const gearsetBonusesHSOS: GearsetBonus = {
	mask: {
		attribute: "Pistol Damage",
		value: 20,
	},
	backpack: {
		attribute: "Critical Hit Chance",
		value: 10,
	},
	vest: {
		attribute: "Headshot Damage",
		value: 15,
	},
	gloves: {
		attribute: "",
		value: 0,
	},
	holster: {
		attribute: "",
		value: 0,
	},
	kneepads: {
		attribute: "Rifle Damage",
		value: 10,
	},
};
