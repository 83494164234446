import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { useContext, useEffect, useState } from "react";

import AppContext from "../../context/app-context";
import Weapon from "../../models/weapon";
import WeaponStats from "../../models/weaponStats";

interface WeaponAttributeAProps {
	slot: string;
}

const WeaponAttributeSecondary = ({ slot }: WeaponAttributeAProps) => {
	const ctxApp = useContext(AppContext);
	const [core2Name, setCore2Name] = useState("Secondary Attribute");
	const [core2Value, setCore2Value] = useState<number | undefined | null>(0);
	const [core2Max, setCore2Max] = useState("0");
	const [fieldDisabled, setFieldDisabled] = useState(false);

	const core2ChangeHandler = (e: CustomEvent) => {
		// console.log(e.detail.value);
		setCore2Value(e.detail.value);
		ctxApp.updateWeaponStat("core2Value", slot, e.detail.value, "number");
	};

	useEffect(() => {
		function checkValue(weapon: Weapon, weaponStats: WeaponStats) {
			setCore2Value((currValue) => {
				if (weaponStats.core2Value === 0) {
					return parseInt(weapon.core2Max);
				} else if (weaponStats.core2Value! > 0) {
					return weaponStats.core2Value;
				} else {
					return currValue;
				}
			});
		}

		switch (slot.toLowerCase()) {
			case "primary":
				if (ctxApp.primaryWeapon.name) {
					setCore2Name(ctxApp.primaryWeapon.core2);
					checkValue(ctxApp.primaryWeapon, ctxApp.primaryStats);
					setCore2Max(ctxApp.primaryWeapon.core2Max);
				}
				break;
			case "secondary":
				if (ctxApp.secondaryWeapon.name) {
					setCore2Name(ctxApp.secondaryWeapon.core2);
					checkValue(ctxApp.secondaryWeapon, ctxApp.secondaryStats);
					setCore2Max(ctxApp.secondaryWeapon.core2Max);
				}
				break;
			case "pistol":
				if (ctxApp.pistolWeapon.name) {
					setCore2Name(ctxApp.pistolWeapon.core2);
					checkValue(ctxApp.pistolWeapon, ctxApp.pistolStats);
					setCore2Max(ctxApp.pistolWeapon.core2Max);
				}
				break;
		}
	}, [
		ctxApp.pistolStats,
		ctxApp.pistolWeapon,
		ctxApp.primaryStats,
		ctxApp.primaryWeapon,
		ctxApp.secondaryStats,
		ctxApp.secondaryWeapon,
		slot,
	]);

	useEffect(() => {
		if (slot.toLowerCase() === "pistol") {
			setCore2Name("");
			setFieldDisabled(true);
		}
	}, [slot]);

	return (
		<>
			<IonItem class="ion-no-padding">
				<IonLabel class="ion-no-padding">{core2Name}</IonLabel>
				<IonInput
					disabled={fieldDisabled}
					class="ion-no-padding"
					className="weaponInput"
					type="number"
					step="0.1"
					min="0.0"
					max={core2Max.toString()}
					value={core2Value}
					// debounce={2000}
					placeholder={core2Max.toString()}
					onIonChange={core2ChangeHandler}
				></IonInput>
			</IonItem>
		</>
	);
};

export default WeaponAttributeSecondary;
