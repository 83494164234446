import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonItem,
	IonLabel,
} from "@ionic/react";
import Weapon from "../../models/weapon";

interface WeaponMainSelectCardProps {
	slot: string;
	id: string;
	weapon: Weapon;
	headerClass?: string;
	contentClass?: string;
	sendSelectedWeaponToParent: Function;
}

const WeaponMainSelectCard = ({
	slot,
	id,
	weapon,
	headerClass = "",
	contentClass = "",
	sendSelectedWeaponToParent,
}: WeaponMainSelectCardProps) => {
	const selectWeapon = (selectedWeapon: Weapon) => {
		sendSelectedWeaponToParent(selectedWeapon);
	};

	function getHeaderClass(weapon: Weapon) {
		let className = "";
		className =
			weapon.quality.toLowerCase() === "named"
				? "high-end "
				: weapon.quality.toLowerCase() + " ";
		className += weapon.weaponType.toLowerCase();
		className = className.replaceAll(" ", "-");
		return className;
	}

	return (
		<>
			<IonCard
				id={id}
				button
				onClick={() => {
					selectWeapon(weapon);
				}}
			>
				<IonCardHeader className={getHeaderClass(weapon)}>
					<IonCardSubtitle>
						{weapon.quality} <br /> {weapon.weaponType}
					</IonCardSubtitle>
					{/* <IonCardTitle>{weapon.weaponType}</IonCardTitle> */}
				</IonCardHeader>
				<IonCardContent className="ion-no-padding">
					<IonItem>
						<IonLabel>
							<h2>Name: {weapon.name}</h2>
							{/* <p>Quality: {weapon.quality}</p> */}
							<p>
								Base Damage:{" "}
								{weapon.baseDamage.toLocaleString("en-US")}
							</p>
							<p>RPM: {weapon.rpm}</p>
							<p>Reload: {weapon.reloadSpeedMS / 1000} sec</p>
						</IonLabel>
					</IonItem>
				</IonCardContent>
			</IonCard>
		</>
	);
};

export default WeaponMainSelectCard;
