import { IonInput, IonItem, IonLabel } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";

interface NodeAttribute {
	name: string;
	max: number;
	var: string;
}

interface WatchNodeInputProps {
	node: string;
	attribute: NodeAttribute;
}

const WatchNodeInput: React.FC<WatchNodeInputProps> = ({ node, attribute }) => {
	const ctxApp = useContext(AppContext);

	const [attributeValue, setAttributeValue] = useState<number | undefined>();

	const changeHandler = (e: CustomEvent) => {
		setAttributeValue(e.detail.value);
		ctxApp.updateWatchStat(node!, attribute.var, e.detail.value);
	};

	useEffect(() => {
		if (ctxApp.watchStats[node][attribute.var] !== 0) {
			setAttributeValue(ctxApp.watchStats[node][attribute.var]);
		}
	}, [attribute.var, ctxApp.watchStats, node]);

	return (
		<>
			<IonItem>
				<IonLabel>{attribute.name}</IonLabel>
				<IonInput
					name={attribute.var}
					className="watchInput"
					type="number"
					min={0}
					max={attribute.max}
					debounce={2000}
					value={attributeValue}
					placeholder={attribute.max.toString()}
					onIonChange={changeHandler}
				></IonInput>
			</IonItem>
		</>
	);
};

export default WatchNodeInput;
