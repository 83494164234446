import BrandsetBonus from "../models/brandsetBonus";
import Gear from "../models/gear";
import GearsetBonus from "../models/gearsetBonus";
import GearStats from "../models/gearStats";
import SpecializationStats from "../models/specializationStats";
import WatchStats from "../models/watchStats";
import Weapon from "../models/weapon";
import WeaponStats from "../models/weaponStats";

export const specializationStats: SpecializationStats = {
	name: "",
	bonuses: [
		{
			name: "",
			val: 0,
		},
		{
			name: "",
			val: 0,
		},
		{
			name: "",
			val: 0,
		},
	],
	mmrDamage: 0,
	rifleDamage: 0,
	arDamage: 0,
	smgDamage: 0,
	lmgDamage: 0,
	shotgunDamage: 0,
	pistolDamage: 0,
};

export const watchStats: WatchStats = {
	offensive: {
		weaponDamage: 0,
		headshotDamage: 0,
		criticalHitDamage: 0,
		criticalHitChance: 0,
	},
	defensive: {
		totalHealth: 0,
		totalArmor: 0,
		explosiveResistance: 0,
		hazardProtection: 0,
	},

	utility: {
		skillDamage: 0,
		skillRepair: 0,
		skillDuration: 0,
		skillHaste: 0,
	},
	handling: {
		accuracy: 0,
		stability: 0,
		reloadSpeed: 0,
		ammoCapacity: 0,
	},
};

export const primaryWeapon: Weapon = {
	_id: {
		oid: "",
	},
	variant: "",
	name: "",
	slot: "",
	quality: "",
	weaponType: "",
	baseDamage: 0,
	optimalRange: 0,
	rpm: 0,
	magSize: 0,
	reloadSpeedMS: 0,
	hsd: 0,
	core1: "",
	core1Max: "",
	core2: "",
	core2Max: "",
	attribute1: "",
	talent: "",
	optics: "",
	underBarrel: "",
	magazine: "",
	muzzle: "",
};

export const secondaryWeapon: Weapon = {
	_id: {
		oid: "",
	},
	variant: "",
	name: "",
	slot: "",
	quality: "",
	weaponType: "",
	baseDamage: 0,
	optimalRange: 0,
	rpm: 0,
	magSize: 0,
	reloadSpeedMS: 0,
	hsd: 0,
	core1: "",
	core1Max: "",
	core2: "",
	core2Max: "",
	attribute1: "",
	talent: "",
	optics: "",
	underBarrel: "",
	magazine: "",
	muzzle: "",
};

export const pistolWeapon: Weapon = {
	_id: {
		oid: "",
	},
	variant: "",
	name: "",
	slot: "",
	quality: "",
	weaponType: "",
	baseDamage: 0,
	optimalRange: 0,
	rpm: 0,
	magSize: 0,
	reloadSpeedMS: 0,
	hsd: 0,
	core1: "",
	core1Max: "",
	core2: "",
	core2Max: "",
	attribute1: "",
	talent: "",
	optics: "",
	underBarrel: "",
	magazine: "",
	muzzle: "",
};

export const maskGear: Gear = {
	_id: {
		oid: "",
	},
	slot: "",
	quality: "",
	name: "",
	type: "",
	brand: "",
	core: "",
	attribute1: "",
	attribute2: "",
	mod: "",
	talent: "",
};

export const backpackGear: Gear = {
	_id: {
		oid: "",
	},
	slot: "",
	quality: "",
	name: "",
	type: "",
	brand: "",
	core: "",
	attribute1: "",
	attribute2: "",
	mod: "",
	talent: "",
};

export const vestGear: Gear = {
	_id: {
		oid: "",
	},
	slot: "",
	quality: "",
	name: "",
	type: "",
	brand: "",
	core: "",
	attribute1: "",
	attribute2: "",
	mod: "",
	talent: "",
};

export const glovesGear: Gear = {
	_id: {
		oid: "",
	},
	slot: "",
	quality: "",
	name: "",
	type: "",
	brand: "",
	core: "",
	attribute1: "",
	attribute2: "",
	mod: "",
	talent: "",
};

export const holsterGear: Gear = {
	_id: {
		oid: "",
	},
	slot: "",
	quality: "",
	name: "",
	type: "",
	brand: "",
	core: "",
	attribute1: "",
	attribute2: "",
	mod: "",
	talent: "",
};

export const kneepadsGear: Gear = {
	_id: {
		oid: "",
	},
	slot: "",
	quality: "",
	name: "",
	type: "",
	brand: "",
	core: "",
	attribute1: "",
	attribute2: "",
	mod: "",
	talent: "",
};

export const primaryStats: WeaponStats = {
	expertise: 0,
	core1Value: 0,
	core2Value: 0,
	attribute1: "",
	attribute1Value: 0,
	talent: "",
	modOpticName: "",
	modOpticStatPos: "",
	modOpticValuePos: 0,
	modOpticStatNeg: "",
	modOpticValueNeg: 0,
	modMagazineName: "",
	modMagazineStatPos: "",
	modMagazineValuePos: 0,
	modMagazineStatNeg: "",
	modMagazineValueNeg: 0,
	modUnderbarrelName: "",
	modUnderbarrelStatPos: "",
	modUnderbarrelValuePos: 0,
	modUnderbarrelStatNeg: "",
	modUnderbarrelValueNeg: 0,
	modMuzzleName: "",
	modMuzzleStatPos: "",
	modMuzzleValuePos: 0,
	modMuzzleStatNeg: "",
	modMuzzleValueNeg: 0,
};

export const secondaryStats: WeaponStats = {
	expertise: 0,
	core1Value: 0,
	core2Value: 0,
	attribute1: "",
	attribute1Value: 0,
	talent: "",
	modOpticName: "",
	modOpticStatPos: "",
	modOpticValuePos: 0,
	modOpticStatNeg: "",
	modOpticValueNeg: 0,
	modMagazineName: "",
	modMagazineStatPos: "",
	modMagazineValuePos: 0,
	modMagazineStatNeg: "",
	modMagazineValueNeg: 0,
	modUnderbarrelName: "",
	modUnderbarrelStatPos: "",
	modUnderbarrelValuePos: 0,
	modUnderbarrelStatNeg: "",
	modUnderbarrelValueNeg: 0,
	modMuzzleName: "",
	modMuzzleStatPos: "",
	modMuzzleValuePos: 0,
	modMuzzleStatNeg: "",
	modMuzzleValueNeg: 0,
};

export const pistolStats: WeaponStats = {
	expertise: 0,
	core1Value: 0,
	core2Value: 0,
	attribute1: "",
	attribute1Value: 0,
	talent: "",
	modOpticName: "",
	modOpticStatPos: "",
	modOpticValuePos: 0,
	modOpticStatNeg: "",
	modOpticValueNeg: 0,
	modMagazineName: "",
	modMagazineStatPos: "",
	modMagazineValuePos: 0,
	modMagazineStatNeg: "",
	modMagazineValueNeg: 0,
	modUnderbarrelName: "",
	modUnderbarrelStatPos: "",
	modUnderbarrelValuePos: 0,
	modUnderbarrelStatNeg: "",
	modUnderbarrelValueNeg: 0,
	modMuzzleName: "",
	modMuzzleStatPos: "",
	modMuzzleValuePos: 0,
	modMuzzleStatNeg: "",
	modMuzzleValueNeg: 0,
};

export const maskStats: GearStats = {
	expertise: 0,
	core1Name: "",
	core1Value: 0,
	attribute1Name: "",
	attribute1Value: 0,
	attribute2Name: "",
	attribute2Value: 0,
	talent: "",
	modName: "",
	modValue: 0,
};

export const backpackStats: GearStats = {
	expertise: 0,
	core1Name: "",
	core1Value: 0,
	attribute1Name: "",
	attribute1Value: 0,
	attribute2Name: "",
	attribute2Value: 0,
	talent: "",
	modName: "",
	modValue: 0,
};

export const vestStats: GearStats = {
	expertise: 0,
	core1Name: "",
	core1Value: 0,
	attribute1Name: "",
	attribute1Value: 0,
	attribute2Name: "",
	attribute2Value: 0,
	talent: "",
	modName: "",
	modValue: 0,
};

export const glovesStats: GearStats = {
	expertise: 0,
	core1Name: "",
	core1Value: 0,
	attribute1Name: "",
	attribute1Value: 0,
	attribute2Name: "",
	attribute2Value: 0,
	talent: "",
	modName: "",
	modValue: 0,
};

export const holsterStats: GearStats = {
	expertise: 0,
	core1Name: "",
	core1Value: 0,
	attribute1Name: "",
	attribute1Value: 0,
	attribute2Name: "",
	attribute2Value: 0,
	talent: "",
	modName: "",
	modValue: 0,
};

export const kneepadsStats: GearStats = {
	expertise: 0,
	core1Name: "",
	core1Value: 0,
	attribute1Name: "",
	attribute1Value: 0,
	attribute2Name: "",
	attribute2Value: 0,
	talent: "",
	modName: "",
	modValue: 0,
};

export const brandsetBonuses: BrandsetBonus[] = [
	{
		slot: "",
		attribute: "",
		value: 0,
	},
];

export const gearsetBonuses: GearsetBonus = {
	mask: {
		attribute: "",
		value: 0,
	},
	backpack: {
		attribute: "",
		value: 0,
	},
	vest: {
		attribute: "",
		value: 0,
	},
	gloves: {
		attribute: "",
		value: 0,
	},
	holster: {
		attribute: "",
		value: 0,
	},
	kneepads: {
		attribute: "",
		value: 0,
	},
};
