import { IonHeader, IonTitle, IonToolbar } from "@ionic/react";

interface HeaderProps {
	title: string;
}

const Header = ({ title }: HeaderProps) => {
	return (
		<>
			{" "}
			<IonHeader>
				<IonToolbar>
					<IonTitle>{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
		</>
	);
};

export default Header;
