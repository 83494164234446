import { IonBadge, IonItem, IonLabel } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";

interface StatsExpertiseProps {
	weaponSlot: string;
	showHeader: Function;
}

const StatsExpertise = ({ weaponSlot, showHeader }: StatsExpertiseProps) => {
	const ctxApp = useContext(AppContext);
	const [showAttribute, setShowAttribute] = useState(true);
	const [showStartBadge, setShowStartBadge] = useState(true);
	const [weaponBaseDamage, setWeaponBaseDamage] = useState(0);
	const [expertiseValue, setExpertiseValue] = useState(0);

	useEffect(() => {
		// console.log(weaponSlot);
		function updateParentShowHeader() {
			showHeader("Expertise", !showAttribute);
		}

		function checkWeapon(expertise: number, baseDamage: number) {
			if (expertise === 0) {
				setShowAttribute(false);
			} else {
				setWeaponBaseDamage(baseDamage);
				setExpertiseValue(expertise);
				setShowStartBadge(true);
				setShowAttribute(true);
			}
		}

		switch (weaponSlot.toLowerCase()) {
			case "primary":
				// console.log(ctxApp.primaryStats.expertise);
				checkWeapon(
					ctxApp.primaryStats.expertise,
					ctxApp.primaryWeapon.baseDamage
				);
				break;
			case "secondary":
				checkWeapon(
					ctxApp.secondaryStats.expertise,
					ctxApp.secondaryWeapon.baseDamage
				);
				break;
			case "pistol":
				checkWeapon(
					ctxApp.pistolStats.expertise,
					ctxApp.pistolWeapon.baseDamage
				);
				break;
		}
		updateParentShowHeader();
	}, [
		ctxApp.pistolStats.expertise,
		ctxApp.pistolWeapon.baseDamage,
		ctxApp.primaryStats.expertise,
		ctxApp.primaryWeapon.baseDamage,
		ctxApp.secondaryStats.expertise,
		ctxApp.secondaryWeapon.baseDamage,
		showAttribute,
		showHeader,
		weaponSlot,
	]);

	return (
		<>
			{showAttribute && (
				<IonItem key={""}>
					{showStartBadge && (
						<IonBadge slot="start">{expertiseValue}%</IonBadge>
					)}
					<IonLabel>Expertise</IonLabel>
					{showStartBadge && (
						<IonBadge slot="end">
							{Math.round(
								weaponBaseDamage * (expertiseValue / 100)
							).toLocaleString("en-US")}
						</IonBadge>
					)}
					{!showStartBadge && (
						<IonBadge slot="end">{expertiseValue}%</IonBadge>
					)}
				</IonItem>
			)}
		</>
	);
};

export default StatsExpertise;
