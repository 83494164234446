import { IonBadge, IonItem, IonLabel } from "@ionic/react";
import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import Gear from "../../models/gear";

interface StatsGearsetsProps {
	weaponSlot: string;
	gearSlot: string;
	attribute: string;
}

const StatsGearsets = ({
	weaponSlot,
	gearSlot,
	attribute,
}: StatsGearsetsProps) => {
	const ctxApp = useContext(AppContext);
	const [showAttribute, setShowAttribute] = useState(false);
	const [gearSlotLabel, setGearSlotLabel] = useState("");
	const [showStartBadge, setShowStartBadge] = useState(true);
	const [weaponBaseDamage, setWeaponBaseDamage] = useState(0);
	const [gearValue, setGearValue] = useState(0);
	const [weaponDamage, setWeaponDamage] = useState("");

	useEffect(() => {
		switch (weaponSlot.toLowerCase()) {
			case "primary":
				setWeaponBaseDamage(ctxApp.primaryWeapon.baseDamage);
				setWeaponDamage(ctxApp.primaryWeapon.core1);
				break;
			case "secondary":
				setWeaponBaseDamage(ctxApp.secondaryWeapon.baseDamage);
				setWeaponDamage(ctxApp.secondaryWeapon.core1);
				break;
			case "pistol":
				setWeaponBaseDamage(ctxApp.pistolWeapon.baseDamage);
				setWeaponDamage(ctxApp.pistolWeapon.core1);
				break;
		}
	}, [
		ctxApp.pistolWeapon.baseDamage,
		ctxApp.pistolWeapon.core1,
		ctxApp.primaryWeapon.baseDamage,
		ctxApp.primaryWeapon.core1,
		ctxApp.secondaryWeapon.baseDamage,
		ctxApp.secondaryWeapon.core1,
		weaponSlot,
	]);

	useEffect(() => {
		function checkGear(
			gear: Gear,
			gearsetBonusName: string,
			gearsetBonusValue: number
		) {
			setShowAttribute(true);

			// console.log(weaponDamage);
			switch (attribute) {
				case "Weapon Damage":
					if (gearsetBonusName === weaponDamage) {
						setShowAttribute(true);
						setGearSlotLabel(gearsetBonusName);
						setGearValue(gearsetBonusValue);
					} else {
						setShowAttribute(false);
					}
					break;
				case "Critical Hit Chance":
					if (gearsetBonusName === attribute) {
						setShowAttribute(true);
						setShowStartBadge(false);
						setGearSlotLabel(gear.name);
						setGearValue(gearsetBonusValue);
					} else {
						setShowAttribute(false);
					}
					break;
				case "Critical Hit Damage":
					if (gearsetBonusName === attribute) {
						setShowStartBadge(false);
						setGearSlotLabel(gear.name);
						setGearValue(gearsetBonusValue);
					} else {
						setShowAttribute(false);
					}
					break;
				case "Headshot Damage":
					if (gearsetBonusName === attribute) {
						setShowStartBadge(true);
						setGearSlotLabel(gear.name);
						setGearValue(gearsetBonusValue);
					} else {
						setShowAttribute(false);
					}
					break;
			}
		}
		switch (gearSlot) {
			case "mask":
				setGearSlotLabel("Mask");
				checkGear(
					ctxApp.maskGear,
					ctxApp.gearsetBonuses.mask.attribute,
					ctxApp.gearsetBonuses.mask.value
				);
				break;
			case "backpack":
				checkGear(
					ctxApp.backpackGear,
					ctxApp.gearsetBonuses.backpack.attribute,
					ctxApp.gearsetBonuses.backpack.value
				);
				// checkGear(ctxApp.gearsetBonuses.mask.attribute);
				// if (ctxApp.gearsetBonuses.backpack.attribute === weaponDamage) {
				// 	// checkGear(ctxApp.gearsetBonuses.backpack);
				// } else {
				// 	setShowAttribute(false);
				// }
				break;
			case "vest":
				checkGear(
					ctxApp.vestGear,
					ctxApp.gearsetBonuses.vest.attribute,
					ctxApp.gearsetBonuses.vest.value
				);
				// checkGear(ctxApp.gearsetBonuses.mask.attribute);
				// if (ctxApp.gearsetBonuses.vest.attribute === weaponDamage) {
				// 	// checkGear(ctxApp.gearsetBonuses.vest);
				// } else {
				// 	setShowAttribute(false);
				// }
				break;
			case "gloves":
				checkGear(
					ctxApp.glovesGear,
					ctxApp.gearsetBonuses.gloves.attribute,
					ctxApp.gearsetBonuses.gloves.value
				);
				// checkGear(ctxApp.gearsetBonuses.mask.attribute);
				// if (ctxApp.gearsetBonuses.gloves.attribute === weaponDamage) {
				// 	// checkGear(ctxApp.gearsetBonuses.gloves);
				// } else {
				// 	setShowAttribute(false);
				// }
				break;
			case "holster":
				checkGear(
					ctxApp.holsterGear,
					ctxApp.gearsetBonuses.holster.attribute,
					ctxApp.gearsetBonuses.holster.value
				);
				// checkGear(ctxApp.gearsetBonuses.mask.attribute);
				// if (ctxApp.gearsetBonuses.holster.attribute === weaponDamage) {
				// 	// checkGear(ctxApp.gearsetBonuses.holster);
				// } else {
				// 	setShowAttribute(false);
				// }
				break;
			case "kneepads":
				checkGear(
					ctxApp.kneepadsGear,
					ctxApp.gearsetBonuses.kneepads.attribute,
					ctxApp.gearsetBonuses.kneepads.value
				);
				// checkGear(ctxApp.gearsetBonuses.mask.attribute);
				// if (ctxApp.gearsetBonuses.kneepads.attribute === weaponDamage) {
				// 	// checkGear(ctxApp.gearsetBonuses.kneepads);
				// } else {
				// 	setShowAttribute(false);
				// }
				break;
		}
	}, [
		attribute,
		ctxApp.backpackGear,
		ctxApp.gearsetBonuses.backpack.attribute,
		ctxApp.gearsetBonuses.backpack.value,
		ctxApp.gearsetBonuses.gloves.attribute,
		ctxApp.gearsetBonuses.gloves.value,
		ctxApp.gearsetBonuses.holster.attribute,
		ctxApp.gearsetBonuses.holster.value,
		ctxApp.gearsetBonuses.kneepads.attribute,
		ctxApp.gearsetBonuses.kneepads.value,
		ctxApp.gearsetBonuses.mask.attribute,
		ctxApp.gearsetBonuses.mask.value,
		ctxApp.gearsetBonuses.vest.attribute,
		ctxApp.gearsetBonuses.vest.value,
		ctxApp.glovesGear,
		ctxApp.holsterGear,
		ctxApp.kneepadsGear,
		ctxApp.maskGear,
		ctxApp.vestGear,
		gearSlot,
		weaponDamage,
	]);

	return (
		<Fragment>
			{showAttribute && (
				<IonItem key={gearSlot + "Gearset"}>
					{showStartBadge && (
						<IonBadge slot="start">{gearValue}%</IonBadge>
					)}
					<IonLabel>{gearSlotLabel}</IonLabel>
					{showStartBadge && (
						<IonBadge slot="end">
							{Math.round(
								weaponBaseDamage * (gearValue / 100)
							).toLocaleString("en-US")}
						</IonBadge>
					)}
					{!showStartBadge && (
						<IonBadge slot="end">{gearValue}%</IonBadge>
					)}
				</IonItem>
			)}
		</Fragment>
	);
};

export default StatsGearsets;
