import Specialization from "../models/specialization";

const SpecializationsData: Specialization[] = [
	{
		_id: {
			$oid: "6248804cd03ce64590e28749",
		},
		name: "Demolitionist",
		value: "Demolitionist",
		stats: [
			{
				name: "Pulse Resistance",
				val: 50,
			},
			{
				name: "Explosive Damage",
				val: 10,
			},
			{
				name: "Burn Resistance",
				val: 20,
			},
			{
				name: "",
				val: 0,
			},
		],
		weapons: {
			mmr: {
				name: "Depleted Rounds",
				value: 5,
			},
			rifle: {
				name: "This is My Rifle",
				value: 15,
			},
			ar: {
				name: "E.M.I",
				value: 15,
			},
			lmg: {
				name: "Onslaught",
				value: 5,
			},
			shotgun: {
				name: "Running The Gun",
				value: 15,
			},
			smg: {
				name: "Spray and Pray",
				value: 5,
			},
			pistol: {
				name: "Gunslinger",
				value: 5,
			},
		},
	},
	{
		_id: {
			$oid: "6248804cd03ce64590e2874d",
		},
		name: "Firewall",
		value: "Firewall",
		stats: [
			{
				name: "Pulse Resistance",
				val: 50,
			},
			{
				name: "Health on Kill %",
				val: 30,
			},
			{
				name: "Burn Duration",
				val: 20,
			},
			{
				name: "",
				val: 0,
			},
		],
		weapons: {
			mmr: {
				name: "Depleted Rounds",
				value: 5,
			},
			rifle: {
				name: "This is My Rifle",
				value: 15,
			},
			ar: {
				name: "E.M.I",
				value: 5,
			},
			lmg: {
				name: "Onslaught",
				value: 5,
			},
			shotgun: {
				name: "Running The Gun",
				value: 5,
			},
			smg: {
				name: "Spray and Pray",
				value: 15,
			},
			pistol: {
				name: "Gunslinger",
				value: 15,
			},
		},
	},
	{
		_id: {
			$oid: "6248804cd03ce64590e2874c",
		},
		name: "Gunner",
		value: "Gunner",
		stats: [
			{
				name: "Pulse Resistance",
				val: 50,
			},
			{
				name: "Armor on Kill %",
				val: 15,
			},
			{
				name: "Every 3rd Reload Speed",
				val: 50,
			},
			{
				name: "",
				val: 0,
			},
		],
		weapons: {
			mmr: {
				name: "Depleted Rounds",
				value: 5,
			},
			rifle: {
				name: "This is My Rifle",
				value: 15,
			},
			ar: {
				name: "E.M.I",
				value: 15,
			},
			lmg: {
				name: "Onslaught",
				value: 5,
			},
			shotgun: {
				name: "Running The Gun",
				value: 15,
			},
			smg: {
				name: "Spray and Pray",
				value: 5,
			},
			pistol: {
				name: "Gunslinger",
				value: 5,
			},
		},
	},
	{
		_id: {
			$oid: "6248804cd03ce64590e28747",
		},
		name: "Sharpshooter",
		value: "Sharpshooter",
		stats: [
			{
				name: "Pulse Resistance",
				val: 50,
			},
			{
				name: "Stability",
				val: 15,
			},
			{
				name: "Headshot Damage",
				val: 15,
			},
			{
				name: "",
				val: 0,
			},
		],
		weapons: {
			mmr: {
				name: "Depleted Rounds",
				value: 5,
			},
			rifle: {
				name: "This is My Rifle",
				value: 15,
			},
			ar: {
				name: "E.M.I",
				value: 5,
			},
			lmg: {
				name: "Onslaught",
				value: 5,
			},
			shotgun: {
				name: "Running The Gun",
				value: 15,
			},
			smg: {
				name: "Spray and Pray",
				value: 5,
			},
			pistol: {
				name: "Gunslinger",
				value: 15,
			},
		},
	},
	{
		_id: {
			$oid: "6248804cd03ce64590e28748",
		},
		name: "Survivalist",
		value: "Survivalist",
		stats: [
			{
				name: "Pulse Resistance",
				val: 50,
			},
			{
				name: "Protection from Elites",
				val: 10,
			},
			{
				name: "Outgoing Healing",
				val: 15,
			},
			{
				name: "",
				val: 0,
			},
		],
		weapons: {
			mmr: {
				name: "Depleted Rounds",
				value: 5,
			},
			rifle: {
				name: "This is My Rifle",
				value: 15,
			},
			ar: {
				name: "E.M.I",
				value: 15,
			},
			lmg: {
				name: "Onslaught",
				value: 5,
			},
			shotgun: {
				name: "Running The Gun",
				value: 15,
			},
			smg: {
				name: "Spray and Pray",
				value: 5,
			},
			pistol: {
				name: "Gunslinger",
				value: 5,
			},
		},
	},
	{
		_id: {
			$oid: "6248804cd03ce64590e2874b",
		},
		name: "Technician - Damage",
		value: "Technician - Damage",
		stats: [
			{
				name: "Pulse Resistance",
				val: 50,
			},
			{
				name: "Skill Damage",
				val: 10,
			},
			{
				name: "Skill Tier",
				val: 1,
			},
			{
				name: "Damage to Electronics",
				val: 12,
			},
		],
		weapons: {
			mmr: {
				name: "Depleted Rounds",
				value: 5,
			},
			rifle: {
				name: "This is My Rifle",
				value: 15,
			},
			ar: {
				name: "E.M.I",
				value: 15,
			},
			lmg: {
				name: "Onslaught",
				value: 5,
			},
			shotgun: {
				name: "Running The Gun",
				value: 15,
			},
			smg: {
				name: "Spray and Pray",
				value: 5,
			},
			pistol: {
				name: "Gunslinger",
				value: 5,
			},
		},
	},
	{
		_id: {
			$oid: "6248804cd03ce64590e2874a",
		},
		name: "Technician - Repair",
		value: "Technician - Repair",
		stats: [
			{
				name: "Pulse Resistance",
				val: 50,
			},
			{
				name: "Repair Skills",
				val: 10,
			},
			{
				name: "Skill Tier",
				val: 1,
			},
			{
				name: "Damage to Electronics",
				val: 12,
			},
		],
		weapons: {
			mmr: {
				name: "Depleted Rounds",
				value: 5,
			},
			rifle: {
				name: "This is My Rifle",
				value: 15,
			},
			ar: {
				name: "E.M.I",
				value: 15,
			},
			lmg: {
				name: "Onslaught",
				value: 5,
			},
			shotgun: {
				name: "Running The Gun",
				value: 15,
			},
			smg: {
				name: "Spray and Pray",
				value: 5,
			},
			pistol: {
				name: "Gunslinger",
				value: 5,
			},
		},
	},
];

export default SpecializationsData;
