import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonItem,
	IonLabel,
} from "@ionic/react";
import Gear from "../../models/gear";

interface GearMainSelectCardProps {
	slot: string;
	id: string;
	gear: Gear;
	headerClass?: string;
	contentClass?: string;
	sendSelectedGearToParent: Function;
}

const GearMainSelectCard = ({
	slot,
	id,
	gear,
	headerClass = "",
	contentClass = "",
	sendSelectedGearToParent,
}: GearMainSelectCardProps) => {
	const selectGear = (selectedGear: Gear) => {
		sendSelectedGearToParent(selectedGear);
	};

	function getHeaderClass(gear: Gear) {
		let className = "";
		if (gear.quality.toLowerCase() === "exotic") {
			className += gear.brand.toLowerCase() + " ";
			className += gear.name.toLowerCase();
		} else {
			className += gear.brand.toLowerCase();
		}
		className = className.replaceAll(" &", "");
		className = className.replaceAll(",", "");
		className = className.replaceAll("'", "");
		className = className.replaceAll(".", "");
		className = className.replaceAll(" ", "-");
		return "brand-" + className;
	}

	return (
		<>
			<IonCard
				id={id}
				button
				onClick={() => {
					selectGear(gear);
				}}
			>
				<IonCardHeader className={getHeaderClass(gear)}>
					<IonCardSubtitle>{gear.quality}</IonCardSubtitle>
					{/* <IonCardTitle>{gear.name}</IonCardTitle> */}
				</IonCardHeader>
				<IonCardContent className="ion-no-padding">
					<IonItem>
						<IonLabel>
							<p>Name: {gear.name}</p>
							<p>Core: {gear.core}</p>
						</IonLabel>
					</IonItem>
				</IonCardContent>
			</IonCard>
		</>
	);
};

export default GearMainSelectCard;
