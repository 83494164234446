import {
	IonInput,
	IonItem,
	IonList,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import GearAttributesData from "../../data/GearAttributesData";
import Gear from "../../models/gear";
import GearAttribute from "../../models/gearAttribute";
import GearStats from "../../models/gearStats";

let initGearAttributes: GearAttribute[] = GearAttributesData;
initGearAttributes = initGearAttributes.sort(function (a, b) {
	const nameA = a.name.toUpperCase(); // ignore upper and lowercase
	const nameB = b.name.toUpperCase(); // ignore upper and lowercase
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
});

interface GearAttributeSecondaryProps {
	slot: string;
}

interface SelectChangeEventDetail<T = any> {
	value: T;
}

const GearAttributeSecondary = ({ slot }: GearAttributeSecondaryProps) => {
	const ctxApp = useContext(AppContext);
	const [currentAttributes, setCurrentAttributes] =
		useState(initGearAttributes);
	const [selectedAttribute2Name, setSelectedAttribute2Name] =
		useState("Select Attribute");
	const [selectedAttribute2Value, setSelectedAttribute2Value] = useState<
		number | undefined
	>(undefined);
	const [selectedAttribute2Max, setSelectedAttribute2Max] = useState(0);
	const [showAttribute, setShowAttribute] = useState(true);
	const [selectDisabled, setSelectDisabled] = useState(false);
	const [inputDisabled, setInputDisabled] = useState(false);

	const [selectClassName, setSelectClassName] = useState("gearSelect");
	const [inputClassName, setInputClassName] = useState("gearInput");

	const attribute2NameChangeHandler = (
		e: CustomEvent<SelectChangeEventDetail>
	) => {
		// console.log(e.detail.value);
		setSelectedAttribute2Name(e.detail.value);
		// reset input value only if no stats available
		switch (slot.toLowerCase()) {
			case "mask":
				setSelectedAttribute2Value((currValue) => {
					if (currValue === undefined) {
						return undefined;
					} else {
						return ctxApp.maskStats.attribute2Value!;
					}
				});
				break;
			case "backpack":
				setSelectedAttribute2Value((currValue) => {
					if (currValue === undefined) {
						return undefined;
					} else {
						return ctxApp.backpackStats.attribute2Value!;
					}
				});
				break;
			case "vest":
				setSelectedAttribute2Value((currValue) => {
					if (currValue === undefined) {
						return undefined;
					} else {
						return ctxApp.vestStats.attribute2Value!;
					}
				});
				break;
			case "gloves":
				setSelectedAttribute2Value((currValue) => {
					if (currValue === undefined) {
						return undefined;
					} else {
						return ctxApp.glovesStats.attribute2Value!;
					}
				});
				break;
			case "holster":
				setSelectedAttribute2Value((currValue) => {
					if (currValue === undefined) {
						return undefined;
					} else {
						return ctxApp.holsterStats.attribute2Value!;
					}
				});
				break;
			case "kneepads":
				setSelectedAttribute2Value((currValue) => {
					if (currValue === undefined) {
						return undefined;
					} else {
						return ctxApp.kneepadsStats.attribute2Value!;
					}
				});
				break;
			default:
				setSelectedAttribute2Value(0);
				break;
		}
		// TODO going to attempt to reset always when the name selector changes
		// setSelectedAttribute2Value(0);

		// get/set max attribte value
		// console.log(e.detail);
		if (e.detail.value !== "" && e.detail.value !== "Select Attribute") {
			const selectedAttribute: GearAttribute[] =
				initGearAttributes.filter((attribute) => {
					return attribute.name === e.detail.value;
				});
			setSelectedAttribute2Max(selectedAttribute[0].max);
		}
		ctxApp.updateGearStat("attribute2Name", slot, e.detail.value);
	};

	const attribute2ValueChangeHandler = (e: CustomEvent) => {
		// console.log(e.detail.value);
		setSelectedAttribute2Value(e.detail.value);
		ctxApp.updateGearStat(
			"attribute2Value",
			slot,
			e.detail.value,
			"number"
		);
	};

	const options = {
		cssClass: "my-custom-interface",
	};

	useEffect(() => {
		function check(gear: Gear, gearStats: GearStats) {
			// console.log("checking", slot);
			let updatedName = "";
			let updatedAttributes = initGearAttributes;
			if (gear.quality === "Exotic") {
				const exoticAttribute = initGearAttributes.filter(
					(attribute) => {
						return attribute.name === gear.attribute2;
					}
				);
				setSelectDisabled(true);
				setSelectedAttribute2Name(gear.attribute2);
				setSelectedAttribute2Value((currValue) => {
					if (gearStats.attribute2Value === null) {
						return currValue;
					} else {
						return gearStats.attribute2Value;
					}
				});
				setCurrentAttributes(exoticAttribute);
			} else if (gear.quality === "Gearset") {
				setShowAttribute(false);
				setSelectedAttribute2Name("");
				setSelectedAttribute2Value((currValue) => {
					if (gearStats.attribute2Value === null) {
						return currValue;
					} else {
						return gearStats.attribute2Value;
					}
				});
			} else {
				updatedAttributes = updatedAttributes.filter((attribute) => {
					return (
						attribute.quality === "A" &&
						attribute.name !== gearStats.attribute2Name
					);
				});
				setSelectDisabled(false);
				updatedName =
					gearStats.attribute2Name === ""
						? "Select Attribute"
						: gearStats.attribute2Name;
				setSelectedAttribute2Name(updatedName);
				setSelectedAttribute2Value((currValue) => {
					if (gearStats.attribute2Value === null) {
						return currValue;
					} else {
						return gearStats.attribute2Value;
					}
				});
				setCurrentAttributes(updatedAttributes);
			}
		}

		switch (slot.toLowerCase()) {
			case "mask":
				if (ctxApp.maskGear.name) {
					check(ctxApp.maskGear, ctxApp.maskStats);
				}
				break;
			case "backpack":
				if (ctxApp.backpackGear.name) {
					check(ctxApp.backpackGear, ctxApp.backpackStats);
				}
				break;
			case "vest":
				if (ctxApp.vestGear.name) {
					check(ctxApp.vestGear, ctxApp.vestStats);
				}
				break;
			case "gloves":
				if (ctxApp.glovesGear.name) {
					check(ctxApp.glovesGear, ctxApp.glovesStats);
				}
				break;
			case "holster":
				if (ctxApp.holsterGear.name) {
					check(ctxApp.holsterGear, ctxApp.holsterStats);
				}
				break;
			case "kneepads":
				if (ctxApp.kneepadsGear.name) {
					check(ctxApp.kneepadsGear, ctxApp.kneepadsStats);
				}
				break;
		}
	}, [
		ctxApp.backpackGear,
		ctxApp.backpackStats,
		ctxApp.glovesGear,
		ctxApp.glovesStats,
		ctxApp.holsterGear,
		ctxApp.holsterStats,
		ctxApp.kneepadsGear,
		ctxApp.kneepadsStats,
		ctxApp.maskGear,
		ctxApp.maskStats,
		ctxApp.vestGear,
		ctxApp.vestStats,
		slot,
	]);

	if (!showAttribute) {
		return <></>;
	} else {
		return (
			<>
				<IonList class="ion-no-padding">
					<IonItem class="ion-no-padding">
						{/* <IonLabel>{nameattribute2}: </IonLabel> */}
						<IonSelect
							disabled={selectDisabled}
							interfaceOptions={options}
							className={selectClassName}
							interface="popover"
							placeholder={selectedAttribute2Name}
							value={selectedAttribute2Name}
							onIonChange={attribute2NameChangeHandler}
						>
							{currentAttributes.map(
								(attribute: GearAttribute, index) => {
									return (
										<IonSelectOption
											key={index}
											value={attribute.name}
											class="brown-option"
										>
											{attribute.name}
										</IonSelectOption>
									);
								}
							)}
						</IonSelect>
						<IonInput
							disabled={inputDisabled}
							class="ion-no-padding"
							className={inputClassName}
							type="number"
							value={selectedAttribute2Value}
							max={selectedAttribute2Max}
							min="0"
							debounce={2000}
							placeholder={selectedAttribute2Max.toString()}
							onIonChange={attribute2ValueChangeHandler}
						></IonInput>
					</IonItem>
				</IonList>
			</>
		);
	}
};

export default GearAttributeSecondary;
