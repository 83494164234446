import { IonHeader, IonPage } from "@ionic/react";
import ProfileMain from "../components/profile/ProfileMain";
import Toolbar from "../components/toolbar/Toolbar";
import PageContent from "../components/ui/PageContent";

const Profile: React.FC = () => {
	return (
		<>
			<IonPage>
				<IonHeader>
					<Toolbar title="Profile" />
				</IonHeader>
				<PageContent>
					<ProfileMain />
				</PageContent>
			</IonPage>
		</>
	);
};

export default Profile;
