import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { useContext, useEffect, useState } from "react";

import AppContext from "../../context/app-context";
import Weapon from "../../models/weapon";
import WeaponStats from "../../models/weaponStats";

interface WeaponAttributeProps {
	slot: string;
}

const WeaponAttributePrimary = ({ slot }: WeaponAttributeProps) => {
	const ctxApp = useContext(AppContext);
	const [core1Name, setCore1Name] = useState("Core Attribute");
	const [core1Value, setCore1Value] = useState<number | undefined | null>(0);
	const [core1Max, setCore1Max] = useState("0");

	const core1ChangeHandler = (value: number) => {
		// console.log(value);
		setCore1Value(value);
		ctxApp.updateWeaponStat("core1Value", slot, value, "number");
	};

	useEffect(() => {
		function checkValue(weapon: Weapon, weaponStats: WeaponStats) {
			setCore1Value((currValue) => {
				if (weaponStats.core1Value === 0) {
					return parseInt(weapon.core1Max);
				} else if (weaponStats.core1Value! > 0) {
					return weaponStats.core1Value;
				} else {
					return currValue;
				}
			});
		}

		switch (slot.toLowerCase()) {
			case "primary":
				if (ctxApp.primaryWeapon.name) {
					setCore1Name(ctxApp.primaryWeapon.core1);
					checkValue(ctxApp.primaryWeapon, ctxApp.primaryStats);
					setCore1Max(ctxApp.primaryWeapon.core1Max);
				}
				break;
			case "secondary":
				if (ctxApp.secondaryWeapon.name) {
					setCore1Name(ctxApp.secondaryWeapon.core1);
					checkValue(ctxApp.secondaryWeapon, ctxApp.secondaryStats);
					setCore1Max(ctxApp.secondaryWeapon.core1Max);
				}
				break;
			case "pistol":
				if (ctxApp.pistolWeapon.name) {
					setCore1Name(ctxApp.pistolWeapon.core1);
					checkValue(ctxApp.pistolWeapon, ctxApp.pistolStats);
					setCore1Max(ctxApp.pistolWeapon.core1Max);
				}
				break;
		}
	}, [
		ctxApp.pistolStats,
		ctxApp.pistolWeapon,
		ctxApp.primaryStats,
		ctxApp.primaryWeapon,
		ctxApp.secondaryStats,
		ctxApp.secondaryWeapon,
		slot,
	]);

	// TODO Decimal places

	return (
		<>
			<IonItem class="ion-no-padding">
				<IonLabel class="ion-no-padding">{core1Name}: </IonLabel>
				<IonInput
					class="ion-no-padding"
					className="weaponInput"
					type="number"
					step="0.1"
					min="0.0"
					max={core1Max.toString()}
					value={core1Value}
					// debounce={2000}
					placeholder={core1Max.toString()}
					onIonChange={(e) =>
						core1ChangeHandler(parseFloat(e.detail.value!))
					}
				></IonInput>
			</IonItem>
		</>
	);
};

export default WeaponAttributePrimary;
