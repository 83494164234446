import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
} from "@ionic/react";
import { useState } from "react";
import "./CardBasic.css";

interface CardProps {
	id: string;
	image?: any;
	headerClass?: string;
	contentClass?: string;
	title?: string;
	subTitle?: string;
	content?: any;
}

const CardBasic = ({
	id,
	image = { src: "", alt: "" },
	headerClass = "",
	contentClass = "",
	title,
	subTitle,
	content,
}: CardProps) => {
	const [activeImage, setActiveImage] = useState(image);

	return (
		<>
			<span>{title}</span>
			{/* <IonThumbnail slot="start">
				<img
					className={"gear75"}
					src={"assets/images/mask-1.4-100x92.png"}
					alt={"alt"}
				/>
			</IonThumbnail> */}
			{/* <p className="ion-no-margin cardTitle">{title}</p> */}
			<IonCard id={id} button className="uiCard">
				{activeImage.src !== "" && (
					<img
						src={"assets/images/" + activeImage.src}
						alt={activeImage.alt}
					/>
				)}
				<IonCardHeader className={headerClass}>
					{/* <img
						className="rightImage"
						alt={activeImage.alt}
						src={"assets/images/mask.png"}
					/> */}
					<IonCardSubtitle>{subTitle}</IonCardSubtitle>
					{/* <IonCardTitle>{subTitle}</IonCardTitle> */}
				</IonCardHeader>
				<IonCardContent className="ion-no-padding">
					{content}
				</IonCardContent>
			</IonCard>
		</>
	);
};

export default CardBasic;

// position: absolute;
// top: -65px;
// left: 0px;
