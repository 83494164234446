import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useContext } from "react";
import AppContext from "../../context/app-context";
import CalculationsTalent from "./CalculationsTalent";
import WeaponCalculations from "./CalculationsWeapon";

interface CalculationsMainProps {
	prop?: string;
}

const CalculationsMain = ({ prop }: CalculationsMainProps) => {
	const ctxApp = useContext(AppContext);
	return (
		<>
			<IonGrid>
				<IonRow>
					<IonCol size="12" size-sm="4">
						<WeaponCalculations
							slot="primary"
							weapon={ctxApp.primaryWeapon}
							weaponStats={ctxApp.primaryStats}
						/>
					</IonCol>
					<IonCol size="12" size-sm="4">
						<WeaponCalculations
							slot="secondary"
							weapon={ctxApp.secondaryWeapon}
							weaponStats={ctxApp.secondaryStats}
						/>
					</IonCol>
					<IonCol size="12" size-sm="4">
						<WeaponCalculations
							slot="pistol"
							weapon={ctxApp.pistolWeapon}
							weaponStats={ctxApp.pistolStats}
						/>
					</IonCol>
					<IonCol size="12">
						<CalculationsTalent />
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	);
};

export default CalculationsMain;
