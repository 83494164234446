import { useEffect, useState } from "react";

import "./WatchMain.css";
import WatchNodeInput from "./WatchNodeInput";

interface WatchMainProps {
	node: string;
}

interface NodeAttributes {
	name: string;
	max: number;
	var: string;
}

const nodes = {
	offensive: [
		{ name: "Weapon Damage", max: 10, var: "weaponDamage" },
		{ name: "Critical Hit Chance", max: 10, var: "criticalHitChance" },
		{ name: "Critical Hit Damage", max: 20, var: "criticalHitDamage" },
		{ name: "Headshot Damage", max: 20, var: "headshotDamage" },
	],
	defensive: [
		{ name: "Total Health", max: 10, var: "totalHealth" },
		{ name: "Total Armor", max: 10, var: "totalArmor" },
		{ name: "Explosive Resistance", max: 10, var: "explosiveResistance" },
		{ name: "Hazard Protection", max: 10, var: "hazardProtection" },
	],
	utility: [
		{ name: "Skill Damage", max: 10, var: "skillDamage" },
		{ name: "Skill Repair", max: 10, var: "skillRepair" },
		{ name: "Skill Duration", max: 20, var: "skillDuration" },
		{ name: "Skill Haste", max: 10, var: "skillHaste" },
	],
	handling: [
		{ name: "Accuracy", max: 10, var: "accuracy" },
		{ name: "Stability", max: 10, var: "stability" },
		{ name: "Reload Speed", max: 10, var: "reloadSpeed" },
		{ name: "Ammo Capacity", max: 20, var: "ammoCapacity" },
	],
};

const WatchMain: React.FC<WatchMainProps> = ({ node }) => {
	const [nodeAttributes, setNodeAttributes] = useState<NodeAttributes[]>();
	const [nodeTitle, setNodeTitle] = useState<string>("");

	useEffect(() => {
		if (node !== "") {
			setNodeTitle(node!.charAt(0).toUpperCase() + node!.slice(1));
		}
	}, [node]);

	useEffect(() => {
		switch (node) {
			case "offensive":
				setNodeAttributes(nodes.offensive);
				break;
			case "defensive":
				setNodeAttributes(nodes.defensive);
				break;
			case "utility":
				setNodeAttributes(nodes.utility);
				break;
			case "handling":
				setNodeAttributes(nodes.handling);
				break;
		}
	}, [node]);

	return (
		<>
			{/* {nodeTitle} */}
			{nodeAttributes?.map((attribute, index) => {
				return (
					<WatchNodeInput
						key={index}
						node={node}
						attribute={attribute}
					/>
				);
			})}
		</>
	);
};

export default WatchMain;
