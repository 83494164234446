import { IonBadge, IonItem, IonLabel } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";

interface StatsTalentsProps {
	weaponSlot: string;
	attribute: string;
}

const StatsTalents = ({ weaponSlot, attribute }: StatsTalentsProps) => {
	const ctxApp = useContext(AppContext);
	const [showAttribute, setShowAttribute] = useState(true);
	const [gearSlotLabel, setGearSlotLabel] = useState("");
	const [showStartBadge, setShowStartBadge] = useState(true);
	const [weaponBaseDamage, setWeaponBaseDamage] = useState(0);
	const [weaponType, setWeaponType] = useState("");
	const [specValue, setSpecValue] = useState(0);

	useEffect(() => {
		switch (weaponSlot.toLowerCase()) {
			case "primary":
				setWeaponType(ctxApp.primaryWeapon.weaponType);
				setWeaponBaseDamage(ctxApp.primaryWeapon.baseDamage);
				break;
			case "secondary":
				setWeaponType(ctxApp.secondaryWeapon.weaponType);
				setWeaponBaseDamage(ctxApp.secondaryWeapon.baseDamage);
				break;
			case "pistol":
				setWeaponType(ctxApp.pistolWeapon.weaponType);
				setWeaponBaseDamage(ctxApp.pistolWeapon.baseDamage);
				break;
		}
	}, [
		ctxApp.pistolWeapon.baseDamage,
		ctxApp.pistolWeapon.weaponType,
		ctxApp.primaryWeapon.baseDamage,
		ctxApp.primaryWeapon.weaponType,
		ctxApp.secondaryWeapon.baseDamage,
		ctxApp.secondaryWeapon.weaponType,
		weaponSlot,
	]);

	useEffect(() => {
		setGearSlotLabel("Specialization");

		switch (attribute) {
			case "Weapon Damage":
				switch (weaponType) {
					case "Marksman Rifle":
						setSpecValue(ctxApp.specializationStats.mmrDamage);
						setShowAttribute(true);
						break;
					case "Rifle":
						setSpecValue(ctxApp.specializationStats.rifleDamage);
						setShowAttribute(true);
						break;
					case "Assault Rifle":
						setSpecValue(ctxApp.specializationStats.arDamage);
						setShowAttribute(true);
						break;
					case "LMG":
						setSpecValue(ctxApp.specializationStats.lmgDamage);
						setShowAttribute(true);
						break;
					case "SMG":
						setSpecValue(ctxApp.specializationStats.smgDamage);
						setShowAttribute(true);
						break;
					case "Shotgun":
						setSpecValue(ctxApp.specializationStats.shotgunDamage);
						setShowAttribute(true);
						break;
					case "Pistol":
						setSpecValue(ctxApp.specializationStats.pistolDamage);
						setShowAttribute(true);
						break;
				}
				if (specValue === 0) {
					setShowAttribute(false);
				}
				break;
			case "Critical Hit Chance":
				setShowAttribute(false);
				break;
			case "Critical Hit Damage":
				break;
			case "Headshot Damage":
				break;
		}
	}, [
		attribute,
		ctxApp.specializationStats.arDamage,
		ctxApp.specializationStats.lmgDamage,
		ctxApp.specializationStats.mmrDamage,
		ctxApp.specializationStats.pistolDamage,
		ctxApp.specializationStats.rifleDamage,
		ctxApp.specializationStats.shotgunDamage,
		ctxApp.specializationStats.smgDamage,
		specValue,
		weaponType,
	]);

	return (
		<>
			{showAttribute && (
				<IonItem key={""}>
					{showStartBadge && (
						<IonBadge slot="start">{specValue}%</IonBadge>
					)}
					<IonLabel>{gearSlotLabel}</IonLabel>
					{showStartBadge && (
						<IonBadge slot="end">
							{Math.round(
								weaponBaseDamage * (specValue / 100)
							).toLocaleString("en-US")}
						</IonBadge>
					)}
					{!showStartBadge && (
						<IonBadge slot="end">{specValue}%</IonBadge>
					)}
				</IonItem>
			)}
		</>
	);
};

export default StatsTalents;
