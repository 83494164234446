const GearAttributesData = [
	{
		_id: {
			$oid: "624877bbd03ce64590e2865d",
		},
		quality: "A",
		type: "O",
		name: "Weapon Handling",
		stat: "Weapon Handling",
		statFactor: "number",
		max: 8,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2865e",
		},
		quality: "A",
		type: "O",
		name: "Critical Hit Chance",
		stat: "Critical Hit Chance",
		statFactor: "number",
		max: 6,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2865f",
		},
		quality: "A",
		type: "O",
		name: "Critical Hit Damage",
		stat: "Critical Hit Damage",
		statFactor: "number",
		max: 12,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28660",
		},
		quality: "A",
		type: "O",
		name: "Headshot Damage",
		stat: "Headshot Damage",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28661",
		},
		quality: "A",
		type: "D",
		name: "Armor Regeneration",
		stat: "Armor Regeneration",
		statFactor: "number",
		max: 4925,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28662",
		},
		quality: "A",
		type: "D",
		name: "Hazard Protection",
		stat: "Hazard Protection",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28663",
		},
		quality: "A",
		type: "D",
		name: "Health",
		stat: "Health",
		statFactor: "number",
		max: 18935,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28664",
		},
		quality: "A",
		type: "D",
		name: "Explosive Resistance",
		stat: "Explosive Resistance",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28665",
		},
		quality: "A",
		type: "U",
		name: "Skill Damage",
		stat: "Skill Damage",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28666",
		},
		quality: "A",
		type: "U",
		name: "Repair Skills",
		stat: "Repair Skills",
		statFactor: "number",
		max: 20,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28667",
		},
		quality: "A",
		type: "U",
		name: "Skill Haste",
		stat: "Skill Haste",
		statFactor: "number",
		max: 12,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28668",
		},
		quality: "A",
		type: "U",
		name: "Status Effects",
		stat: "Status Effects",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28669",
		},
		quality: "N",
		type: "O",
		name: "Damage to TOC",
		stat: "Damage to TOC",
		statFactor: "number",
		max: 8,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2866a",
		},
		quality: "N",
		type: "O",
		name: "Damage to Armor",
		stat: "Damage to Armor",
		statFactor: "number",
		max: 8,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2866b",
		},
		quality: "N",
		type: "U",
		name: "Scanner Pulse Haste",
		stat: "Scanner Pulse Haste",
		statFactor: "number",
		max: 100,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2866c",
		},
		quality: "N",
		type: "O",
		name: "Health Damage",
		stat: "Health Damage",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2866d",
		},
		quality: "N",
		type: "O",
		name: "Headshot Damage",
		stat: "Headshot Damage",
		statFactor: "number",
		max: 20,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2866e",
		},
		quality: "N",
		type: "O",
		name: "Melee Damage",
		stat: "Melee Damage",
		statFactor: "number",
		max: 500,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2866f",
		},
		quality: "N",
		type: "D",
		name: "Armor on Kill %",
		stat: "Armor on Kill %",
		statFactor: "percent",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28670",
		},
		quality: "N",
		type: "U",
		name: "Skill Health",
		stat: "Skill Health",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28671",
		},
		quality: "N",
		type: "U",
		name: "Status Effects ",
		stat: "Status Effects ",
		statFactor: "number",
		max: 15,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28672",
		},
		quality: "N",
		type: "U",
		name: "Shield Health",
		stat: "Shield Health",
		statFactor: "number",
		max: 50,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28673",
		},
		quality: "N",
		type: "O",
		name: "Ammo Capacity",
		stat: "Ammo Capacity",
		statFactor: "number",
		max: 20,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28674",
		},
		quality: "N",
		type: "D",
		name: "Armor Regeneration %",
		stat: "Armor Regeneration %",
		statFactor: "percent",
		max: 1,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28675",
		},
		quality: "E",
		type: "O",
		name: "Critical Hit Chance",
		stat: "Critical Hit Chance",
		statFactor: "number",
		max: 6,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28676",
		},
		quality: "E",
		type: "O",
		name: "Critical Hit Damage",
		stat: "Critical Hit Damage",
		statFactor: "number",
		max: 12,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28677",
		},
		quality: "E",
		type: "U",
		name: "Skill Haste",
		stat: "Skill Haste",
		statFactor: "number",
		max: 12,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28678",
		},
		quality: "E",
		type: "U",
		name: "Skill Damage",
		stat: "Skill Damage",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28679",
		},
		quality: "E",
		type: "D",
		name: "Explosive Resistance",
		stat: "Explosive Resistance",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2867a",
		},
		quality: "E",
		type: "D",
		name: "Armor Regeneration",
		stat: "Armor Regeneration",
		statFactor: "number",
		max: 4925,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2867b",
		},
		quality: "E",
		type: "U",
		name: "Repair Skills",
		stat: "Repair Skills",
		statFactor: "number",
		max: 20,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2867c",
		},
		quality: "E",
		type: "O",
		name: "Weapon Handling",
		stat: "Weapon Handling",
		statFactor: "number",
		max: 8,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2867d",
		},
		quality: "E",
		type: "O",
		name: "Headshot Damage",
		stat: "Headshot Damage",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2867e",
		},
		quality: "E",
		type: "U",
		name: "Status Effects",
		stat: "Status Effects",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e2867f",
		},
		quality: "E",
		type: "D",
		name: "Hazard Protection",
		stat: "Hazard Protection",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28680",
		},
		quality: "E",
		type: "D",
		name: "Health",
		stat: "Health",
		statFactor: "number",
		max: 18935,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28681",
		},
		quality: "N",
		type: "O",
		name: "Pistol Damage",
		stat: "Pistol Damage",
		statFactor: "number",
		max: 9,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28682",
		},
		quality: "N",
		type: "U",
		name: "Skill Efficiency",
		stat: "Skill Efficiency",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28683",
		},
		quality: "A",
		type: "O",
		name: "Stability",
		stat: "Stability",
		statFactor: "number",
		max: 10,
	},
	{
		_id: {
			$oid: "624877bbd03ce64590e28684",
		},
		quality: "A",
		type: "O",
		name: "Accuracy",
		stat: "Accuracy",
		statFactor: "number",
		max: 10,
	},
];

export default GearAttributesData;
