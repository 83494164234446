import { IonContent, IonHeader, IonPage } from "@ionic/react";
import { Fragment } from "react";
import BuildMain from "../components/build/BuildMain";
import Toolbar from "../components/toolbar/Toolbar";

interface BuildProps {
	id?: object;
}

const Build: React.FC<BuildProps> = ({ id }) => {
	// console.log(buildId);
	return (
		<>
			<IonPage>
				<IonHeader>
					<Toolbar title="Build Image" />
				</IonHeader>
				<IonContent>
					<BuildMain />
				</IonContent>
			</IonPage>
		</>
	);
};

export default Build;
