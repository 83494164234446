import {
	IonAvatar,
	IonButton,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	useIonLoading,
} from "@ionic/react";
import { useHistory } from "react-router";
import { eyeOutline, repeatSharp } from "ionicons/icons";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../context/app-context";

// testing
import {
	primaryWeaponHSOS,
	primaryStatsHSOS,
	secondaryWeaponHSOS,
	secondaryStatsHSOS,
	pistolWeaponHSOS,
	pistolStatsHSOS,
	maskGearHSOS,
	maskStatsHSOS,
	backpackGearHSOS,
	backpackStatsHSOS,
	vestGearHSOS,
	vestStatsHSOS,
	glovesGearHSOS,
	glovesStatsHSOS,
	holsterGearHSOS,
	holsterStatsHSOS,
	kneepadsGearHSOS,
	kneepadsStatsHSOS,
	watchStatsHSOS,
	specializationStatsHSOS,
	brandsetBonusesHSOS,
	gearsetBonusesHSOS,
} from "../../data/Loadout-HeadhshotOS";

import {
	primaryWeaponCritOS,
	primaryStatsCritOS,
	secondaryWeaponCritOS,
	secondaryStatsCritOS,
	pistolWeaponCritOS,
	pistolStatsCritOS,
	maskGearCritOS,
	maskStatsCritOS,
	backpackGearCritOS,
	backpackStatsCritOS,
	vestGearCritOS,
	vestStatsCritOS,
	glovesGearCritOS,
	glovesStatsCritOS,
	holsterGearCritOS,
	holsterStatsCritOS,
	kneepadsGearCritOS,
	kneepadsStatsCritOS,
	watchStatsCritOS,
	specializationStatsCritOS,
	brandsetBonusesCritOS,
	gearsetBonusesCritOS,
} from "../../data/Loadout-CritOS";

interface LibraryMainProps {
	props?: string;
}

const builds = [
	{
		id: "1",
		title: "DH Raid DPS below SHD 500",
		desc: "Standard DPS build for agents below SHD 500. Need to get 50% CHC and 50% CHD. Three pieces Providence Defense (ideally with Chest and Backpack). One piece Ceska for the CHC. Optional with Coyote's if you have it. All secondary and mods should be CHC and CHD.",
		spec: "demolitionist",
	},
	{
		id: "2",
		title: "IH Raid DPS below SHD 500",
		desc: "desc",
		spec: "demolitionist",
	},
	{
		id: "3",
		title: "Regulus Headshot One-Shot Build",
		desc: "desc",
		spec: "sharpshooter",
	},
];

const LibraryMain: React.FC<LibraryMainProps> = ({ props }) => {
	const ctxApp = useContext(AppContext);

	const history = useHistory();
	const [present, dismiss] = useIonLoading();

	const loadBuildHandler = (buildId: string) => {
		// console.log("Loading Build: Id: ", buildId);
		present({
			message: "Loading...",
			duration: 5000,
			spinner: "lines-sharp-small",
		});
		// "bubbles" ｜ "circles" ｜ "circular" ｜ "crescent" ｜ "dots" ｜ "lines" ｜ "lines-sharp" ｜ "lines-sharp-small" ｜ "lines-small" ｜ null ｜ undefined
		// console.log("Loading Headshot OS Build");

		switch (buildId) {
			case "1":
				ctxApp.loadBuild("primary", primaryWeaponHSOS);
				ctxApp.loadBuild("primaryStats", primaryStatsHSOS);
				ctxApp.loadBuild("secondary", secondaryWeaponHSOS);
				ctxApp.loadBuild("secondaryStats", secondaryStatsHSOS);
				ctxApp.loadBuild("pistol", pistolWeaponHSOS);
				ctxApp.loadBuild("pistolStats", pistolStatsHSOS);
				ctxApp.loadBuild("mask", maskGearHSOS);
				ctxApp.loadBuild("maskStats", maskStatsHSOS);
				ctxApp.loadBuild("backpack", backpackGearHSOS);
				ctxApp.loadBuild("backpackStats", backpackStatsHSOS);
				ctxApp.loadBuild("vest", vestGearHSOS);
				ctxApp.loadBuild("vestStats", vestStatsHSOS);
				ctxApp.loadBuild("gloves", glovesGearHSOS);
				ctxApp.loadBuild("glovesStats", glovesStatsHSOS);
				ctxApp.loadBuild("holster", holsterGearHSOS);
				ctxApp.loadBuild("holsterStats", holsterStatsHSOS);
				ctxApp.loadBuild("kneepads", kneepadsGearHSOS);
				ctxApp.loadBuild("kneepadsStats", kneepadsStatsHSOS);
				ctxApp.loadBuild("watchStats", watchStatsHSOS);
				ctxApp.loadBuild(
					"specializationStats",
					specializationStatsHSOS
				);
				ctxApp.loadBuild("brandsetBonuses", brandsetBonusesHSOS);
				ctxApp.loadBuild("gearsetBonuses", gearsetBonusesHSOS);
				break;
			case "2":
				ctxApp.loadBuild("primary", primaryWeaponCritOS);
				ctxApp.loadBuild("primaryStats", primaryStatsCritOS);
				ctxApp.loadBuild("secondary", secondaryWeaponCritOS);
				ctxApp.loadBuild("secondaryStats", secondaryStatsCritOS);
				ctxApp.loadBuild("pistol", pistolWeaponCritOS);
				ctxApp.loadBuild("pistolStats", pistolStatsCritOS);
				ctxApp.loadBuild("mask", maskGearCritOS);
				ctxApp.loadBuild("maskStats", maskStatsCritOS);
				ctxApp.loadBuild("backpack", backpackGearCritOS);
				ctxApp.loadBuild("backpackStats", backpackStatsCritOS);
				ctxApp.loadBuild("vest", vestGearCritOS);
				ctxApp.loadBuild("vestStats", vestStatsCritOS);
				ctxApp.loadBuild("gloves", glovesGearCritOS);
				ctxApp.loadBuild("glovesStats", glovesStatsCritOS);
				ctxApp.loadBuild("holster", holsterGearCritOS);
				ctxApp.loadBuild("holsterStats", holsterStatsCritOS);
				ctxApp.loadBuild("kneepads", kneepadsGearCritOS);
				ctxApp.loadBuild("kneepadsStats", kneepadsStatsCritOS);
				ctxApp.loadBuild("watchStats", watchStatsCritOS);
				ctxApp.loadBuild(
					"specializationStats",
					specializationStatsCritOS
				);
				ctxApp.loadBuild("brandsetBonuses", brandsetBonusesCritOS);
				ctxApp.loadBuild("gearsetBonuses", gearsetBonusesCritOS);
				break;
		}
		history.replace("/home");
	};

	return (
		<>
			<IonContent>
				<IonList>
					{builds.map((build, index) => {
						return (
							<IonItem key={index}>
								<IonAvatar slot="start">
									<img
										alt=""
										src={
											"/assets/images/" +
											build.spec +
											".png"
										}
									/>
								</IonAvatar>
								<IonLabel>
									<h2>{build.title}</h2>
									<h3>Category?</h3>
									<p>{build.desc}</p>
									<IonButton
										onClick={() =>
											loadBuildHandler(build.id)
										}
									>
										<IonIcon
											slot="start"
											icon={repeatSharp}
										/>
										Load
									</IonButton>
									<Link to={"/build/" + build.id}>
										<IonButton>
											<IonIcon
												slot="start"
												icon={eyeOutline}
											/>
											View
										</IonButton>
									</Link>
								</IonLabel>
							</IonItem>
						);
					})}
					{/* <IonItem>
						<Link to="/library/builds/2">
							<IonLabel>
								Standard DPS Build below SHD 500 (Iron Horse
								w/TamperProof)
							</IonLabel>
						</Link>
					</IonItem>
					<IonItem>
						<IonButton
							onClick={(e) => {
								e.preventDefault();
								// history.push("/dashboard/users/3");
							}}
						>
							<IonLabel>Build 3</IonLabel>
						</IonButton>
					</IonItem> */}
				</IonList>
			</IonContent>
		</>
	);
};

export default LibraryMain;
