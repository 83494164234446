const TalentsData = [
	{
		_id: {
			$oid: "624872fad03ce64590e28508",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "Ablative Nano-Plating",
		label: "Ablative Nano-Plating",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Whenever your or any ally's armor breaks, they gain 80% of your armor as bonus armor for 10s.\n\nCooldown per ally: 45s.\nKilling an enemy with your specialization weapon removes this cooldown for all allies.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2850d",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Apex Predator",
		label: "Apex Predator",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Enemies within 15m receive a debuff, amplifying your weapon damage against them by 20%. Killing a debuffed enemy with your weapon disorients other enemies within 5m, and amplifies weapon damage by 5% for 10s, stacking up to 5 times.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2850e",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Best Buds",
		label: "Best Buds",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increase the damage buff from 25% to 50%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28510",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Bloodsucker",
		label: "Bloodsucker",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Killing an enemy adds and refreshes a stack of +10% bonus armor for 10s. Max stack is 10.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28511",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Braced",
		label: "Braced",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "While in cover weapon handling is increased by +45%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28517",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Complete Uptime",
		label: "Complete Uptime",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Cancelling your skills will reset their cooldown.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28518",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Composure",
		label: "Composure",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "While in cover, increases total weapon damage by 15%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28520",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Endless Hunger",
		label: "Endless Hunger",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases the duration of Apex Predator stacks from 10s to 30s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28524",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Feedback Loop",
		label: "Feedback Loop",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Whenever you use or cancel a skill, your other skill's cooldown is automatically reduced by 30s while increasing total skill damage and repair by 10% for 20s. Feedback Loop can occur once every 20s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2852b",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Headhunter",
		label: "Headhunter",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "After killing an enemy with a headshot, your next weapon hit within 30s deals an additional 125% of that killing blow's damage. Damage is capped to 800% of your weapon damage. This is raised to 1250% if your headshot damage is greater than 150%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28532",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Leadership",
		label: "Leadership",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Performing a cover to cover grants 15% of your armor as bonus armor to you and all allies for 10s. This is doubled if you end within 10m of an enemy. Cooldown: 10s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28533",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Mad Bomber",
		label: "Mad Bomber",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: 'Grenade radius is increased by 50%. Grenades that kill an enemy are refunded. Grenades can now be "cooked" by holding the fire button, making them explode earlier. Gain +15% bonus armor while aiming grenades.',
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28539",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Opportunistic",
		label: "Opportunistic",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Enemies you hit with shotguns and marksman rifles amplifies the damage they take by 10% from all sources for 5s. REQUIRES a Marksman Rifle or Shotgun Drawn",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2853c",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Overwhelming Force",
		label: "Overwhelming Force",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases the radius of disorient on Apex Predator kills from 5m to 10m.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2853d",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "Pack Instincts",
		label: "Pack Instincts",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "You and all allies gain a bonus based on the distance of the last enemy you hit.\n\n0-15m: +25% critical hit damage.\n15-25m: +10% critical hit damage and +10% critical hit chance.\n25m+: +25% critical hit chance",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2853e",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Parabellum Rounds",
		label: "Parabellum Rounds",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Hollow-Point Ammo weapon damage amplification to 35%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2853f",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "Parkour!",
		label: "Parkour!",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Performing a cover to cover or vaulting reloads your drawn weapon and grants +25% bonus armor for 5s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28540",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Patriotic Boost",
		label: "Patriotic Boost",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: 'Increases "Red, White and Blue" debuff strength. Red: 8% to 12%. White: 2% to 3%. Blue: 8% to 12%',
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2854a",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfect Overwatch",
		label: "Perfect Overwatch",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "After staying in cover for 8s, increases you and your allies�?? total weapon and skill damage by 12% as long as you remain in cover or in a cover-to-cover move.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2854d",
		},
		type: "gear",
		slot: "Backpack",
		quality: "N",
		name: "Perfect Tech Support",
		label: "Perfect Tech Support",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Skill kills increase total skill damage by 25% for 27s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28551",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfectly Efficient",
		label: "Perfectly Efficient",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Using an armor kit has a 75% chance to not consume the armor kit. Specialization armor kit bonuses are increased by 100%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2855b",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "Quick Draw",
		label: "Quick Draw",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "While your pistol is holstered, gain a stacking buff every 0.3s, up to 100.\n\nWhen you swap to it, your first shot consumes the buff and deals +10% damage per stack. This deals headshot damage to anywhere you hit. \n\nREQUIRES: Pistol Equipped",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2855f",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Rules of Engagement",
		label: "Rules of Engagement",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Killing a status effected enemy grants Hollow-Point Ammo for your active weapon. Hollow-Point Ammo amplifies weapon damage by 20% and applies bleed on hit.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28560",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Safeguard",
		label: "Safeguard",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "While at full armor, increases total skill repair by 100%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28562",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Short Circuit",
		label: "Short Circuit",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Decreases Feedback Loop cooldown from 20s to 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28568",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "Stand Your Ground",
		label: "Stand Your Ground",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Cannot be staggered by explosions.\n\nIncreases total weapon damage by 3% each second you are not moving. Stacks up to 10 until you start moving. All stacks lost 10s after moving.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2857a",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Vigilance",
		label: "Vigilance",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases total weapon damage by 25%. Taking damage disables this buff for 4s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2857c",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Wicked",
		label: "Wicked",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Applying a status effect increases total weapon damage by 18% for 20s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28584",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Perpetuation",
		label: "Perpetuation",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Headshots grant +50% status effect damage and duration to the next status effect you apply. Cooldown: 20s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2858a",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Steady Handed",
		label: "Steady Handed",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Hits grant a stack of +1% Accuracy and Stability. At 100 stacks, consumes them to refill the magazine.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28592",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Finisher",
		label: "Finisher",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: true,
		shotgun: false,
		desc: "Swapping from this weapon within 10s of killing an enemy grants 30% critical hit chance and 30% critical hit damage for 15s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28593",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Salvage",
		label: "Salvage",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: true,
		shotgun: false,
		desc: "Killing a target has a +70% chance to refill the magazine.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28599",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Unwavering",
		label: "Unwavering",
		ar: false,
		rifle: false,
		mmr: false,
		smg: true,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Swapping to this weapon grants +300% weapon handling for 5s. Kills refresh the buff. Swapping away disables this from all weapons for 5s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2859d",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Rifleman",
		label: "Rifleman",
		ar: false,
		rifle: true,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Landing headshots adds a stack of bonus +10% weapon damage for 5s. Max stack is 5. Additional headshots refresh the duration.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2859e",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Near Sighted",
		label: "Near Sighted",
		ar: true,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Receive +80% stability at the cost of -35% optimal range.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2859f",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "On Empty",
		label: "On Empty",
		ar: true,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Reloading from empty grants +30% weapon handling for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285a0",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Overflowing",
		label: "Overflowing",
		ar: true,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Every 3 reloads from empty increases your base magazine capacity by 100%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285a6",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Bullet Hell",
		label: "Bullet Hell",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "This weapon never needs to be reloaded. For every 100 bullets that hit an enemy, replenish some ammo to you and your allies' reserves.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285b2",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Boomerang",
		label: "Perfect Boomerang",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Critical hits have a 75% chance to return the bullet to the magazine. If a bullet is returned to the magazine, the next shot has +50% increased damage.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285b3",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Breadbasket",
		label: "Perfect Breadbasket",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Landing body shots adds a stack of bonus +50% headshot damage to the next headshot for 10s. Max stack is 2.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285b4",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Distance",
		label: "Perfect Distance",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "+100% optimal range.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285bd",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Near Sighted",
		label: "Perfect Near Sighted",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Receive +100% stability at the cost of -35% optimal range.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285be",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Optimist",
		label: "Perfect Optimist",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Weapon damage is increased by +4% for every 10% ammo missing from the magazine.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285c6",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Ranger",
		label: "Perfect Ranger",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Amplifies weapon damage by 2% for every 4m you are away from your target.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285db",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Future Perfection",
		label: "Future Perfection",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Weapon kills grant +1 skill tier for 19s. Stacks up to 3 times. Weapon kills at skill tier 6 grant overcharge for 15s. Overcharge cooldown: 90s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285df",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Payment in Kind",
		label: "Payment in Kind",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Dealing damage to enemies adds a stack of +1% critical hit damage, up to 200 stacks, lasting 10 seconds. On reload, apply a 10 second bleed to yourself, which deals 0.5% armor damage per stack.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2850a",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Adrenaline Rush",
		label: "Adrenaline Rush",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Whenever you are within 10m of an enemy, gain 20% bonus armor for 5s. Stacks up to 3 times. Cooldown: 5s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28513",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Clutch",
		label: "Clutch",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "If you are below 15% armor, critical hits repair 2.5% missing armor. Kills allow you to repair up to 100% armor for 4-10s, based on your number of offensive core attributes.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28516",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Compiler Optimization",
		label: "Compiler Optimization",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Decreases Hackstep Protocol cooldown from 20s to 15s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2851c",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Dead Man's Hand",
		label: "Dead Man's Hand",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Flip a card when landing shots with a Marksman Rifle. After 5 cards are flipped, the damage of your next shot is amplified by 30%. More shots are enhanced the better the hand revealed. Four of a Kind: 4 shots. Full House: 3 shots. Aces and Eights: 2 shots. Flip an additional card on headshots.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2851f",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Empathic Resolve",
		label: "Empathic Resolve",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Repairing an ally increases their total weapon and skill damage by 3-15% for 20s. 1-7% if self. Effectiveness increased by your skill tier.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28529",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Gunslinger",
		label: "Gunslinger",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Weapon swapping increases total weapon damage by 20% for 5s. This buff is lost for 5s if you weapon swap while it is active.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2852c",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Hostile Negotiations",
		label: "Hostile Negotiations",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Critical hits mark enemies for 20s, up to 3 marks total. When you critically hit a marked enemy, all other marked enemies take 60% of the damage dealt. Whenever a marked enemy dies, gain +2% critical hit damage, stacking up to 20 times, or until combat ends.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2852e",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Indirect Transmission",
		label: "Indirect Transmission",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Your status effects now spread to all enemies within 10m and refresh 50% of the duration.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28531",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Kinetic Momentum",
		label: "Kinetic Momentum",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "When in combat, each skill generates a stack while active or not on cooldown. Stacks increase your total skill damage by 1% and total skill repair by 2%. Up to 15 stacks per skill. Lost when on cooldown.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28534",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Makeshift Repairs",
		label: "Makeshift Repairs",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Whenever you or your shield take damage, 20% of that amount is repaired to both over 15s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28535",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Multithreaded Execution",
		label: "Multithreaded Execution",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Hackstep Protocol bonus armor from 50% to 100%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28538",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "One in Hand...",
		label: "One in Hand...",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Throwing a grenade refunds it and grants +1 skill tier for 15s. Grants overcharge if already at skill tier 6. Cooldown: 60s\n\nTwo in the Bag:\n+1 Armor Kit Capacity\n+3 Grenade Capacity\n+25% Ammo Capacity\n+10% Repair-skills\n+10% Status Effects",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2853b",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Overwatch",
		label: "Overwatch",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "After staying in cover for 10s, increases you and your allies�?? total weapon and skill damage by 12% as long as you remain in cover or in a cover-to-cover move.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28543",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfect Braced",
		label: "Perfect Braced",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "While in cover weapon handling is increased by +50%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28544",
		},
		type: "gear",
		slot: "Backpack",
		quality: "N",
		name: "Perfect Clutch",
		label: "Perfect Clutch",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "If you are below 20% armor, critical hits repair 2.5% missing armor. Kills allow you to repair up to 100% armor for 4-10s, based on your number of offensive core attributes.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2854b",
		},
		type: "gear",
		slot: "Backpack",
		quality: "N",
		name: "Perfect Shock and Awe",
		label: "Perfect Shock and Awe",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Applying a status effect to an enemy increases total skill damage and repair by 20% for 27s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28550",
		},
		type: "gear",
		slot: "Backpack",
		quality: "N",
		name: "Perfectly Calculated",
		label: "Perfectly Calculated",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Kills from cover reduce skill cooldowns by 15%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28563",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Signature Moves",
		label: "Signature Moves",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases signature weapon damage by 20%, and doubles the amount of signature weapon ammo generated by Aggressive Recon.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28564",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Skilled",
		label: "Skilled",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Skill kills have a 25% chance to reset skill cooldowns.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28573",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "Transference Overclock",
		label: "Transference Overclock",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Grants +15% Hive skill haste per skill tier.\n\nDetonating a Hive refreshes your skill cooldowns and grants overcharge for 15s. If at Skill Tier 6, this effect also applies to all allies.\n\nAllies receiving this effect are unable to benefit from it again for 120s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28574",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Trauma",
		label: "Trauma",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Applies blind to an enemy hit in the head. Cooldown: 30s. Applies bleed to an enemy hit in the chest. Cooldown: 30s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28576",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Unbreakable",
		label: "Unbreakable",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "When your armor is depleted, repair 95% of your armor. Cooldown: 60s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28577",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Unstoppable Force",
		label: "Unstoppable Force",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Killing an enemy increases total weapon damage by 5% for 15s. Stacks up to 5 times.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28578",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Vanguard",
		label: "Vanguard",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Deploying a shield makes it invulnerable for 5s and grants 45% of your armor as bonus armor to all other allies for 20s. Cooldown: 25s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2857e",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Close & Personal",
		label: "Close & Personal",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Killing a target within 7m grants +30% weapon damage for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2857f",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Eyeless",
		label: "Eyeless",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Deal +20% weapon damage to blinded enemies. After 4 kills, applies blind to the next enemy you hit.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28585",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Preservation",
		label: "Preservation",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Killing an enemy repairs 10% armor over 5s. Headshot kills improve the repair by an additional 10%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28586",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Ranger",
		label: "Ranger",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Amplifies weapon damage by 2% for every 5m you are away from your target.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2858b",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Strained",
		label: "Strained",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Gain +10% critical hit damage for every 0.5s you are firing. Stacks up to 5 times.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2858d",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Future Perfect",
		label: "Future Perfect",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Weapon kills grant +1 skill tier for 15s. Stacks up to 3 times. Weapon kills at skill tier 6 grant overcharge for 15s. Overcharge cooldown: 90s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2859c",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Boomerang",
		label: "Boomerang",
		ar: false,
		rifle: true,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Critical hits have a 50% chance to return the bullet to the magazine. If a bullet is returned to the magazine, the next shot has +40% increased damage.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285a2",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Big Game Hunter",
		label: "Big Game Hunter",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "When scoped, switches to semi-automatic fire mode, dealing 450% weapon damage with each shot. Headshots grant +2% headshot damage. Stacks up to 50 times. Once at full stacks, 10 stacks decay every 4 seconds until all stacks have been removed. Headshots delay decaying of stacks.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285a3",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Eagle's Strike",
		label: "Eagle's Strike",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Accuracy increases as you continuously fire, up to +30%. Headshot kills grant the Tenacity buff for 15s. The strength of Tenacity is increased by 1% for body shots and 5% for headshots. Tenacity: 40%-80% of damage taken is delayed until the buff expires. All of the total delayed damage is reduced for each enemy killed while the buff is active, up to 100% with 3 kills.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285ab",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Agonizing Bite",
		label: "Agonizing Bite",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Diamondback randomly marks an enemy. Hitting that enemy consumes the mark, guaranteeing a critical hit with damage amplified by 20%. After hitting a mark, all shots fired are guaranteed critical hits for 5s. A new random enemy is marked afterwards and whenever you reload.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285ad",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Sandman",
		label: "Sandman",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Melee attacks instantly kill non-elite enemies. Cooldown: 15s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285b0",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Hidden Rock",
		label: "Hidden Rock",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Headshots apply confuse. Cooldown: 20s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285b7",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Finisher",
		label: "Perfect Finisher",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Swapping from this weapon within 10s of killing an enemy grants 35% critical hit chance and 40% critical hit damage for 15s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285b9",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Frenzy",
		label: "Perfect Frenzy",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "For every 8 bullets in the magazine capacity, gain +3% rate of fire and +3% weapon damage for 5s when reloading from empty.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285bc",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Lucky Shot",
		label: "Perfect Lucky Shot",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Magazine capacity is increased by 30%. Missed shots from cover have a 100% chance to return to the magazine.",
		attribute: "Mag Size %",
		val: "30",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285c1",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Overwhelm",
		label: "Perfect Overwhelm",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Suppressing an enemy grants +12% weapon damage for 12 seconds. Max stack is 4.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285c4",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Pummel",
		label: "Perfect Pummel",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "2 consecutive kills refill the magazine and grant +40% weapon damage for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285c7",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Reformation",
		label: "Perfect Reformation",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Headshots grant +40% skill repair for 15s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285cc",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Strained",
		label: "Perfect Strained",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Gain +10% critical hit damage for every 0.5s you are firing. Stacks up to 8 times.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285d0",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly Close & Personal",
		label: "Perfectly Close & Personal",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Killing a target within 7m grants +38% weapon damage for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285d1",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly Extra",
		label: "Perfectly Extra",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "+50% magazine capacity.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285d2",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly Ignited",
		label: "Perfectly Ignited",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Deal +25% weapon damage to burning enemies. After 3 kills, applies burning to the next enemy you hit.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285d5",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly On Empty",
		label: "Perfectly On Empty",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Reloading from empty grants +40% weapon handling for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285e0",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "In Plain Sight",
		label: "In Plain Sight",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Your scoped view displays additional information about enemies not targeting you. Headshot and weakpoint damage against enemies not targeting you amplified by 50%. Headshot kills reset the cooldown of the Decoy skill. This bonus will wait until the Decoy goes on cooldown if currently active.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2850b",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Aggressive Recon",
		label: "Aggressive Recon",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Dealing damage with a signature weapon increases total weapon damage by 20% for 60s. Automatically generate signature weapon ammo every 60s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2850c",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "Alternating Current",
		label: "Alternating Current",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Generate a stack of 3% Skill Damage on one of your skill every second, capping at 10 stacks. After 10 seconds at the cap the stacks transfer to your other skill. The proces then repeat ad-infinitum.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2850f",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "Bleeding Edge",
		label: "Bleeding Edge",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Shooting enemies within 15m applies bleed to the target.\n\nRepair 3-48% of your armor per second for every enemy that is bleeding within 15m.\n\nRepair strength per number of bleeding enemies:\n1: 3%\n2: 6%\n3: 12%\n4: 24%\n5: 48%",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28514",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Combined Arms",
		label: "Combined Arms",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Shooting an enemy increases total skill damage by 25% for 3s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28519",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Concussion",
		label: "Concussion",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Headshots increase total weapon damage by 10% for 1.5s. 5s with marksman rifles. Headshot kills increase total weapon damage by 15% for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2851a",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Creeping Death",
		label: "Creeping Death",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "When you apply a status effect, it is also applied to all enemies within 8m of your target. Cooldown: 15s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2851b",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Critical Measures",
		label: "Critical Measures",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Hostile Negotiations damage to additional marked enemies from 60% to 100%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2851d",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "Dragon's Glare",
		label: "Dragon's Glare",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "While in combat, applies burn to the enemy closest to you within 20m.\n\nCooldown: 35s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28521",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Energize",
		label: "Energize",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Using an armor kit grants +1 skill tier for 15s. If already at skill tier 6, grants overcharge. Cooldown: 60s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28523",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Explosive Delivery",
		label: "Explosive Delivery",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Whenever you throw a skill, 1.5 seconds after landing, it creates an explosion damaging enemies with 5m. Damage scales with skill tier dealing 25-100% damage of a concussion grenade. At most once per skill.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28526",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Galvanize",
		label: "Galvanize",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Applying a blind, ensnare, confuse, or shock to an enemy grants 40% of your armor as bonus armor to allies within 20m of that enemy for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28528",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Ground Control",
		label: "Ground Control",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases you and your allies' total weapon and skill damage by 15% when at full armor. When you repair an ally, you and all allies within 5m of you are also repaired for 60% of that amount.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2852d",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Improved Materials",
		label: "Improved Materials",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Makeshift Repairs from 20% to 30%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28537",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Obliterate",
		label: "Obliterate",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Critical hits increase total weapon damage by 1% for 5s. Stacks up to 25 times.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2853a",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Overclock",
		label: "Overclock",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Allies within 7m of your deployed skills, gain +25% reload speed and reduces their active cooldowns by 0.2s each second.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28545",
		},
		type: "gear",
		slot: "Backpack",
		quality: "N",
		name: "Perfect Combined Arms",
		label: "Perfect Combined Arms",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Shooting an enemy increases total skill damage by 30% for 3s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28546",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfect Focus",
		label: "Perfect Focus",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases total weapon damage by 6% every second you are aiming while scoped 8x or higher, up to 60%. REQUIRES a Scope with 8x magnification or higher",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28549",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfect Intimidate",
		label: "Perfect Intimidate",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "While you have bonus armor, amplifies total weapon damage by 40% to enemies within 10m.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2854c",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfect Spark",
		label: "Perfect Spark",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Damaging enemies with a skill increases total weapon damage by 15% for 20s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28557",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Process Refinery",
		label: "Process Refinery",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Makeshift Repairs speed from 15s to 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2855a",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Protector",
		label: "Protector",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "When your shield is damaged, you gain +5% bonus armor and all other allies gain 15% of your armor as bonus armor for 3s. Cooldown: 3s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2855c",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Reassigned",
		label: "Reassigned",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Killing an enemy adds 1 round of a random special ammo into your sidearm. Cooldown: 15s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28561",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Shock and Awe",
		label: "Shock and Awe",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Applying a status effect to an enemy increases total skill damage and repair by 20% for 20s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28567",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Spotter",
		label: "Spotter",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Amplifies total weapon and skill damage by 15% to pulsed enemies.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28569",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Strategic Combat Support",
		label: "Strategic Combat Support",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Ground Control proximity repair from 60% to 120%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2856b",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Symptom Aggravator",
		label: "Symptom Aggravator",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Amplifies all damage you deal to status effected targets by 30%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2856e",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Tamper Proof",
		label: "Tamper Proof",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Enemies that walk within 3m of your hive, turret, or remote pulse are shocked. Arm time: 2s. Cooldown per skill: 10s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2856f",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Target Rich Environment",
		label: "Target Rich Environment",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Hostile Negotiations mark count from 3 to 5.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28571",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Tend and Befriend",
		label: "Tend and Befriend",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Interacting with your deployed skills grants the skill 25% skill damage for 10s. This buff cannot be refreshed.\n\nInteractions include:\nUsing / Deploying the Skill\nChanging the skills target\nHealing the skill",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28572",
		},
		type: "gear",
		slot: "Exotic",
		quality: "E",
		name: "Toxic Delivery",
		label: "Toxic Delivery",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Status effects also apply a damage over time debuff for 10s.\n\nTotal damage dealt is equal to 50% of your concussion grenade damage and increased by your status effect attributes.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28575",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Trauma Specialist",
		label: "Trauma Specialist",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases the duration of your bleed status effects by 50% and all bleed damage done by 100%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2857b",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Waving the Flag",
		label: "Waving the Flag",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: 'Increases "Red, White and Blue" rotation speed to 1.5s.',
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28581",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Ignited",
		label: "Ignited",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Deal +20% weapon damage to burning enemies. After 4 kills, applies burning to the next enemy you hit.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28582",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Killer",
		label: "Killer",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Killing an enemy with a critical hit grants +40% critical hit damage for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28587",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Reformation",
		label: "Reformation",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Headshots grant +30% skill repair for 15s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28589",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Spike",
		label: "Spike",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Headshots grant +20% skill damage for 15s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2858c",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Vindictive",
		label: "Vindictive",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Killing an enemy with a status effect applied grants you and all allies within 15m 15% critical hit chance and 15% critical hit damage for 20s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2858f",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Pummel",
		label: "Pummel",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: true,
		desc: "3 consecutive kills refill the magazine and grant +40% weapon damage for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28595",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Overwhelm",
		label: "Overwhelm",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: true,
		pistol: false,
		shotgun: false,
		desc: "Suppressing an enemy, that is not currently suppressed, grants +10% weapon damage for 12 seconds. Max stack is 4.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28597",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Measured",
		label: "Measured",
		ar: true,
		rifle: false,
		mmr: false,
		smg: true,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "The top half of the magazine has 20% rate of fire and -30% weapon damage. The bottom half of the magazine has -20% rate of fire and 30% total weapon damage.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2859a",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "First Blood",
		label: "First Blood",
		ar: false,
		rifle: false,
		mmr: true,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "If scoped, your first shot fired from out of combat or after fully reloading from empty deals headshot damage to any part of the body hit. REQUIRES a Scope with 8x magnification or higher.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2859b",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Naked",
		label: "Naked",
		ar: false,
		rifle: false,
		mmr: true,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Hitting an enemy with no armor grants +50% headshot damage for 5s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285a4",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Tenacity",
		label: "Tenacity",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "40%-80% of damage taken is delayed until the buff expires. All of the total delayed damage is reduced for each enemy killed while the buff is active, up to 100% with 3 kills.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285a7",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Plague of the Outcasts",
		label: "Plague of the Outcasts",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Hits apply a debuff dealing 100% weapon damage over 10s. This stacking up to 50 times. Whenever an enemy dies with this debuff, all stacks are transferred to a nearby enemy within 25m.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285aa",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Geri and Freki",
		label: "Geri and Freki",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "On trigger-pull, fire both barrels at once. When fired from the right shoulder, hits add offensive primers, and defensive primers when fired from the left shoulder. Hits from one shoulder will detonate all of the opposite shoulder's primers when present. When detonated, each offensive primer deals 100% weapon damage, while each defensive primer grants +4% bonus armor and +10% amplified damage to armor plates for 5s. Primer effectiveness is doubled at 10 stacks.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285b1",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Allegro",
		label: "Perfect Allegro",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "+12% rate of fire.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285b8",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect First Blood",
		label: "Perfect First Blood",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "If scoped, your first 2 shots fired from out of combat or after fully reloading from empty deals headshot damage to any part of the body hit. REQUIRES a Scope with 8x magnification or higher.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285ba",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Jazz Hands",
		label: "Perfect Jazz Hands",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "+30% reload speed.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285bb",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Killer",
		label: "Perfect Killer",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Killing an enemy with a critical hit grants +50% critical hit damage for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285c2",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Perpetuation",
		label: "Perfect Perpetuation",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Headshots grant +50% status effect damage and duration to the next status effect you apply. Cooldown: 16s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285c5",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Pumped Up",
		label: "Perfect Pumped Up",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Reloading grants +5% weapon damage for 10s. Stacks up to 5 times.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285cb",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Spike",
		label: "Perfect Spike",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Headshots grant +25% skill damage for 15s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285cf",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly Accurate",
		label: "Perfectly Accurate",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "+50% accuracy.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285d4",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly Naked",
		label: "Perfectly Naked",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Hitting an enemy with no armor grants +50% headshot damage for 8s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285d6",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly Optimized",
		label: "Perfectly Optimized",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "+30% weapon handling.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285dc",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly In Sync",
		label: "Perfectly In Sync",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Hitting an enemy grants +20% skill damage for 5s. Using a skill or damaging an enemy with a skill grants +20% weapon damage for 5s. Damage increases are doubled while both buffs are active at the same time.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285dd",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Septic Shock",
		label: "Septic Shock",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Shooting a target applies stacks of venom, which last for 10s. Increasing stacks adds more severe debuffs to the target. \n1- Poison 3 - Disorient 6 - Shock 7 - Target takes 20% damage (from all sources) Stacks no longer increase. Duration of Status Effects is based on percentage of pellets hit on applying shot.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28509",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Ace in the Sleeve",
		label: "Ace in the Sleeve",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Amplifies 1 extra shot when revealing your hand.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28512",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Calculated",
		label: "Calculated",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Kills from cover reduce skill cooldowns by 10%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28515",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Companion",
		label: "Companion",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "While you are within 5m of an ally or skill, total weapon damage is increased by 15%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2851e",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Efficient",
		label: "Efficient",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Using an armor kit has a 50% chance to not consume the armor kit. Specialization armor kit bonuses are increased by 100%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28522",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Entrench",
		label: "Entrench",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "If you are below 30% armor, headshots from cover repair 20% of your armor. Cooldown: 3s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28525",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Focus",
		label: "Focus",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases total weapon damage by 5% every second you are aiming while scoped 8x or higher, up to 50%. REQUIRES a Scope with 8x magnification or higher",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28527",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Glass Cannon",
		label: "Glass Cannon",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "All damage you deal is amplified by 25%. All damage you take is amplified by 50%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2852a",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Hackstep Protocol",
		label: "Hackstep Protocol",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Replaces armor kits with an instant, infinite-use ability on a 20s cooldown, that repairs 20% armor, grants 50% bonus armor and hides your nameplate for 5s. Increases total weapon damage by 1% per 5% bonus armor gained, up to 20%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2852f",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Intimidate",
		label: "Intimidate",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "While you have bonus armor, amplifies total weapon damage by 35% to enemies within 10m.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28530",
		},
		type: "gear",
		slot: "Backpack",
		quality: "E",
		name: "Kill Confirmed",
		label: "Kill Confirmed",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Enemies you kill drop a trophy on death. Collecting trophies provides both a short and long-term buff, the first of which scales with the number of core attributes equipped and lasts 10 seconds.\n\n5% weapon damage per red core,\n10% bonus armor per blue core.\n5% skill efficiency per yellow core.\n\nFor every trophy collected, gain an additional +1% weapon damage, +1% skill efficiency, and +0.1% armor regeneration for 300s. Maximum 30 stacks.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28536",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "No Limit",
		label: "No Limit",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Dead Man's Hand damage bonus from 30% to 50%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28536",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Poker Face",
		label: "Poker Face",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "PLEASE UPDATE ME!!!",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28541",
		},
		type: "gear",
		slot: "Backpack",
		quality: "N",
		name: "Perfect Adrenaline Rush",
		label: "Perfect Adrenaline Rush",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Whenever you are within 10m of an enemy, gain 23% bonus armor for 5s. Stacks up to 3 times. Cooldown: 5s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28542",
		},
		type: "gear",
		slot: "Backpack",
		quality: "N",
		name: "Perfect Bloodsucker",
		label: "Perfect Bloodsucker",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Killing an enemy adds and refreshes a stack of +12% bonus armor for 10s. Max stack is 10.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28547",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfect Glass Cannon",
		label: "Perfect Glass Cannon",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "All damage you deal is amplified by 30%. All damage you take is amplified by 60%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28548",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfect Headhunter",
		label: "Perfect Headhunter",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "After killing an enemy with a headshot, your next weapon hit within 30s deals an additional 150% of that killing blow's damage. Damage is capped to 800% of your weapon damage. This is raised to 1250% if your headshot damage is greater than 150%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2854e",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfect Vanguard",
		label: "Perfect Vanguard",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Deploying a shield makes it invulnerable for 5s and grants 50% of your armor as bonus armor to all other allies for 20s. Cooldown: 25s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2854f",
		},
		type: "gear",
		slot: "Backpack",
		quality: "N",
		name: "Perfect Vigilance",
		label: "Perfect Vigilance",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases total weapon damage by 25%. Taking damage disables this buff for 3s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28552",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfectly Skilled",
		label: "Perfectly Skilled",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Skill kills have a 30% chance to reset skill cooldowns.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28553",
		},
		type: "gear",
		slot: "Vest",
		quality: "N",
		name: "Perfectly Unbreakable",
		label: "Perfectly Unbreakable",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "When your armor is depleted, repair 100% of your armor. Cooldown: 55s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28554",
		},
		type: "gear",
		slot: "Backpack",
		quality: "N",
		name: "Perfectly Wicked",
		label: "Perfectly Wicked",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Applying a status effect increases total weapon damage by 18% for 27s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28555",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Positive Reinforcement",
		label: "Positive Reinforcement",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Feedback Loop skill damage and repair bonus from +10% to +25%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28556",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Press the Advantage",
		label: "Press the Advantage",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases max stacks for Striker's Gamble from 50 to 100.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28558",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Proliferation",
		label: "Proliferation",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Indirect Transmission range from 10m to 15m and refresh percentage from 50% to 75%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28559",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Protected Reload",
		label: "Protected Reload",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Grants +20% bonus armor while reloading. Grants 0-18% of your armor as bonus armor to all other allies when they are reloading, based on your defensive core attribute.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2855d",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Red, White and Blue",
		label: "Red, White and Blue",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Every 2s, enemies you shoot receive a stacking debuff of Red, White and Blue. Red: Amplifies the enemy's damage taken by 8%. White: Shooting the enemy repairs the attacking agent's armor by 2% once every second. Blue: Decreases the enemy's damage dealt by 8%. Full Flag: Enemies that die while under the effect of all 3 debuffs create a 5m explosion, dealing damage equal to their total health and armor. (Explosion damage reduced on Named enemy deaths.)",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2855e",
		},
		type: "gear",
		slot: "Backpack",
		quality: "S",
		name: "Risk Management",
		label: "Risk Management",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases total weapon damage gained per stack of Striker's Gamble from 0.5% to 0.65%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28565",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Spark",
		label: "Spark",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Damaging enemies with a skill increases total weapon damage by 15% for 15s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28566",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Specialized Destruction",
		label: "Specialized Destruction",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Aggressive Recon weapon damage bonus from 20% to 40%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2856a",
		},
		type: "gear",
		slot: "A",
		quality: "S",
		name: "Striker's Gamble",
		label: "Striker's Gamble",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Weapon hits amplify total weapon damage by 0.5%, stacking up to 50 times. 1 stack is lost each second. 2 stacks are lost every time you miss.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2856c",
		},
		type: "gear",
		slot: "Vest",
		quality: "S",
		name: "Tactical Superiority",
		label: "Tactical Superiority",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Increases Ground Control damage bonus from +15% to +25%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2856d",
		},
		type: "gear",
		slot: "Vest",
		quality: "A",
		name: "Tag Team",
		label: "Tag Team",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "The last enemy you have damaged with a skill is marked. Dealing weapon damage to that enemy consumes the mark to reduce active cooldowns by 6s. Cooldown: 4s",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28570",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Tech Support",
		label: "Tech Support",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Skill kills increase total skill damage by 25% for 20s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28579",
		},
		type: "gear",
		slot: "Backpack",
		quality: "A",
		name: "Versatile",
		label: "Versatile",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Amplifies total weapon damage for 10s when swapping between your primary and secondary weapons if they are different. 35% to enemies within 15m for Shotguns and SMGs. 35% to enemies further than 25m for Rifles and Marksman Rifles. 10% to enemies between 15-25m for LMGs and Assault Rifles. At most once per 5s per weapon type.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2857d",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Breadbasket",
		label: "Breadbasket",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Landing body shots adds a stack of bonus +35% headshot damage to the next headshot for 10s. Max stack is 3.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28580",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Fast Hands",
		label: "Fast Hands",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Critical hits add a stack of 4% reload speed bonus. Max stack is 40.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28583",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Optimist",
		label: "Optimist",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Weapon damage is increased by +3% for every 10% ammo missing from the magazine.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28588",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Sadist",
		label: "Sadist",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Deal +20% weapon damage to bleeding enemies. After 4 kills, applies bleed to next enemy you hit.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e2858e",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "In Sync",
		label: "In Sync",
		ar: true,
		rifle: true,
		mmr: true,
		smg: true,
		lmg: true,
		pistol: true,
		shotgun: true,
		desc: "Hitting an enemy grants +15% skill damage for 5s. Using a skill or damaging an enemy with a skill grants +15% weapon damage for 5s. Damage increases are doubled while both buffs are active at the same time.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28590",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Pumped Up",
		label: "Pumped Up",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: true,
		desc: "Reloading grants +1% weapon damage for 10s. Stacks up to 25 times.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28591",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Lucky Shot",
		label: "Lucky Shot",
		ar: false,
		rifle: true,
		mmr: true,
		smg: false,
		lmg: false,
		pistol: true,
		shotgun: false,
		desc: "Magazine capacity is increased by 20%. Missed shots from cover have a 100% chance to return to the magazine.",
		attribute: "Mag Size %",
		val: "20",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28594",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Frenzy",
		label: "Frenzy",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: true,
		pistol: false,
		shotgun: false,
		desc: "For every 10 bullets in the magazine capacity, gain +3% rate of fire and +3% weapon damage for 5s when reloading from empty.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28596",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Unhinged",
		label: "Unhinged",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: true,
		pistol: false,
		shotgun: false,
		desc: "Grants +18% weapon damage at the cost of -25% Stability and -25% Accuracy.",
		attribute: "Weapon Damage",
		val: "18",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e28598",
		},
		type: "weapon",
		slot: "weapon",
		quality: "A",
		name: "Outsider",
		label: "Outsider",
		ar: false,
		rifle: false,
		mmr: false,
		smg: true,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "After killing an enemy, gain 100% optimal range and +100% accuracy for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285a1",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Electromagnetic Accelerator",
		label: "Electromagnetic Accelerator",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Shots fired deal 0-100% weapon damage based on how long the trigger is held before releasing.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285a5",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Adaptive Instincts",
		label: "Adaptive Instincts",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Hitting 30 headshots grants +20% critical hit chance and +50% critical hit damage for 45s. Hitting 75 body shots grants +90% weapon damage for 45s. Hitting 30 leg shots grants +150% reload speed for 45s. Buffs refresh when out of combat.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285a8",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Regicide",
		label: "Regicide",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Headshot kills create a 5m explosion, dealing 400% weapon damage and applying bleed to all enemies hit.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285a9",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Liberty or Death",
		label: "Liberty or Death",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Hits grant +2% weapon damage. Stacks up to 30 times. Headshots consume all stacks, repairing your shield for 3% per stack.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285ac",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Binary Trigger",
		label: "Binary Trigger",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "This weapon fires on trigger pull and release. If both bullets hit the same enemy, gain a stack. At 7 stacks, shooting an enemy deals +500% amplified damage and creates a 7m explosion dealing 500% weapon damage, consuming the stacks.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285ae",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Breathe Free",
		label: "Breathe Free",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "When moving, gain 4 stacks per second, or 8 stacks if sprinting, up to 32 stacks total. Each round fired consumes a stack, amplifying damage by 75%. Kills grant +20% movement speed for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285af",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Incessant Chatter",
		label: "Incessant Chatter",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "When you reload, rate of fire is increased by 20% for each enemy within 15m for the duration of that magazine. Max stacks: 5. Kills refill 50% of your magazine.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285b5",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Eyeless",
		label: "Perfect Eyeless",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Deal +25% weapon damage to blinded enemies. After 3 kills, applies blind to the next enemy you hit.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285b6",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Fast Hands",
		label: "Perfect Fast Hands",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Critical hits add a stack of 8% reload speed bonus. Max stack is 40.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285bf",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Outsider",
		label: "Perfect Outsider",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "After killing an enemy, gain 125% optimal range and +125% Accuracy for 10s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285c0",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Overflowing",
		label: "Perfect Overflowing",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Every 2 reloads from empty increases your base magazine capacity by 100%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285c3",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Preservation",
		label: "Perfect Preservation",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Killing an enemy repairs 12% armor over 5s. Headshot kills improve the repair by an additional 12%.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285c8",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Rifleman",
		label: "Perfect Rifleman",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Landing headshots adds a stack of bonus +11% weapon damage for 5s. Max stack is 6. Additional headshots refresh the duration.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285c9",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Sadist",
		label: "Perfect Sadist",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Deal +25% weapon damage to bleeding enemies. After 3 kills, applies bleed to next enemy you hit.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285ca",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Salvage",
		label: "Perfect Salvage",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Killing a target has a +85% chance to refill the magazine.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285cd",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Unwavering",
		label: "Perfect Unwavering",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Swapping to this weapon grants +400% weapon handling for 5s. Kills refresh the buff. Swapping away disables this from all weapons for 5s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285ce",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfect Vindictive",
		label: "Perfect Vindictive",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Killing an enemy with a status effect applied grants you and all allies within 20m 18% critical hit chance and 18% critical hit damage for 20s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285d3",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly Measured",
		label: "Perfectly Measured",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "The top half of the magazine has 20% rate of fire and -27% weapon damage. The bottom half of the magazine has -20% rate of fire and 33% total weapon damage.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285d7",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly Steady Handed",
		label: "Perfectly Steady Handed",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Hits grant a stack of +1% Accuracy and Stability. At 75 stacks, consumes them to refill the magazine.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285d8",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Perfectly Unhinged",
		label: "Perfectly Unhinged",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Grants +22% weapon damage at the cost of -25% Stability and -25% Accuracy.",
		attribute: "Weapon Damage",
		val: "22",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285d9",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Swift",
		label: "Swift",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Performing a cover to cover grants +20% weapon damage for 15s.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285da",
		},
		type: "weapon",
		slot: "weapon",
		quality: "N",
		name: "Primer Rounds",
		label: "Primer Rounds",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Damaged enemies lose 50% burn resistance for 5 seconds.",
		attribute: "",
		val: "",
	},
	{
		_id: {
			$oid: "624872fad03ce64590e285de",
		},
		type: "weapon",
		slot: "weapon",
		quality: "E",
		name: "Capacitance",
		label: "Capacitance",
		ar: false,
		rifle: false,
		mmr: false,
		smg: false,
		lmg: false,
		pistol: false,
		shotgun: false,
		desc: "Shooting enemies builds stacks to a cap of 40. Each stack grants 1.5% Skill Damage. After 5 seconds, stacks decay 1 per second. For each skill tier, gain 7.5% weapon damage.",
		attribute: "",
		val: "",
	},
];

export default TalentsData;
