import {
	IonItem,
	IonLabel,
	IonList,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import WeaponMod from "../../models/weaponMod";
import WeaponModsData from "../../data/WeaponModsData";
import Weapon from "../../models/weapon";
import AppContext from "../../context/app-context";

interface WeaponModSlotProps {
	slot: string;
	weapon: Weapon;
	location: string;
}

const interfaceOptions = {
	cssClass: "my-custom-interface",
};

let initMods: WeaponMod[] = WeaponModsData.sort(function (a, b) {
	const nameA = a.name.toUpperCase(); // ignore upper and lowercase
	const nameB = b.name.toUpperCase(); // ignore upper and lowercase
	// console.log(nameA, "=", nameB);
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	// names must be equal
	return 0;
});

const WeaponModSlot = ({ slot, weapon, location }: WeaponModSlotProps) => {
	const ctxApp = useContext(AppContext);
	const [currentWeapon, setCurrentWeapon] = useState(weapon);
	const [selectedModName, setSelectedModName] = useState("");
	const [currentMods, setCurrentMods] = useState(initMods);
	const [modSelectDisabled, setModSelectDisabled] = useState(false);

	const modChangeHandler = (e: CustomEvent) => {
		// console.log(e.detail.value);
		// console.log(location);
		// get the mod
		const selectedMod = initMods.filter((mod) => {
			return (
				mod.name === e.detail.value &&
				mod.slot.toLowerCase() === location.toLowerCase()
			);
		});
		if (selectedMod.length > 0) {
			// console.log(selectedMod);
			setSelectedModName(e.detail.value);
			let attributeName = "mod" + location;
			let attributeValuePos = attributeName + "ValuePos";
			let attributeValueNeg = attributeName + "ValueNeg";
			ctxApp.updateWeaponStat(attributeName, slot, selectedMod[0].name);
			ctxApp.updateWeaponStat(
				attributeValuePos,
				slot,
				selectedMod[0].posVal,
				"number"
			);
			ctxApp.updateWeaponStat(
				attributeValueNeg,
				slot,
				selectedMod[0].negVal,
				"number"
			);
		}
	};

	useEffect(() => {
		switch (slot.toLowerCase()) {
			case "primary":
				setCurrentWeapon(ctxApp.primaryWeapon);
				break;
			case "secondary":
				setCurrentWeapon(ctxApp.secondaryWeapon);
				// setSelectedModName(ctxApp.secondaryStats["mod" + location]);
				break;
			case "pistol":
				setCurrentWeapon(ctxApp.pistolWeapon);
				// setSelectedModName(ctxApp.pistolStats["mod" + location]);
				break;
		}
	}, [
		ctxApp.pistolStats,
		ctxApp.pistolWeapon,
		ctxApp.primaryStats,
		ctxApp.primaryWeapon,
		ctxApp.secondaryStats,
		ctxApp.secondaryWeapon,
		slot,
		location,
	]);

	useEffect(() => {
		function canUseMod(weapon: Weapon, mod: any) {
			// console.log(weapon, mod);
			// console.log(location);

			let types: any = [];
			switch (location.toLowerCase()) {
				case "optic":
					// console.log(
					// 	location,
					// 	weapon,
					// 	weapon.optics
					// 		.split("/")
					// 		.map((element) => element.trim())
					// );
					types = weapon.optics
						.split("/")
						.map((element) => element.trim());
					break;
				case "magazine":
					// console.log(
					// 	location,
					// 	weapon.magazine.split("/").map((element) => element.trim())
					// );
					types = weapon.magazine
						.split("/")
						.map((element) => element.trim());
					break;
				case "under barrel":
					// console.log(
					// 	location,
					// 	weapon.underBarrel
					// 		.split("/")
					// 		.map((element) => element.trim())
					// );
					types = weapon.underBarrel
						.split("/")
						.map((element) => element.trim());
					break;
				case "muzzle":
					// console.log(
					// 	location,
					// 	weapon.muzzle.split("/").map((element) => element.trim())
					// );
					types = weapon.muzzle
						.split("/")
						.map((element) => element.trim());
					break;
			}
			if (types.length > 0) {
				for (let i = 0; i < types.length; i++) {
					const element = types[i];
					// console.log(mod);
					if (mod.type === element) {
						return true;
					}
				}
			}
			return false;
		}

		function assignModSelected() {
			switch (slot.toLowerCase()) {
				case "primary":
					switch (location.toLowerCase()) {
						case "optic":
							setSelectedModName(
								ctxApp.primaryStats.modOpticName
							);
							break;
						case "magazine":
							setSelectedModName(
								ctxApp.primaryStats.modMagazineName
							);
							break;
						case "under barrel":
							setSelectedModName(
								ctxApp.primaryStats.modUnderbarrelName
							);
							break;
						case "muzzle":
							setSelectedModName(
								ctxApp.primaryStats.modMuzzleName
							);
							break;
					}
					break;
				case "secondary":
					switch (location.toLowerCase()) {
						case "optic":
							setSelectedModName(
								ctxApp.secondaryStats.modOpticName
							);
							break;
						case "magazine":
							setSelectedModName(
								ctxApp.secondaryStats.modMagazineName
							);
							break;
						case "under barrel":
							setSelectedModName(
								ctxApp.secondaryStats.modUnderbarrelName
							);
							break;
						case "muzzle":
							setSelectedModName(
								ctxApp.secondaryStats.modMuzzleName
							);
							break;
					}
					break;
				case "pistol":
					switch (location.toLowerCase()) {
						case "optic":
							setSelectedModName(ctxApp.pistolStats.modOpticName);
							break;
						case "magazine":
							setSelectedModName(
								ctxApp.pistolStats.modMagazineName
							);
							break;
						case "under barrel":
							setSelectedModName(
								ctxApp.pistolStats.modUnderbarrelName
							);
							break;
						case "muzzle":
							setSelectedModName(
								ctxApp.pistolStats.modMuzzleName
							);
							break;
					}
					break;
			}
		}

		// label: "552 Holo Sight"
		// name: "552 Holo Sight"
		// negStat: ""
		// negStatFactor: ""
		// negVal: ""
		// posStat: "Accuracy"
		// posStatFactor: "number"
		// posVal: "10"
		// slot: "Optic"
		// spec: "A"
		// type: "Long"
		// _id: {oid: '62487bd5d03ce64590e286fc'}
		// console.log(initMods);

		const updatedMods = initMods.filter((mod) => {
			// return talent.type === "gear" && talent.quality === "A";
			return (
				mod.spec === "A" &&
				mod.slot.toLowerCase() === location.toLowerCase() &&
				canUseMod(currentWeapon, mod)
			);
		});
		// console.log(location, updatedMods);
		if (updatedMods.length === 0) {
			setModSelectDisabled(true);
			// setSelectedModName("");
		} else {
			setModSelectDisabled(false);
		}
		setCurrentMods(updatedMods);
		assignModSelected();
	}, [
		ctxApp.pistolStats.modMagazineName,
		ctxApp.pistolStats.modMuzzleName,
		ctxApp.pistolStats.modOpticName,
		ctxApp.pistolStats.modUnderbarrelName,
		ctxApp.primaryStats.modMagazineName,
		ctxApp.primaryStats.modMuzzleName,
		ctxApp.primaryStats.modOpticName,
		ctxApp.primaryStats.modUnderbarrelName,
		ctxApp.secondaryStats.modMagazineName,
		ctxApp.secondaryStats.modMuzzleName,
		ctxApp.secondaryStats.modOpticName,
		ctxApp.secondaryStats.modUnderbarrelName,
		currentWeapon,
		location,
		slot,
	]);

	return (
		<>
			<IonList class="ion-no-padding">
				<IonItem class="ion-no-padding">
					<IonLabel position="floating">{location}:</IonLabel>
					<IonSelect
						disabled={modSelectDisabled}
						interfaceOptions={interfaceOptions}
						className="weaponInput"
						interface="popover"
						placeholder="Select Mod"
						value={selectedModName}
						onIonChange={modChangeHandler}
					>
						{currentMods.map((mod, index) => {
							return (
								<IonSelectOption
									key={index}
									value={mod.name}
									class="brown-option"
								>
									{/* {mod.name} */}
									{/* {" : "} */}
									{mod.posStat === ""
										? mod.name
										: mod.posStat}
									{" + "}
									{mod.posVal === "" ? "0" : mod.posVal}
								</IonSelectOption>
							);
						})}
					</IonSelect>
				</IonItem>
			</IonList>
		</>
	);
};

export default WeaponModSlot;
