import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonImg,
	IonRow,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import Gear from "../../models/gear";
import GearStats from "../../models/gearStats";
import css from "./BuildMain.module.css";
import "./BuildMain.css";
interface BuildGearProps {
	slot: string;
}

const BuildGear: React.FC<BuildGearProps> = ({ slot }) => {
	const ctxApp = useContext(AppContext);
	const [gearName, setGearName] = useState<string>("None Equipped");
	// const [gearHeaderImage, setGearHeaderImage] = useState(
	// 	css["brand511tactical"]
	// );
	const [gearHeaderClassName, setGearHeaderClassName] = useState("");
	const [gearSlotImage, setGearSlotImage] = useState(
		"assets/images/spacer.png"
	);
	const [talentLabel, setTalentLabel] = useState("");
	const [talentImage, setTalentImage] = useState("assets/images/spacer.png");
	const [gearCoreLabel, setGearCoreLabel] = useState("");
	const [coreImage, setCoreImage] = useState(
		"assets/images/core-weapon-damage.png"
	);
	const [gearAttribute1Label, setGearAttribute1Label] = useState("");
	const [attribute1Image, setAttribute1Image] = useState(
		"assets/images/core-weapon-damage.png"
	);
	const [gearAttribute2Label, setGearAttribute2Label] = useState("");
	const [attribute2Image, setAttribute2Image] = useState(
		"assets/images/core-weapon-damage.png"
	);
	const [gearModLabel, setGearModLabel] = useState("");
	const [modImage, setModImage] = useState("assets/images/spacer.png");
	const [expertiseImage, setExpertiseImage] = useState(
		"assets/images/proficient.png"
	);
	const [expertiseValue, setExpertiseValue] = useState<string | number>(
		"Proficient"
	);

	useEffect(() => {
		function processSlot(gear: Gear, stats: GearStats) {
			setGearName(gear.name);
			let gearHeaderImage =
				css[
					"brand" +
						gear.brand
							.toLowerCase()
							.replaceAll(" &", "")
							.replaceAll(",", "")
							.replaceAll(".", "")
							.replaceAll("'", "")
							.replaceAll(" ", "")
				];
			// console.log(gearHeaderImage);
			if (gear.quality.toLowerCase() === "exotic") {
				let className = gear.name
					.toLowerCase()
					.replaceAll(" &", "")
					.replaceAll(",", "")
					.replaceAll(".", "")
					.replaceAll("'", "")
					.replaceAll(" ", "");
				// console.log(gearHeaderClassName);
				setGearHeaderClassName(`brand-header brandexotic${className}`);
			} else {
				let className = gear.brand
					.toLowerCase()
					.replaceAll(" &", "")
					.replaceAll(",", "")
					.replaceAll(".", "")
					.replaceAll("'", "")
					.replaceAll(" ", "-");
				setGearHeaderClassName(`brand-header brand-${className}`);
				// console.log(gearHeaderClassName);
			}
			// setGearHeaderImage(gearHeaderImage);
			if (gear.slot !== "") {
				setGearSlotImage("assets/images/" + gear.slot + ".png");
			} else {
				setGearSlotImage("assets/images/spacer.png");
			}

			if (stats.core1Name !== "") {
				setGearCoreLabel(stats.core1Name);
				setCoreImage(
					`assets/images/gear-core-${stats.core1Name
						.toLowerCase()
						.replaceAll(" ", "-")}.png`
				);
			} else {
				setCoreImage("assets/images/spacer.png");
			}

			if (stats.attribute1Name !== "") {
				setGearAttribute1Label(stats.attribute1Name);
				setAttribute1Image(
					`assets/images/gear-attribute-${stats.attribute1Name
						.toLowerCase()
						.replaceAll(" ", "-")}.png`
				);
			} else {
				setAttribute1Image("assets/images/spacer.png");
			}

			if (stats.attribute2Name !== "") {
				setGearAttribute2Label(stats.attribute2Name);
				setAttribute2Image(
					`assets/images/gear-attribute-${stats.attribute2Name
						.toLowerCase()
						.replaceAll(" ", "-")}.png`
				);
			} else {
				setAttribute2Image("assets/images/spacer.png");
			}

			if (stats.modName !== "") {
				setGearModLabel(stats.modName);
				setModImage(
					`assets/images/gear-mod-${stats.modName
						.toLowerCase()
						.replaceAll(" ", "-")}.png`
				);
			} else {
				setModImage("assets/images/spacer.png");
			}
			// console.log(talentImage);
			if (stats.talent !== "") {
				setTalentLabel(stats.talent);
				setTalentImage(
					`assets/images/gear-talent-${stats.talent
						.toLowerCase()
						.replaceAll(" ", "-")}.png`
				);
			} else {
				setTalentLabel("");
				setTalentImage("assets/images/spacer.png");
			}
			setExpertiseValue(stats.expertise);
			if (stats.expertise !== null) {
				setExpertiseImage(
					`assets/images/proficient-${stats.expertise}.png`
				);
			} else {
				setExpertiseImage(`assets/images/proficient.png`);
			}
			// setTalent(
			// 	require("../../assets/images/weapon-talent-" +
			// 		stats.talent +
			// 		".png")
			// );
		}

		switch (slot.toLowerCase()) {
			case "mask":
				processSlot(ctxApp.maskGear, ctxApp.maskStats);
				break;
			case "backpack":
				processSlot(ctxApp.backpackGear, ctxApp.backpackStats);
				break;
			case "vest":
				processSlot(ctxApp.vestGear, ctxApp.vestStats);
				break;
			case "gloves":
				processSlot(ctxApp.glovesGear, ctxApp.glovesStats);
				break;
			case "holster":
				processSlot(ctxApp.holsterGear, ctxApp.holsterStats);
				break;
			case "kneepads":
				processSlot(ctxApp.kneepadsGear, ctxApp.kneepadsStats);
				break;
		}
	}, [
		ctxApp.backpackGear,
		ctxApp.backpackStats,
		ctxApp.glovesGear,
		ctxApp.glovesStats,
		ctxApp.holsterGear,
		ctxApp.holsterStats,
		ctxApp.kneepadsGear,
		ctxApp.kneepadsStats,
		ctxApp.maskGear,
		ctxApp.maskStats,
		ctxApp.vestGear,
		ctxApp.vestStats,
		gearHeaderClassName,
		slot,
	]);

	return (
		<>
			<IonCol
				className="ion-no-padding"
				size="12"
				size-lg="6"
				size-md="6"
				size-sm="6"
			>
				<IonGrid className="ion-no-padding">
					<IonRow>
						<IonCol className="ionColBorder ion-no-padding">
							<IonCard>
								{/* <img
													className={css.weaponHeader}
													alt=""
													src={
														"assets/images/assault-rifle-high-end.png"
													}
												/> */}
								<IonCardHeader className={gearHeaderClassName}>
									<IonImg
										className={css.gearSlotImage}
										alt={""}
										src={gearSlotImage}
									/>
									<img
										className={css.gearTalentImage}
										alt={""}
										src={talentImage}
									/>
									<IonImg
										className={css.gearExpertiseImage}
										alt={""}
										src={expertiseImage}
									/>
									<p className={css.gearTitle}>{gearName}</p>
									<p className={css.gearTalentLabel}>
										{talentLabel}
									</p>
									{/* <p className={css.gearExpertiseLabel}>
										Expertise ({expertiseValue})
									</p> */}
									<p className={css.gearCoreLabel}>
										{gearCoreLabel}
									</p>
									<IonImg
										className={css.gearCoreImage}
										alt={""}
										src={coreImage}
									/>
									<p className={css.gearAttribute1Label}>
										{gearAttribute1Label}
									</p>
									<IonImg
										className={css.gearAttribute1Image}
										alt={""}
										src={attribute1Image}
									/>
									<p className={css.gearAttribute2Label}>
										{gearAttribute2Label}
									</p>
									<IonImg
										className={css.gearAttribute2Image}
										alt={""}
										src={attribute2Image}
									/>
									<p className={css.gearModLabel}>
										{gearModLabel}
									</p>
									<IonImg
										className={css.gearModImage}
										alt={""}
										src={modImage}
									/>
									<IonCardSubtitle>&nbsp;</IonCardSubtitle>
									<IonCardTitle></IonCardTitle>
								</IonCardHeader>

								<IonCardContent className="ion-no-padding"></IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCol>
		</>
	);
};

export default BuildGear;
