import {
	IonAccordion,
	IonAccordionGroup,
	IonItem,
	IonLabel,
} from "@ionic/react";
import weaponModSlots from "../../models/weaponModSlots";
import WeaponModSlot from "./WeaponModSlot";
import Weapon from "../../models/weapon";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";

interface WeaponModProps {
	slot: string;
	weapon: Weapon;
}

const WeaponMods = ({ slot, weapon }: WeaponModProps) => {
	const ctxApp = useContext(AppContext);
	const [selectedWeapon, setSelectedWeapon] = useState(weapon);

	useEffect(() => {
		if (slot.toLowerCase() === "primary") {
			setSelectedWeapon(ctxApp.primaryWeapon);
		}
		if (slot.toLowerCase() === "secondary") {
			setSelectedWeapon(ctxApp.secondaryWeapon);
		}
		if (slot.toLowerCase() === "pistol") {
			setSelectedWeapon(ctxApp.pistolWeapon);
		}
	}, [
		ctxApp.primaryWeapon,
		ctxApp.secondaryWeapon,
		ctxApp.pistolWeapon,
		slot,
	]);

	return (
		<>
			<IonAccordionGroup className="ion-no=-padding">
				<IonAccordion className="ion-no=-padding" value="mods">
					<IonItem slot="header" color="light">
						<IonLabel>Mods</IonLabel>
					</IonItem>
					<div className="ion-no-padding" slot="content">
						{/* <WeaponModOptic slot={slot} weapon={selectedWeapon} /> */}
						{weaponModSlots.map((modSlot, index) => {
							return (
								<WeaponModSlot
									key={index}
									slot={slot}
									weapon={selectedWeapon}
									location={modSlot.name}
								/>
							);
						})}
					</div>
				</IonAccordion>
			</IonAccordionGroup>
		</>
	);
};

export default WeaponMods;
