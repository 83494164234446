import { IonBadge, IonItem, IonLabel } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/app-context";
import Weapon from "../../models/weapon";
import WeaponStats from "../../models/weaponStats";

interface StatsWeaponProps {
	weaponSlot: string;
	attribute: string;
	showHeader: Function;
}

const StatsWeapon = ({
	weaponSlot,
	attribute,
	showHeader,
}: StatsWeaponProps) => {
	const ctxApp = useContext(AppContext);
	const [showHSDAttribute, setShowHSDAttribute] = useState(false);
	const [showAttribute, setShowAttribute] = useState(true);
	const [gearSlotLabel, setGearSlotLabel] = useState("");
	const [gearSlotDesc, setGearSlotDesc] = useState("");
	const [showStartBadge, setShowStartBadge] = useState(true);
	const [weaponBaseDamage, setWeaponBaseDamage] = useState(0);
	const [weaponHSDValue, setWeaponHSDValue] = useState(0);
	const [weaponHSDLabel, setWeaponHSDLabel] = useState("");
	const [weaponHSDDesc, setWeaponHSDDesc] = useState("");
	const [gearValue, setGearValue] = useState<number | null>(0);
	const [weaponDamage, setWeaponDamage] = useState("");

	useEffect(() => {
		switch (weaponSlot.toLowerCase()) {
			case "primary":
				setWeaponBaseDamage(ctxApp.primaryWeapon.baseDamage);
				setWeaponDamage(ctxApp.primaryWeapon.core1);
				break;
			case "secondary":
				setWeaponBaseDamage(ctxApp.secondaryWeapon.baseDamage);
				setWeaponDamage(ctxApp.secondaryWeapon.core1);
				break;
			case "pistol":
				setWeaponBaseDamage(ctxApp.pistolWeapon.baseDamage);
				setWeaponDamage(ctxApp.pistolWeapon.core1);
				break;
		}
	}, [
		ctxApp.pistolWeapon.baseDamage,
		ctxApp.pistolWeapon.core1,
		ctxApp.primaryWeapon.baseDamage,
		ctxApp.primaryWeapon.core1,
		ctxApp.secondaryWeapon.baseDamage,
		ctxApp.secondaryWeapon.core1,
		weaponSlot,
	]);

	useEffect(() => {
		function updateParentShowHeader() {
			showHeader("Weapon", !showAttribute);
		}
		function checkWeapon(weapon: Weapon, weaponStats: WeaponStats) {
			switch (attribute) {
				case "Weapon Damage":
					if (weaponStats.core1Value === 0) {
						setShowAttribute(false);
					} else {
						setShowAttribute(true);
						setGearValue(weaponStats.core1Value);
					}
					break;
				case "Critical Hit Chance":
					if (weapon.core2 === attribute) {
						setShowAttribute(true);
						setGearValue(weaponStats.core2Value);
					} else if (weaponStats.attribute1 === attribute) {
						setShowAttribute(true);
						setGearValue(weaponStats.attribute1Value);
					} else {
						setShowAttribute(false);
					}
					break;
				case "Critical Hit Damage":
					// console.log(weapon.core2);
					if (weapon.core2 === attribute) {
						setShowAttribute(true);
						setGearValue(weaponStats.core2Value);
					} else if (weaponStats.attribute1 === attribute) {
						setShowAttribute(true);
						setGearValue(weaponStats.attribute1Value);
					} else {
						setShowAttribute(false);
					}
					break;
				case "Headshot Damage":
					// console.log(weapon.core2);
					if (weapon.core2 === attribute) {
						setShowHSDAttribute(true);
						setWeaponHSDLabel(weapon.name);
						setShowAttribute(true);
						setGearValue(weaponStats.core2Value);
					} else if (weaponStats.attribute1 === attribute) {
						setShowHSDAttribute(true);
						setWeaponHSDLabel(weapon.name);
						setWeaponHSDDesc(attribute);
						setShowAttribute(true);
						setGearValue(weaponStats.attribute1Value);
					} else {
						setShowHSDAttribute(false);
						setShowAttribute(false);
					}
					break;
			}
		}

		switch (weaponSlot.toLowerCase()) {
			case "primary":
				checkWeapon(ctxApp.primaryWeapon, ctxApp.primaryStats);
				setGearSlotLabel(ctxApp.primaryWeapon.name);
				setGearSlotDesc(ctxApp.primaryWeapon.core1);
				setWeaponHSDValue(ctxApp.primaryWeapon.hsd);
				break;
			case "secondary":
				// console.log(ctxApp.secondaryWeapon, ctxApp.secondaryStats);
				checkWeapon(ctxApp.secondaryWeapon, ctxApp.secondaryStats);
				setWeaponHSDValue(ctxApp.secondaryWeapon.hsd);
				break;
			case "pistol":
				checkWeapon(ctxApp.pistolWeapon, ctxApp.pistolStats);
				setWeaponHSDValue(ctxApp.pistolWeapon.hsd);
				break;
		}

		// updateParentShowHeader();
	}, [
		attribute,
		ctxApp.pistolStats,
		ctxApp.pistolWeapon,
		ctxApp.primaryStats,
		ctxApp.primaryWeapon,
		ctxApp.secondaryStats,
		ctxApp.secondaryWeapon,
		showAttribute,
		showHeader,
		weaponSlot,
	]);

	return (
		<>
			{showHSDAttribute && (
				<IonItem key={"weaponHSD"}>
					<IonBadge slot="start">{weaponHSDValue}%</IonBadge>
					<IonLabel>
						<h3>{weaponHSDLabel}</h3>
						<p>{weaponHSDDesc}</p>
					</IonLabel>
					<IonBadge slot="end">
						{Math.round(
							weaponBaseDamage * (weaponHSDValue / 100)
						).toLocaleString("en-US")}
					</IonBadge>
				</IonItem>
			)}
			{showAttribute && (
				<IonItem key={""}>
					{showStartBadge && (
						<IonBadge slot="start">{gearValue}%</IonBadge>
					)}
					<IonLabel>
						<h3>{gearSlotLabel}</h3>
						<p>{gearSlotDesc}</p>
					</IonLabel>
					{showStartBadge && (
						<IonBadge slot="end">
							{Math.round(
								weaponBaseDamage * (gearValue! / 100)
							).toLocaleString("en-US")}
						</IonBadge>
					)}
					{!showStartBadge && (
						<IonBadge slot="end">{gearValue}%</IonBadge>
					)}
				</IonItem>
			)}
		</>
	);
};

export default StatsWeapon;
