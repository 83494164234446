import Gear from "../models/gear";

const GearData: Gear[] = [
	{
		_id: {
			oid: "625ece2d26c312c72a8e7064",
		},
		slot: "vest",
		quality: "Named",
		name: "Zero F's",
		type: "N",
		brand: "Badger Tuff",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "Perfectly Unbreakable",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e705e",
		},
		slot: "vest",
		quality: "High End",
		name: "5.11 Tactical",
		type: "A",
		brand: "5.11 Tactical",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7061",
		},
		slot: "vest",
		quality: "Named",
		name: "Pristine Example",
		type: "N",
		brand: "Airaldi Holdings",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "Perfect Focus",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e705f",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Aces & Eights",
		type: "S",
		brand: "Aces & Eights",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "No Limit",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7063",
		},
		slot: "vest",
		quality: "High End",
		name: "Badger Tuff",
		type: "A",
		brand: "Badger Tuff",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7068",
		},
		slot: "vest",
		quality: "High End",
		name: "Fenris Group AB",
		type: "A",
		brand: "Fenris Group AB",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7069",
		},
		slot: "vest",
		quality: "Named",
		name: "Ferocious Calm",
		type: "N",
		brand: "Fenris Group AB",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "Perfect Overwatch",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7073",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Hard Wired",
		type: "S",
		brand: "Hard Wired",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "Positive Reinforcement",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7075",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Negotiator's Dilemma",
		type: "S",
		brand: "Negotiator's Dilemma",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "Target Rich Environment",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7078",
		},
		slot: "vest",
		quality: "High End",
		name: "Petrov Defense Group",
		type: "A",
		brand: "Petrov Defense Group",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7079",
		},
		slot: "vest",
		quality: "Named",
		name: "Vedmedytsya Vest",
		type: "N",
		brand: "Petrov Defense Group",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "Perfect Braced",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e707b",
		},
		slot: "vest",
		quality: "Named",
		name: "The Sacrifice",
		type: "N",
		brand: "Providence Defense",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "Perfect Glass Cannon",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e707c",
		},
		slot: "vest",
		quality: "High End",
		name: "Richter & Kaiser GmbH",
		type: "A",
		brand: "Richter & Kaiser GmbH",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7080",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Tip of the Spear",
		type: "S",
		brand: "Tip of the Spear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "Specialized Destruction",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7081",
		},
		slot: "vest",
		quality: "Gearset",
		name: "True Patriot",
		type: "S",
		brand: "True Patriot",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "D",
		talent: "Waving the Flag",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7084",
		},
		slot: "vest",
		quality: "High End",
		name: "Improvised Body Armor",
		type: "A",
		brand: "Crafted",
		core: "A",
		attribute1: "A",
		attribute2: "A",
		mod: "A",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e708c",
		},
		slot: "vest",
		quality: "Exotic",
		name: "Ridgeway's Pride",
		type: "E",
		brand: "Exotic",
		core: "Weapon Damage",
		attribute1: "Critical Hit Chance",
		attribute2: "Critical Hit Damage",
		mod: "O",
		talent: "Bleeding Edge",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e708e",
		},
		slot: "vest",
		quality: "High End",
		name: "Belstone Armory",
		type: "A",
		brand: "Belstone Armory",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7090",
		},
		slot: "mask",
		quality: "High End",
		name: "5.11 Tactical",
		type: "A",
		brand: "5.11 Tactical",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7099",
		},
		slot: "mask",
		quality: "High End",
		name: "Fenris Group AB",
		type: "A",
		brand: "Fenris Group AB",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e709f",
		},
		slot: "mask",
		quality: "High End",
		name: "Grupo Sombra S.A.",
		type: "A",
		brand: "Grupo Sombra S.A.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70a3",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Negotiator's Dilemma",
		type: "S",
		brand: "Negotiator's Dilemma",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70a5",
		},
		slot: "mask",
		quality: "High End",
		name: "Overlord Armaments",
		type: "A",
		brand: "Overlord Armaments",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70a8",
		},
		slot: "mask",
		quality: "High End",
		name: "Richter & Kaiser GmbH",
		type: "A",
		brand: "Richter & Kaiser GmbH",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70a9",
		},
		slot: "mask",
		quality: "High End",
		name: "Sokolov Concern",
		type: "A",
		brand: "Sokolov Concern",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ac",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Tip of the Spear",
		type: "S",
		brand: "Tip of the Spear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70b7",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Rigger",
		type: "S",
		brand: "Rigger",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ba",
		},
		slot: "kneepads",
		quality: "Exotic",
		name: "Sawyer's Kneepads",
		type: "E",
		brand: "Exotic",
		core: "Armor",
		attribute1: "Explosive Resistance",
		attribute2: "Health",
		mod: "",
		talent: "Stand Your Ground",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70bc",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Aces & Eights",
		type: "S",
		brand: "Aces & Eights",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70bd",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Airaldi Holdings",
		type: "A",
		brand: "Airaldi Holdings",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70bf",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Badger Tuff",
		type: "A",
		brand: "Badger Tuff",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70c3",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Fenris Group AB",
		type: "A",
		brand: "Fenris Group AB",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70c4",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Foundry Bulwark",
		type: "S",
		brand: "Foundry Bulwark",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70c8",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Grupo Sombra S.A.",
		type: "A",
		brand: "Grupo Sombra S.A.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70c9",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Hana-U Corporation",
		type: "A",
		brand: "Hana-U Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ca",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Hard Wired",
		type: "S",
		brand: "Hard Wired",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ce",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Ongoing Directive",
		type: "S",
		brand: "Ongoing Directive",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70d1",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Petrov Defense Group",
		type: "A",
		brand: "Petrov Defense Group",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70d4",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Sokolov Concern",
		type: "A",
		brand: "Sokolov Concern",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70d7",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Tip of the Spear",
		type: "S",
		brand: "Tip of the Spear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70de",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Empress International",
		type: "A",
		brand: "Empress International",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70e0",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Rigger",
		type: "S",
		brand: "Rigger",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70e7",
		},
		slot: "holster",
		quality: "High End",
		name: "Badger Tuff",
		type: "A",
		brand: "Badger Tuff",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70e9",
		},
		slot: "holster",
		quality: "High End",
		name: "Ceska Vyroba s.r.o.",
		type: "A",
		brand: "Ceska Vyroba s.r.o.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ea",
		},
		slot: "holster",
		quality: "High End",
		name: "China Light Industries Corporation",
		type: "A",
		brand: "China Light Industries Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70eb",
		},
		slot: "holster",
		quality: "Named",
		name: "Claws Out",
		type: "N",
		brand: "Wyvern Wear",
		core: "Skill Tier",
		attribute1: "Melee Damage",
		attribute2: "Pistol Damage",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ec",
		},
		slot: "holster",
		quality: "Exotic",
		name: "Dodge City Gunslinger's Holster",
		type: "E",
		brand: "Exotic",
		core: "Weapon Damage",
		attribute1: "Weapon Handling",
		attribute2: "Headshot Damage",
		mod: "",
		talent: "Quick Draw",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ed",
		},
		slot: "holster",
		quality: "High End",
		name: "Douglas & Harding",
		type: "A",
		brand: "Douglas & Harding",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ee",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Eclipse Protocol",
		type: "S",
		brand: "Eclipse Protocol",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70f1",
		},
		slot: "holster",
		quality: "Named",
		name: "Forge",
		type: "N",
		brand: "Richter & Kaiser GmbH",
		core: "Skill Tier",
		attribute1: "Shield Health",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70f2",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Foundry Bulwark",
		type: "S",
		brand: "Foundry Bulwark",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70fa",
		},
		slot: "holster",
		quality: "Exotic",
		name: "Imperial Dynasty",
		type: "E",
		brand: "Exotic",
		core: "Skill Tier",
		attribute1: "Status Effects",
		attribute2: "Hazard Protection",
		mod: "",
		talent: "Dragon's Glare",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70fd",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Negotiator's Dilemma",
		type: "S",
		brand: "Negotiator's Dilemma",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7100",
		},
		slot: "holster",
		quality: "High End",
		name: "Petrov Defense Group",
		type: "A",
		brand: "Petrov Defense Group",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e710c",
		},
		slot: "holster",
		quality: "High End",
		name: "Yaahl Gear",
		type: "A",
		brand: "Yaahl Gear",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e710d",
		},
		slot: "gloves",
		quality: "Exotic",
		name: "BTSU Gloves",
		type: "E",
		brand: "Exotic",
		core: "Skill Tier",
		attribute1: "Skill Haste",
		attribute2: "Repair Skills",
		mod: "",
		talent: "Transference Overclock",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7116",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Tip of the Spear",
		type: "S",
		brand: "Tip of the Spear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7119",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Hunter's Fury",
		type: "S",
		brand: "Hunter's Fury",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e711c",
		},
		slot: "gloves",
		quality: "High End",
		name: "Airaldi Holdings",
		type: "A",
		brand: "Airaldi Holdings",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e711e",
		},
		slot: "gloves",
		quality: "High End",
		name: "Badger Tuff",
		type: "A",
		brand: "Badger Tuff",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7123",
		},
		slot: "gloves",
		quality: "High End",
		name: "Gila Guard",
		type: "A",
		brand: "Gila Guard",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7124",
		},
		slot: "gloves",
		quality: "High End",
		name: "Golan Gear Ltd",
		type: "A",
		brand: "Golan Gear Ltd",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7128",
		},
		slot: "gloves",
		quality: "High End",
		name: "Overlord Armaments",
		type: "A",
		brand: "Overlord Armaments",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e712e",
		},
		slot: "gloves",
		quality: "High End",
		name: "Yaahl Gear",
		type: "A",
		brand: "Yaahl Gear",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7130",
		},
		slot: "gloves",
		quality: "High End",
		name: "Walker, Harris & Co.",
		type: "A",
		brand: "Walker, Harris & Co.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7134",
		},
		slot: "gloves",
		quality: "Named",
		name: "Motherly Love",
		type: "N",
		brand: "Alps Summit Armaments",
		core: "Skill Tier",
		attribute1: "Skill Health",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7135",
		},
		slot: "gloves",
		quality: "Named",
		name: "Contractor's Gloves",
		type: "N",
		brand: "Petrov Defense Group",
		core: "Weapon Damage",
		attribute1: "Damage to Armor",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7062",
		},
		slot: "vest",
		quality: "High End",
		name: "Alps Summit Armaments",
		type: "A",
		brand: "Alps Summit Armaments",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7067",
		},
		slot: "vest",
		quality: "High End",
		name: "Douglas & Harding",
		type: "A",
		brand: "Douglas & Harding",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7074",
		},
		slot: "vest",
		quality: "High End",
		name: "Murakami Industries",
		type: "A",
		brand: "Murakami Industries",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e707a",
		},
		slot: "vest",
		quality: "High End",
		name: "Providence Defense",
		type: "A",
		brand: "Providence Defense",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e707f",
		},
		slot: "vest",
		quality: "Gearset",
		name: "System Corruption",
		type: "S",
		brand: "System Corruption",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "D",
		talent: "Compiler Optimization",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7082",
		},
		slot: "vest",
		quality: "High End",
		name: "Wyvern Wear",
		type: "A",
		brand: "Wyvern Wear",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7083",
		},
		slot: "vest",
		quality: "High End",
		name: "Yaahl Gear",
		type: "A",
		brand: "Yaahl Gear",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7085",
		},
		slot: "vest",
		quality: "High End",
		name: "Walker, Harris & Co.",
		type: "A",
		brand: "Walker, Harris & Co.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7088",
		},
		slot: "vest",
		quality: "High End",
		name: "Empress International",
		type: "A",
		brand: "Empress International",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e708a",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Rigger",
		type: "S",
		brand: "Rigger",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "Best Buds",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7093",
		},
		slot: "mask",
		quality: "High End",
		name: "Alps Summit Armaments",
		type: "A",
		brand: "Alps Summit Armaments",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70a2",
		},
		slot: "mask",
		quality: "High End",
		name: "Murakami Industries",
		type: "A",
		brand: "Murakami Industries",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70a4",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Ongoing Directive",
		type: "S",
		brand: "Ongoing Directive",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70a6",
		},
		slot: "mask",
		quality: "High End",
		name: "Petrov Defense Group",
		type: "A",
		brand: "Petrov Defense Group",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ab",
		},
		slot: "mask",
		quality: "Gearset",
		name: "System Corruption",
		type: "S",
		brand: "System Corruption",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70af",
		},
		slot: "mask",
		quality: "High End",
		name: "Yaahl Gear",
		type: "A",
		brand: "Yaahl Gear",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70b2",
		},
		slot: "mask",
		quality: "Exotic",
		name: "Vile",
		type: "E",
		brand: "Exotic",
		core: "Skill Tier",
		attribute1: "Status Effects",
		attribute2: "Hazard Protection",
		mod: "U",
		talent: "Toxic Delivery",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70b3",
		},
		slot: "mask",
		quality: "High End",
		name: "Walker, Harris & Co.",
		type: "A",
		brand: "Walker, Harris & Co.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70b8",
		},
		slot: "mask",
		quality: "High End",
		name: "Belstone Armory",
		type: "A",
		brand: "Belstone Armory",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70bb",
		},
		slot: "kneepads",
		quality: "High End",
		name: "5.11 Tactical",
		type: "A",
		brand: "5.11 Tactical",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70be",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Alps Summit Armaments",
		type: "A",
		brand: "Alps Summit Armaments",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70c2",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Douglas & Harding",
		type: "A",
		brand: "Douglas & Harding",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70c7",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Golan Gear Ltd",
		type: "A",
		brand: "Golan Gear Ltd",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70cc",
		},
		slot: "kneepads",
		quality: "Named",
		name: "Emperor's Guard",
		type: "N",
		brand: "Murakami Industries",
		core: "Skill Tier",
		attribute1: "Armor Regeneration %",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70cd",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Negotiator's Dilemma",
		type: "S",
		brand: "Negotiator's Dilemma",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70d2",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Providence Defense",
		type: "A",
		brand: "Providence Defense",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70d6",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "System Corruption",
		type: "S",
		brand: "System Corruption",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70d8",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "True Patriot",
		type: "S",
		brand: "True Patriot",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70da",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Yaahl Gear",
		type: "A",
		brand: "Yaahl Gear",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70db",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Improvised Kneepads",
		type: "A",
		brand: "Crafted",
		core: "A",
		attribute1: "A",
		attribute2: "A",
		mod: "A",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70e1",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Belstone Armory",
		type: "A",
		brand: "Belstone Armory",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70e3",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Aces & Eights",
		type: "S",
		brand: "Aces & Eights",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70e8",
		},
		slot: "holster",
		quality: "High End",
		name: "Belstone Armory",
		type: "A",
		brand: "Belstone Armory",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70f3",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Future Initiative",
		type: "S",
		brand: "Future Initiative",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70f5",
		},
		slot: "holster",
		quality: "High End",
		name: "Golan Gear Ltd",
		type: "A",
		brand: "Golan Gear Ltd",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70f7",
		},
		slot: "holster",
		quality: "High End",
		name: "Hana-U Corporation",
		type: "A",
		brand: "Hana-U Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70fb",
		},
		slot: "holster",
		quality: "High End",
		name: "Improvised Holster",
		type: "A",
		brand: "Crafted",
		core: "A",
		attribute1: "A",
		attribute2: "A",
		mod: "A",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70fe",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Ongoing Directive",
		type: "S",
		brand: "Ongoing Directive",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7101",
		},
		slot: "holster",
		quality: "High End",
		name: "Providence Defense",
		type: "A",
		brand: "Providence Defense",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7102",
		},
		slot: "holster",
		quality: "High End",
		name: "Richter & Kaiser GmbH",
		type: "A",
		brand: "Richter & Kaiser GmbH",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7103",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Rigger",
		type: "S",
		brand: "Rigger",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7104",
		},
		slot: "holster",
		quality: "High End",
		name: "Sokolov Concern",
		type: "A",
		brand: "Sokolov Concern",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7108",
		},
		slot: "holster",
		quality: "Gearset",
		name: "True Patriot",
		type: "S",
		brand: "True Patriot",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7109",
		},
		slot: "holster",
		quality: "High End",
		name: "Walker, Harris & Co.",
		type: "A",
		brand: "Walker, Harris & Co.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e710b",
		},
		slot: "holster",
		quality: "High End",
		name: "Wyvern Wear",
		type: "A",
		brand: "Wyvern Wear",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7114",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Striker's Battlegear",
		type: "S",
		brand: "Striker's Battlegear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7118",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Eclipse Protocol",
		type: "S",
		brand: "Eclipse Protocol",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e711d",
		},
		slot: "gloves",
		quality: "High End",
		name: "Alps Summit Armaments",
		type: "A",
		brand: "Alps Summit Armaments",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7120",
		},
		slot: "gloves",
		quality: "High End",
		name: "China Light Industries Corporation",
		type: "A",
		brand: "China Light Industries Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e712b",
		},
		slot: "gloves",
		quality: "High End",
		name: "Richter & Kaiser GmbH",
		type: "A",
		brand: "Richter & Kaiser GmbH",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e712c",
		},
		slot: "gloves",
		quality: "High End",
		name: "Sokolov Concern",
		type: "A",
		brand: "Sokolov Concern",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e712d",
		},
		slot: "gloves",
		quality: "High End",
		name: "Wyvern Wear",
		type: "A",
		brand: "Wyvern Wear",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e712f",
		},
		slot: "gloves",
		quality: "High End",
		name: "Improvised Gloves",
		type: "A",
		brand: "Crafted",
		core: "A",
		attribute1: "A",
		attribute2: "A",
		mod: "A",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7131",
		},
		slot: "gloves",
		quality: "High End",
		name: "Empress International",
		type: "A",
		brand: "Empress International",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7132",
		},
		slot: "gloves",
		quality: "High End",
		name: "Belstone Armory",
		type: "A",
		brand: "Belstone Armory",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7133",
		},
		slot: "gloves",
		quality: "Named",
		name: "Deathgrips",
		type: "N",
		brand: "5.11 Tactical",
		core: "Armor",
		attribute1: "Armor on Kill %",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7139",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Aces & Eights",
		type: "S",
		brand: "Aces & Eights",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "Poker Face",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e713a",
		},
		slot: "backpack",
		quality: "High End",
		name: "Airaldi Holdings",
		type: "A",
		brand: "Airaldi Holdings",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e713b",
		},
		slot: "backpack",
		quality: "High End",
		name: "Alps Summit Armaments",
		type: "A",
		brand: "Alps Summit Armaments",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7143",
		},
		slot: "backpack",
		quality: "High End",
		name: "Fenris Group AB",
		type: "A",
		brand: "Fenris Group AB",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e714e",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Negotiator's Dilemma",
		type: "S",
		brand: "Negotiator's Dilemma",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "Critical Measures",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7150",
		},
		slot: "backpack",
		quality: "High End",
		name: "Overlord Armaments",
		type: "A",
		brand: "Overlord Armaments",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7151",
		},
		slot: "backpack",
		quality: "High End",
		name: "Petrov Defense Group",
		type: "A",
		brand: "Petrov Defense Group",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7164",
		},
		slot: "backpack",
		quality: "Exotic",
		name: "Memento",
		type: "E",
		brand: "Exotic",
		core: "Weapon Damage",
		attribute1: "",
		attribute2: "",
		mod: "U",
		talent: "Kill Confirmed",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7137",
		},
		slot: "backpack",
		quality: "Exotic",
		name: "Acosta's Go-Bag",
		type: "E",
		brand: "Exotic",
		core: "Skill Tier",
		attribute1: "Skill Haste",
		attribute2: "Skill Damage",
		mod: "U",
		talent: "One in Hand...",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e713c",
		},
		slot: "backpack",
		quality: "Named",
		name: "Percussive Maintenance",
		type: "N",
		brand: "Alps Summit Armaments",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "Perfect Tech Support",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e713f",
		},
		slot: "backpack",
		quality: "Named",
		name: "Devil's Due",
		type: "N",
		brand: "Ceska Vyroba s.r.o.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "Perfect Clutch",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7141",
		},
		slot: "backpack",
		quality: "Named",
		name: "Strategic Alignment",
		type: "N",
		brand: "China Light Industries Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "Perfect Shock and Awe",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7145",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Future Initiative",
		type: "S",
		brand: "Future Initiative",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "Strategic Combat Support",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7149",
		},
		slot: "backpack",
		quality: "High End",
		name: "Grupo Sombra S.A.",
		type: "A",
		brand: "Grupo Sombra S.A.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7154",
		},
		slot: "backpack",
		quality: "High End",
		name: "Richter & Kaiser GmbH",
		type: "A",
		brand: "Richter & Kaiser GmbH",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e715a",
		},
		slot: "backpack",
		quality: "High End",
		name: "Wyvern Wear",
		type: "A",
		brand: "Wyvern Wear",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e715c",
		},
		slot: "backpack",
		quality: "High End",
		name: "Improvised Backpack",
		type: "A",
		brand: "Crafted",
		core: "A",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e715d",
		},
		slot: "backpack",
		quality: "High End",
		name: "Walker, Harris & Co.",
		type: "A",
		brand: "Walker, Harris & Co.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e715e",
		},
		slot: "backpack",
		quality: "Named",
		name: "Matador",
		type: "N",
		brand: "Walker, Harris & Co.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "Perfect Adrenaline Rush",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7160",
		},
		slot: "backpack",
		quality: "High End",
		name: "Empress International",
		type: "A",
		brand: "Empress International",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7163",
		},
		slot: "backpack",
		quality: "Named",
		name: "Battery Pack",
		type: "N",
		brand: "Empress International",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "Perfectly Calculated",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7166",
		},
		slot: "backpack",
		quality: "Named",
		name: "Liquid Engineer",
		type: "N",
		brand: "Belstone Armory",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "Perfect Bloodsucker",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7165",
		},
		slot: "backpack",
		quality: "High End",
		name: "Belstone Armory",
		type: "A",
		brand: "Belstone Armory",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e705d",
		},
		slot: "vest",
		quality: "Exotic",
		name: "Tardigrade Armor System",
		type: "E",
		brand: "Exotic",
		core: "Armor",
		attribute1: "Explosive Resistance",
		attribute2: "Armor Regeneration",
		mod: "D",
		talent: "Ablative Nano-Plating",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7060",
		},
		slot: "vest",
		quality: "High End",
		name: "Airaldi Holdings",
		type: "A",
		brand: "Airaldi Holdings",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e706c",
		},
		slot: "vest",
		quality: "High End",
		name: "Gila Guard",
		type: "A",
		brand: "Gila Guard",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e706d",
		},
		slot: "vest",
		quality: "Named",
		name: "Pointman",
		type: "N",
		brand: "Gila Guard",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "Perfect Vanguard",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e706e",
		},
		slot: "vest",
		quality: "High End",
		name: "Golan Gear Ltd",
		type: "A",
		brand: "Golan Gear Ltd",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7070",
		},
		slot: "vest",
		quality: "High End",
		name: "Grupo Sombra S.A.",
		type: "A",
		brand: "Grupo Sombra S.A.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7071",
		},
		slot: "vest",
		quality: "Named",
		name: "Door-Kicker's Knock",
		type: "N",
		brand: "Grupo Sombra S.A.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "Perfect Spark",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7072",
		},
		slot: "vest",
		quality: "High End",
		name: "Hana-U Corporation",
		type: "A",
		brand: "Hana-U Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7076",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Ongoing Directive",
		type: "S",
		brand: "Ongoing Directive",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "Parabellum Rounds",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e707e",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Striker's Battlegear",
		type: "S",
		brand: "Striker's Battlegear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "Press the Advantage",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7087",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Eclipse Protocol",
		type: "S",
		brand: "Eclipse Protocol",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "Proliferation",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7089",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Hunter's Fury",
		type: "S",
		brand: "Hunter's Fury",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "Endless Hunger",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e708d",
		},
		slot: "vest",
		quality: "Named",
		name: "Everyday Carrier",
		type: "N",
		brand: "Belstone Armory",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "Perfectly Efficient",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7092",
		},
		slot: "mask",
		quality: "High End",
		name: "Airaldi Holdings",
		type: "A",
		brand: "Airaldi Holdings",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7097",
		},
		slot: "mask",
		quality: "High End",
		name: "Douglas & Harding",
		type: "A",
		brand: "Douglas & Harding",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e709a",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Foundry Bulwark",
		type: "S",
		brand: "Foundry Bulwark",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "D",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e709d",
		},
		slot: "mask",
		quality: "Named",
		name: "Nightwatcher",
		type: "N",
		brand: "Gila Guard",
		core: "Armor",
		attribute1: "Scanner Pulse Haste",
		attribute2: "A",
		mod: "D",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70a0",
		},
		slot: "mask",
		quality: "High End",
		name: "Hana-U Corporation",
		type: "A",
		brand: "Hana-U Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70a1",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Hard Wired",
		type: "S",
		brand: "Hard Wired",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70aa",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Striker's Battlegear",
		type: "S",
		brand: "Striker's Battlegear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ae",
		},
		slot: "mask",
		quality: "High End",
		name: "Wyvern Wear",
		type: "A",
		brand: "Wyvern Wear",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70b5",
		},
		slot: "mask",
		quality: "High End",
		name: "Empress International",
		type: "A",
		brand: "Empress International",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70b6",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Hunter's Fury",
		type: "S",
		brand: "Hunter's Fury",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70cb",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Murakami Industries",
		type: "A",
		brand: "Murakami Industries",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70d0",
		},
		slot: "kneepads",
		quality: "Named",
		name: "Fox's Prayer",
		type: "N",
		brand: "Overlord Armaments",
		core: "Weapon Damage",
		attribute1: "Damage to TOC",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70d3",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Richter & Kaiser GmbH",
		type: "A",
		brand: "Richter & Kaiser GmbH",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70d9",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Wyvern Wear",
		type: "A",
		brand: "Wyvern Wear",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70dd",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Eclipse Protocol",
		type: "S",
		brand: "Eclipse Protocol",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70df",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Hunter's Fury",
		type: "S",
		brand: "Hunter's Fury",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70e2",
		},
		slot: "holster",
		quality: "High End",
		name: "5.11 Tactical",
		type: "A",
		brand: "5.11 Tactical",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ef",
		},
		slot: "holster",
		quality: "High End",
		name: "Empress International",
		type: "A",
		brand: "Empress International",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70f0",
		},
		slot: "holster",
		quality: "High End",
		name: "Fenris Group AB",
		type: "A",
		brand: "Fenris Group AB",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70f4",
		},
		slot: "holster",
		quality: "High End",
		name: "Gila Guard",
		type: "A",
		brand: "Gila Guard",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70f8",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Hard Wired",
		type: "S",
		brand: "Hard Wired",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e710f",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Foundry Bulwark",
		type: "S",
		brand: "Foundry Bulwark",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7110",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Future Initiative",
		type: "S",
		brand: "Future Initiative",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7112",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Negotiator's Dilemma",
		type: "S",
		brand: "Negotiator's Dilemma",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e711b",
		},
		slot: "gloves",
		quality: "High End",
		name: "5.11 Tactical",
		type: "A",
		brand: "5.11 Tactical",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7122",
		},
		slot: "gloves",
		quality: "High End",
		name: "Fenris Group AB",
		type: "A",
		brand: "Fenris Group AB",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7127",
		},
		slot: "gloves",
		quality: "High End",
		name: "Murakami Industries",
		type: "A",
		brand: "Murakami Industries",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7138",
		},
		slot: "backpack",
		quality: "High End",
		name: "5.11 Tactical",
		type: "A",
		brand: "5.11 Tactical",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e713e",
		},
		slot: "backpack",
		quality: "High End",
		name: "Ceska Vyroba s.r.o.",
		type: "A",
		brand: "Ceska Vyroba s.r.o.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7142",
		},
		slot: "backpack",
		quality: "High End",
		name: "Douglas & Harding",
		type: "A",
		brand: "Douglas & Harding",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7146",
		},
		slot: "backpack",
		quality: "High End",
		name: "Gila Guard",
		type: "A",
		brand: "Gila Guard",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e714b",
		},
		slot: "backpack",
		quality: "Named",
		name: "Force Multiplier",
		type: "N",
		brand: "Hana-U Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "Perfect Combined Arms",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7152",
		},
		slot: "backpack",
		quality: "High End",
		name: "Providence Defense",
		type: "A",
		brand: "Providence Defense",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7156",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Striker's Battlegear",
		type: "S",
		brand: "Striker's Battlegear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "Risk Management",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e715f",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Eclipse Protocol",
		type: "S",
		brand: "Eclipse Protocol",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "Symptom Aggravator",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7162",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Rigger",
		type: "S",
		brand: "Rigger",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "Complete Uptime",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7065",
		},
		slot: "vest",
		quality: "High End",
		name: "Ceska Vyroba s.r.o.",
		type: "A",
		brand: "Ceska Vyroba s.r.o.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7066",
		},
		slot: "vest",
		quality: "High End",
		name: "China Light Industries Corporation",
		type: "A",
		brand: "China Light Industries Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e706a",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Foundry Bulwark",
		type: "S",
		brand: "Foundry Bulwark",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "D",
		talent: "Improved Materials",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e706b",
		},
		slot: "vest",
		quality: "Gearset",
		name: "Future Initiative",
		type: "S",
		brand: "Future Initiative",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "Tactical Superiority",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e706f",
		},
		slot: "vest",
		quality: "Named",
		name: "Hunter-Killer",
		type: "N",
		brand: "Golan Gear Ltd",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "Perfect Intimidate",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7077",
		},
		slot: "vest",
		quality: "High End",
		name: "Overlord Armaments",
		type: "A",
		brand: "Overlord Armaments",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e707d",
		},
		slot: "vest",
		quality: "High End",
		name: "Sokolov Concern",
		type: "A",
		brand: "Sokolov Concern",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7086",
		},
		slot: "vest",
		quality: "Named",
		name: "Chainkiller",
		type: "N",
		brand: "Walker, Harris & Co.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "Perfect Headhunter",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e708b",
		},
		slot: "vest",
		quality: "Named",
		name: "Caesar's Guard",
		type: "N",
		brand: "Empress International",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "Perfectly Skilled",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e708f",
		},
		slot: "mask",
		quality: "Exotic",
		name: "Coyote's Mask",
		type: "E",
		brand: "Exotic",
		core: "Weapon Damage",
		attribute1: "Critical Hit Chance",
		attribute2: "Critical Hit Damage",
		mod: "O",
		talent: "Pack Instincts",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7091",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Aces & Eights",
		type: "S",
		brand: "Aces & Eights",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7094",
		},
		slot: "mask",
		quality: "High End",
		name: "Badger Tuff",
		type: "A",
		brand: "Badger Tuff",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7095",
		},
		slot: "mask",
		quality: "High End",
		name: "Ceska Vyroba s.r.o.",
		type: "A",
		brand: "Ceska Vyroba s.r.o.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7096",
		},
		slot: "mask",
		quality: "High End",
		name: "China Light Industries Corporation",
		type: "A",
		brand: "China Light Industries Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7098",
		},
		slot: "mask",
		quality: "Named",
		name: "Punch Drunk",
		type: "N",
		brand: "Douglas & Harding",
		core: "Weapon Damage",
		attribute1: "Headshot Damage",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e709b",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Future Initiative",
		type: "S",
		brand: "Future Initiative",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e709c",
		},
		slot: "mask",
		quality: "High End",
		name: "Gila Guard",
		type: "A",
		brand: "Gila Guard",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "D",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e709e",
		},
		slot: "mask",
		quality: "High End",
		name: "Golan Gear Ltd",
		type: "A",
		brand: "Golan Gear Ltd",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70a7",
		},
		slot: "mask",
		quality: "High End",
		name: "Providence Defense",
		type: "A",
		brand: "Providence Defense",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ad",
		},
		slot: "mask",
		quality: "Gearset",
		name: "True Patriot",
		type: "S",
		brand: "True Patriot",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "D",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70b0",
		},
		slot: "mask",
		quality: "Named",
		name: "The Hollow Man",
		type: "N",
		brand: "Yaahl Gear",
		core: "Armor",
		attribute1: "Health Damage",
		attribute2: "A",
		mod: "O",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70b1",
		},
		slot: "mask",
		quality: "High End",
		name: "Improvised Mask",
		type: "A",
		brand: "Crafted",
		core: "A",
		attribute1: "A",
		attribute2: "A",
		mod: "A",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70b4",
		},
		slot: "mask",
		quality: "Gearset",
		name: "Eclipse Protocol",
		type: "S",
		brand: "Eclipse Protocol",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70b9",
		},
		slot: "kneepads",
		quality: "Exotic",
		name: "NinjaBike Messenger Kneepads",
		type: "E",
		brand: "Exotic",
		core: "Weapon Damage",
		attribute1: "Critical Hit Chance",
		attribute2: "Critical Hit Damage",
		mod: "",
		talent: "Parkour!",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70c0",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Ceska Vyroba s.r.o.",
		type: "A",
		brand: "Ceska Vyroba s.r.o.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70c1",
		},
		slot: "kneepads",
		quality: "High End",
		name: "China Light Industries Corporation",
		type: "A",
		brand: "China Light Industries Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70c5",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Future Initiative",
		type: "S",
		brand: "Future Initiative",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70c6",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Gila Guard",
		type: "A",
		brand: "Gila Guard",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70cf",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Overlord Armaments",
		type: "A",
		brand: "Overlord Armaments",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70d5",
		},
		slot: "kneepads",
		quality: "Gearset",
		name: "Striker's Battlegear",
		type: "S",
		brand: "Striker's Battlegear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70dc",
		},
		slot: "kneepads",
		quality: "High End",
		name: "Walker, Harris & Co.",
		type: "A",
		brand: "Walker, Harris & Co.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70e4",
		},
		slot: "holster",
		quality: "High End",
		name: "Airaldi Holdings",
		type: "A",
		brand: "Airaldi Holdings",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70e5",
		},
		slot: "holster",
		quality: "High End",
		name: "Alps Summit Armaments",
		type: "A",
		brand: "Alps Summit Armaments",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70e6",
		},
		slot: "holster",
		quality: "Named",
		name: "Ammo Dump",
		type: "N",
		brand: "Badger Tuff",
		core: "Armor",
		attribute1: "Ammo Capacity",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70f6",
		},
		slot: "holster",
		quality: "High End",
		name: "Grupo Sombra S.A.",
		type: "A",
		brand: "Grupo Sombra S.A.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70f9",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Hunter's Fury",
		type: "S",
		brand: "Hunter's Fury",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70fc",
		},
		slot: "holster",
		quality: "High End",
		name: "Murakami Industries",
		type: "A",
		brand: "Murakami Industries",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e70ff",
		},
		slot: "holster",
		quality: "High End",
		name: "Overlord Armaments",
		type: "A",
		brand: "Overlord Armaments",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7105",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Striker's Battlegear",
		type: "S",
		brand: "Striker's Battlegear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7106",
		},
		slot: "holster",
		quality: "Gearset",
		name: "System Corruption",
		type: "S",
		brand: "System Corruption",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7107",
		},
		slot: "holster",
		quality: "Gearset",
		name: "Tip of the Spear",
		type: "S",
		brand: "Tip of the Spear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e710a",
		},
		slot: "holster",
		quality: "Exotic",
		name: "Waveform",
		type: "E",
		brand: "Exotic",
		core: "Skill Tier",
		attribute1: "Skill Damage",
		attribute2: "Skill Haste",
		mod: "",
		talent: "Alternating Current",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e710e",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Aces & Eights",
		type: "S",
		brand: "Aces & Eights",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7111",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Hard Wired",
		type: "S",
		brand: "Hard Wired",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7113",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Ongoing Directive",
		type: "S",
		brand: "Ongoing Directive",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7115",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "System Corruption",
		type: "S",
		brand: "System Corruption",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7117",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "True Patriot",
		type: "S",
		brand: "True Patriot",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e711a",
		},
		slot: "gloves",
		quality: "Gearset",
		name: "Rigger",
		type: "S",
		brand: "Rigger",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e711f",
		},
		slot: "gloves",
		quality: "High End",
		name: "Ceska Vyroba s.r.o.",
		type: "A",
		brand: "Ceska Vyroba s.r.o.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7121",
		},
		slot: "gloves",
		quality: "High End",
		name: "Douglas & Harding",
		type: "A",
		brand: "Douglas & Harding",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7125",
		},
		slot: "gloves",
		quality: "High End",
		name: "Grupo Sombra S.A.",
		type: "A",
		brand: "Grupo Sombra S.A.",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7126",
		},
		slot: "gloves",
		quality: "High End",
		name: "Hana-U Corporation",
		type: "A",
		brand: "Hana-U Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7129",
		},
		slot: "gloves",
		quality: "High End",
		name: "Petrov Defense Group",
		type: "A",
		brand: "Petrov Defense Group",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e712a",
		},
		slot: "gloves",
		quality: "High End",
		name: "Providence Defense",
		type: "A",
		brand: "Providence Defense",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7136",
		},
		slot: "gloves",
		quality: "Named",
		name: "Firm Handshake",
		type: "N",
		brand: "Sokolov Concern",
		core: "Weapon Damage",
		attribute1: "Status Effects",
		attribute2: "A",
		mod: "",
		talent: "",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e713d",
		},
		slot: "backpack",
		quality: "High End",
		name: "Badger Tuff",
		type: "A",
		brand: "Badger Tuff",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7140",
		},
		slot: "backpack",
		quality: "High End",
		name: "China Light Industries Corporation",
		type: "A",
		brand: "China Light Industries Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7144",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Foundry Bulwark",
		type: "S",
		brand: "Foundry Bulwark",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "D",
		talent: "Process Refinery",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7147",
		},
		slot: "backpack",
		quality: "High End",
		name: "Golan Gear Ltd",
		type: "A",
		brand: "Golan Gear Ltd",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7148",
		},
		slot: "backpack",
		quality: "Named",
		name: "Anarchist's Cookbook",
		type: "N",
		brand: "Golan Gear Ltd",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "Perfectly Wicked",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e714a",
		},
		slot: "backpack",
		quality: "High End",
		name: "Hana-U Corporation",
		type: "A",
		brand: "Hana-U Corporation",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e714c",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Hard Wired",
		type: "S",
		brand: "Hard Wired",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "Short Circuit",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e714d",
		},
		slot: "backpack",
		quality: "High End",
		name: "Murakami Industries",
		type: "A",
		brand: "Murakami Industries",
		core: "Skill Tier",
		attribute1: "A",
		attribute2: "A",
		mod: "U",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e714f",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Ongoing Directive",
		type: "S",
		brand: "Ongoing Directive",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "U",
		talent: "Trauma Specialist",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7153",
		},
		slot: "backpack",
		quality: "Named",
		name: "The Gift",
		type: "N",
		brand: "Providence Defense",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "Perfect Vigilance",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7155",
		},
		slot: "backpack",
		quality: "High End",
		name: "Sokolov Concern",
		type: "A",
		brand: "Sokolov Concern",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7157",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "System Corruption",
		type: "S",
		brand: "System Corruption",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "D",
		talent: "Multithreaded Execution",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7158",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Tip of the Spear",
		type: "S",
		brand: "Tip of the Spear",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "Crossbow Expert",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7159",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "True Patriot",
		type: "S",
		brand: "True Patriot",
		core: "Armor",
		attribute1: "A",
		attribute2: "",
		mod: "D",
		talent: "Patriotic Boost",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e715b",
		},
		slot: "backpack",
		quality: "High End",
		name: "Yaahl Gear",
		type: "A",
		brand: "Yaahl Gear",
		core: "Armor",
		attribute1: "A",
		attribute2: "A",
		mod: "O",
		talent: "A",
	},
	{
		_id: {
			oid: "625ece2d26c312c72a8e7161",
		},
		slot: "backpack",
		quality: "Gearset",
		name: "Hunter's Fury",
		type: "S",
		brand: "Hunter's Fury",
		core: "Weapon Damage",
		attribute1: "A",
		attribute2: "",
		mod: "O",
		talent: "Overwhelming Force",
	},
];

export default GearData;
